import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { updateJob } from '../../reducers/jobReducer';
import { setNotification } from '../../reducers/notificationReducer';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  arrowIcon: {
    fontSize: 22,
    color: theme.palette.black.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  content: {
    padding: 18,
    flex: 1,
  },
  inputGroup: {
    marginTop: 35,
    '& > div:first-child': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '16px',
      marginBottom: 8,
    },
    '& > div:last-child > input': {
      borderColor: '#757575',
    },
  },
  infoBox: {
    marginTop: 35,
    color: '#E66500',
    fontSize: 16,
    border: '1px solid #E66500',
    borderRadius: 4,
    backgroundColor: 'rgba(255,165,66,0.1)',
    padding: '20px 15px',
  },
  closeIcon: {
    color: theme.palette.white.main,
  },
  buttonsContainer: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    display: 'flex',
    '& > button:first-child': {
      height: '100%',
      flex: 1,
      paddingLeft: 72,
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.5,
    },
    '& > button:last-child': {
      width: 56,
      height: 56,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const STOP_JOB = 'stopJob';
const RETURN_JOB_TO_QUEUE = 'returnJobToQueue';
const STATUS_ON_HOLD = 4;
const STATUS_CHECKED_IN = 2;

function InspectionStopView({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputField = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const job = useSelector(state => state.jobs.job);

  const [actionType, setActionType] = useState(STOP_JOB);
  const [onHoldReason, setOnHoldReason] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const handleActionChange = (event) => {
    setActionType(event.target.value);
    if (error) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (actionType === STOP_JOB && !onHoldReason) {
      setError(true);
      inputField.current.focus();
      return;
    }
    setIsSaving(true);
    dispatch(updateJob({
      chainId: selectedChainId,
      job: {
        ...job,
        status: actionType === STOP_JOB ? STATUS_ON_HOLD : STATUS_CHECKED_IN,
        onHoldReason: actionType === STOP_JOB ? onHoldReason : '',
      },
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(setNotification({ message: t('inspectionView.jobReturnedToQueue') }));
        onClose();
        history.push('/workQueue');
      })
      .catch((error) => {
        setIsSaving(false);
        console.warn('Failed to stop inspection:', error);
        dispatch(setNotification({ message: t('saveFailed'), type: 'error' }));
      });
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
          <div className={classes.title}>{t('inspectionView.jobCancellation')}</div>
        </div>
        <div className={classes.content}>
          <RadioGroup value={actionType} onChange={handleActionChange}>
            <FormControlLabel
              value={STOP_JOB}
              control={<Radio color='primary' />}
              label={t('inspectionView.stopJob')}
            />
            <FormControlLabel
              value={RETURN_JOB_TO_QUEUE}
              control={<Radio color='primary' />}
              label={t('inspectionView.returnToJobQueue')}
            />
          </RadioGroup>
          {actionType === STOP_JOB ? (
            <div className={classes.inputGroup}>
              <div>{`${t('inspectionView.cancelReason')} *`}</div>
              <OutlinedInput
                value={onHoldReason}
                inputRef={inputField}
                onChange={e => setOnHoldReason(e.target.value)}
                fullWidth
                margin='dense'
                error={error}
              />
            </div>
          ) : (
            <div className={classes.infoBox}>
              {t('inspectionView.returnToQueueInfo')}
            </div>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            className={classes.continueButton}
            classes={{ label: classes.continueButtonLabel }}
            onClick={handleSubmit}
            disabled={isSaving}
          >
            {isSaving && t('inspectionView.isSaving') }
            {!isSaving && t(actionType === STOP_JOB ? 'inspectionView.stopJob' : 'inspectionView.returnToJobQueue')}
          </Button>
          <IconButton onClick={onClose} disabled={isSaving}>
            <CloseRoundedIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
    </Drawer>
  );
}

InspectionStopView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InspectionStopView;