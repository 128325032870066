import html from './data-table-html';
import css from './data-table-css';
import checkboxImage from '../../assets/icons/checkbox.svg';
import checkboxSelectedImage from '../../assets/icons/checkbox_selected.svg';
import arrowUpImage from '../../assets/icons/arrow_up.svg';
import arrowDownImage from '../../assets/icons/arrow_down.svg';
import arrowLeftImage from '../../assets/icons/arrow_left.svg';
import arrowRightImage from '../../assets/icons/arrow_right.svg'
import sortImage from '../../assets/icons/sort.svg';

/**
 * List data in table according to given columns, pagination and data.
 *
 * Properties:
 * columns - Column data, e.g. [{ id: 'name', text: 'Name', sortable: true, invisible: true, align: 'right' }]
 * data - Table content, e.g. [{ id: 1, name: 'Row name', disabled: true }]
 * paginated - If pagination controls should be shown (true|false, default = false)
 * totalItemCount - Total amount of items, used in pagination (default = 0)
 * removable - Setting for allowing table row removal (true|false, default = false)
 * selectable - Setting for displaying checkboxes in table rows (true|false, default = false)
 * selectedIds - IDs of rows which are selected using checkboxes (true|false, default = false)
 * sortBy - ID of column by which the table is sorted
 * sortDirection - Sorting direction, either ascending or descending ('asc'|'desc', default = 'asc')
 *
 * Private properties:
 * pagination - Pagination values, e.g. { index: 0, itemsPerPage: 5 }
 *
 * Events:
 * dataTableEvent - Sent when clicking header cells, row delete or pagination buttons.
 * dataTableRowClick - Sent when clicking table row.
 */

const ALL = 'all';
const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50, ALL];
const ASC = 'asc';
const DESC = 'desc';

window.customElements.define('data-table', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.translationService = { translate: (word) => { return word; } };

    this._state = {
      columns: [],
      data: [],
      originalSortBy: '',
      sortBy: '',
      originalSortDirection: ASC,
      sortDirection: ASC,
      selectedIds: [],
      removable: false,
      paginated: false,
      selectable: false,
      totalItemCount: 0,
      pagination: {
        index: 0,
        itemsPerPage: ITEMS_PER_PAGE_OPTIONS[0],
      },
    };

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
      ],
    );

    this.build();
  }

  // Requests needed services.
  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      // Using window.dispatchEvent, because this element is not added to the DOM still.
      window.dispatchEvent(requestServiceEvent);
    });
  }

    // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
  }

  build = async () => {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>' + html;
    this.addRoot();
  }

  addRoot() {
    this.root = this.shadowRoot.getElementById('table-container');
    this.tableHeadRow = this.shadowRoot.getElementById('table-head-row');
    this.tableBody = this.shadowRoot.getElementById('table-body');
    this.paginationContainer = this.shadowRoot.getElementById('pagination-container');
    window.requestAnimationFrame(() => this.render());
  }

  set data(val) {
    this.setState('data', val);
  }

  set columns(val) {
    this.setState('columns', val);
  }

  set removable(val) {
    this.setState('removable', val);
  }

  set selectable(val) {
    this.setState('selectable', val);
  }

  set paginated(val) {
    this.setState('paginated', val);
  }

  set totalItemCount(val) {
    this.setState('totalItemCount', val);
  }

  set selectedIds(val) {
    this.setState('selectedIds', val);
  }

  get selectedIds() {
    return this._state.selectedIds; 
  }

  set sortBy(val) {
    this.setState('sortBy', val);
  }

  set sortDirection(val) {
    this.setState('sortDirection', val);
  }

  set index(val) {
    this._state = {
      ...this._state,
      pagination: {
        itemsPerPage: this._state.pagination.itemsPerPage,
        index: val,
      },
    };
    window.requestAnimationFrame(() => this.render());
  }

  set dataAndLayout(val) {
    this._state = {
      ...this._state,
      data: val.data,
      paginated: Boolean(val.paginated),
      columns: val.columns,
      removable: Boolean(val.removable),
      originalSortBy: val.sortBy || this._state.sortBy,
      sortBy: val.sortBy || this._state.sortBy,
      originalSortDirection: val.sortDirection || this._state.sortDirection,
      sortDirection: val.sortDirection || this._state.sortDirection,
      selectedIds: val.selectedIds || this._state.selectedIds,
      selectable: val.selectable || this._state.selectable,
    };
    window.requestAnimationFrame(() => this.render());
  }

  reset = () => {
    this._state = {
      ...this._state,
      sortBy: this._state.originalSortBy,
      sortDirection: this._state.originalSortDirection,
      pagination: {
        index: 0,
        itemsPerPage: ITEMS_PER_PAGE_OPTIONS[0],
      },
    };
    window.requestAnimationFrame(() => this.render());
  }

  setState = (property, value) => {
    this._state = {
      ...this._state,
      [property]: value,
    };
    window.requestAnimationFrame(() => this.render());
  }

  dispatchTableEvent = (data) => {
    const tableEvent = new CustomEvent('dataTableEvent', {
      bubbles: true,
      composed: true,
      detail: data || this._state,
    });
    this.shadowRoot.dispatchEvent(tableEvent);
  }

  updateSorting = (columnId) => {
    if (this._state.sortBy === columnId) {
      this._state.sortDirection = this._state.sortDirection === DESC ? ASC : DESC;
    } else {
      this._state.sortBy = columnId;
      this._state.sortDirection = ASC;
    }
    this.dispatchTableEvent();
  }

  renderHeader = () => {
    // Create column for checkboxes.
    if (this._state.selectable) {
      const headCell = document.createElement('th');
      headCell.setAttribute('scope', 'col');
      this.tableHeadRow.appendChild(headCell);
    }
    // List given columns.
    this._state.columns.forEach((columnDef) => {
      if (columnDef.invisible) {
        return;
      }
      const headCell = document.createElement('th');
      headCell.setAttribute('scope', 'col');
      this.tableHeadRow.appendChild(headCell);

      const headCellContent = document.createElement('div');
      headCell.appendChild(headCellContent);

      const text = document.createElement('div');
      text.textContent = columnDef.text;
      headCellContent.appendChild(text);

      if (columnDef.align === 'right') {
        headCellContent.setAttribute('class', 'align-right');
      }
      // Add sorting icon.
      if (columnDef.sortable) {
        const icon = document.createElement('img');
        headCellContent.appendChild(icon);
        headCellContent.setAttribute('class', 'sortable');
        headCellContent.onclick = () => this.updateSorting(columnDef.id);
        if (this._state.sortBy === columnDef.id) {
          if (this._state.sortDirection === ASC) {
            icon.setAttribute('src', arrowUpImage);
          } else {
            icon.setAttribute('src', arrowDownImage);
          }
        } else {
          icon.setAttribute('src', sortImage);
        }
      }
    });
    // Create column for delete buttons.
    if (this._removable) {
      const headCell = document.createElement('th');
      headCell.setAttribute('scope', 'col');
      headCell.setAttribute('class', 'remove-column');
      this.tableHeadRow.appendChild(headCell);
    }
  }

  rowClick(rowData) {
    const rowClickedEvent = new CustomEvent('dataTableRowClick', {
      bubbles: true,
      composed: true,
      detail: {
        data: rowData,
      },
    });
    this.root.dispatchEvent(rowClickedEvent);
  }

  toggleRowSelect = (id) => {
    const { selectedIds } = this._state;
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1),
      );
    }
    this._state = {
      ...this._state,
      selectedIds: newSelectedIds,
    }
    this.dispatchTableEvent();
    this.render();
  }

  renderBody = () => {
    this._state.data.forEach((rowData) => {
      const tableRow = document.createElement('tr');
      this.tableBody.appendChild(tableRow);
      if (rowData.disabled) {
        tableRow.setAttribute('class', 'disabled');
      } else if (this._state.selectable) {
        tableRow.addEventListener('click', () => this.toggleRowSelect(rowData.id));
      } else {
        tableRow.addEventListener('click', () => this.rowClick(rowData));
      }
      const rowSelected = this._state.selectedIds.includes(rowData.id);
      if (!rowData.disabled && rowSelected) {
        tableRow.setAttribute('class', 'selected');
      }

      // Create cell for checkbox.
      if (this._state.selectable) {
        const cell = document.createElement('td');
        tableRow.appendChild(cell);
        if (!rowData.disabled) {
          const checkbox = document.createElement('img');
          const imgSrc = rowSelected ? checkboxSelectedImage : checkboxImage;
          checkbox.setAttribute('src', imgSrc);
          cell.appendChild(checkbox);
        }
      }
      this._state.columns.forEach(columnDef => {
        if (columnDef.invisible) {
          return;
        }
        const cell = document.createElement('td');
        cell.textContent = rowData[columnDef.id];
        tableRow.appendChild(cell);
        if (columnDef.align === 'right') {
          cell.setAttribute('class', 'align-right');
        }
        if (columnDef.primary) {
          cell.setAttribute('primary-column', '');
        }
      });
      // Create cell for delete buttons.
      if (this._state.removable) {
        const cell = document.createElement('td');
        const iconContainer = document.createElement('div');
        iconContainer.setAttribute('class', 'remove-cell');
        cell.appendChild(iconContainer);
        const removeImage = document.createElement('div');
        removeImage.onclick = () => this.dispatchTableEvent({ deleteRowData: rowData });
        iconContainer.appendChild(removeImage);
        tableRow.appendChild(cell);
      }
    });
  }

  handleBackdropClick = (event, onClose) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    onClose();
  };

  toggleDropdown = (dropdown, dropdownBackdrop) => {
    dropdown.classList.toggle('collapsed');
    dropdownBackdrop.classList.toggle('collapsed');
  }

  setPaginationValue = (property, value) => {
    const pagination = {
      ...this._state.pagination,
      [property]: value,
    };
    this._state = {
      ...this._state,
      pagination,
    };
    this.dispatchTableEvent();
    window.requestAnimationFrame(() => this.render());
  }

  renderPagination = () => {
    if (!this._state.paginated) {
      this.paginationContainer.setAttribute('hidden', '');
      return;
    }
    this.paginationContainer.removeAttribute('hidden');

    const { index, itemsPerPage } = this._state.pagination;
    const { totalItemCount, data } = this._state;

    const itemsPerPageText = this.shadowRoot.getElementById('items-per-page-text');
    itemsPerPageText.textContent = this.translationService.translate("rowsPerPage")

    const itemsPerPageDropdownBackdrop = this.shadowRoot.getElementById('items-per-page-dropdown-backdrop');
    itemsPerPageDropdownBackdrop.onclick = (event) => this.handleBackdropClick(event, () => this.toggleDropdown(itemsPerPageSelectDropdown, itemsPerPageDropdownBackdrop));

    const itemsPerPageSelectText = this.shadowRoot.getElementById('items-per-page-select-text');
    itemsPerPageSelectText.textContent = itemsPerPage === ALL ? 'Kaikki' : itemsPerPage;

    const itemsPerPageSelect = this.shadowRoot.getElementById('items-per-page-select');
    itemsPerPageSelect.onclick = () => this.toggleDropdown(itemsPerPageSelectDropdown, itemsPerPageDropdownBackdrop);

    const itemsPerPageSelectDropdown = this.shadowRoot.getElementById('items-per-page-dropdown');
    if (itemsPerPageSelectDropdown.childNodes.length === 0) {
      ITEMS_PER_PAGE_OPTIONS.forEach((choiceDef) => {
        const choice = document.createElement('div');
        choice.textContent = choiceDef === ALL ? 'Kaikki' : choiceDef;
        choice.onclick = () => this.setPaginationValue('itemsPerPage', choiceDef);
        itemsPerPageSelectDropdown.appendChild(choice);
      });
    }

    const selectImage = this.shadowRoot.getElementById('items-per-page-select-image');
    selectImage.src = arrowDownImage;

    const pageContainer = this.shadowRoot.getElementById('page-container');
    const displayedItemsStart = itemsPerPage === ALL
      ? 1
      : index * itemsPerPage + 1;
    const displayedItemsEnd = itemsPerPage === ALL
      ? data.length
      : index * itemsPerPage + data.length;
    pageContainer.textContent = `${displayedItemsStart}-${displayedItemsEnd} / ${totalItemCount}`;

    const prevButton = this.shadowRoot.getElementById('prev-button');
    prevButton.onclick = () => this.setPaginationValue('index', index - 1);
    const prevImage = this.shadowRoot.getElementById('prev-image');
    prevImage.src = arrowLeftImage;
    if (itemsPerPage === ALL || index < 1) {
      prevButton.setAttribute('disabled', '');
    } else {
      prevButton.removeAttribute('disabled');
    }
    const nextButton = this.shadowRoot.getElementById('next-button');
    nextButton.onclick = () => this.setPaginationValue('index', index + 1);
    const nextImage = this.shadowRoot.getElementById('next-image');
    nextImage.src = arrowRightImage;
    if (itemsPerPage === ALL || totalItemCount <= index * itemsPerPage + data.length) {
      nextButton.setAttribute('disabled', '');
    } else {
      nextButton.removeAttribute('disabled');
    }
  }

  render() {
    if (!this.root) {
      return;
    }

    const data = this._state.data;
    if (!Array.isArray(data)) {
      return;
    }

    while (this.tableHeadRow.childNodes.length > 0) {
      this.tableHeadRow.removeChild(this.tableHeadRow.firstChild);
    }
    while (this.tableBody.childNodes.length > 0) {
      this.tableBody.removeChild(this.tableBody.firstChild);
    }

    this.renderHeader();
    this.renderBody();
    this.renderPagination();
  }
});
