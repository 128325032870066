import dayjs from 'dayjs';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';
import { DEFAULT_QUALITY_CONTROL_DEVIATION_FILTERS, ALL_DEVIATION_TYPES } from '../lib/deviationConstants';

export const updateQualityControlDeviation = createAsyncThunk(
  'qualityControlDeviation/updateDeviation',
  async ({ chainId, payload }, API) => {
    const procName = payload.id ? 'UpdateQualityControlDeviation' : 'CreateQualityControlDeviation';
    
    const transformedPayload = {
      ...payload,
      reportedAt: dayjs(payload.reportedAt).format('YYYY-MM-DD'),
      chainId: parseInt(chainId),
      siteId: parseInt(payload.siteId),
      reportedBy: parseInt(payload.reportedBy),
      supervisedBy: parseInt(payload.supervisedBy),
      deviationTypeId: parseInt(payload.deviationTypeId),
      deviationCategoryId: payload.deviationCategoryId ? parseInt(payload.deviationCategoryId) : null,
      rootCauseTargetId: payload.rootCauseTargetId ? parseInt(payload.rootCauseTargetId) : null,
      rootCauseId: payload.rootCauseId ? parseInt(payload.rootCauseId) : null,
      customerInformation: payload.customerInformation ? JSON.stringify(payload.customerInformation) : null,
    };

    const query = {
      procName: procName,
      data: transformedPayload,
    };

    // Remove unnecessary fields based on the procedure
    if (procName === 'CreateQualityControlDeviation') {
      delete transformedPayload.id;
    }

    const state = API.getState();
    const response = await sendRequest(chainId, query, 'POST', state.auth.token);
    return { ...payload, id: response.id };
  }
);

export const fetchQualityControlDeviations = createAsyncThunk(
  'qualityControlDeviation/fetchDeviations',
  async ({ chainId, siteId }, API) => {
    const query = {
      procName: 'GetQualityControlDeviations',
      data: { chainId, siteId },
    };
    const state = API.getState();
    const deviations = await sendRequest(chainId, query, 'POST', state.auth.token);
    return deviations.map(deviation => ({
      ...deviation,
      customerInformation: JSON.parse(deviation.customerInformation || '{}'),
    }));
  }
);

export const deleteQualityControlDeviation = createAsyncThunk(
  'qualityControlDeviation/deleteDeviation',
  async ({ chainId, siteId, deviationId }, API) => {
    const query = {
      procName: 'DeleteQualityControlDeviation',
      data: {
        id: deviationId,
        chainId,
        siteId,
      },
    };
    const state = API.getState();
    await sendRequest(chainId, query, 'POST', state.auth.token);
    return deviationId;
  }
);

export const listQualityControlDeviations = createAsyncThunk(
  'qualityControlDeviation/listDeviations',
  async ({ chainId, siteId, filters }, API) => {
    const query = {
      procName: 'ListQualityControlDeviations',
      data: {
        chainId,
        siteId,
        startTime: filters.startTime ? dayjs(filters.startTime).format('YYYY-MM-DD') : null,
        endTime: filters.endTime ? dayjs(filters.endTime).format('YYYY-MM-DD') : null,
        additionInfoFilter: filters.additionInfoFilter,
        deviationTypeId: filters.deviationTypeId === ALL_DEVIATION_TYPES ? -1 : filters.deviationTypeId,
      },
    };
    const state = API.getState();
    const deviations = await sendRequest(chainId, query, 'POST', state.auth.token);
    return deviations;
  }
);

export const fetchQualityControlDeviationTypes = createAsyncThunk(
  'qualityControlDeviation/fetchDeviationTypes',
  async ({ chainId, siteId }, API) => {
    const query = {
      procName: 'GetQualityControlOptions',
      data: { chainId, siteId, optionsType: 1 },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const fetchQualityControlDeviationCategories = createAsyncThunk(
  'qualityControlDeviation/fetchDeviationCategories',
  async ({ chainId, siteId }, API) => {
    const query = {
      procName: 'GetQualityControlOptions',
      data: { chainId, siteId, optionsType: 2 },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const fetchQualityControlDeviationRootCauseTargets = createAsyncThunk(
  'qualityControlDeviation/fetchRootCauseTargets',
  async ({ chainId, siteId }, API) => {
    const query = {
      procName: 'GetQualityControlOptions',
      data: { chainId, siteId, optionsType: 3 },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const fetchQualityControlDeviation = createAsyncThunk(
  'qualityControlDeviation/fetchDeviation',
  async ({ chainId, siteId, deviationId }, API) => {
    const query = {
      procName: 'GetQualityControlDeviation',
      data: { chainId, siteId, id: deviationId },
    };
    const state = API.getState();
    const deviation = await sendRequest(chainId, query, 'POST', state.auth.token);
    return {
      ...deviation,
      customerInformation: JSON.parse(deviation.customerInformation || '{}'),
    };
  }
);

export const NEW_DEVIATION_INITIAL_STATE = {
  createdAt: dayjs().format('DD.MM.YYYY'),
  registrationNumber: '',
  reportedBy: '',
  supervisedBy: '',
  deviationTypeId: '',
  deviationCategoryId: '',
  rootCauseTargetId: '',
  rootCauseId: '',
  additionalInfo: '',
  customerName: '',
  contactPerson: '',
  address: '',
  phoneNumber: '',
  email: '',
  description: '',
  correctiveActions: '',
  rootCause: '',
  requirements: '',
  preventionMeasures: '',
};

const qualityControlDeviationsSlice = createSlice({
  name: 'qualityControlDeviations',
  initialState: {
    deviations: [],
    currentDeviation: null,
    isRequesting: false,
    filters: DEFAULT_QUALITY_CONTROL_DEVIATION_FILTERS,
    deviationTypes: [],
    deviationCategories: [],
    rootCauseTargets: [],
    isFetchingDeviation: false,
  },
  reducers: {
    setDeviationFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetDeviationFilters: (state) => {
      state.filters = DEFAULT_QUALITY_CONTROL_DEVIATION_FILTERS;
    },
    setNewDeviation: (state, action) => {
      state.newDeviation = { ...NEW_DEVIATION_INITIAL_STATE, ...action.payload };
    },
    clearNewDeviation: (state) => {
      state.newDeviation = NEW_DEVIATION_INITIAL_STATE;
    },
    updateNewDeviation: (state, action) => {
      state.newDeviation = { ...state.newDeviation, ...action.payload };
    },
  },
  extraReducers: {
    [updateQualityControlDeviation.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateQualityControlDeviation.fulfilled]: (state, { payload }) => {
      state.currentDeviation = payload;
      state.isRequesting = false;
    },
    [updateQualityControlDeviation.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlDeviations.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchQualityControlDeviations.fulfilled]: (state, { payload }) => {
      state.deviations = payload;
      state.isRequesting = false;
    },
    [fetchQualityControlDeviations.rejected]: (state) => {
      state.isRequesting = false;
    },
    [deleteQualityControlDeviation.pending]: (state) => {
      state.isRequesting = true;
    },
    [deleteQualityControlDeviation.fulfilled]: (state) => {
      state.isRequesting = false;
    },
    [deleteQualityControlDeviation.rejected]: (state) => {
      state.isRequesting = false;
    },
    [listQualityControlDeviations.pending]: (state) => {
      state.isRequesting = true;
    },
    [listQualityControlDeviations.fulfilled]: (state, { payload }) => {
      state.deviations = payload;
      state.isRequesting = false;
    },
    [listQualityControlDeviations.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlDeviationTypes.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchQualityControlDeviationTypes.fulfilled]: (state, { payload }) => {
      state.deviationTypes = payload;
      state.isRequesting = false;
    },
    [fetchQualityControlDeviationTypes.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlDeviationCategories.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchQualityControlDeviationCategories.fulfilled]: (state, { payload }) => {
      state.deviationCategories = payload;
      state.isRequesting = false;
    },
    [fetchQualityControlDeviationCategories.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlDeviationRootCauseTargets.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchQualityControlDeviationRootCauseTargets.fulfilled]: (state, { payload }) => {
      state.rootCauseTargets = payload;
      state.isRequesting = false;
    },
    [fetchQualityControlDeviationRootCauseTargets.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlDeviation.pending]: (state) => {
      state.isFetchingDeviation = true;
    },
    [fetchQualityControlDeviation.fulfilled]: (state, { payload }) => {
      state.currentDeviation = payload;
      state.isFetchingDeviation = false;
    },
    [fetchQualityControlDeviation.rejected]: (state) => {
      state.isFetchingDeviation = false;
    },
  },
});

// Export the new actions
export const { setDeviationFilters, resetDeviationFilters, setNewDeviation, clearNewDeviation, updateNewDeviation } = qualityControlDeviationsSlice.actions;

export const actions = qualityControlDeviationsSlice.actions;
export default qualityControlDeviationsSlice.reducer;
