import { sendNotificationEvent } from '../../../appShell/notificationHelpers';
import './settings-data-table';
import layout from './layout-html';

const NAME = 'name';
const ADDRESS = 'address';
const ATJ_USERNAME = 'atjUsername';
const COMMISSION_COEFFICIENT = 'commissionCoefficient';
const COLUMN_IDS = [NAME, ADDRESS, ATJ_USERNAME, COMMISSION_COEFFICIENT];

class UserSettingsSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      user: null,
      userData: {},
      sites: new Map(),
      siteGroups: new Map(),
      userSites: [],
      userSiteGroups: [],
      data: new Map(),
    };
  }

  // Sets translation service.
  setServices = (controlState, translationService) => {
    this.controlState = controlState;
    this.translationService = translationService;
  }

  build = async () => {
    this.shadowRoot.innerHTML = layout;

    // Confirm dialog.
    this.confirmationDialog = document.createElement('confirmation-dialog');

    // Form container.
    this.rootElement = this.shadowRoot.getElementById('table');

    // Save.
    this.saveButton = this.shadowRoot.getElementById('button-save');
    this.saveButton.appendChild(document.createTextNode(this.translationService.translate('save')));
    this.saveButton.onclick = this.onSave

    // Cancel.
    this.cancelButton = this.shadowRoot.getElementById('button-cancel');
    this.cancelButton.appendChild(document.createTextNode(this.translationService.translate('cancel')));
    this.cancelButton.onclick = this.onCancel

    // Delete user button.
    this.deleteUserButton = this.shadowRoot.getElementById('button-delete-user');
    this.deleteUserButton.appendChild(document.createTextNode(this.translationService.translate('usersView.delete')));
    this.deleteUserButton.onclick = this.onDeleteUser

    this.refreshData();
    this._state.isBuilt = true;
  }

  connectedCallback() {
    this.build().then(() => this.fetchData());
    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
  }

  // Handles data table events.
  handleDataTableEvent = (event) => {
    const payload = event.detail;
    if(payload.deleteRowData) {
      this.addConfirmationDialog(payload.deleteRowData);
    }
  }

  // Performs tab initialization with the given user data.
  init(store, user, sites, siteGroups, deleteManager) {
    this.store = store;
    this._state = {
      ...this._state,
      user,
      sites,
      siteGroups
    };
    this.deleteManager = deleteManager;
  }

  onSave = () => {
    const toSave = [...this._state.data.values()].map(d => {
      var previousSettings = this._state.userSiteSettings.get(d.id);
      return {
        siteId: d.id,
        [ATJ_USERNAME]: d[ATJ_USERNAME] || '',
        [COMMISSION_COEFFICIENT]: d[COMMISSION_COEFFICIENT] || '',
        defaultPrinterId: previousSettings?.defaultPrinterId || null,
        defaultAtjPrinterId: previousSettings?.defaultAtjPrinterId || null,
      };
    });
    const data = {
      ...this._state.userData,
      siteSettings: JSON.stringify(toSave)
    };
    this.saveData(data);
  }

  onCancel = () => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-list',
        data: {}
      },
    });
    this.shadowRoot.dispatchEvent(navEvent);
  };

  onDeleteUser = () => this.deleteManager.addConfirmationDialog(this.shadowRoot);

  updateUserData = (userData) => {
    if(userData) {
      const user = userData;
      this._state.userSites = user.sites;
      this._state.userSiteGroups = user.siteGroups;
      this._state.userSiteSettings = new Map((user.siteSettings || []).map(ss => [ss.siteId, ss]));

      const data = new Map(this._state.sites
        .filter(s => this._state.userSites.includes(s.id) || this._state.userSiteGroups.includes(s.siteGroupId))
        .map(s => {
          const ss = this._state.userSiteSettings.get(s.id) || {};
          return [s.id, {
            id: s.id,
            [NAME]: s.name,
            [ADDRESS]: s.address,
            [ATJ_USERNAME]: ss[ATJ_USERNAME] || '',
            [COMMISSION_COEFFICIENT]: ss[COMMISSION_COEFFICIENT] || '',
          }];
        }));
      this._state.data = data;
      this._state.userData = user;
    }
  }

  fetchData = async () => {
    try {
      const data = { userId: this._state.user.id };
      const userData = await this.store.sendCrudRequest(this.controlState.chainId, 'GetUser', data);
      this.updateUserData(userData);
      this.refreshData();
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      sendNotificationEvent(this.translationService.translate('usersView.fetchFailed'), 'error');
    }
  }

  saveData = async (data) => {
    try {
      const userData = await this.store.sendCrudRequest(this.controlState.chainId, 'UpdateUser', {
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        preferredLanguage: data.preferredLanguage,
        sites: data.sites,
        siteGroups: data.siteGroups,
        siteSettings: data.siteSettings,
        permissions: data.permissions,
        lastSiteId: data.lastSiteId,
      });
      this.updateUserData(userData);
      this.refreshData();
      sendNotificationEvent(this.translationService.translate('chainView.updateSuccessful'));
    } catch (error) {
      console.error(error);
      sendNotificationEvent(this.translationService.translate('chainView.updateFailed'), 'error');
    }
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`usersView.${columnId}`),
        sortable: false,
        primary: columnId === NAME,
      };
    });
  }

  removeGroup = (gid) => {
    const group = this._state.siteGroups.find(g => g.id === Number(gid));
    this.addConfirmationDialog(group);
  }

  refreshData = () => {
    if(!this.rootElement) {
      return;
    }

    // Populate data table.
    this.rootElement.dataAndLayout = {
      data: [...this._state.data.values()],
      columns: this.getTableColumnDefinitions(),
      sortBy: 'name',
      sortDirection: 'desc',
      paginated: false,
      selectable: true,
      selectedIds: []
    };
    this.rootElement.totalItemCount = 0;
    this.rootElement.addEventListener('dDataTableInputEvent', (event) => {
      this._state.data.get(event.detail.siteId)[event.detail.field] = event.detail.value;
    });
  }
}

window.customElements.define('user-settings-section', UserSettingsSection);
