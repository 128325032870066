import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import store from './app/store';
import { swUpdated } from './reducers/serviceWorkerReducer';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';

import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';

import './i18n';
import { msalConfig, loginRequest } from './authConfig';
import LoginFailedView from './components/login/LoginFailedView';
import LoadingView from './components/navigation/LoadingView';

dayjs.locale('fi');
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat);

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>

    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={LoginFailedView}
      loadingComponent={LoadingView}
    >

      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </MsalAuthenticationTemplate>

  </MsalProvider>,
  document.getElementById('root'),
);

serviceWorker.register({
  onUpdate: (registration) => {
    store.dispatch(swUpdated(registration));
  },
});
