import React from 'react';
import PropTypes from 'prop-types';
import randomcolor from 'randomcolor';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  userButton: {
    padding: 8,
  },
  userIcon: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    fontSize: 14,
    color: '#091300',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
}));

function UserButton({ firstName, lastName, onClick, disabled }) {
  const classes = useStyles();

  const initials = firstName && lastName
    ? firstName.charAt(0) + lastName.charAt(0)
    : '-';
  const backgroundColor = firstName && lastName
    ? randomcolor({
      seed: (firstName + lastName).toLowerCase(),
      luminosity: 'light',
      format: 'rgb',
    })
    : '#B8B8B8';

  return (
    <IconButton className={classes.userButton} onClick={onClick} disabled={disabled}>
      <div className={classes.userIcon} style={{ backgroundColor }}>
        <span>{initials}</span>
      </div>
    </IconButton>
  );
}

UserButton.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
UserButton.defaultProps = {
  firstName: '',
  lastName: '',
  disabled: false,
};

export default UserButton;