import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFI from './webComponents/localization/fi-FI.json';
import translationSV from './webComponents/localization/sv-FI.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      "fi-FI": {
        translation: translationFI
      },
      "sv-FI": {
        translation: translationSV
      },
    },
    fallbackLng: 'sv-FI',
    preload: ["fi-FI", "sv-FI"],
    lng: 'fi-FI',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    parseMissingKeyHandler: (key) => `#${key}#`,
  });

export default i18n;