import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendChainsRequest } from '../api/api';

export const fetchChains = createAsyncThunk('chain/fetchChains', async ({ selectedChainId }, API) => {
  const state = API.getState();
  if (selectedChainId == null || selectedChainId == 0) {
    selectedChainId = state.chains.selectedChainId;
  }
  const chains = await sendChainsRequest(null, null, 'GET', state.auth.token);
  return { chains, selectedChainId };
});

// For chain management.
export const fetchChainList = createAsyncThunk('chain/fetchChainList', async (_, API) => {
  const state = API.getState();
  const chains = await sendChainsRequest('/list', null, 'GET', state.auth.token);
  return chains;
});

// For chain management.
export const fetchChainDetails = createAsyncThunk('chain/fetchChainDetails', async ({ chainId }, API) => {
  const state = API.getState();
  const chain = await sendChainsRequest(`/list/${chainId}`, null, 'GET', state.auth.token);
  return chain;
});

export const createChain = createAsyncThunk('chain/createChain', async ({ data }, API) => {
  const state = API.getState();
  const chain = await sendChainsRequest('', data, 'POST', state.auth.token);
  return chain;
});

export const updateChain = createAsyncThunk('chain/updateChain', async ({ id, data }, API) => {
  const state = API.getState();
  const chain = await sendChainsRequest(`/${id}`, data, 'PUT', state.auth.token);
  return chain;
});

const chainSlice = createSlice({
  name: 'chain',
  initialState: {
    chains: [],
    selectedChainId: null,
    isFetching: false,
    chainList: [],
    chainDetails: null,
  },
  reducers: {
    setSelectedChainId(state, { payload }) {
      state.selectedChainId = payload;
    },
  },
  extraReducers: {
    [fetchChains.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchChains.fulfilled]: (state, { payload }) => {
      const {chains, selectedChainId} = payload;
      state.chains = chains;
      state.isFetching = false;
      // Set default chain ID.
      if (selectedChainId && chains.find((chain) => chain.id === selectedChainId)) {
        // Select the given chain.
        state.selectedChainId = selectedChainId;
      } else {
        if (chains.length > 0) {
          state.selectedChainId = chains[0].id;  // NOTE1: first chain is selected by default, if there are multiple chains. Backend returns all chains only for super users.
        }
      }
    },
    [fetchChains.rejected]: (state) => {
      state.isFetching = false;
    },
    [fetchChainList.fulfilled]: (state, { payload }) => {
      state.chainList = payload;
    },
    [fetchChainDetails.fulfilled]: (state, { payload }) => {
      state.chainDetails = payload;
    },
  },
});

export const {
  setSelectedChainId,
} = chainSlice.actions;

export default chainSlice.reducer;
