import './site-groups-list-view';
import './site-groups-detail-root';

class SiteGroupsApp extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.addModules();
    this.createRoot();
    this.addEventListeners();
  }

  // Add script tags to load required child components
  addModules = () => {
    // Create child elements and store references.
    this.siteGroupsListView = document.createElement('site-groups-list-view');
    this.siteGroupsDetailRoot = document.createElement('site-groups-detail-root');

    this.routes = {
      '/site-groups-list': this.siteGroupsListView,
      '/site-groups-detail': this.siteGroupsDetailRoot,
    };
    this.currentRoute = '/site-groups-list';
  }

  createRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'sites-app');
    this.root.setAttribute('class', 'app');
    this.shadowRoot.appendChild(this.root);
    window.requestAnimationFrame(() => this.initialRender());
  }

  pushRoute = () => {
    const routeName = this.navEvent.detail.route;
    this.currentRoute = routeName;
    this.mainContent.removeChild(this.mainContent.childNodes[0]);
    const route = this.routes[routeName];
    route.navData = this.navEvent.detail ? this.navEvent.detail.data : {};
    this.mainContent.appendChild(route);
  }

  siteGroupsNavEventListener = (event) => {
    // Navigation event to React root.
    const navigationEvent = new CustomEvent('navigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: event.detail.route === '/site-groups-detail'
          ? `/siteGroups/${event.detail.data ? event.detail.data.id : 'new'}`
          : '/siteGroups',
      },
    });
    window.dispatchEvent(navigationEvent);

    this.navEvent = event;
    window.requestAnimationFrame(() => this.pushRoute());
  }

  // Add event listeners for events dispatched by modules.
  addEventListeners = () => {
    this.addEventListener('siteGroupsNavEvent', this.siteGroupsNavEventListener);
  }

  initialRender = () => {
    const mainContent = this.mainContent = document.createElement('div');
    mainContent.setAttribute('class', 'main-content');
    this.root.appendChild(mainContent);
    this.mainContent.appendChild(this.siteGroupsListView);
  }
}
window.customElements.define('site-groups-app', SiteGroupsApp);
