export const CATEGORY_INSTRUMENT = 'instrument';
export const CATEGORY_PRINTER = 'printer';
export const CATEGORY_OTHER = 'other';

export const TYPE_GENERAL_PRINTER = 'generalPrinter';
export const TYPE_RECEIPT_PRINTER = 'receiptPrinter';
export const TYPE_ATJ_PRINTER = 'atjPrinter';

const INSTRUMENT = 'instrument';
const PRINTER = 'printer';
export const OTHER = 'other';
export const CATEGORIES = {
  INSTRUMENT,
  PRINTER,
  OTHER,
};

const GENERAL_PRINTER = 'generalPrinter';
const RECEIPT_PRINTER = 'receiptPrinter';
const ATJ_PRINTER = 'atjPrinter';
export const PRINTER_OPTIONS = [
  GENERAL_PRINTER,
  RECEIPT_PRINTER,
  ATJ_PRINTER,
];

export const SHOCK = 'shock';
export const BRAKE = 'brake';
export const SHOCK_BRAKE = 'shockBrake';
export const GAS_LEAK = 'gasLeak';
export const LIMITER = 'limiter';
export const OBD = 'obd';
export const EMISSION = 'emission';
export const TYRE_GROOVE = 'tyreGroove';
export const HEADLIGHTS = 'headlights';
export const NOISE = 'noise';
export const INSTRUMENT_TYPES = {
  SHOCK,
  BRAKE,
  SHOCK_BRAKE,
  GAS_LEAK,
  OTHER,
  LIMITER,
  OBD,
  EMISSION,
  TYRE_GROOVE,
  HEADLIGHTS,
  NOISE,
};
export const INSTRUMENT_OPTIONS = [
  SHOCK,
  BRAKE,
  SHOCK_BRAKE,
  GAS_LEAK,
  OTHER,
  LIMITER,
  OBD,
  EMISSION,
  TYRE_GROOVE,
  HEADLIGHTS,
  NOISE,
];

const TYPE = 'type';
const NAME = 'name';
const DESCRIPTION = 'description';
const ADDITIONAL_INFORMATION = 'additionalInfo';
const STATUS = 'status';
const TAKEN_IN_USE_DATE = 'takenInUseDate';
const UNIT = 'unit';
const BIGGER_IS_WORSE = 'biggerIsWorse';
const SMALLEST_ALLOWED_VALUE = 'smallestAllowedValue';
const LARGEST_WHEEL_DIFFERENCE = 'largestWheelDifference';
const FUEL_TYPES = 'fuelTypes';
const MODEL = 'model';
const SMALLEST_ALLOWED_VALUE_SUMMER = 'smallestAllowedValueSummer';
const SMALLEST_ALLOWED_VALUE_WINTER = 'smallestAllowedValueWinter';
export const BASIC_FORM_FIELDS = {
  TYPE,
  NAME,
  DESCRIPTION,
  ADDITIONAL_INFORMATION,
  STATUS,
  TAKEN_IN_USE_DATE,
};
export const MEASUREMENTS_FIELDS = {
  TYPE,
  UNIT,
  BIGGER_IS_WORSE,
  SMALLEST_ALLOWED_VALUE,
  LARGEST_WHEEL_DIFFERENCE,
  FUEL_TYPES,
  MODEL,
  SMALLEST_ALLOWED_VALUE_SUMMER,
  SMALLEST_ALLOWED_VALUE_WINTER,
};

const CONNECTION_TYPE = 'connectionType';
const EXTERNAL_DEVICE_ID = 'externalDeviceId';
export const DEVICE_ADDITIONAL_FIELDS = {
  CONNECTION_TYPE,
  EXTERNAL_DEVICE_ID,
};

const MEASUREMENT_TARGET = 'measurementTarget';
const MINIMUM_VALUE = 'minimumValue';
const DEFAULT_VALUE = 'defaultValue';
const MAXIMUM_VALUE = 'maximumValue';
const RANGE = 'range';
const STEPS = 'steps';
export const MEASUREMENTS_COLUMNS = {
  MEASUREMENT_TARGET,
  MINIMUM_VALUE,
  DEFAULT_VALUE,
  MAXIMUM_VALUE,
  RANGE,
  STEPS,
};

export const IN_USE = 'inUse';
const IN_REPAIR = 'inRepair';
const REMOVED = 'removed';
export const STATUS_OPTIONS = [IN_USE, IN_REPAIR, REMOVED];

export const CONNECTION_NONE = 'none';
export const CONNECTION_AUTOCOM = 'autocom';
export const CONNECTION_AVL = 'avl';
export const CONNECTION_OPUS = 'opus';
export const CONNECTION_TYPE_OPTIONS = [CONNECTION_NONE, CONNECTION_AUTOCOM, CONNECTION_AVL, CONNECTION_OPUS];

const FRONT_BRAKE = 'frontBrake';
const REAR_BRAKE = 'rearBrake';
const PARK_BRAKE = 'parkBrake';
export const BRAKE_TYPE_OPTIONS = [
  FRONT_BRAKE,
  REAR_BRAKE,
  PARK_BRAKE,
];

export const GASOLINE = 'gasoline';
export const DIESEL = 'diesel';

export const MODEL_31121977 = 'model_-31121977';
export const MODEL_01011978_30091986 = 'model_01011978-30091986';
export const MODEL_01101986_31121989 = 'model_01101986-31121989';
export const MODEL_01011990_31122000 = 'model_01011990-31122000';
export const MODEL_01012001_10Y = 'model_01012001-10Y';
export const MODEL_10Y = 'model_10Y';
export const MODEL_OPTIONS = [
  MODEL_31121977,
  MODEL_01011978_30091986,
  MODEL_01101986_31121989,
  MODEL_01011990_31122000,
  MODEL_01012001_10Y,
  MODEL_10Y,
];

export const REV = 'rev';
export const IDLE = 'idle';

export const CARBON_MONOXIDE = 'carbonMonoxide';
export const HYDRO_CARBON = 'hydroCarbon';
export const CARBON_DIOXIDE = 'carbonDioxide';
export const OXYGEN = 'oxygen';
export const LAMBDA = 'lambda';
export const K_VALUE = 'kValue';
export const EMISSION_MEASUREMENTS = {
  CARBON_MONOXIDE,
  HYDRO_CARBON,
  CARBON_DIOXIDE,
  OXYGEN,
};
export const EMISSION_MEASUREMENTS_REV = {
  CARBON_MONOXIDE,
  HYDRO_CARBON,
  CARBON_DIOXIDE,
  OXYGEN,
  LAMBDA,
};
export const EMISSION_MEASUREMENTS_DIESEL = { K_VALUE };

const DEFAULT_TABLE_DATA = {
  [MINIMUM_VALUE]: '0',
  [DEFAULT_VALUE]: '0',
  [MAXIMUM_VALUE]: '0',
  [RANGE]: '0',
  [STEPS]: 0,
};
function getDefaultIdleTableData() {
  return {
    [CARBON_MONOXIDE]: { ...DEFAULT_TABLE_DATA },
    [HYDRO_CARBON]: { ...DEFAULT_TABLE_DATA },
    [CARBON_DIOXIDE]: { ...DEFAULT_TABLE_DATA },
    [OXYGEN]: { ...DEFAULT_TABLE_DATA },
  };
}
function getDefaultRevTableData() {
  return {
    [CARBON_MONOXIDE]: { ...DEFAULT_TABLE_DATA },
    [HYDRO_CARBON]: { ...DEFAULT_TABLE_DATA },
    [CARBON_DIOXIDE]: { ...DEFAULT_TABLE_DATA },
    [OXYGEN]: { ...DEFAULT_TABLE_DATA },
    [LAMBDA]: { ...DEFAULT_TABLE_DATA },
  };
}
export const DEFAULT_FORM_DATA = {
  [TYPE]: '',
  [NAME]: '',
  [DESCRIPTION]: '',
  [ADDITIONAL_INFORMATION]: '',
  [STATUS]: IN_USE,
  [TAKEN_IN_USE_DATE]: null,
  [SHOCK]: {
    [UNIT]: '',
    [BIGGER_IS_WORSE]: false,
    [SMALLEST_ALLOWED_VALUE]: '',
    [LARGEST_WHEEL_DIFFERENCE]: '',
    [SHOCK]: {
      ...DEFAULT_TABLE_DATA,
    },
  },
  [BRAKE]: {
    [UNIT]: '',
    [FRONT_BRAKE]: { ...DEFAULT_TABLE_DATA },
    [REAR_BRAKE]: { ...DEFAULT_TABLE_DATA },
    [PARK_BRAKE]: { ...DEFAULT_TABLE_DATA },
  },
  [FUEL_TYPES]: [],
  [MODEL]: MODEL_31121977,
  [`${MODEL_31121977}_${IDLE}`]: getDefaultIdleTableData(),
  [`${MODEL_31121977}_${REV}`]: getDefaultRevTableData(),
  [`${MODEL_01011978_30091986}_${IDLE}`]: getDefaultIdleTableData(),
  [`${MODEL_01011978_30091986}_${REV}`]: getDefaultRevTableData(),
  [`${MODEL_01101986_31121989}_${IDLE}`]: getDefaultIdleTableData(),
  [`${MODEL_01101986_31121989}_${REV}`]: getDefaultRevTableData(),
  [`${MODEL_01011990_31122000}_${IDLE}`]: getDefaultIdleTableData(),
  [`${MODEL_01011990_31122000}_${REV}`]: getDefaultRevTableData(),
  [`${MODEL_01012001_10Y}_${IDLE}`]: getDefaultIdleTableData(),
  [`${MODEL_01012001_10Y}_${REV}`]: getDefaultRevTableData(),
  [DIESEL]: {
    [K_VALUE]: { ...DEFAULT_TABLE_DATA },
  },
  [TYRE_GROOVE]: {
    [UNIT]: '',
    [SMALLEST_ALLOWED_VALUE_SUMMER]: '',
    [SMALLEST_ALLOWED_VALUE_WINTER]: '',
    [TYRE_GROOVE]: {
      ...DEFAULT_TABLE_DATA,
    },
  },
};
