import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { RESERVATION_TYPES, DRIVE_IN, ON_HOLD, JOB_STATUS } from '../../lib/jobConstants';
import { getTimeDifferenceInMinutes, getDateAndTime } from '../../lib/dateTimeFunctions';
import { toCamelCase } from '../../lib/commonFunctions';
import { getProductNames, lowerCaseFirstLetter, isFourWheelDrive } from '../../lib/jobFunctions';
import InfoMessage from '../common/InfoMessage';

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px - 56px)',
      padding: '10px 16px',
    },
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 10,
    '& > div:first-child': {
      fontSize: 22,
      fontWeight: 600,
      flex: 1,
    },
    '& > div:last-child': {
      fontSize: 12,
      color: '#091300',
    },
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: 15,
    },
    '& > div:first-child': {
      fontWeight: 600,
      marginBottom: 3,
    },
    '& > div:last-child': {
      color: theme.palette.black.main,
      opacity: 0.7,
    },
  },
  errorSection: {
    '& > div:last-child': {
      color: '#C50000',
      opacity: 1,
    },
  },
  columnsContainer: {
    display: 'flex',
    borderBottom: '1px solid #E9E9E9',
    paddingBottom: 12,
    '& > div': {
      flex: 1,
    },
    '& > div:last-child': {
      paddingLeft: 20,
    },
  },
  additionalInfoContainer: {
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: '1px solid #E9E9E9',
  },
  errorMessage: {
    padding: '13px 12px',
    backgroundColor: 'rgba(197,0,0,0.1)',
    border: '1px solid #C50000',
    borderRadius: 4,
    color: '#C50000',
    display: 'flex',
    flexDirection: 'column',
  },
  fourWheelDrive: {
    marginTop: 5,
  },
}));

// Returns check-in time as HH:MM and amount of minutes from check-in time.
function getCheckInTime(date, translate) {
  if (!date) {
    return '-';
  }
  const currentTime = new Date();
  return `${getDateAndTime(new Date(date), translate)} (${Math.floor(getTimeDifferenceInMinutes(currentTime, new Date(date)))}min)`;
}


// Returns job restrictions if any.
function getWarningInformation(job, translate) {
  const details = job.atjInformation?.sanoma?.ajoneuvo?.teknisettiedot;
  if (!details) {
    return []
  }

  // Collect ATJ restrictions.
  const result = [];
  for (const item of (details.katsastusrajoitukset ?? [])) {
    const label = translate(`restrictions.katsastusrajoitus`);
    const description = item.syyselite;
    result.push(label + (description ? ': ' + description : ''));
  }
  for (const item of (details.kayttokieltorajoitukset ?? [])) {
    const label = translate(`restrictions.kayttokieltorajoitus`);
    if (item.kayttokieltomaarkats === true) {
      result.push(`${label}: ${translate('restrictions.kayttokieltomaarkats')}`);
    }
    if (item.kayttokieltovalvkats === true) {
      result.push(`${label}: ${translate('restrictions.kayttokieltovalvkats')}`);
    }
  }
  for (const item of (details.rekisterointirajoitukset ?? [])) {
    const label = translate(`restrictions.rekisterointirajoitus`);
    const description = item.rajoituslajiselite;
    result.push(label + (description ? ': ' + description : ''));
  }
  if (details.edellinenmak?.katsastuspaatos === 3) {
    result.push(translate('restrictions.previousScheduledInspectionRejected'));
  }

  // Result.
  return result;
}

function JobDetailsContent({ job }) {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const inspectionLines = useSelector(state => state.inspectionLines.allInspectionLines);
  const vehicleBrands = useSelector(state => state.jobs.vehicleBrands);

  if (!job) {
    return <div />;
  }

  // Get restrictions.
  const warningInformation = getWarningInformation(job, translate);

  const reservationType = RESERVATION_TYPES.get(job.reservationTypeId) || '';
  const productNames = getProductNames(job.productInformation);

  const vehicleInformation = typeof job.vehicleInformation === 'string'
    ? JSON.parse(job.vehicleInformation || '{}', toCamelCase)
    : job.vehicleInformation;
  const vehicleBrand = vehicleInformation.make
    ? vehicleBrands.find(brand => brand.identifier === vehicleInformation.make)
    : null;

  const customerInformation = typeof job.customerInformation === 'string'
    ? JSON.parse(job.customerInformation || '{}', toCamelCase)
    : job.customerInformation;
  const isCompanyCustomer = Boolean(customerInformation?.companyCustomer);
  const customerName = isCompanyCustomer
    ? customerInformation?.name || '-'
    : `${customerInformation?.firstName || '-'} ${customerInformation?.lastName || '-'}`;

  const reservationTimeValue = reservationType === DRIVE_IN ? '-' : getDateAndTime(new Date(job.reservationTime), translate);
  const checkInTimeValue = getCheckInTime(job.checkedinAt, translate);
  const workStartTime = job.inProgressAt ? getDateAndTime(new Date(job.inProgressAt), translate) : '-';

  const inspectionLine = job.lineId ? inspectionLines.find(line => line.id === job.lineId) : null;
  let lineTitle = inspectionLine?.name;
  if (inspectionLine?.deletedAt) {
    lineTitle = `${lineTitle} (${lowerCaseFirstLetter(translate('linesView.deleted'))})`;
  }

  const hasFourWheelDrive = isFourWheelDrive(job.atjInformation?.sanoma?.ajoneuvo?.teknisettiedot?.akselit);

  return (
    <div className={classes.content}>
      <div className={classes.contentTitle}>
        <div>{productNames || '-'}</div>
        <div>{`ID: ${job.externalJobId || '-'}`}</div>
      </div>

      <div className={classes.additionalInfoContainer}>
        <div className={classes.section}>
          <div>{translate('workQueueView.additionalInformation')}</div>
          <div>
            {job.additionalInformation || '-'}
          </div>
        </div>
        {warningInformation?.length > 0 && (
          <div className={classes.errorMessage}>
            {warningInformation.map((info, index) => <span key={index}>{info}</span>)}
          </div>
        )}
        {(hasFourWheelDrive) && (
          <div className={classes.fourWheelDrive}>
            <InfoMessage message={translate('brakeAbsorber.fourWheelDrive')} />
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div>{translate('workQueueView.makeAndModel')}</div>
        <div>{`${vehicleBrand?.shortlegend || '-'} ${vehicleInformation.vehicleModel || ''}`}</div>
      </div>

      <div className={classes.columnsContainer}>
        <div>
          <div className={classes.section}>
            <div>{isCompanyCustomer ? translate('workQueueView.companyCustomer') : translate('workQueueView.customer')}</div>
            <div>{customerName || '-'}</div>
          </div>
          <div className={classes.section}>
            <div>
              {translate(`reservationTypes.${reservationType}`)}
            </div>
            <div>{reservationTimeValue}</div>
          </div>
          <div className={classes.section}>
            <div>{translate('workQueueView.checkInTime')}</div>
            <div>{checkInTimeValue}</div>
          </div>
        </div>

        <div>
          <div className={classes.section}>
            <div>{translate('workQueueView.phoneNumber')}</div>
            <div>{customerInformation?.phone || '-'}</div>
          </div>
          <div className={clsx(classes.section, { [classes.errorSection]: !job.paymentStatus })}>
            <div>{translate('workQueueView.paymentStatus')}</div>
            <div>
              {job.paymentStatus ? translate('workQueueView.paid') : translate('workQueueView.notPaid')}
            </div>
          </div>
          <div className={classes.section}>
            <div>{translate('workQueueView.workStartTime')}</div>
            <div>{workStartTime}</div>
          </div>
        </div>
      </div>

      {job.status === JOB_STATUS[ON_HOLD] && (
        <div className={classes.additionalInfoContainer}>
          <div className={classes.section}>
            <div>{translate('workQueueView.onHoldReason')}</div>
            <div>{job.onHoldReason || '-'}</div>
          </div>
        </div>
      )}

      <div className={classes.additionalInfoContainer}>
        <div className={classes.section}>
          <div>{translate('workQueueView.line')}</div>
          <div>{inspectionLine ? lineTitle : '-'}</div>
        </div>
      </div>
    </div>
  );
}

JobDetailsContent.propTypes = {
  job: PropTypes.shape({
    registrationNumber: PropTypes.string,
    vehicleClassId: PropTypes.string,
    reservationTypeId: PropTypes.number,
    jobTypeId: PropTypes.number,
    reservationTime: PropTypes.string,
    checkedinAt: PropTypes.string,
    inProgressAt: PropTypes.string,
    status: PropTypes.number,
    paymentStatus: PropTypes.bool,
    lineId: PropTypes.number,
    externalJobId: PropTypes.string,
  }),
};

export default JobDetailsContent;
