import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import theme from './theme';
import TopBar from './components/navigation/TopBar';
import NavBar from './components/navigation/NavBar';
import Routes from './app/Routes';
import RefreshSnackbar from './components/navigation/RefreshSnackbar';
import Notification from './components/navigation/Notification';
import SiteSelectionContainer from './components/sites/SiteSelectionContainer';
import { setAccessToken } from './reducers/authReducer';
import ErrorBoundary from './components/common/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  app: {
    fontFamily: 'Jost, sans-serif',
    backgroundColor: 'white',
    height: '100vh',
    minHeight: 300,
    width: '100vw',
    minWidth: 300,
    display: 'flex',
  },
  frame: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(139.4deg, rgba(98,0,238,0.005) 0.07%, rgba(236,56,188,0.05) 32.27%, rgba(115,3,192,0.05) 71.14%, rgba(98,0,238,0.05) 99.96%, rgba(98,0,238,0.05) 100%)',
    width: 'calc(100vw - 180px)',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  siteSelection: {
    background: 'linear-gradient(139.4deg, rgba(98,0,238,0.005) 0.07%, rgba(236,56,188,0.05) 32.27%, rgba(115,3,192,0.05) 71.14%, rgba(98,0,238,0.05) 99.96%, rgba(98,0,238,0.05) 100%)',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topBar: {
  },
  mainContent: {
    height: 'calc(100vh - 50px)',
    overflow: 'auto',
    zIndex: 1,
  },
}));

const TOKEN_EXPIRE_CHECK_INTERVAL = 1000 * 300;

function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}

function AppRoot() {
  const classes = useStyles();
  const location = useLocation();
  const isRoot = location.pathname === '/' || location.pathname === '';
  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();
  const [exp, setExp] = useState(null);
  const [upn, setUpn] = useState('');

  // Check token expiration interval.
  useInterval(() => {
    const expiresInSeconds = exp * 1000;
    const beforeExpireInSeconds = 600 * 1000; // 10mins.
    if (exp && Date.now() >= new Date(expiresInSeconds - beforeExpireInSeconds)) {
      setExp(null);
    }
  }, TOKEN_EXPIRE_CHECK_INTERVAL);

  useEffect(() => {
    // Initialization not ready yet.
    if(!instance || !accounts) return;

    // Access token has not expired yet, do nothing.
    if (exp) return;

    // Acquire new access token.
    if (inProgress === InteractionStatus.None) {
      const account = accounts[0];
      account.username = account.username ?? upn;
      const accessTokenRequest = {
        scopes: [],
        account
      }

      const handleAccessTokenResponse = (accessTokenResponse) => {
        const {exp: newExp, upn: newUpn} = accessTokenResponse.idTokenClaims;
        const {idToken} = accessTokenResponse;
        setExp(newExp);
        setUpn(newUpn);
        dispatch(setAccessToken(idToken));
      }

      instance.acquireTokenSilent(accessTokenRequest)
        .then(handleAccessTokenResponse)
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
              .then(handleAccessTokenResponse)
              .catch((error) => {
                // Acquire token interactive failure
                console.error(error);
              });
          }
          console.error(error);
        })
    }
  }, [instance, accounts, inProgress, dispatch, exp, upn]);

  // SiteSelection doesn't have NavBar and TopBar components.
  return (
    <>
      {isRoot ? <div className={classes.siteSelection}><SiteSelectionContainer /></div> :
        <div className={classes.app}>
          <NavBar />
          <div className={classes.frame}>
            <TopBar />
            <div className={classes.mainContent}>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      }
      <RefreshSnackbar />
      <Notification />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <Router>
          <AppRoot />
        </Router>
      </MuiPickersUtilsProvider >
    </ThemeProvider>
  );
}

export default App;
