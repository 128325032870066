import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import JobDetailsContent from './JobDetailsContent';
import { translate } from '../../lib/localization';
import { ON_HOLD, JOB_STATUS, CHECKED_IN, IN_PROGRESS, CONDITION_INSPECTION_ID } from '../../lib/jobConstants';
import { compareCaseInsensitive, getBaseJobTypeId } from '../../lib/jobFunctions';
import { isSuperUser } from '../../lib/userFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  list: {
    flex: 1,
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost',
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.black.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
  },
  content: {
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px - 56px)',
      padding: '10px 16px',
    },
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 10,
    '& > div:first-child': {
      fontSize: 22,
      fontWeight: 600,
      flex: 1,
    },
    '& > div:last-child': {
      fontSize: 12,
      color: '#091300',
    },
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: 15,
    },
    '& > div:first-child': {
      fontWeight: 600,
      marginBottom: 3,
    },
    '& > div:last-child': {
      color: theme.palette.black.main,
      opacity: 0.7,
    },
  },
  errorSection: {
    '& > div:last-child': {
      color: '#C50000',
      opacity: 1,
    },
  },
  columnsContainer: {
    display: 'flex',
    borderBottom: '1px solid #E9E9E9',
    paddingBottom: 12,
    '& > div': {
      flex: 1,
    },
    '& > div:last-child': {
      paddingLeft: 20,
    },
  },
  additionalInfoContainer: {
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: '1px solid #E9E9E9',
  },
  errorMessage: {
    padding: '13px 12px',
    backgroundColor: 'rgba(197,0,0,0.1)',
    border: '1px solid #C50000',
    borderRadius: 4,
    color: '#C50000',
  },
  continueButton: {
    height: 56,
    borderRadius: 0,
  },
  continueButtonLabel: {
    '& > div': {
      display: 'flex',
      width: '100%',
      '& > div:first-child': {
        flex: 1,
        marginLeft: 24,
        fontSize: 16,
        letterSpacing: 0.5,
      },
    },
  },
  deleteButton: {
    flex: 1,
  },
}));

/**
 * Get the text for the inspection action button.
 * @param {number} assignedUserId The id of the user assigned to the job.
 * @param {number} userId The id of the current user.
 * @param {number} jobStatus The status of the job.
 * @param {number} baseJobTypeId The base job type id of the job.
 * @returns {string} The text for the inspection action button.
 */
function getStartInspectionText(assignedUserId, userId, jobStatus, baseJobTypeId) {
  if (!userId) {
    return '';
  }
  // Only the assigned user (if job is assigned) can start inspection for checked-in vehicle.
  if (jobStatus === JOB_STATUS[CHECKED_IN] && (!assignedUserId || assignedUserId === userId)) {
    // Return different text for condition inspection.
    if (baseJobTypeId === CONDITION_INSPECTION_ID) {
      return translate('workQueueView.startConditionInspection');
    }
    return translate('workQueueView.startInspection');
  }
  // Only the assigned user can continue inspection for job in progress. Anyone can continue a job on hold.
  if (jobStatus === JOB_STATUS[ON_HOLD] || (jobStatus === JOB_STATUS[IN_PROGRESS] && assignedUserId === userId)) {
    // Return different text for condition inspection.
    if (baseJobTypeId === CONDITION_INSPECTION_ID) {
      return translate('workQueueView.continueConditionInspection');
    }
    return translate('workQueueView.continueInspection');
  }
}

//#region Job details view for desktop.
function JobDetailsDialog({ job, open, onClose, onDelete, title, inspectionActionText, onStartInspection, children }) {
  const classes = useStyles();

  // Only inprogress job can be deleted by super.
  const user = useSelector(state => state.auth.user);
  const isSuper = isSuperUser(user);
  const canDelete = job?.status === JOB_STATUS[IN_PROGRESS] && isSuper;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {canDelete && <div className={classes.deleteButton}>
          <Button onClick={onDelete} color='primary' variant='outlined'>
            {translate('delete')}
          </Button>
        </div>}
        <Button onClick={onClose} color='primary'>
          {translate('cancel')}
        </Button>
        {(Boolean(inspectionActionText) && onStartInspection) && (
          <Button
            onClick={onStartInspection}
            color='primary'
            variant='contained'
          >
            {inspectionActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

JobDetailsDialog.propTypes = {
  job: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  startInspectionEnabled: PropTypes.bool,
  onStartInspection: PropTypes.func,
  onDelete: PropTypes.func,
};

JobDetailsDialog.defaultProps = {
  startInspectionEnabled: false,
};
//#endregion

//#region Job details view for mobile.
function JobDetailsDrawer({ open, onClose, title, inspectionActionText, onStartInspection, children }) {
  const classes = useStyles();

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    onClose();
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div className={classes.mainContainer}>
        <div
          className={classes.list}
          role='presentation'
          onKeyDown={toggleDrawer}
        >
          <div className={classes.header}>
            <div>
              <IconButton onClick={onClose}>
                <ArrowBackRoundedIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
            <div className={classes.title}>{title}</div>
          </div>
          {children}
        </div>
        {(inspectionActionText && onStartInspection) && (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            className={classes.continueButton}
            classes={{ label: classes.continueButtonLabel }}
            onClick={onStartInspection}
          >
            <div>
              <div>{inspectionActionText}</div>
              <ArrowForwardRoundedIcon />
            </div>
          </Button>
        )}
      </div>
    </Drawer>
  );
}

JobDetailsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  startInspectionEnabled: PropTypes.bool,
  onStartInspection: PropTypes.func,
};

JobDetailsDrawer.defaultProps = {
  startInspectionEnabled: false,
};
//#endregion

function JobDetails({ open, onClose, onDelete, job, onStartInspection, onContinueInspection }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useSelector(state => state.auth.user);
  const inspectors = useSelector(state => state.users.inspectionUsers);

  const title = job ? `${job.registrationNumber || '-'}, ${job.vehicleClassId || '-'}` : '';
  const userInspector = inspectors.find(inspector => compareCaseInsensitive(inspector.userId, user.id));
  const userInspectorId = userInspector ? userInspector.id : '';
  const startInspectionText = job ? getStartInspectionText(job.assignedUserId, userInspectorId, job.status, getBaseJobTypeId(job.productInformation)) : '';

  const handleStart = job?.status === JOB_STATUS[IN_PROGRESS] ? onContinueInspection : onStartInspection;

  return isMobileScreen ? (
    <JobDetailsDrawer
      open={open}
      onClose={onClose}
      title={title}
      inspectionActionText={startInspectionText}
      onStartInspection={handleStart}
    >
      <JobDetailsContent job={job} />
    </JobDetailsDrawer>
  ) : (
    <JobDetailsDialog
      open={open}
      onClose={onClose}
      onDelete={() => onDelete(job)}
      title={title}
      inspectionActionText={startInspectionText}
      onStartInspection={handleStart}
      job={job}
    >
      <JobDetailsContent job={job} />
    </JobDetailsDialog>
  );
}

JobDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job: PropTypes.shape({
    registrationNumber: PropTypes.string,
    vehicleClassId: PropTypes.string,
    reservationTypeId: PropTypes.number,
    jobTypeId: PropTypes.number,
    reservationTime: PropTypes.string,
    checkedinAt: PropTypes.string,
    inProgressAt: PropTypes.string,
    assignedUserId: PropTypes.number,
    status: PropTypes.number,
    paymentStatus: PropTypes.bool,
    lineId: PropTypes.number,
  }),
  onStartInspection: PropTypes.func,
  onContinueInspection: PropTypes.func,
}

export default JobDetails;
