import { VehicleGroupStore } from './vehicleGroupStore.js';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';
import css from './vehicle-group-detail-css';

const NAME = 'name';
const ID = 'id';
const DESCRIPTION = 'description';
const VEHICLEGROUP = 'vehicleGroupName'
const COLUMN_IDS = [ID, DESCRIPTION, VEHICLEGROUP];

window.customElements.define('vehicle-group-detail-view', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this.store = new VehicleGroupStore();
    this._state = {
      isNew: false,
      data: [],
      formData: {},
      vehicleGroupId: null,
      vehicleGroupData: null,
      sortBy: ID,
      sortDirection: 'asc' ,
      isBuilt: false
     };

    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
    this.addEventListener('dataTableRowClick', this.handleDataTableRowClick);
  }

  set vehicleGroupId(val)
  {
    this._state.isNew = false;
    this._state.vehicleGroupId = val;
  }

  set isNew(val)
  {
    this._state.isNew = val;
  }

  connectedCallback() {
    this.addRoot();
    this.refreshData().then( () =>
    window.requestAnimationFrame( async () => {
      await this.build();
      await this.render();
    }));
  }

  disconnectedCallback() {
    this.resetState();
  }

  resetState() {
    this.store.clearData();
    this._state.isNew = false;
    this._state.data = [];
    this._state.formData = {};
    this._state.vehicleGroupId = null;
    this._state.vehicleGroupData = null;
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    if (!this.controlState.chainId && controlState.chainId) {
      this.refreshData();
    }
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
    this.refreshVehicleClasses();
  }

  build = async () => {
    this.addTitle();
    this.addForm();
    this.addTableTitle();
    this.addDataTable();
    this.addButtons();
    this._state.isBuilt = true;
  }

  addTitle() {
    this.titleContainer = document.createElement('div');
    this.titleContainer.setAttribute('class', 'title');
    this.titleContainer.textContent = this._state.isNew ?  this.translationService.translate( 'vehicleGroupsView.addVehicleGroup' )
                                                        : this._state.vehicleGroupData.name;
  }

  addTableTitle() {
    this.tableTitleContainer = document.createElement('div');
    this.tableTitleContainer.setAttribute('class', 'table-title');
    this.tableTitleContainer.textContent = this.translationService.translate( 'vehicleGroupsView.chooseVehicleClasses' )
  }

  addDataTable() {
    this.dataTable = document.createElement('data-table');
    this.dataTable.selectable = true;
    this.dataTable.selectedIds = this._state.vehicleGroupData?.vehicleClasses != null ? this._state.vehicleGroupData.vehicleClasses.map( vc => vc.id) : [];
    this.dataTable.dataAndLayout = {
      data: this._state.data,
      columns: this.getTableColumnDefinitions(),
      sortBy: this._state.sortBy,
      sortDirection: this._state.sortDirection,
    };
  }

  addForm()
  {
    this.formContainer = document.createElement('div');
    this.formContainer.setAttribute('id', 'form-container');
    this.formContainer.setAttribute('class', 'form-container');
    this.addBasicVehicleGroupInfoFields();
  }


  addButtons() {
    this.buttonContainer = document.createElement('div');
    this.buttonContainer.setAttribute('class', 'buttons-container');
    const saveButton = document.createElement('button');
    saveButton.setAttribute('id', 'save-button');
    saveButton.setAttribute('class', 'primary');
    saveButton.textContent = this.translationService.translate('save');
    saveButton.onclick = this.handleSave;
    this.buttonContainer.appendChild(saveButton);
    const cancelButton = document.createElement('button');
    cancelButton.setAttribute('id', 'cancel-button');
    cancelButton.textContent = this.translationService.translate('cancel');
    cancelButton.onclick = () => { this.goToVehicleGroupsView(); }
    this.buttonContainer.appendChild(cancelButton);
    const deleteButton = document.createElement('button');
    deleteButton.setAttribute('class', 'delete-button');
    deleteButton.textContent = this.translationService.translate('vehicleGroupsView.deleteGroup');
    deleteButton.onclick = () => { this.addDeleteConfirmationDialog(); }
    this.buttonContainer.appendChild(deleteButton);  }

  addRoot = async () => {
    const root = document.createElement('div');
    root.setAttribute('id', 'vehicle-group-detail-view');
    root.setAttribute('class', 'main-container');
    this.root = root;
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.shadowRoot.appendChild(root);
    this.confirmationDialog = document.createElement('confirmation-dialog');
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`vehicleGroupsView.${columnId}`),
        sortable: false
      };
    });
  }

  getBasicInfoInputDefinitions = () => [
    {
      id: NAME,
      label: this.translationService.translate('vehicleGroupsView.name'),
      maxLength: 200,
    },
    {
      id: DESCRIPTION,
      label: this.translationService.translate('vehicleGroupsView.description'),
      maxLength: 2000,
    }
  ];

  addBasicVehicleGroupInfoFields = () => {
    const basicInfoContainer = document.createElement('div');
    basicInfoContainer.setAttribute('class', 'form-column');
    this.formContainer.appendChild(basicInfoContainer);

    this.getBasicInfoInputDefinitions().forEach(inputDef => {
      const inputContainer = document.createElement('div');
      basicInfoContainer.appendChild(inputContainer);

      const inputLabel = document.createElement('div');
      inputLabel.setAttribute('class', 'input-label');
      inputLabel.textContent = inputDef.label;
      inputContainer.appendChild(inputLabel);

      // Set the actual data in inputs
      const input = document.createElement('input');
      input.setAttribute('id', inputDef.id);
      input.setAttribute('maxlength', inputDef.maxLength);
      if( !this._state.isNew)
      {
        input.setAttribute('value', this._state.vehicleGroupData[inputDef.id]);
      }

      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
      inputContainer.appendChild(input);

    });
  }

  addDeleteConfirmationDialog = () => {
    this.addConfirmationDialog(
      `${this.translationService.translate('vehicleGroupsView.deleteConfirmation')} '${this._state.vehicleGroupData.name}'?`,
      this.deleteGroup,
      this.translationService.translate('vehicleGroupsView.deleteGroup'),
      this.translationService.translate('cancel')
    );
  }

  addConfirmationDialog = (content, onAccept, acceptButtonText, cancelButtonText) => {
    window.requestAnimationFrame(() => {
      this.root.appendChild(this.confirmationDialog);
      this.confirmationDialog.values = {
        content,
        onAccept,
        acceptButtonText,
        onCancel: this.removeConfirmationDialog,
        cancelButtonText: cancelButtonText
      };
    });
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.confirmationDialog));
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
  }

  goToVehicleGroupsView() {
    const navEvent = new CustomEvent('vehicleGroupNavigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: 'vehicleGroups',
        data: {},
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  render = async () => {
    this.root.appendChild(this.titleContainer);
    this.root.appendChild(this.formContainer);
    this.root.appendChild(this.tableTitleContainer);
    this.root.appendChild(this.dataTable);
    this.root.appendChild(this.buttonContainer);
  }

  handleSave = async () => {
    if(this._state.isNew)
    {
      await this.createVehicleGroup();
    }
    else {
      await this.updateVehicleGroup();
    }
  }

  updateVehicleGroup = async () => {
    try {
      this._fetchingData = true;
      await this.store.updateVehicleGroup(this.controlState.chainId,{
        chainId: this.controlState.chainId,
        vehicleGroupId: this._state.vehicleGroupData['id'],
        name: this._state.formData['name'],
        description: this._state.formData['description'],
        vehicleClasses: this.dataTable.selectedIds.map( (i) => { return {id: i} })
      });
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('vehicleGroupsView.vehicleGroupUpdated'));
      this.goToVehicleGroupsView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('vehicleGroupsView.saveFailed'), 'error');
      this.goToVehicleGroupsView();
      this._fetchingData = false;
    }
  }

  deleteGroup = async () => {
    try {
      this._fetchingData = true;
      await this.store.deleteVehicleGroup(this.controlState.chainId,this._state.vehicleGroupId);
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('vehicleGroupsView.vehicleGroupDeleted'));
      this.goToVehicleGroupsView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('vehicleGroupsView.saveFailed'), 'error');
      this.goToVehicleGroupsView();
      this._fetchingData = false;
    }
  }


  createVehicleGroup = async () => {
    try {
      this._fetchingData = true;
      await this.store.createVehicleGroup(this.controlState.chainId,{
        chainId: this.controlState.chainId,
        name: this._state.formData['name'],
        description: this._state.formData['description'] || '',
        vehicleClasses: this.dataTable.selectedIds.map( (i) => { return {id: i} })
      });
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('vehicleGroupsView.vehicleGroupCreated'));
      this.goToVehicleGroupsView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('vehicleGroupsView.saveFailed'), 'error');
      this.goToVehicleGroupsView();
      this._fetchingData = false;
    }
  }


  refreshData = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    if(!this._state.isNew)
    {
      await this.refreshVehicleGroup();
    }
    await this.refreshVehicleClasses();

  }

  refreshVehicleClasses = async () => {
    try
    {
      //Get vehicleClasses
      const { sortBy, sortDirection } = this._state;
      const data = await this.store.getVehicleClassesInChain(this.translationService, this.controlState.chainId, this._state.vehicleGroupId, sortBy, sortDirection);
      this._state.data = data;
      if( this._state.isBuilt)
      {
        this.dataTable.dataAndLayout = {
          data: this._state.data,
          columns: this.getTableColumnDefinitions(),
          sortBy: this._state.sortBy,
          sortDirection: this._state.sortDirection,
        };
      }

    } catch (error) {
      sendNotificationEvent(this.translationService.translate('error'), 'error');
      this.goToVehicleGroupsView();
      this._fetchingData = false;
    }
  }

  refreshVehicleGroup = async () =>
  {
    try
    {
      //Get selected vehicleGroup
      const vehicleGroup = await this.store.getVehicleGroup(this.controlState.chainId, this.translationService, this._state.vehicleGroupId);
      this._state.vehicleGroupData = vehicleGroup[0]; //TODO: fix API return single instead of array
      this._state.formData = vehicleGroup[0];
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('error'), 'error');
      this.goToVehicleGroupsView();
      this._fetchingData = false;
    }
  }

});