import COMPONENT_FAULT_CODES from './componentFaultCodes';
import FAULT_CODES from './faultCodes';
import FAULT_LOCATIONS, { HIGHLIGHTED_LOCATIONS } from './faultLocations';

export const LANGUAGE_COUNT = 2;

export const SEVERITY_LEVEL_REPAIR_RECOMMENDATION = 1;
export const SEVERITY_LEVEL_REJECTED = 2;
export const SEVERITY_LEVEL_VEHICLE_IMMOBILISED = 3;

// Fault sections.
export const MAIN_FAULT = 'mainFault';
export const MAIN_FAULT_ID = 'mainFaultId';
export const SUB_FAULT = 'subFault';
export const SUB_FAULT_ID = 'subFaultId';
export const COMPONENT_FAULT = 'componentFault';
export const COMPONENT_FAULT_ID = 'componentFaultId';
export const FAULT = 'fault';
export const FAULT_ID = 'faultId';
export const LOCATION = 'location';
export const MAIN_FAULT_LOCATION_ID = 'mainFaultLocationId';
export const SUB_FAULT_LOCATION_ID = 'subFaultLocationId';

/**
 * Fault severity titles.
 */
export const SEVERITY_CODES = new Map([
  [SEVERITY_LEVEL_REPAIR_RECOMMENDATION, ['Korjauskehotus', 'Reparationsuppmaning']],
  [SEVERITY_LEVEL_REJECTED, ['Hylätty', 'Icke godkänd']],
  [SEVERITY_LEVEL_VEHICLE_IMMOBILISED, ['Ajokielto', 'Körförbjud']],
]);

/**
 * Returns severity text of the given fault object.
 * @param {object} fault object
 * @param {int} languageId language id.
 * @returns {string} severity text.
 */
export function getSeverityText(fault, languageId) {
  return SEVERITY_CODES.get(fault.severityLevel)[languageId];
}

/**
 * Main fault codes, for easier access. Exported from the db.
 */
export const MAIN_FAULT_CODES = new Map([
  [0, ['Ajoneuvon tunnistus', 'Fordonets identifiering']],
  [1, ['Jarrujärjestelmät', 'Bromslänksystemen']],
  [2, ['Valaisimet ja sähkölaitteet', 'Belysning och elektrisk utrustningar']],
  [3, ['Ympäristöhaitat', 'Miljöskador']],
  [4, ['Alusta ja kori', 'Chassi och karosseri']],
  [5, ['Akselistot, pyörät ja jousitus', 'Axlar, däck och fjädring']],
  [6, ['Ohjauslaitteet', 'Styrinrättning']],
  [7, ['Muut laitteet ja varusteet', 'Övriga anordningar']],
  [8, ['Koeajo ja näkyvyys', 'Provkörning och sikt']],
  [9, ['Lisätarkastukset', 'Övriga inspektioner']],
  [10, ['Kaasuajoneuvojen lisätarkastukset', 'Extra kontroller för gasfordon']],
  [11, ['Sähkö- ja hybridiajoneuvojen lisätarkastukset', 'Extra kontroller för el- och hybridfordon']],
]);

/**
 * Returns fault title of the given fault object.
 * @param {object} fault object
 * @param {int} languageId language id.
 * @returns {string} fault text.
 */
export function getFaultTitle(fault, languageId) {
  return FAULT_CODES.get(fault.faultId)[languageId];
}

/**
 * Returns sub fault title of the given fault.
 * @param {object} fault object
 * @param {int} languageId language id.
 * @returns {string} sub fault text.
 */
export function getSubFaultTitle(fault, languageId) {
  const subFaultCodes = COMPONENT_FAULT_CODES.get(fault.mainFaultId)[LANGUAGE_COUNT];
  return subFaultCodes.get(fault.subFaultId)[languageId];
}

/**
 * Returns component fault title of the given fault.
 * @param {object} fault object
 * @param {int} languageId language id.
 * @returns {string} component text.
 */
export function getComponentFaultTitle(fault, languageId) {
  const subFaultCodes = COMPONENT_FAULT_CODES.get(fault.mainFaultId)[LANGUAGE_COUNT];
  const componentFaultCodes = subFaultCodes.get(fault.subFaultId);
  return componentFaultCodes.slice(LANGUAGE_COUNT).find(cfc => cfc[0] === fault.componentFaultId)[languageId + 1];
}

/**
 * Returns location title of the given fault.
 * @param {object} fault object
 * @param {int} languageId language id.
 * @returns {string} component text.
 */
export function getFaultLocationTitle(fault, languageId) {
  const mainLocation = FAULT_LOCATIONS.get(fault.mainFaultLocationId);
  const mainLocationTitle = mainLocation ? mainLocation[languageId] : '-';
  const subLocations = mainLocation?.slice(LANGUAGE_COUNT) ?? [];
  const subLocation = fault.subFaultLocationId ? subLocations?.find(l => l[0] === fault.subFaultLocationId) : null;
  return subLocation ? subLocation[languageId + 1] : mainLocationTitle;
}

/**
 * Returns main fault ID of fault code.
 * @param {string} faultCode - Full fault code.
 * @returns {number} - Main fault ID.
 */
export function getMainFaultId(faultCode) {
  if (!faultCode || faultCode.length < 2) {
    return null;
  }
  return parseInt(faultCode.substring(0, 2), 10);
}

/**
 * Returns main fault data according to ID.
 * @param {number} mainFaultId - Main fault ID.
 * @returns {Array} - Main fault data.
 */
export function getMainFault(mainFaultId) {
  if (!Number.isInteger(mainFaultId)) {
    return null;
  }
  return COMPONENT_FAULT_CODES.get(mainFaultId);
}

export function getMainFaultTitle(fault, languageId) {
  if (!Number.isInteger(fault.mainFaultId)) {
    return null;
  }
  return COMPONENT_FAULT_CODES.get(fault.mainFaultId)[languageId];
}

/**
 * Returns sub fault ID of fault code.
 * @param {string} faultCode - Full fault code.
 * @returns {number} - Sub fault ID.
 */
export function getSubFaultId(faultCode) {
  if (!faultCode || faultCode.length < 4) {
    return null;
  }
  return parseInt(faultCode.substring(2, 4), 10);
}

/**
 * Returns sub fault data according to ID.
 * @param {number} subFaultId - Sub fault ID.
 * @param {Array} mainFault - Main fault data.
 * @returns {Array} - Sub fault data.
 */
export function getSubFault(subFaultId, mainFault) {
  if (!Number.isInteger(subFaultId) || !mainFault) {
    return null;
  }
  const subFaults = mainFault[2];
  return subFaults.get(subFaultId);
}

/**
 * Returns component fault ID of fault code.
 * @param {string} faultCode - Full fault code.
 * @returns {number} - Component fault ID.
 */
export function getComponentFaultId(faultCode) {
  if (!faultCode || faultCode.length < 6) {
    return null;
  }
  return parseInt(faultCode.substring(4, 6), 10);
}

/**
 * Returns component fault data according to ID.
 * @param {number} componentFaultId - Component fault ID.
 * @param {Array} subFault - Sub fault data.
 * @returns {Array} - Component fault data.
 */
export function getComponentFault(componentFaultId, subFault) {
  if (!Number.isInteger(componentFaultId) || !subFault) {
    return null;
  }
  const componentFaults = subFault.slice(LANGUAGE_COUNT);
  const componentFault = componentFaults.find((item) => item[0] === componentFaultId);
  // Remove ID from return value to match other results (e.g. sub fault);
  return componentFault ? componentFault.slice(1) : null;
}

/**
 * Returns fault ID of fault code.
 * @param {string} faultCode - Fault ID from user input.
 * @param {Array} componentFault - Component fault data.
 * @returns {number} - Fault ID.
 */
export function getFaultId(faultCode, componentFault) {
  if (!componentFault || !faultCode) {
    return null;
  }
  const faultId = parseInt(faultCode.substring(6), 10);
  if (isNaN(faultId)) {
    return null;
  }

  // Check that fault ID is valid for component fault.
  const faultIds = componentFault[2];
  return faultIds.some(id => id === faultId) ? faultId : null;
}

/**
 * Returns main and sub fault location IDs in given location ID.
 * @param {string} faultLocationId - Full fault location ID.
 * @returns {{ mainFaultLocationId: number, subFaultLocationId: number }} - Parsed location IDs.
 */
export function getFaultLocationIds(faultLocationId) {
  if (!faultLocationId) {
    return { mainFaultLocationId: null, subFaultLocationId: null };
  }
  // Parse main fault location ID.
  const mainLocationIsAxle = faultLocationId.charAt(0) === '-';
  const mainLocationEndIndex = mainLocationIsAxle ? 2 : 1;
  const mainFaultLocationId = parseInt(faultLocationId.substring(0, mainLocationEndIndex), 10);
  if (isNaN(mainFaultLocationId)) {
    return { mainFaultLocationId: null, subFaultLocationId: null };
  }
  // Parse sub fault location ID.
  const subFaultLocationId = parseInt(faultLocationId.substring(mainLocationEndIndex), 10);
  return {
    mainFaultLocationId,
    subFaultLocationId: !isNaN(subFaultLocationId) ? subFaultLocationId : null,
  };
}

/**
 * Returns fault location according to main and sub location IDs.
 * @param {number} mainFaultLocationId - Main fault location ID.
 * @param {number} subFaultLocationId - Sub fault location ID.
 * @returns {Array} - Fault location data.
 */
export function getFaultLocation(mainFaultLocationId, subFaultLocationId) {
  if (!Number.isInteger(mainFaultLocationId)) {
    return null;
  }

  // Axle locations below -6 are not allowed.
  if (mainFaultLocationId < -5) {
    mainFaultLocationId = 0;
    subFaultLocationId = null;
  }

  const mainLocation = FAULT_LOCATIONS.get(mainFaultLocationId);
  if (!Number.isInteger(subFaultLocationId)) {
    return mainLocation;
  }
  const subLocations = mainLocation.slice(2);
  const subLocation = subLocations.find(l => l[0] === subFaultLocationId);
  return subLocation ? subLocation.slice(1) : null;
}

/**
 * Returns ID as string prefixed with zeroes to given length.
 * @param {number} id - ID.
 * @param {number} minLength - Minimum length (default = 1).
 * @returns {string} - Parsed ID.
 */
export function idToString(id, minLength = 1) {
  if (!Number.isInteger(id)) {
    return '';
  }
  return id.toString().padStart(minLength, '0');
}

/**
 * Adds main fault ID to sub fault ID.
 * @param {Array} faultTarget - Fault target.
 * @param {string} mainFaultId - ID of the main fault.
 * @returns {Array} - Fault target with combined ID.
 */
function addPrefixToSubFault(subFault, mainFaultId) {
  const subFaultId = idToString(subFault[0], 2);
  const mfId = idToString(mainFaultId, 2);
  const faultTargetData = subFault[1];
  return [`${mfId}${subFaultId}`, faultTargetData];
}

/**
 * Adds sub fault ID to component fault ID.
 * @param {Array} componentFault - Component fault.
 * @param {string} subFaultId - ID of the sub fault.
 * @returns {Array} - Component fault with combined ID.
 */
function addPrefixToComponentFault(componentFault, subFaultId) {
  const componentId = idToString(componentFault[0], 2);
  return [`${subFaultId}${componentId}`, componentFault.slice(1)];
}

/**
 * Returns filtered main faults.
 * @param {string} faultCode - Filter for main fault IDs.
 * @param {string} nameFilter - Filter for main fault names.
 * @param {number} languageId - Langauge ID (default = 0 (FI)).
 * @returns {Array} - Filtered main faults.
 */
export function getMainFaults(faultCode, nameFilter, languageId = 0) {
  const mainFaultId = getMainFaultId(faultCode);
  let results = [...MAIN_FAULT_CODES.entries()].map((mainFault) => [mainFault[0].toString().padStart(2, '0'), mainFault[1]]);

  // Apply filters.
  if (mainFaultId || nameFilter) {
    const codeFilter = mainFaultId?.toString();
    results = results.filter((mainFault) => {
      const idMatch = !codeFilter || mainFault[0] === codeFilter;
      const nameFilterMatch = !nameFilter || mainFault[1][languageId].toLowerCase().includes(nameFilter.toLowerCase());
      return idMatch && nameFilterMatch;
    });
  }

  return results;
}

/**
 * Returns filtered sub faults filtered by given main fault ID.
 * Sub fault IDs are prefixed by main fault ID.
 * @param {string} faultCode - Full fault code.
 * @param {string} nameFilter - Filter for sub fault names.
 * @param {number} languageId - Langauge ID (default = 0 (FI)).
 * @returns {Array} - Sub faults.
 */
export function getSubFaults(faultCode, nameFilter, languageId = 0) {
  const mainFaultId = getMainFaultId(faultCode);
  const mainTarget = mainFaultId ? COMPONENT_FAULT_CODES.get(mainFaultId) : null;

  let results = [];
  if (mainTarget) {
    // Return fault targets of main target.
    results = [...mainTarget[2].entries()].map((subFault) => addPrefixToSubFault(subFault, mainFaultId));
  } else {
    // Return all fault targets of every main target.
    results = [...COMPONENT_FAULT_CODES.entries()]
      .flatMap((mainTarget) => {
        const subFaults = [...mainTarget[1][LANGUAGE_COUNT].entries()];
        return subFaults.map((subFault) => addPrefixToSubFault(subFault, mainTarget[0]));
      });
  }

  const codeFilter = faultCode.substring(0, 3);
  if (codeFilter || nameFilter) {
    // Apply filters.
    results = results.filter((subFault) => {
      const codeFilterMatch = !codeFilter || subFault[0].indexOf(codeFilter) === 0;
      const nameFilterMatch = !nameFilter || subFault[1][languageId].toLowerCase().includes(nameFilter.toLowerCase());
      return codeFilterMatch && nameFilterMatch;
    });
  }

  return results;
}

/**
 * Returns filtered component faults filtered by given main fault and fault target IDs.
 * Component fault IDs are prefixed by main fault and sub fault IDs.
 * @param {string} faultCode - Full fault code.
 * @param {string} nameFilter - Filter for component fault names.
 * @param {number} languageId - Language ID (default = 0 (FI)).
 * @returns {Array} - Component faults.
 */
export function getComponentFaults(faultCode, nameFilter, languageId = 0) {
  const subFaults = getSubFaults(faultCode);
  const subFaultId = getSubFaultId(faultCode);
  const stringSubFaultId = idToString(subFaultId, 2);
  const subFault = subFaultId ? subFaults.find(item => item[0] === stringSubFaultId) : null;
  let results = [];

  if (subFault) {
    // Return component faults of selected sub faults.
    const componentFaults = subFault[1].slice(LANGUAGE_COUNT);
    results = componentFaults.map((component) => addPrefixToComponentFault(component, subFault[0]));
  } else {
    // Return all component faults.
    results = subFaults.flatMap((subFault) => {
      const componentFaults = subFault[1].slice(LANGUAGE_COUNT);
      return componentFaults.map((component) => addPrefixToComponentFault(component, subFault[0]));
    });
  }

  // Apply filters.
  const componentFaultCodeFilter = faultCode.substring(0, 5);
  if (componentFaultCodeFilter || nameFilter) {
    results = results.filter((subFault) => {
      const codeFilterMatch = !componentFaultCodeFilter || subFault[0].indexOf(componentFaultCodeFilter) === 0;
      const nameFilterMatch = !nameFilter || subFault[1][languageId].toLowerCase().includes(nameFilter.toLowerCase());
      return codeFilterMatch && nameFilterMatch;
    });
  }
  return results;
}

/**
 * Returns faults filtered by given main fault, sub fault and component fault IDs.
 * Fault IDs are prefixed by main fault, sub fault and component fault IDs.
 * @param {string} faultCode - Full fault code (min. 6 characters required).
 * @param {string} nameFilter - Filter for fault names.
 * @param {number} languageId - Langauge ID (default = 0 (FI)).
 * @returns {Array} - Faults.
 */
export function getFaults(faultCode, nameFilter, languageId = 0) {
  if (!Number.isInteger(getComponentFaultId(faultCode)) || faultCode.length < 6) {
    // Skip if no component selected.
    return [];
  }

  const componentFaults = getComponentFaults(faultCode);
  const componentFaultFilter = faultCode.substring(0, 6);
  const componentFault = componentFaults.find(c => c[0] === componentFaultFilter);
  if (!componentFault) {
    return [];
  }

  // Return all faults with code prefixes.
  const faultIds = componentFault[1][LANGUAGE_COUNT];
  let results = faultIds.map((id) => [componentFaultFilter + id, FAULT_CODES.get(id)]);

  // Apply filters.
  const codeFilter = faultCode.substring(0, 9);
  if (codeFilter || nameFilter) {
    results = results.filter((subFault) => {
      const codeFilterMatch = !codeFilter || subFault[0].indexOf(codeFilter) === 0;
      const nameFilterMatch = !nameFilter || subFault[1][languageId].toLowerCase().includes(nameFilter.toLowerCase());
      return codeFilterMatch && nameFilterMatch;
    });
  }

  return results;
}

/**
 * Returns fault locations filtered by given fault location ID.
 * Location IDs are prefixed by main fault, sub fault, component fault and fault IDs.
 * @param {string} faultLocationId - ID of fault location.
 * @param {string} nameFilter - Filter for location names.
 * @param {number} languageId - Langauge ID (default = 0 (FI)).
 * @returns {Array} - Fault locations.
 */
export function getFaultLocations(faultLocationId, nameFilter, languageId = 0) {
  if (!faultLocationId || faultLocationId.length === 0) {
    // Show all main locations.
    let results = [...FAULT_LOCATIONS.entries()];
    if (nameFilter) {
      results = results.filter(item => item[1][languageId].toLowerCase().includes(nameFilter.toLowerCase()));
    }
    return results.map((location) => [location[0].toString(), location[1]]);
  }

  // Filter fault locations according to fault location ID.
  const mainLocationIsAxle = faultLocationId.charAt(0) === '-';
  const mainLocationEndIndex = mainLocationIsAxle ? 2 : 1;
  const stringMainFaultLocationId = faultLocationId.substring(0, mainLocationEndIndex);
  const mainFaultLocationId = parseInt(faultLocationId.substring(0, mainLocationEndIndex), 10);
  if (mainLocationIsAxle && mainFaultLocationId === 0) {
    // Invalid value, axle location cannot be equal to zero.
    return [];
  }

  const mainFaultLocation = FAULT_LOCATIONS.get(mainFaultLocationId);
  if (!mainFaultLocation) {
    // Main fault location not found - filter main fault location options.
    return [...FAULT_LOCATIONS.entries()]
      .map(item => [item[0].toString(), item[1]])
      .filter(item => {
        const codeFilterMatch = item[0].includes(stringMainFaultLocationId);
        const nameFilterMatch = !nameFilter || item[1][languageId].toLowerCase().includes(nameFilter.toLowerCase());
        return codeFilterMatch && nameFilterMatch;
      });
  }

  // Main fault location found - list sub fault locations.
  const subFaultLocations = mainFaultLocation.slice(2);
  const stringSubFaultLocationId = faultLocationId.substring(mainLocationEndIndex);
  const subFaultLocationId = parseInt(stringSubFaultLocationId, 10);
  let results = subFaultLocations;

  // Apply filter if needed.
  if (Number.isInteger(subFaultLocationId) || nameFilter) {
    results = results.filter((item) => {
      const idFilterMatch = !Number.isInteger(subFaultLocationId) || item[0].toString().indexOf(stringSubFaultLocationId) === 0;
      const nameFilterMatch = !nameFilter || item[languageId + 1].toLowerCase().includes(nameFilter.toLowerCase());
      return idFilterMatch && nameFilterMatch;
    });
  }

  return results.map((item) => [`${mainFaultLocationId}${item[0]}`, item.slice(1)]);
}

/**
 * Returns fault location highlights.
 * @returns {Array} - Fault location highlights.
 */
export function getFaultLocationHighlights() {
  return HIGHLIGHTED_LOCATIONS.map((highlightId) => {
    // Find main location ID.
    const mainLocationId = highlightId.charAt(0);
    const mainLocation = FAULT_LOCATIONS.get(parseInt(mainLocationId, 10));
    const subLocationOptions = mainLocation.slice(LANGUAGE_COUNT);

    // Find sub location ID.
    const subLocationId = parseInt(highlightId.substring(1), 10);
    const subLocation = subLocationOptions.find(option => option[0] === subLocationId);
    return [highlightId, subLocation.slice(1)];
  });
}

/**
 * Determines if fault has all required IDs.
 * @param {Object} fault - Fault data.
 * @returns {boolean} - True if fault is valid.
 */
export function isFaultValid(fault) {
  const keys = [MAIN_FAULT_ID, SUB_FAULT_ID, COMPONENT_FAULT_ID, FAULT_ID, MAIN_FAULT_LOCATION_ID];
  return fault && keys.every(key => Number.isInteger(fault[key]));
}

/**
 * Determines if favorite fault has all required IDs.
 * @param {Object} fault - Fault data.
 * @returns {boolean} - True if fault is valid.
 */
export function isFavoriteFaultValid(fault) {
  const keys = [MAIN_FAULT_ID, SUB_FAULT_ID, COMPONENT_FAULT_ID, FAULT_ID];
  return fault && keys.every(key => Number.isInteger(fault[key]));
}


/**
 * Returns default severity for fault.
 * @param {string} faultCode - Fault code.
 * @param {number} - Default fault severity.
 * @returns {number} - Severity (1-3). 0 if no severity for fault.
 */
export function getFaultSeverity(faultCode) {
  if (!faultCode || faultCode.length < 6) {
    return null;
  }

  const mainFaultId = getMainFaultId(faultCode);
  const mainFault = getMainFault(mainFaultId);

  const subFaultId = getSubFaultId(faultCode);
  const subFault = getSubFault(subFaultId, mainFault);

  const componentFaultId = getComponentFaultId(faultCode);
  const componentFault = getComponentFault(componentFaultId, subFault);

  const faultId = getFaultId(faultCode, componentFault);

  if (!componentFault || !Number.isInteger(faultId)) {
    return null;
  }

  // Find severity for fault.
  const faultIds = componentFault[2];
  const severities = componentFault[3];
  const index = faultIds.findIndex(id => id === faultId);
  return severities[index];
}
