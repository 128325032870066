export default `
.main-container {
    margin: 0px;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 4px;
    min-height: 620px;
  }
  
  .title-container {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .form-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
  }

  .form-column
  {
    width: calc(100%-24px)
  }
  
  .title {
    font-size: 24px;
    color: rgba(0,0,0,0.87);
    margin: 20px;
    margin-bottom: 10px;
  }

  .table-title {
    font-size: 18px;
    color: rgba(0,0,0,0.42);
    margin: 20px;
  }
  
  .filter-container {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    height: 36px;
    max-width: 340px;
    width: 100%;
    border-radius: 4px;
    padding: 0 10px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .filter-container > input {
    background-color: transparent;
    outline: none;
    border: none;
    margin-left: 10px;
    font-family: var(--font);
    font-size: 16px;
  }
  
  .filter-container > input::placeholder {
    color: rgba(0,0,0,0.6);
  }

  .buttons-container {
    margin: 10px;
  }
  
  button {
    display: inline-block;
    align-items: center;
    margin: 10px;
    height: 36px;
    font-family: var(--font);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.3;
    text-transform: uppercase;
    color: var(--buttonColor);
    background-color: var(--buttonBackground);
    border: 1px solid var(--primaryColor);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 16px;
    min-width: 140px;
    cursor: pointer;
  }
  
  button > img {
    margin-right: 6px;
  }
  
  button:hover {
    background-color: var(--buttonBackgroundHover);
  }
  
  button:focus {
    outline: none;
  }

  .delete-button {
    float: right;
  }
  
.input-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  margin-bottom: 7px;
  margin-top: 15px;
}

input {
  box-sizing: border-box;
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  padding: 0px 12px;
  width: 50ch;
  font-family: var(--font);
}

input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
}

input.small {
  width: 120px;
}

input[error] {
  border-color: var(--errorColor);
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button[disabled].primary:hover {
  background-color: var(--primaryButtonBackground);
}


.dropdown {
  max-height: 500px;
  transform: max-height 0.3s;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  font-size: 16px;
  line-height: 23px;
  border-radius: 4px;
  background-color: #FFFFFF;
  cursor: pointer;
  min-width: 230px;
  transition: max-height 0.3s;
  transition: box-shadow 0.1s;
  overflow: hidden;
  padding: 5px;
  font-family: var(--font);
}

.dropdown-align-right {
  left: inherit;
  right: 0;
}

.dropdown.collapsed {
  max-height: 0px;
  box-shadow: none;
}

.dropdown > div {
  padding: 16px;
}

.dropdown > div:hover {
  color: var(--primaryColor);
}

.dropdown > div:not(:last-child) {
  padding-bottom: 8px;
}

.dropdown > div:not(:first-child) {
  padding-top: 8px;
}

.dropdown-backdrop {
  z-index: 100;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  cursor: default;
}

.dropdown-backdrop.collapsed {
  display: none;
}

.category-container
{
  flex: 1;
}
`;