import { createSlice, createAsyncThunk } from '@reduxjs/toolkit/dist/index';
import { sendChainRequest } from '../api/api';
import { formatFaultReportAverages, formatFaultReportData } from '../lib/faultReportFunctions';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const isOutdatedRequest = (state, requestId) => {
  const pendingRequestId = state.faultReport.requestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

const isOutdatedAveragesRequest = (state, requestId) => {
  const pendingRequestId = state.faultReport.averagesRequestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

export const fetchFaultReport = createAsyncThunk('faultReport/fetchFaultReport', async ({criteria, isImmediate}, { getState, requestId }) => {
  if (! isImmediate) {
    await sleep(500);
  }

  if (isOutdatedRequest(getState(), requestId)) {
    return null;
  }

  const report = await sendChainRequest(criteria.chainId, 'POST', '/fault-report', '', criteria);

  if (isOutdatedRequest(getState(), requestId)) {
    return null;
  }

  return {
    criteria,
    report,
  };
});

export const fetchFaultReportAverages = createAsyncThunk('faultReport/fetchFaultReportAverages', async ({criteria, isImmediate}, { getState, requestId }) => {
  if (! isImmediate) {
    await sleep(500);
  }

  if (isOutdatedAveragesRequest(getState(), requestId)) {
    return null;
  }

  const averages = await sendChainRequest(criteria.chainId, 'POST', '/fault-report/averages', '', criteria);

  if (isOutdatedAveragesRequest(getState(), requestId)) {
    return null;
  }

  return {
    criteria,
    averages,
  };
});

export const fetchFaultReportUsers = createAsyncThunk('faultReport/fetchFaultReportUsers', async ({chainId}, { getState, requestId }) => {
  const users = await sendChainRequest(chainId, 'POST', '/fault-report/users');

  return {
    users,
  };
});

export const exportFaultReport = createAsyncThunk('faultReport/exportFaultReport', async ({chainId, data}, { getState, requestId }) => {
  const report = await sendChainRequest(chainId, 'POST', '/fault-report/export', '', data);
  return report;
});

const faultReportSlice = createSlice({
  name: 'faultReport',
  initialState: {
    data: formatFaultReportData(null),
    averages: null,
    fetching: false,
  },
  reducers: {
    resetFaultReportData: (state) => {
      state.data = formatFaultReportData(null);
      state.criteria = null;
    },
  },
  extraReducers: {
    [fetchFaultReport.pending]: (state, { meta }) => {
        state.data = formatFaultReportData(null);
        state.fetching = true;
        state.requestId = meta.requestId;
    },
    [fetchFaultReport.fulfilled]: (state, { payload }) => {
        if (payload) {
          state.data = formatFaultReportData(payload?.report);
          state.criteria = payload?.criteria ?? null;
          state.fetching = false;
          state.requestId = null;
        }
    },
    [fetchFaultReportAverages.pending]: (state, { meta }) => {
      state.averages = null;
      state.fetching = true;
      state.averagesRequestId = meta.requestId;
    },
    [fetchFaultReportAverages.fulfilled]: (state, { payload }) => {
        if (payload) {
          state.averages = formatFaultReportAverages(payload?.averages);
          state.criteria = payload?.criteria ?? null;
          state.fetching = false;
          state.averagesRequestId = null;
        }
    },
    [fetchFaultReportUsers.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.users = payload.users;
      }
    },
  },
});

export const {
  resetFaultReportData,
} = faultReportSlice.actions;

export default faultReportSlice.reducer;
