// Form fields.
const SITES = 'sites';
const EMPTY_FORM_DATA = {
  [SITES]: [],
};

// Table columns.
const NAME = 'name';
const ADDRESS = 'address';
const PHONE_NUMBER = 'phoneNumber';
const EMAIL = 'email';
const COLUMN_IDS = [NAME, ADDRESS, PHONE_NUMBER, EMAIL];

const ASC = 'asc';

class SiteGroupSitesSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => word };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      sortBy: NAME,
      sortDirection: ASC,
      onChange: null,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ],
    );
  }

  // Life cycle callback, invoked when the custom element is first connected to the document's DOM.
  connectedCallback() {
    // Listen for table events.
    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
    this.addEventListener('dataTableRowClick', this.goToSiteView);

    if (!this._state.isBuilt) {
      this.addRoot();
      this.refreshData();
      this._state.isBuilt = true;
      return;
    }
    this.refreshData();
  }

  disconnectedCallback() {
    this._state = {
      ...this._state,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };
  }

  set onChange(val) {
    this._state.onChange = val;
  }

  set siteGroup(val) {
    this._state.formData = Object.assign({}, {
      [SITES]: val.sites || [],
    });
    this.refreshData();
  }

  set dataLength(val) {
    this._state.dataLength = val;
    this.refreshData();
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
  }

  // Handles data table events.
  handleDataTableEvent = (event) => {
    this._state.sortBy = event.detail.sortBy;
    this._state.sortDirection = event.detail.sortDirection;
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'site-group-site-section');
    this.shadowRoot.appendChild(this.root);
    this.dataTable = document.createElement('data-table');
    this.root.appendChild(this.dataTable);
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`siteGroupView.${columnId}`),
        sortable: true,
        primary: columnId === NAME,
      };
    });
  }

  refreshData = async () => {
    const { sortBy, sortDirection, dataLength } = this._state;
    this.dataTable.dataAndLayout = {
      data: this._state.formData.sites,
      columns: this.getTableColumnDefinitions(),
      sortBy: sortBy,
      sortDirection: sortDirection,
      paginated: true,
      removable: true,
    };
    this.dataTable.totalItemCount = dataLength;
  }
}

window.customElements.define('site-group-sites-section', SiteGroupSitesSection);
