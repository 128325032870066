import dayjs from 'dayjs'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit/dist/index';
import { sendChainRequest, sendRequest } from '../api/api';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const isOutdatedListRequest = (state, requestId) => {
  const pendingRequestId = state.improvementTarget.listRequestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

const isOutdatedMonitoringRequest = (state, requestId) => {
  const pendingRequestId = state.improvementTarget.monitoringRequestId;

  return pendingRequestId && pendingRequestId !== requestId;
}

const getRefKey = (item) => {
  if (item.source === 2) {
    return 'trafi';
  } else if (item.source === 1) {
    return item.chainId === 0 ? 'doris' : 'chain';
  } else {
    return 'site';
  }
}

const formatImprovementTargetMonitoring = (monitoring) => {
  const data = {}
  const refs = {}

  const startDate = dayjs(monitoring.valuesStartDate);
  const endDate = dayjs(monitoring.valuesEndDate ?? null).endOf('month');

  const timeKeys = [];
  for (let month = startDate; month <= endDate; month = month.add(1, 'month')) {
    const timeKey = month.format('MM/YY');
    timeKeys.push(timeKey);
  }

  for (const item of monitoring.values) {
    const isFault = !! item.mainFaultId;

    if (item.userId) {
      const timeKey = item.month?.toString()?.padStart(2, '0') + '/' + item.year.toString().substring(2, 4);
      const faultKey = isFault ? `f.${item.mainFaultId}.${item.subFaultId}.${item.componentFaultId}.${item.faultId ?? 'x'}-l.${item.severityLevel ?? 'x'}` : `reject`;
      const lineKey = `${faultKey}-u.${item.userId}`;

      if (! data[lineKey]) {
        data[lineKey] = {
          chainId: item.chainId,
          siteId: item.siteId,
          fault: {
            mainFaultId: item.mainFaultId,
            subFaultId: item.subFaultId,
            componentFaultId: item.componentFaultId,
            severityLevel: item.severityLevel,
          },
          userId: item.userId,
          values: {},
        };
      }

      data[lineKey].values[timeKey] = {
        averageAge: item.averageAge,
        count: item.count,
        total: item.total,
        totalDrivingBan: item.totalDrivingBan,
        totalReject: item.totalReject,
      };
    } else {
      const timeKey = item.month?.toString()?.padStart(2, '0') + '/' + item.year.toString().substring(2, 4);
      const refKey = getRefKey(item);

      if (! refs[refKey]) {
        refs[refKey] = {
          chainId: item.chainId,
          source: item.source,
          values: {},
        };
      }

      refs[refKey].values[timeKey] = {
        averageAge: item.averageAge,
        count: item.count,
        total: item.total,
        totalDrivingBan: item.totalDrivingBan,
        totalReject: item.totalReject,
      };
    }
  }

  const r = {
    ...monitoring,
    times: timeKeys,
    values: Object.values(data),
    refData: refs,
  };

  return r;
}

export const fetchImprovementTargetList = createAsyncThunk('improvementTarget/fetchImprovementTargetList', async ({chainId, filters, isImmediate}, { getState, requestId }) => {
  if (! isImmediate) {
    await sleep(500);
  }

  if (isOutdatedListRequest(getState(), requestId)) {
    return null;
  }

  const searchCriteria = filters ?? getState().improvementTarget.criteria

  const query = {
    procName: 'GetImprovementTargetList',
    data: { ...searchCriteria, chainId },
  };

  const state = getState();

  const listData = await sendRequest(chainId, query, 'POST', state.auth.token);

  if (isOutdatedMonitoringRequest(getState(), requestId)) {
    return null;
  }

  return {
    criteria: searchCriteria,
    list: listData,
  };
});

export const fetchImprovementTargetMonitoring = createAsyncThunk('improvementTarget/fetchImprovementTargetMonitoring', async ({criteria, isImmediate}, { getState, requestId }) => {
  if (! isImmediate) {
    await sleep(500);
  }

  if (isOutdatedMonitoringRequest(getState(), requestId)) {
    return null;
  }

  const criteriaEndDate = dayjs(criteria.endDate ?? null);
  const maxEndDate = dayjs().subtract(1, 'day');
  const endDate = criteriaEndDate.isAfter(maxEndDate) ? maxEndDate : criteriaEndDate;
  const startDate = criteria.startDate ? dayjs(criteria.startDate) : dayjs().startOf('month');

  const searchCriteria = {...criteria, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')};
  const state = getState();
  const monitoring = await sendChainRequest(criteria.chainId, 'POST', '/quality-control/improvement-target/monitoring', '', searchCriteria, state.auth.token);

  if (isOutdatedMonitoringRequest(getState(), requestId)) {
    return null;
  }

  return {
    monitoring,
  };
});

export const fetchImprovementTarget = createAsyncThunk(
  'improvementTarget/fetchImprovementTarget',
  async ({ chainId, siteId, improvementTargetId }, API) => {
    const query = {
      procName: 'GetImprovementTarget',
      data: { id: improvementTargetId },
    };

    const state = API.getState();
    const improvementTarget = await sendRequest(chainId, query, 'POST', state.auth.token);

    return { ...improvementTarget };
  }
);

export const updateImprovementTarget = createAsyncThunk(
  'improvementTarget/updateImprovementTarget',
  async ({ chainId, payload }, API) => {
    const uriPostfix = payload.id ? `/${payload.id}` : '';

    const transformedPayload = {
      ...payload,
    };

    if (! transformedPayload.id) {
      delete transformedPayload.id;
    }

    const state = API.getState();
    const improvementTarget = await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target' + uriPostfix, '', transformedPayload, state.auth.token);

    return { ...improvementTarget };
  }
);

export const markImprovementTargetsCompleted = createAsyncThunk(
  'improvementTarget/markImprovementTargetsCompleted',
  async ({ chainId, ids }, API) => {
    const state = API.getState();
    await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target/mark-completed', '', ids, state.auth.token);

    return null;
  }
);

export const acceptImprovementTargetSuggestions = createAsyncThunk(
  'improvementTarget/acceptImprovementTargetSuggestions',
  async ({ chainId, ids }, API) => {
    const state = API.getState();
    await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target/accept-suggestions', '', ids, state.auth.token);

    return null;
  }
);

export const createImprovementTargetSuggestions = createAsyncThunk(
  'improvementTarget/createImprovementTargetSuggestions',
  async ({ chainId, suggestions }, API) => {
    const state = API.getState();

    return await sendChainRequest(chainId, 'POST', '/quality-control/improvement-target/create-suggestions', '', suggestions, state.auth.token);
  }
);

const improvementTargetSlice = createSlice({
  name: 'improvementTarget',
  initialState: {
    criteria: {},
    current: null,
    list: null,
    listFilters: {},
    monitoring: null,
    newSuggestionIds: null,
    isRequesting: false,
  },
  reducers: {
    resetCurrentImprovementTarget: (state, action) => {
      state.current = null;
      state.monitoring = null;
    },
    setImprovementTargetListFilters: (state, action) => {
      state.listFilters = {...action.payload};
    },
  },
  extraReducers: {
    [fetchImprovementTargetList.pending]: (state, { meta }) => {
      state.list = null;
      state.isRequesting = true;
      state.listRequestId = meta.requestId;
      state.monitoring = null;
    },
    [fetchImprovementTargetList.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.list = payload?.list ?? [];
        state.isRequesting = false;
        state.listRequestId = null;
      }
    },
    [fetchImprovementTargetList.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchImprovementTargetMonitoring.pending]: (state, { meta }) => {
      state.monitoring = null;
      state.isRequesting = true;
      state.monitoringRequestId = meta.requestId;
    },
    [fetchImprovementTargetMonitoring.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.monitoring = formatImprovementTargetMonitoring(payload?.monitoring);
        state.isRequesting = false;
        state.monitoringRequestId = null;
      }
    },
    [fetchImprovementTargetMonitoring.rejected]: (state, { meta }) => {
      state.isRequesting = false;
    },
    [fetchImprovementTarget.pending]: (state) => {
      state.current = null;
      state.isRequesting = true;
      state.monitoring = null;
    },
    [fetchImprovementTarget.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.current = payload ?? {};
        state.isRequesting = false;
      }
    },
    [fetchImprovementTarget.rejected]: (state) => {
      state.isRequesting = false;
    },
    [updateImprovementTarget.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateImprovementTarget.fulfilled]: (state, { payload }) => {
      state.current = payload ?? {};
      state.isRequesting = false;
    },
    [updateImprovementTarget.rejected]: (state) => {
      state.isRequesting = false;
    },
    [acceptImprovementTargetSuggestions.pending]: (state) => {
      state.isRequesting = true;
    },
    [acceptImprovementTargetSuggestions.fulfilled]: (state, { payload }) => {
      state.isRequesting = false;
      state.list = null;
      state.current = {};
    },
    [acceptImprovementTargetSuggestions.rejected]: (state) => {
      state.isRequesting = false;
    },
    [createImprovementTargetSuggestions.pending]: (state) => {
      state.isRequesting = true;
      state.newSuggestionIds = null;
    },
    [createImprovementTargetSuggestions.fulfilled]: (state, { payload }) => {
      state.isRequesting = false;
      state.newSuggestionIds = payload ?? [];
      state.list = null;
      state.current = {};
    },
    [createImprovementTargetSuggestions.rejected]: (state) => {
      state.isRequesting = false;
    },
    [markImprovementTargetsCompleted.pending]: (state) => {
      state.isRequesting = true;
    },
    [markImprovementTargetsCompleted.fulfilled]: (state, { payload }) => {
      state.isRequesting = false;
      state.list = null;
      state.current = {};
    },
    [markImprovementTargetsCompleted.rejected]: (state) => {
      state.isRequesting = false;
    },
  },
});

export const {
  resetCurrentImprovementTarget,
  setImprovementTargetListFilters,
} = improvementTargetSlice.actions;

export default improvementTargetSlice.reducer;
