/**
 * @prettier
 */

const MAX_IMAGE_WIDTH_HEIGHT = 1000;

/**
 * Resizes image and returns it to callback as base64 string.
 * @param {Object} file - Image file.
 * @param {func} callback - Callback function.
 */
export function resizeImageAsBase64(file, callback) {
  // Use FileReader to read image file contents.
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    // Use img element to draw image to canvas.
    const img = document.createElement('img');
    img.addEventListener('load', () => {
      // Determine new image dimensions if image is larger than maximum.
      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > MAX_IMAGE_WIDTH_HEIGHT) {
          height *= MAX_IMAGE_WIDTH_HEIGHT / width;
          width = MAX_IMAGE_WIDTH_HEIGHT;
        }
      } else {
        if (height > MAX_IMAGE_WIDTH_HEIGHT) {
          width *= MAX_IMAGE_WIDTH_HEIGHT / height;
          height = MAX_IMAGE_WIDTH_HEIGHT;
        }
      }

      // Create canvas and set new dimensions.
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      // Draw image to canvas with new dimensions.
      var context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);

      // Convert to base64 encoded string and return using callback.
      const dataUrl = canvas.toDataURL(file.type);
      callback(dataUrl);
    });

    // Give image file content to img element (triggers load event).
    img.src = reader.result;
  });

  // Read image file as base64 encoded string.
  reader.readAsDataURL(file);
}

function descendingComparator(a, b, orderBy, secondaryOrderBy = '') {
  const [aValue, bValue] = [a[orderBy], b[orderBy]];
  if (aValue === bValue) {
    if (secondaryOrderBy) {
      return descendingComparator(a, b, secondaryOrderBy);
    }
    return 0
  }
  if (aValue === null && bValue !== null) {
    return -1;
  }
  if (bValue === null && aValue !== null) {
    return 1;
  }
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function stableSort(array, orderBy, order, secondaryOrderBy = '') {
  const comparator =
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy, secondaryOrderBy)
      : (a, b) => -descendingComparator(a, b, orderBy, secondaryOrderBy);
  return array.sort(comparator);
}
