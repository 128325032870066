import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

function updateLine(lines, payload) {
  // Add.
  if(!lines.find(l => l.id === payload.id)) {
    return lines.concat(payload);
  }

  // Update.
  const result = lines.map((item) => {
      if (item.id !== Number(payload.id)) {
          return item
      }
      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        ...payload,
    }
  });
  return result;
}

export const updateInspectionLine = createAsyncThunk('inspectionLine/updateLine', async ({ chainId, payload }, API) => {
  const procName = payload.id ? 'UpdateInspectionLine' : 'CreateInspectionLine';
  const query = {
    procName: procName,
    data: payload,
  };

  // Backend will raise too many arguments specified error if there are extra arguments.
  if (procName === 'UpdateInspectionLine') {
    delete payload.siteId;
  }
  if (procName === 'CreateInspectionLine') {
    delete payload.id;
  }

  // Send request.
  const state = API.getState();
  const result = await sendRequest(chainId, query, 'POST', state.auth.token);
  const data = {
    ...payload,
    ...result,
  };
  return data;
});

export const fetchInspectionLines = createAsyncThunk('inspectionLine/fetchInspectionLines', async ({ chainId, siteId }, API) => {
  const query = {
    procName: 'GetInspectionLinesInSite',
    data: { siteId },
  };
  const state = API.getState();
  const lines = await sendRequest(chainId, query, 'POST', state.auth.token);
  return lines.map(line => ({
    ...line,
    measurementTypeAndDeviceItems: parseMeasurementTypeAndDeviceItems(line.measurementTypeAndDeviceItems),
  }));
});

export const deleteInspectionLine = createAsyncThunk('inspectionLine/deleteInspectionLine', async ({ selectedChainId, lineId }, API) => {
  const query = {
    procName: 'DeleteInspectionLine',
    data: {
      chainId: selectedChainId,
      lineId,
    },
  };
  const state = API.getState();
  await sendRequest(selectedChainId, query, 'POST', state.auth.token);
  return lineId;
});

const parseMeasurementTypeAndDeviceItems = (measurementTypeAndDeviceItems) => {
  const data = JSON.parse(measurementTypeAndDeviceItems ?? '[]');
  return data.map((item) => ({
    ...item,
    subMeasurementItems: item.subMeasurementItems ?? [],
  }));
};

const inspectionLinesSlice = createSlice({
  name: 'inspectionLines',
  initialState: {
    allInspectionLines: [],  // Contains also deleted lines.
    inspectionLines: [],
    isRequesting: false,
  },
  extraReducers: {
    [updateInspectionLine.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateInspectionLine.fulfilled]: (state, { payload }) => {
      const lines = updateLine(state.inspectionLines, payload);
      state.inspectionLines = lines;
      state.isRequesting = false;
    },
    [updateInspectionLine.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchInspectionLines.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchInspectionLines.fulfilled]: (state, { payload }) => {
      state.allInspectionLines = payload;
      state.inspectionLines = payload.filter(line => !line.deletedAt);
      state.isRequesting = false;
    },
    [fetchInspectionLines.rejected]: (state) => {
      state.isRequesting = false;
    },
    [deleteInspectionLine.pending]: (state) => {
      state.isRequesting = true;
    },
    [deleteInspectionLine.fulfilled]: (state, { payload }) => {
      state.inspectionLines = state.inspectionLines.filter(line => line.id !== payload);
      state.allInspectionLines = state.allInspectionLines.map(line => {
        if (line.id !== payload) {
          return line;
        }
        return { ...line, deletedAt: new Date() };
      });
      state.isRequesting = false;
    },
    [deleteInspectionLine.rejected]: (state) => {
      state.isRequesting = false;
    },
  },
});

export const actions = inspectionLinesSlice.actions;
export default inspectionLinesSlice.reducer;
