import dayjs from 'dayjs';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';
import { DEFAULT_QUALITY_CONTROL_NOTICE_FILTERS } from '../lib/noticeConstants';

export const updateQualityControlNotice = createAsyncThunk(
  'qualityControlNotice/updateNotice',
  async ({ chainId, payload }, API) => {
    const procName = payload.id ? 'UpdateQualityControlNotice' : 'CreateQualityControlNotice';

    const transformedPayload = {
      ...payload,
      chainId: parseInt(chainId),
      siteId: parseInt(payload.siteId),
    };

    const query = {
      procName,
      data: transformedPayload,
    };

    if (procName === 'CreateQualityControlNotice') {
      delete transformedPayload.id;
      delete transformedPayload.createdByName;
    }

    const state = API.getState();
    const response = await sendRequest(chainId, query, 'POST', state.auth.token);
    return {
      ...payload,
      createdByName: payload.createdByName,
      createdAt: payload.createAt ? payload.createAt : new Date().toISOString(),
      id: response.id,
    };
  }
);

export const listQualityControlNotices = createAsyncThunk(
  'qualityControlNotice/listNotices',
  async ({ chainId, siteId, filters }, API) => {
    const query = {
      procName: 'ListQualityControlNotices',
      data: {
        chainId,
        siteId,
        startTime: filters.startTime ? dayjs(filters.startTime).format('YYYY-MM-DD') : null,
        endTime: filters.endTime ? dayjs(filters.endTime).format('YYYY-MM-DD') : null,
        registrationNumber: filters.registrationNumber,
      },
    };
    const state = API.getState();
    const notices = await sendRequest(chainId, query, 'POST', state.auth.token);
    return notices;
  }
);

export const deleteQualityControlNotice = createAsyncThunk(
  'qualityControlNotice/deleteNotice',
  async ({ jobId, chainId, siteId, noticeId }, API) => {
    const query = {
      procName: jobId ? 'DeleteJobQualityControlNotice' : 'DeleteQualityControlNotice',
      data: jobId ? {
        id: noticeId,
        jobId,
        chainId,
        siteId,
      } : {
        id: noticeId,
        chainId,
        siteId,
      },
    };
    const state = API.getState();
    await sendRequest(chainId, query, 'POST', state.auth.token);
    return noticeId;
  }
);

export const fetchQualityControlNotice = createAsyncThunk(
  'qualityControlNotice/fetchNotice',
  async ({ chainId, siteId, noticeId }, API) => {
    const query = {
      procName: 'GetQualityControlNotice',
      data: { chainId, siteId, id: noticeId },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const fetchQualityControlNoticesByJobId = createAsyncThunk(
  'qualityControlNotice/fetchNoticesByJobId',
  async ({ chainId, siteId, jobId }, API) => {
    const query = {
      procName: 'GetQualityControlNoticesByJobId',
      data: { chainId, siteId, jobId },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const NEW_NOTICE_INITIAL_STATE = {
  createdAt: dayjs().format('DD.MM.YYYY'),
  description: '',
  registrationNumber: '',
};

const qualityControlNoticesSlice = createSlice({
  name: 'qualityControlNotices',
  initialState: {
    notices: [],
    currentNotice: NEW_NOTICE_INITIAL_STATE,
    isRequesting: false,
    filters: DEFAULT_QUALITY_CONTROL_NOTICE_FILTERS,
  },
  reducers: {
    setNoticeFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetNoticeFilters: (state) => {
      state.filters = DEFAULT_QUALITY_CONTROL_NOTICE_FILTERS;
    },
  },
  extraReducers: {
    [updateQualityControlNotice.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateQualityControlNotice.fulfilled]: (state, { payload }) => {
      const existingIndex = state.notices.findIndex(notice => notice.id === payload.id);
      if (existingIndex !== -1) {
        state.notices[existingIndex] = payload;
      } else {
        state.notices = [payload, ...state.notices];
      }
      state.isRequesting = false;
    },
    [updateQualityControlNotice.rejected]: (state) => {
      state.isRequesting = false;
    },
    [listQualityControlNotices.pending]: (state) => {
      state.isRequesting = true;
    },
    [listQualityControlNotices.fulfilled]: (state, { payload }) => {
      state.notices = payload;
      state.currentNotice = NEW_NOTICE_INITIAL_STATE;
      state.isRequesting = false;
    },
    [listQualityControlNotices.rejected]: (state) => {
      state.isRequesting = false;
    },
    [deleteQualityControlNotice.pending]: (state) => {
      state.isRequesting = true;
    },
    [deleteQualityControlNotice.fulfilled]: (state, { payload }) => {
      state.notices = state.notices.filter(notice => notice.id !== payload);
      state.isRequesting = false;
    },
    [deleteQualityControlNotice.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlNotice.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchQualityControlNotice.fulfilled]: (state, { payload }) => {
      state.currentNotice = payload;
      state.isRequesting = false;
    },
    [fetchQualityControlNotice.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchQualityControlNoticesByJobId.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchQualityControlNoticesByJobId.fulfilled]: (state, { payload }) => {
      state.notices = payload;
      state.isRequesting = false;
    },
    [fetchQualityControlNoticesByJobId.rejected]: (state) => {
      state.isRequesting = false;
    },
  },
});

export const { setNoticeFilters, resetNoticeFilters } = qualityControlNoticesSlice.actions;

export default qualityControlNoticesSlice.reducer;