import html from './contact-info-section-html';
import css from './contact-info-section-css';

// Form field IDs.
const ADDRESS = 'address';
const ZIP_CODE = 'zipCode';
const CITY = 'city';
const CONTACT_PERSON_NAME = 'contactPersonName';
const PHONE_NUMBER = 'phoneNumber';
const FAX_NUMBER = 'faxNumber';
const EMAIL = 'email';
const WWW_ADDRESS = 'wwwAddress';
const EMPTY_FORM_DATA = {
  [ADDRESS]: '',
  [ZIP_CODE]: '',
  [CITY]: '',
  [CONTACT_PERSON_NAME]: '',
  [PHONE_NUMBER]: '',
  [FAX_NUMBER]: '',
  [EMAIL]: '',
  [WWW_ADDRESS]: '',
};

class ContactInfoSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      formData: Object.assign({}, EMPTY_FORM_DATA),
      onChange: null,
    };

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.build();
  }

  disconnectedCallback() {
    this._state = {
      ...this._state,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };
  }

  set onChange(val) {
    this._state.onChange = val;
  }

  get isValid() {
    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    return errorInputs.length === 0;
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
  }

  setSite(site) {
    this._state.formData = Object.assign({}, site);
    this.refreshData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
  }

  async build() {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>' + html;
    this.addRoot();
  }

  addRoot = () => {
    this.root = this.shadowRoot.getElementById('contact-info-section');
    this.formContainer = this.shadowRoot.getElementById('form-container');
    this._state.isBuilt = true;
    this.renderForm();
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
    const input = this.formContainer.querySelector(`input#${property}`);

    const inputDef = this.getInputDefinitions().find(def => def.id === property);
    if (inputDef.required && !value) {
      input.setAttribute('error', '');
    } else if (value) {
      input.removeAttribute('error');
    }
    this._state.onChange(property, value);
  }

  addTitles = () => {
    const addressInfoTitle = this.shadowRoot.getElementById('address-info-title');
    addressInfoTitle.textContent = this.translationService.translate('siteView.addressInfo');
    const otherContactInfoTitle = this.shadowRoot.getElementById('other-contact-info-title');
    otherContactInfoTitle.textContent = this.translationService.translate('siteView.otherContactInfo');
  }

  getInputDefinitions = () => [
    {
      id: ADDRESS,
      label: this.translationService.translate('siteView.address'),
      maxLength: 300,
    },
    {
      id: ZIP_CODE,
      label: this.translationService.translate('siteView.zipCode'),
      maxLength: 5,
    },
    {
      id: CITY,
      label: this.translationService.translate('siteView.city'),
      maxLength: 200,
    },
    {
      id: CONTACT_PERSON_NAME,
      label: this.translationService.translate('siteView.contactPerson'),
      maxLength: 200,
    },
    {
      id: PHONE_NUMBER,
      label: this.translationService.translate('siteView.phoneNumber'),
      maxLength: 200,
    },
    {
      id: FAX_NUMBER,
      label: this.translationService.translate('siteView.faxNumber'),
      maxLength: 200,
    },
    {
      id: EMAIL,
      label: this.translationService.translate('siteView.email'),
      maxLength: 200,
    },
    {
      id: WWW_ADDRESS,
      label: this.translationService.translate('siteView.wwwAddress'),
      maxLength: 300,
    },
  ];

  refreshData = () => {
    this.getInputDefinitions().forEach((inputDef) => {
      const label = this.shadowRoot.getElementById(`${inputDef.id}-label`);
      label.textContent = inputDef.label;
      const input = this.shadowRoot.getElementById(inputDef.id);
      input.value = this._state.formData[inputDef.id] || '';
      if (inputDef.maxLength) {
        input.setAttribute('maxlength', inputDef.maxLength);
      }
      if (this._fetchingData) {
        input.setAttribute('disabled', '');
      } else {
        input.removeAttribute('disabled');
      }
      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
    });
  }

  renderForm = () => {
    this.addTitles();
    this.refreshData();
  }
}

window.customElements.define('contact-info-section', ContactInfoSection);
