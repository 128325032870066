import './sites-list-view';
import './sites-detail-root';

class SitesApp extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.addModules();
    this.createRoot();
    this.addEventListeners();
  }

  // Add script tags to load required child components
  addModules = () => {
    // Create child elements and store references.
    this.sitesListView = document.createElement('sites-list-view');
    this.sitesDetailRoot = document.createElement('sites-detail-root');

    this.routes = {
      '/sites-list': this.sitesListView,
      '/sites-detail': this.sitesDetailRoot,
    };
    this.currentRoute = '/sites-list';
  }

  createRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'sites-app');
    this.root.setAttribute('class', 'app');
    this.shadowRoot.appendChild(this.root);
    window.requestAnimationFrame(() => this.initialRender());
  }

  pushRoute = () => {
    const routeName = this.navEvent.detail.route;
    this.currentRoute = routeName;
    this.mainContent.removeChild(this.mainContent.childNodes[0]);
    const route = this.routes[routeName];
    route.navData = this.navEvent.detail ? this.navEvent.detail.data : {};
    this.mainContent.appendChild(route);
  }

  sitesNavEventListener = (event) => {
    // Navigation event to React root.
    const navigationEvent = new CustomEvent('navigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: event.detail.route === '/sites-detail'
          ? `/sites/${event.detail.data ? event.detail.data.id : 'new'}`
          : '/sites',
      },
    });
    window.dispatchEvent(navigationEvent);

    this.navEvent = event;
    window.requestAnimationFrame(() => this.pushRoute());
  }

  // Add event listeners for events dispatched by modules.
  addEventListeners = () => {
    this.addEventListener('sitesNavEvent', this.sitesNavEventListener);
  }

  initialRender = () => {
    const mainContent = this.mainContent = document.createElement('div');
    mainContent.setAttribute('class', 'main-content');
    this.root.appendChild(mainContent);
    this.mainContent.appendChild(this.sitesListView);
  }
}
window.customElements.define('sites-app', SitesApp);
