import { sendNotificationEvent } from '../../../appShell/notificationHelpers';
import { stableSort } from '../../../assets/lib/commonFunctions';
import layout from './layout-html';
import './permissions-data-table';

const NAME = 'name';  // The translated text. Not part of the data.
const SCOPE = 'scope';
const CAN_READ = 'canRead';
const CAN_WRITE = 'canWrite';
const ORDER_BY = NAME;
const ORDER_DIRECTION = 'asc';
const COLUMN_IDS = [SCOPE, NAME, CAN_READ, CAN_WRITE];

class UserPermissionsSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      store: null,
      user: null,
      userData: {},
      sites: new Map(),
      siteGroups: new Map(),
      userPermissionsOriginal: [],
      userPermissions: [],
      sortBy: ORDER_BY,
      sortDirection: ORDER_DIRECTION,
      index: 0,
      itemsPerPage: 10,
      permissionTemplates: new Map(),
    };
  }

  // Sets translation service.
  setServices = (controlState, translationService) => {
    this.controlState = controlState;
    this.translationService = translationService;
  }

  build = async () => {
    this.shadowRoot.innerHTML = layout;

    // Confirm dialog.
    this.confirmationDialog = document.createElement('confirmation-dialog');

    // Form container.
    this.rootElement = this.shadowRoot.getElementById('table');
    this.rootElement.addEventListener('dDataTableEvent', this.handleDataTableEvent);
    this.rootElement.addEventListener('dDataTableInputEvent', this.handleDataTableInputEvent);

    // Permission templates.
    const elem = this.shadowRoot.getElementById('templatesLabel');
    elem.textContent = this.translationService.translate('usersView.permissionTemplatesLabel');
    this.templatesElem = this.shadowRoot.getElementById('roleTemplates');
    this.templatesElem.addEventListener('change', this.applyTemplate);

    // Save.
    this.saveButton = this.shadowRoot.getElementById('button-save');
    this.saveButton.appendChild(document.createTextNode(this.translationService.translate('save')));
    this.saveButton.onclick = this.onSave;

    // Cancel.
    this.cancelButton = this.shadowRoot.getElementById('button-cancel');
    this.cancelButton.appendChild(document.createTextNode(this.translationService.translate('cancel')));
    this.cancelButton.onclick = () => {
      if (this.isDirty()) {
        this.addDiscardConfirmationDialog(() => this.goToListView());
      } else {
        this.goToListView();
      }
    }

    // Delete user button.
    this.deleteUserButton = this.shadowRoot.getElementById('button-delete-user');
    this.deleteUserButton.appendChild(document.createTextNode(this.translationService.translate('usersView.delete')));
    this.deleteUserButton.onclick = this.onDeleteUser
  }

  connectedCallback() {
    this.build().then(() => this.fetchData());
  }

  handleDataTableEvent = (event) => {
    this._state.sortBy = event.detail.sortBy;
    this._state.sortDirection = event.detail.sortDirection;
    this._state.index = event.detail.pagination.index;
    this._state.itemsPerPage = event.detail.pagination.itemsPerPage;
    this.refreshData();
  }

  applyTemplate = (event) => {
    const selectedValue = Number(event.target.value);
    const template = this._state.permissionTemplates.get(selectedValue);
    if(template) {
      const permissions = new Map(template.permissions.map(p => [p.scope, p]));
      for(const p of this._state.userPermissions) {
        const tp = permissions.get(p.scope.scopeName);
        if(tp) {
          p.canRead = tp.canRead;
          p.canWrite = tp.canWrite;
        } else {
          p.canRead = false;
          p.canWrite = false;
        }
      }
      this.checkFormButtonStatus();
    }
    window.requestAnimationFrame(() => this.refreshData());
  }

  isDirty = () => {
    return this._state.userPermissions.some((permission, index) => {
      const originalPermission = this._state.userPermissionsOriginal[index];
      return permission.canRead !== originalPermission.canRead
        || permission.canWrite !== originalPermission.canWrite;
    });
  }

  /**
   * Checks if form is modified and sets button status accordingly.
   */
  checkFormButtonStatus = () => {
    if (this.isDirty()) {
      this.saveButton.removeAttribute('disabled');
    } else {
      this.saveButton.setAttribute('disabled', '');
    }
  }

  handleDataTableInputEvent = (event) => {
    const scope = this._state.userPermissions.find(p => p.scope === event.detail.scope);
    scope.canRead = event.detail.canRead;
    scope.canWrite = event.detail.canWrite;
    this.checkFormButtonStatus();
  }

  // Performs tab initialization with the given user data.
  init(store, user, sites, siteGroups, deleteManager, addDiscardConfirmationDialog) {
    this.store = store;
    this._state = {
      ...this._state,
      user,
      sites,
      siteGroups
    };
    this.deleteManager = deleteManager;
    this.addDiscardConfirmationDialog = addDiscardConfirmationDialog;
  }

  goToListView = () => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-list',
        data: {}
      },
    });
    this.rootElement.dispatchEvent(navEvent);
  }

  onSave = () => {
    const permissionsToSave = this._state.userPermissions
    .filter(up => up.canRead || up.canWrite)
    .map(p => ({
      scope: p.scope.scopeName,
      name: p.name,
      canRead: p.canRead,
      canWrite: p.canWrite
    }));
    const data = {
      ...this._state.userData,
      permissions: JSON.stringify(permissionsToSave)
    };
    this.saveData(data);
  }

  onCancel = () => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-list',
        data: {}
      },
    });
    this.shadowRoot.dispatchEvent(navEvent);
  };

  onDeleteUser = () => this.deleteManager.addConfirmationDialog(this.shadowRoot);

  updateUserData = (userData, permissionTemplates, permissionScopes) => {
    if(permissionScopes) {
      this._state.permissionScopes = permissionScopes;
    }
    if(permissionTemplates) {
      this._state.permissionTemplates = new Map(permissionTemplates.map(t => [t.id, {...t, permissions: JSON.parse(t.permissions)}]));
    }
    if(userData) {
      const user = userData;
      let permissions = JSON.parse(user.permissions || '[]');
      const userPermissionsMap = new Map(permissions.map(p => [p.scope, p]));
      permissions = this._state.permissionScopes.map(scope => {
        const userEntry = userPermissionsMap.get(scope.scopeName);
        return {
          scope,
          name: this.translationService.translate(`permissions.${scope.scopeName}`),
          canRead: userEntry ? userEntry.canRead : false,
          canWrite: userEntry ? userEntry.canWrite : false
        }
      });
      this._state.userPermissions = stableSort(permissions, NAME, ORDER_DIRECTION);
      this._state.userPermissionsOriginal = this._state.userPermissions.map(item => ({ ...item }));
      this._state.userData = user;
      this.checkFormButtonStatus();
    }
  }

  fetchData = async () => {
    const data = { userId: this._state.user.id };
    try {
      const userData = await this.store.sendCrudRequest(this.controlState.chainId, 'GetUser', data);
      const permissionTemplates = await this.store.sendCrudRequest(this.controlState.chainId, 'GetPermissionTemplates', {});
      const permissionScopes = await this.store.getPermissionScopes(this.controlState.chainId);
      this.updateUserData(userData, permissionTemplates, permissionScopes);
      this.populateTemplates();
      this.refreshData();
    } catch(error) {
      sendNotificationEvent(this.translationService.translate('usersView.fetchFailed'), 'error');
    }
  }

  saveData = async (data) => {
    try {
      const userData = await this.store.sendCrudRequest(this.controlState.chainId,'UpdateUser', {
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        preferredLanguage: data.preferredLanguage,
        sites: data.sites,
        siteGroups: data.siteGroups,
        siteSettings: data.siteSettings,
        permissions: data.permissions,
        lastSiteId: data.lastSiteId,
      });
      this.updateUserData(userData);
      this.refreshData();
      sendNotificationEvent(this.translationService.translate('chainView.updateSuccessful'));
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('usersView.saveFailed'), 'error');
    }
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      if(columnId === NAME || columnId === SCOPE) {  // Name and scope represent same columns.
        return {
          id: columnId,
          text: this.translationService.translate(`usersView.scope`),
          sortable: true,
          invisible: columnId === SCOPE,
          primary: columnId === NAME,
        };
      }
      return {
        id: columnId,
        text: this.translationService.translate(`usersView.${columnId}`),
        sortable: true,
        primary: columnId === NAME,
      };
    });
  }

  removeGroup = (gid) => {
    const group = this._state.siteGroups.find(g => g.id === Number(gid));
    this.addConfirmationDialog(group);
  }

  populateTemplates = () => {
    this.templatesElem.querySelectorAll('*').forEach(n => n.remove());
    const selectAnOption = new Option('---', -1, true, true);
    this.templatesElem.append(selectAnOption);
    for(const pt of this._state.permissionTemplates.values()) {
      this.templatesElem.append(new Option(pt.roleName, pt.id));
    }
  }

  refreshData = () => {
    if(!this.rootElement) {
      return;
    }

    // Populate data table.
    const {sortBy, sortDirection, index, itemsPerPage} = this._state;
    let result = stableSort(this._state.userPermissions, sortBy, sortDirection);
    result = result.slice(index * itemsPerPage, (index + 1) * itemsPerPage);
    this.rootElement.dataAndLayout = {
      data: result,
      columns: this.getTableColumnDefinitions(),
      sortBy: sortBy,
      sortDirection: sortDirection,
      paginated: true,
      sortable: true,
      selectedIds: [],
      totalItemCount: this._state.userPermissions.length,
      translate: this.translationService.translate,
      currentUser: this.controlState.user,
    };
  }
}

window.customElements.define('user-permissions-section', UserPermissionsSection);
