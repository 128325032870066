export default `
.form-container {
  display: flex;
}

.form-column {
  padding: 20px;
  flex: 1;
}

.form-column:not(:last-child) {
  border-right: 1px solid rgba(0,0,0,0.11);
  padding-right: 50px;
}

.form-column:not(:first-child) {
  padding-left: 50px;
}

.form-column > div {
  margin-bottom: 20px;
  max-width: 400px;
}

.secondary-title {
  font-size: 18px;
  color:rgba(0,0,0,0.6);
}

.input-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  margin-bottom: 7px;
}

input {
  box-sizing: border-box;
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  padding: 0px 12px;
  width: calc(100% - 24px);
}

input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
}

input.small {
  width: 120px;
}

input[error] {
  border-color: var(--errorColor);
}

.logo-input {
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 81px;
  background-color: var(--gray);
  max-width: 400px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-input > img {
  max-height: 80px;
  max-width: 390px;
}

.file-input {
  display: none;
}

.commissions-container {
  display: flex;
}

.commissions-container > div:not(:last-child) {
  margin-right: 20px;
}

.buttons-container {
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.11);
}

.buttons-container > button:not(:last-child) {
  margin-right: 20px;
}

button {
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--buttonColor);
  background-color: var(--buttonBackground);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 140px;
  cursor: pointer;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button:focus {
  outline: none;
}

button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button[disabled].primary:hover {
  background-color: var(--primaryButtonBackground);
}
`;