import layout from './layout-html';
import closeImage from '../../assets/icons/close.svg';
import arrowUpImage from '../../assets/icons/arrow_up.svg';

class CollapsibleSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({mode: 'open'});
    this._state = {
      isBuilt: false,
      isOpen: false,
      count: 0,
    };
  }

  //#region callbacks
  static get observedAttributes() {
    return ['open', 'count', 'title', 'removable', 'value'];
  }

  connectedCallback() {
    this._state.isOpen = this.getAttribute('open') === 'true';
    this._state.count = this.getAttribute('count') || '0';
    if(!this._state.isBuilt) {
      this.build();
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this._state[name] = newValue;
    this.refresh();
  }
  //#endregion callbacks

  isOpen = () => this._state.open === 'true';
  showHide = () => this.setAttribute('open', this.getAttribute('open') === 'true' ? 'false' : 'true');

  build = async () => {
    this.shadowRoot.innerHTML = layout;
    this.titleElem = this.shadowRoot.querySelector('.title');
    this.contentElem = this.shadowRoot.querySelector('.content');
    this.countElem = this.shadowRoot.querySelector('.count');
    this.removeElem = this.shadowRoot.querySelector('.remove');
    this.removeElem.addEventListener('click', () => {
      // Emit custom event.
      const event = new CustomEvent('dCollapsibleRemove', {
        bubbles: true,
        composed: true,
        detail: {value: this._state.value},
      });
      this.shadowRoot.dispatchEvent(event);
    });
    this.headerElem = this.shadowRoot.querySelector('.header');
    this.openCloseElem = this.shadowRoot.getElementById('openClose');
    this.titleElem.addEventListener('click', () => this.showHide());
    this.openCloseElem.addEventListener('click', () => this.showHide());
    const closeIconElem = this.shadowRoot.getElementById('close');
    closeIconElem.src = closeImage;
    const openCloseIconElem = this.shadowRoot.getElementById('openClose');
    openCloseIconElem.src = arrowUpImage;
    this.refresh();
  }

  refresh = () => {
    if(!this.headerElem) {
      return;
    }
    this.openClose();
    this.titleElem.textContent = this._state.title;
    this.countElem.textContent = `${this._state.count} kpl`;
    this._state.removable === 'false' ? this.removeElem.setAttribute('hidden', '') : this.removeElem.removeAttribute('hidden');
  }

  /**
   * Open/close this collapsible.
   */
  openClose = () => {
    const isOpen = this.isOpen();
    this.contentElem.hidden = isOpen;
    isOpen ? this.openCloseElem.classList.add('rotate') : this.openCloseElem.classList.remove('rotate');
  }
}

window.customElements.define('collapsible-section', CollapsibleSection);
