import layout from './permissions-data-table-layout-html';
import arrowUpImage from '../../../assets/icons/arrow_up.svg';
import arrowDownImage from '../../../assets/icons/arrow_down.svg';
import sortImage from '../../../assets/icons/sort.svg';
import checkboxSelectedImage from '../../../assets/icons/checkbox_selected.svg';
import checkboxImage from '../../../assets/icons/checkbox.svg';

const ALL = 'all';
const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50, ALL];
const ASC = 'asc';
const DESC = 'desc';

const ON_ICON = checkboxSelectedImage;
const OFF_ICON = checkboxImage;

class PermissionsDataTable extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({mode: 'open'});

    this._state = {
      columns: [],
      data: [],
      originalSortBy: '',
      sortBy: '',
      originalSortDirection: ASC,
      sortDirection: ASC,
      selectedIds: [],
      removable: false,
      paginated: false,
      selectable: false,
      totalItemCount: 0,
      pagination: {
        index: 0,
        itemsPerPage: ITEMS_PER_PAGE_OPTIONS[0],
      },
      isBuilt: false,
      currentUser: {},
    };
  }

  //#region callbacks
  connectedCallback() {
    if(!this._state.isBuilt) {
      this.build();
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this._state[name] = newValue;
    this.refresh();
  }
  //#endregion callbacks

  build = async () => {
    this.shadowRoot.innerHTML = layout;
    this.root = this.shadowRoot.getElementById('table-container');
    this.tableHeadRow = this.shadowRoot.getElementById('table-head-row');
    this.tableBody = this.shadowRoot.getElementById('table-body');
    this.paginationContainer = this.shadowRoot.getElementById('pagination-container');
    window.requestAnimationFrame(() => this.render());
  }

  set dataAndLayout(val) {
    this._state = {
      ...this._state,
      data: val.data,
      paginated: Boolean(val.paginated),
      columns: val.columns,
      removable: Boolean(val.removable),
      originalSortBy: val.sortBy || this._state.sortBy,
      sortBy: val.sortBy || this._state.sortBy,
      originalSortDirection: val.sortDirection || this._state.sortDirection,
      sortDirection: val.sortDirection || this._state.sortDirection,
      selectedIds: val.selectedIds || [],
      selectable: val.selectable || false,
      totalItemCount: val.totalItemCount || 0,
      translate: val.translate,
      currentUser: val.currentUser
    };
    window.requestAnimationFrame(() => this.render());
  }

  render() {
    if (!this.root) {
      return;
    }

    const data = this._state.data;
    if (!Array.isArray(data)) {
      return;
    }

    this.tableHeadRow.querySelectorAll('*').forEach(n => n.remove());
    this.tableBody.querySelectorAll('*').forEach(n => n.remove());

    this.renderHeader();
    this.renderBody();
    this.renderPagination();
  }

  renderHeader = () => {
    // List given columns.
    this._state.columns.forEach((columnDef) => {
      if (columnDef.invisible) {
        return;
      }
      const headCell = document.createElement('th');
      headCell.setAttribute('scope', 'col');
      this.tableHeadRow.appendChild(headCell);

      const headCellContent = document.createElement('div');
      headCell.appendChild(headCellContent);

      const text = document.createElement('div');
      text.textContent = columnDef.text;
      headCellContent.appendChild(text);

      if (columnDef.align === 'right') {
        headCellContent.setAttribute('class', 'align-right');
      }
      // Add sorting icon.
      if (columnDef.sortable) {
        const icon = document.createElement('img');
        headCellContent.appendChild(icon);
        headCellContent.setAttribute('class', 'sortable');
        headCellContent.onclick = () => this.updateSorting(columnDef.id);
        if (this._state.sortBy === columnDef.id) {
          if (this._state.sortDirection === ASC) {
            icon.setAttribute('src', arrowUpImage);
          } else {
            icon.setAttribute('src', arrowDownImage);
          }
        } else {
          icon.setAttribute('src', sortImage);
        }
      }
    });
  }

  dispatchTableEvent = (eventType, data) => {
    const tableEvent = new CustomEvent(eventType, {
      bubbles: true,
      composed: true,
      detail: data,
    });
    this.shadowRoot.dispatchEvent(tableEvent);
  }

  addCell = (cell, columnDef, rowData, isAllowPwdChangeDisabled) => {
    if(columnDef.id === 'canRead') {
      const checkbox = document.createElement('img');
      const imgSrc = rowData.canRead ? ON_ICON : OFF_ICON;
      checkbox.setAttribute('src', imgSrc);

      if (isAllowPwdChangeDisabled) {
        checkbox.setAttribute('disabled', '');
      } else {
        checkbox.addEventListener('click', (event) => {
          rowData.canRead = !rowData.canRead;
          const imgSrc = rowData.canRead ? ON_ICON : OFF_ICON;
          window.requestAnimationFrame(() => checkbox.setAttribute('src', imgSrc));
          this.dispatchTableEvent('dDataTableInputEvent', rowData);
        });
      }
      cell.appendChild(checkbox);
      return;
    }
    if(columnDef.id === 'canWrite') {
      const checkbox = document.createElement('img');
      const imgSrc = rowData.canWrite ? ON_ICON : OFF_ICON;
      checkbox.setAttribute('src', imgSrc);

      if (isAllowPwdChangeDisabled) {
        checkbox.setAttribute('class', 'disabled');
      } else {
        checkbox.addEventListener('click', (event) => {
          rowData.canWrite = !rowData.canWrite;
          const imgSrc = rowData.canWrite ? ON_ICON : OFF_ICON;
          window.requestAnimationFrame(() => checkbox.setAttribute('src', imgSrc));
          this.dispatchTableEvent('dDataTableInputEvent', rowData);
        });
      }
      cell.appendChild(checkbox);
      return;
    }
    cell.textContent = rowData[columnDef.id];
  }

  renderBody = () => {
    const userIsAdmin = ['superuser', 'admin'].includes(this._state.currentUser?.userType?.toLowerCase());

    this._state.data.forEach((rowData) => {
      const tableRow = document.createElement('tr');
      const isPwdChangeDisabled = rowData.scope.scopeName === 'allowPwdChange' && !userIsAdmin;

      this.tableBody.appendChild(tableRow);
      if (isPwdChangeDisabled) {
        tableRow.setAttribute('disabled', '');
      } else {
        tableRow.addEventListener('click', () => this.rowClick(rowData));
      }
        const rowSelected = this._state.selectedIds.includes(rowData.id);
      if (!rowData.disabled && rowSelected) {
        tableRow.setAttribute('class', 'selected');
      }

      this._state.columns.forEach(columnDef => {
        if (columnDef.invisible) {
          return;
        }
        const cell = document.createElement('td');
        this.addCell(cell, columnDef, rowData, isPwdChangeDisabled);
        tableRow.appendChild(cell);
        if (columnDef.align === 'right') {
          cell.setAttribute('class', 'align-right');
        }
        if (columnDef.primary) {
          cell.setAttribute('primary-column', '');
        }
      });
    });
  }

  renderPagination = () => {
    if (!this._state.paginated) {
      this.paginationContainer.setAttribute('hidden', '');
      return;
    }
    this.paginationContainer.removeAttribute('hidden');

    const { index, itemsPerPage } = this._state.pagination;
    const { totalItemCount, data } = this._state;

    const itemsPerPageText = this.shadowRoot.getElementById('items-per-page-text');
    itemsPerPageText.textContent = 'Rivejä sivulla:';

    const itemsPerPageDropdownBackdrop = this.shadowRoot.getElementById('items-per-page-dropdown-backdrop');
    itemsPerPageDropdownBackdrop.onclick = (event) => this.handleBackdropClick(event, () => this.toggleDropdown(itemsPerPageSelectDropdown, itemsPerPageDropdownBackdrop));

    const itemsPerPageSelectText = this.shadowRoot.getElementById('items-per-page-select-text');
    itemsPerPageSelectText.textContent = itemsPerPage === ALL ? 'Kaikki' : itemsPerPage;

    const itemsPerPageSelect = this.shadowRoot.getElementById('items-per-page-select');
    itemsPerPageSelect.onclick = () => this.toggleDropdown(itemsPerPageSelectDropdown, itemsPerPageDropdownBackdrop);

    const itemsPerPageSelectDropdown = this.shadowRoot.getElementById('items-per-page-dropdown');
    if (itemsPerPageSelectDropdown.childNodes.length === 0) {
      ITEMS_PER_PAGE_OPTIONS.forEach((choiceDef) => {
        const choice = document.createElement('div');
        choice.textContent = choiceDef === ALL ? 'Kaikki' : choiceDef;
        choice.onclick = () => this.setPaginationValue('itemsPerPage', choiceDef);
        itemsPerPageSelectDropdown.appendChild(choice);
      });
    }

    const pageContainer = this.shadowRoot.getElementById('page-container');
    const displayedItemsStart = itemsPerPage === ALL
      ? 1
      : index * itemsPerPage + 1;
    const displayedItemsEnd = itemsPerPage === ALL
      ? data.length
      : index * itemsPerPage + data.length;
    pageContainer.textContent = `${displayedItemsStart}-${displayedItemsEnd} / ${totalItemCount}`;

    const prevButton = this.shadowRoot.getElementById('prev-button');
    prevButton.onclick = () => this.setPaginationValue('index', index - 1);
    if (itemsPerPage === ALL || index < 1) {
      prevButton.setAttribute('disabled', '');
    } else {
      prevButton.removeAttribute('disabled');
    }
    const nextButton = this.shadowRoot.getElementById('next-button');
    nextButton.onclick = () => this.setPaginationValue('index', index + 1);
    if (itemsPerPage === ALL || totalItemCount <= index * itemsPerPage + data.length) {
      nextButton.setAttribute('disabled', '');
    } else {
      nextButton.removeAttribute('disabled');
    }
  }

  toggleDropdown = (dropdown, dropdownBackdrop) => {
    dropdown.classList.toggle('collapsed');
    dropdownBackdrop.classList.toggle('collapsed');
  }

  setPaginationValue = (property, value) => {
    const pagination = {
      ...this._state.pagination,
      [property]: value,
    };
    // Reset index if items per page is changed.
    if(property === 'itemsPerPage') {
      pagination.index = 0;
    }
    this._state = {
      ...this._state,
      pagination,
    };
    this.dispatchTableEvent('dDataTableEvent', this._state);
    window.requestAnimationFrame(() => this.render());
  }


  handleBackdropClick = (event, onClose) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    onClose();
  };

  updateSorting = (columnId) => {
    if (this._state.sortBy === columnId) {
      this._state.sortDirection = this._state.sortDirection === DESC ? ASC : DESC;
    } else {
      this._state.sortBy = columnId;
      this._state.sortDirection = ASC;
    }
    this.dispatchTableEvent('dDataTableEvent', this._state);
  }

  rowClick(rowData) {
    const rowClickedEvent = new CustomEvent('dataTableRowClick', {
      bubbles: true,
      composed: true,
      detail: {
        data: rowData,
      },
    });
    this.root.dispatchEvent(rowClickedEvent);
  }
}

window.customElements.define('permissions-data-table', PermissionsDataTable);
