import { stableSort } from "../../assets/lib/commonFunctions";

export class ChainCompanyStore {
  constructor() {
    this.data = null;
    this.chainId = null;
  }

  clearData() {
    this.data = null;
    this.chainId = null;
  }

  get dataLength() {
    return this.data ? this.data.length : 0;
  }

  postData(chainId, data = {}) {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + this.accessToken
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  }

  mapCompanyData = (data, translationService) => {
    return data.map((item) => ({
      ...item,
      siteCount: `${item.siteCount} ${translationService.translate('chainView.pcs')}`,
    }));
  }

  getCompanyData = async (translationService, chainId, sortBy, sortDirection) => {
    const queryObject = {
      procName: 'GetCompaniesInChain',
      data: { chainId },
    };

    if (this.chainId !== chainId) {
      const serverData = await this.postData(chainId, queryObject);
      this.data = this.mapCompanyData(serverData, translationService);
      this.chainId = chainId;
    }

    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.data = stableSort(this.data, sortBy, sortDirection);
    return this.data;
  }
}