import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, Button, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& > button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function AddQualityControlNoticeDialog({ open, onClose, onSubmit, isSubmitting }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [description, setDescription] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if(onSubmit(description.trim())) {
      setDescription('');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('qualityControlView.addNotice')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            <TextField
              label={t('qualityControlView.description')}
              multiline
              minRows={4}
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button onClick={onClose} disabled={isSubmitting}>
              {t('cancel')}
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
              {t('save')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

AddQualityControlNoticeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default AddQualityControlNoticeDialog;