import { createSlice } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

function addOrUpdateDefaultLines(defaultLines, payload) {
  let found = false;
  const result = defaultLines.map((item) => {
    // This isn't the item we care about - keep it as-is.
    if (item.vehicleGroupId !== payload.id) {
      return item
    }

    // Otherwise, this is the one we want - return an updated value.
    found = true;
    return {
      ...item,
      lineId: payload.lineId,
    }
  });

  // New entry.
  if(!found) {
    return result.concat({vehicleGroupId: payload.id, lineId: payload.lineId});
  }
  return result;
}

const defaultLineSlice = createSlice({
  name: 'defaultLines',
  initialState: {
    defaultLines: [],
    defaultLinesOriginal: [],
    isFetching: false,
    isDirty: false,
    fetchError: null,
  },
  reducers: {
    fetchDefaultLinesStart(state) {
      state.isFetching = true;
    },
    fetchDefaultLinesSuccess(state, { payload }) {
      state.defaultLines = payload;
      state.defaultLinesOriginal = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    saveDefaultLinesSuccess(state) {
      state.defaultLinesOriginal = state.defaultLines;
      state.isFetching = false;
      state.fetchError = null;
      state.isDirty = false;
    },
    fetchDefaultLinesFailure(state, { payload }) {
      state.isFetching = false;
      state.fetchError = payload;
    },
    setDefaultLine(state, { payload }) {
      state.defaultLines = addOrUpdateDefaultLines(state.defaultLines, payload);
      state.isDirty = true;
    },
    revertDefaultLine(state, { payload }) {
      state.defaultLines = state.defaultLinesOriginal;
      state.isDirty = false;
    }
  },
});

export const {
  fetchDefaultLinesStart,
  fetchDefaultLinesSuccess,
  fetchDefaultLinesFailure,
  setDefaultLine,
  revertDefaultLine,
  saveDefaultLinesSuccess,
} = defaultLineSlice.actions;

export default defaultLineSlice.reducer;

export const fetchDefaultLines = (chainId, siteId) => async (dispatch, getState) => {
  try {
    dispatch(fetchDefaultLinesStart());
    const queryData = {
      procName: 'GetDefaultInspectionLinesInSite',
      data: { chainId, siteId },
    };
    const {auth} = getState();
    const defaultLines = await sendRequest(chainId, queryData, 'POST', auth.token);
    const values = defaultLines.length ? defaultLines[0].defaultLines : [];
    dispatch(fetchDefaultLinesSuccess(values));
  } catch (err) {
    dispatch(fetchDefaultLinesFailure(err));
  }
}

export const saveDefaultLines = (chainId, siteId) => async (dispatch, getState) => {
  try {
    const {defaultLines, auth} = getState();
    dispatch(fetchDefaultLinesStart());
    const queryData = {
      procName: 'CreateDefaultInspectionLinesInSite',
      data: { chainId, siteId, defaultLines: JSON.stringify(defaultLines.defaultLines.filter(v => v.lineId && v.vehicleGroupId)) },
    };
    await sendRequest(chainId, queryData, 'POST', auth.token);
    dispatch(saveDefaultLinesSuccess());
  } catch (err) {
    dispatch(fetchDefaultLinesFailure(err));
  }
}