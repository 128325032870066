import { isFaultValid } from './faultConstants';

/**
 * Returns fault code prefix: target and fault parts.
 * @param {object} faultInfo fault object
 */
export function getFaultCodePrefix(faultInfo) {
  if (!isFaultValid(faultInfo)) return '';
  return `${faultInfo.mainFaultId.toString().padStart(2, '0')}${faultInfo.subFaultId.toString().padStart(2, '0')}${faultInfo.componentFaultId.toString().padStart(2, '0')}${faultInfo.faultId}`;
}

/**
 * Returns fault code location part.
 * @param {object} faultInfo fault object
 */
export function getFaultCodeLocation(faultInfo) {
  if (!isFaultValid(faultInfo)) return '';
  let location = `${faultInfo.mainFaultLocationId}`;
  if (faultInfo.subFaultLocationId !== null) {
    location = `${faultInfo.mainFaultLocationId}${faultInfo.subFaultLocationId}`;
  }
  return location;
}

/**
 * Converts the given fault info object to a fault code.
 * @param {object} faultInfo fault info
 * @returns fault code
 */
export function getFaultCode(faultInfo) {
  // Check that all components are given.
  if (!isFaultValid(faultInfo)) return '##'; // return something noticeable if faultInfo is partial.
  return `${getFaultCodePrefix(faultInfo)}${getFaultCodeLocation(faultInfo)}`;
}
