import { createSlice } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

const jobTypeSlice = createSlice({
  name: 'jobType',
  initialState: {
    jobTypes: [],
    isFetching: false,
    fetchError: null,
  },
  reducers: {
    fetchJobTypesStart(state) {
      state.isFetching = true;
    },
    fetchJobTypesSuccess(state, { payload }) {
      state.jobTypes = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    fetchJobTypesFailure(state, { payload }) {
      state.isFetching = false;
      state.fetchError = payload;
    },
  },
});

export const {
  fetchJobTypesStart,
  fetchJobTypesSuccess,
  fetchJobTypesFailure,
} = jobTypeSlice.actions;

export default jobTypeSlice.reducer;

export const fetchJobTypes = (chainId) => async (dispatch, getState) => {
  try {
    dispatch(fetchJobTypesStart());
    const queryData = {
      procName: 'GetJobTypesInChain',
      data: { chainId },
    };
    const { auth } = getState();
    const jobTypes = await sendRequest(chainId, queryData, 'POST', auth.token);
    dispatch(fetchJobTypesSuccess(jobTypes));
  } catch (err) {
    dispatch(fetchJobTypesFailure(err));
  }
}
