import { createSlice } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

const measurementTypesSlice = createSlice({
  name: 'measurementTypes',
  initialState: {
    measurementTypes: [],
    isRequesting: false,
    isSuccess: false,
    error: null,
  },
  reducers: {
    requestStarted(state) {
      state.isRequesting = true;
    },
    fetchMeasurementTypesSuccess(state, { payload }) {
      state.measurementTypes = payload;
      state.isRequesting = false;
      state.error = null;
      state.isSuccess = true;
    },
    requestedFailed(state, { payload }) {
      state.isRequesting = false;
      state.error = payload;
      state.isSuccess = false;
    }
  }
});

export const actions = measurementTypesSlice.actions;
export default measurementTypesSlice.reducer;

export const fetchMeasurementTypes = (selectedChainId) => async (dispatch, getState) => {
  try {
    dispatch(actions.requestStarted());
    const query = {
      procName: 'GetMeasurementTypes',
      data: {}
    }
    const { auth } = getState();
    const data = await sendRequest(selectedChainId, query, 'POST', auth.token);
    dispatch(actions.fetchMeasurementTypesSuccess(data));
  } catch (err) {
    dispatch(actions.requestedFailed(err));
  }
}
