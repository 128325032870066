import { ServiceBroker } from './serviceBroker.js';
import { TranslationService } from './services/translationService.js';
import { ProfileService } from './services/profileService.js';
import { ControlStateManager } from './controlStateManager.js';

export default class AppShell {
  constructor(callback) {
    this.serviceBroker = new ServiceBroker(new ControlStateManager());
    this.serviceBroker.RegisterServiceDirectly('translationService', new TranslationService(callback));
    this.serviceBroker.RegisterServiceDirectly('profileService', new ProfileService());
  }
}
