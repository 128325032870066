const sendRequest = (procName, chainId, data, accessToken) => {
  const body = {
    procName,
    data,
  };
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + accessToken
    },
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Request failed:', response.statusText);
    }
    return response.json()
      .then(res => res)
      .catch((err) =>{
        // Empty response - skip.
        return;
      });
  });
}

export const createCompany = (values, accessToken) => {
  const data = {
    name: values.name,
    chainId: values.chainId,
    businessIdentifier: values.businessIdentifier,
    sokuIdentifier: values.sokuIdentifier,
  };
  return sendRequest('createCompany', values.chainId, data, accessToken);
}

export const updateCompany = (values, accessToken) => {
  const data = {
    companyId: values.companyId,
    name: values.name,
    businessIdentifier: values.businessIdentifier,
    sokuIdentifier: values.sokuIdentifier,
  };
  return sendRequest('updateCompany', values.chainId, data, accessToken);
}

export const deleteCompany = (values, accessToken) => {
  return sendRequest('deleteCompany', values.chainId, { companyId: values.companyId }, accessToken);
}