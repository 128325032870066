import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    backgroundColor: theme.palette.backgroundWhite.main,
    color: theme.palette.textDarkGrey.main,
  },
}));

function RefreshSnackbar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const serviceWorkerUpdated = useSelector(state => state.serviceWorker.serviceWorkerUpdated);

  useEffect(() => {
    setOpen(serviceWorkerUpdated);
  }, [serviceWorkerUpdated]);

  const handleClose = () => setOpen(false);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      message={t('updateFoundMessage')}
      ContentProps={{
        classes: {
          root: classes.snackbar,
        },
      }}
      action={(
        <Button
          size='small'
          onClick={() => window.location.reload(true)}
          variant='contained'
          color='primary'
        >
          {t('refresh')}
        </Button>
      )}
    />
  )
}

export default RefreshSnackbar;