import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest, getDeviceDocuments, sendSiteRequest, uploadBlobToAzure } from '../api/api';

export const createDevice = createAsyncThunk('devices/createDevice', async ({ selectedChainId, data }, API) => {
  const query = {
    procName: 'CreateDevice',
    data,
  };
  const state = API.getState();
  const response = await sendRequest(selectedChainId, query, 'POST', state.auth.token);
  return response;
});

export const updateDeviceAdditionalSettings = createAsyncThunk('devices/updateDeviceAdditionalSettings', async ({ selectedChainId, data }, API) => {
  const query = {
    procName: 'UpdateDeviceAdditionalSettings',
    data,
  };
  const state = API.getState();
  await sendRequest(selectedChainId, query, 'POST', state.auth.token);
  return data;
});

export const updateExternalDeviceId = createAsyncThunk('devices/updateExternalDeviceId', async ({ selectedChainId, data }, API) => {
  const query = {
    procName: 'UpdateExternalDeviceId',
    data,
  };
  const state = API.getState();
  await sendRequest(selectedChainId, query, 'POST', state.auth.token);
  return data;
});

export const fetchDeviceDocuments = createAsyncThunk('devices/fetchDeviceDocuments', async ({ chainId, deviceId }, API) => {
  const state = API.getState();
  const response = await getDeviceDocuments(chainId, deviceId, state.auth.token);
  return response;
});

export const fetchDevices = createAsyncThunk('devices/fetchDevices', async ({ chainId, siteId, excludeDeleted }, API) => {
  const query = {
    procName: 'GetDevicesInSite',
    data: {
      chainId,
      siteId,
    },
  };
  const state = API.getState();
  const devices = await sendRequest(chainId, query, 'POST', state.auth.token);
  return excludeDeleted ? devices.filter(d => !d.deletedAt) : devices;
});

export const fetchDevice = createAsyncThunk('devices/fetchDevice', async ({ chainId, deviceId }, API) => {
  const query = {
    procName: 'GetDevice',
    data: {
      chainId,
      deviceId,
    },
  };
  const state = API.getState();
  const device = await sendRequest(chainId, query, 'POST', state.auth.token);
  return device;
});

export const updateDevice = createAsyncThunk('devices/updateDevice', async ({ chainId, data }, API) => {
  const query = {
    procName: 'UpdateDevice',
    data,
  };
  const state = API.getState();
  await sendRequest(chainId, query, 'POST', state.auth.token);
  return data;
});

export const deleteDevice = createAsyncThunk('devices/deleteDevice', async ({ chainId, data }, API) => {
  const state = API.getState();
  const query = {
    procName: 'DeleteDevice',
    data,
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const fetchPrintersInSite = createAsyncThunk('devices/fetchPrintersInSite', async ({ chainId, siteId }, API) => {
  const state = API.getState();
  const printers = await sendSiteRequest(chainId, siteId, 'GET', '/printers', '', null, state.auth.token);
  return printers;
});

export const fetchAtjPrintersInSite = createAsyncThunk('devices/fetchAtjPrintersInSite', async ({ chainId, siteId }, API) => {
  const state = API.getState();
  const atjPrinters = await sendSiteRequest(chainId, siteId, 'GET', '/atjPrinters', '', null, state.auth.token);
  return atjPrinters;
});

export const submitPrint = createAsyncThunk('devices/submitPrint', async ({ chainId, siteId, jobId, printerId, documentLocation, copies = 1, useSitePrint }, API) => {
  const state = API.getState();
  const payload = {
    printerId,
    documentLocation,
    copies,
    useSitePrint,
    jobId,
  };
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/print', '', payload, state.auth.token);
  return response;
});

export const submitCertificatePrint = createAsyncThunk('devices/submitCertificatePrint', async ({ chainId, siteId, printerId, certificateId, useSitePrint }, API) => {
  const state = API.getState();
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/printcertificate', '', { printerId, certificateId, useSitePrint }, state.auth.token);
  return response;
});

export const uploadDocument = createAsyncThunk(
  'devices/uploadDocument',
  async ({ chainId, siteId, deviceId, userId, file, description, technicianName, maintenanceDate }, API) => {
    // Fetch SAS token.
    const state = API.getState();
    const fileNameParts = file.name.split('.');
    const extension = '.' + fileNameParts[fileNameParts.length - 1].toLowerCase();
    const tokenData = await sendSiteRequest(chainId, siteId, 'GET', '/blobDocumentToken', `&extension=${extension}`, null, state.auth.token);

    // Upload file to blob storage.
    uploadBlobToAzure(tokenData.account, tokenData.container, tokenData.sas, tokenData.blobName, file);

    // Save image to DB.
    const queryData = {
      procName: 'AddDeviceDocument',
      data: {
        deviceId,
        addedBy: userId,
        guid: tokenData.resourceId,
        name: file.name,
        extension: tokenData.extension,
        description,
        contentLocation: tokenData.resourceUri,
        technicianName,
        isMaintenanceDocument: 1,
        maintenanceDate,
      },
    };
    const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
    return result;
  }
);

export const deleteDocument = createAsyncThunk('devices/deleteDocument', async ({ chainId, siteId, deviceId, documentId }, API) => {
  const state = API.getState();
  await sendSiteRequest(chainId, siteId, 'DELETE', `/device/${deviceId}/document/${documentId}`, '', null, state.auth.token);
  return documentId;
});

export const updateDeviceMaintenance = createAsyncThunk(
  'devices/updateDeviceMaintenance',
  async ({ chainId, deviceId, maintenanceDate, maintainedBy }, API) => {
    // Save data to DB.
    const state = API.getState();
    const queryData = {
      procName: 'UpdateDeviceMaintenance',
      data: {
        id: deviceId,
        chainId,
        maintenanceDate,
        maintainedBy,
      },
    };
    await sendRequest(chainId, queryData, 'POST', state.auth.token);

    // Update Redux state.
    return {
      maintenanceDate,
      maintainedBy,
    };
  }
);

export const updateDeviceCalibration = createAsyncThunk(
  'devices/updateDeviceCalibration',
  async ({ chainId, deviceId, calibrationDate, calibratedBy, calibrationValidUntilDate }, API) => {
    // Save data to DB.
    const state = API.getState();
    const queryData = {
      procName: 'UpdateDeviceCalibration',
      data: {
        id: deviceId,
        chainId,
        calibrationDate,
        calibratedBy,
        calibrationValidUntilDate,
      },
    };
    await sendRequest(chainId, queryData, 'POST', state.auth.token);

    // Update Redux state.
    return {
      calibrationDate,
      calibratedBy,
      calibrationValidUntilDate,
    };
  }
);

const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    devices: [],
    device: null,
    deviceDocuments: [],
    isRequesting: false,
    sitePrinters: [],
    siteAtjPrinters: [],
    isPrinting: false,
    isUploading: false,
  },
  extraReducers: {
    [createDevice.fulfilled]: (state, action) => {
      state.devices.push(action.payload[0]);
    },
    [fetchDeviceDocuments.pending]: (state) => {
      state.deviceDocuments = [];
    },
    [fetchDeviceDocuments.fulfilled]: (state, action) => {
      state.deviceDocuments = action.payload;
    },
    [fetchDevices.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchDevices.fulfilled]: (state, { payload }) => {
      state.devices = payload;
      state.isRequesting = false;
    },
    [fetchDevices.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchDevice.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchDevice.fulfilled]: (state, { payload }) => {
      state.device = payload;
      state.isRequesting = false;
    },
    [fetchDevice.rejected]: (state) => {
      state.isRequesting = false;
    },
    [updateExternalDeviceId.fulfilled]: (state, { payload: { deviceId, externalDeviceId } }) => {
      const device = state.devices.find(d => d.id === Number(deviceId));
      device.externalDeviceId = externalDeviceId;
    },
    [fetchPrintersInSite.pending]: (state) => {
      state.sitePrinters = [];
    },
    [fetchPrintersInSite.fulfilled]: (state, { payload }) => {
      state.sitePrinters = payload;
    },
    [fetchAtjPrintersInSite.pending]: (state) => {
      state.siteAtjPrinters = [];
    },
    [fetchAtjPrintersInSite.fulfilled]: (state, { payload }) => {
      state.siteAtjPrinters = payload;
    },
    [submitPrint.pending]: (state) => {
      state.isPrinting = true;
    },
    [submitPrint.fulfilled]: (state) => {
      state.isPrinting = false;
    },
    [submitPrint.rejected]: (state) => {
      state.isPrinting = false;
    },
    [uploadDocument.pending]: (state) => {
      state.isUploading = true;
    },
    [uploadDocument.fulfilled]: (state, { payload }) => {
      state.isUploading = false;
      state.deviceDocuments = [...state.deviceDocuments, payload];
    },
    [uploadDocument.rejected]: (state) => {
      state.isUploading = false;
    },
    [updateDeviceMaintenance.fulfilled]: (state, { payload }) => {
      state.device = {
        ...state.device,
        maintenanceDate: payload.maintenanceDate,
        maintainedBy: payload.maintainedBy,
      };
    },
    [updateDeviceCalibration.fulfilled]: (state, { payload }) => {
      state.device = {
        ...state.device,
        calibrationDate: payload.calibrationDate,
        calibratedBy: payload.calibratedBy,
        calibrationValidUntilDate: payload.calibrationValidUntilDate,
      };
    },
    [deleteDocument.fulfilled]: (state, { payload }) => {
      state.deviceDocuments = state.deviceDocuments.filter(doc => doc.id !== payload);
    },
  },
});

export default devicesSlice.reducer;
