import dayjs from 'dayjs';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendSiteRequest } from '../api/api';

export const fetchCommissionsReport = createAsyncThunk('site/fetchCommissionsReport', async ({ chainId, siteId, data }, API) => {
  const state = API.getState();
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/commissionsReport', '', data, state.auth.token);
  return response;
});

export const fetchInspectionsReport = createAsyncThunk('site/fetchInspectionsReport', async ({ chainId, siteId, data }, API) => {
  const state = API.getState();
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/inspectionsReport', '', data, state.auth.token);
  return response;
});

export const fetchSurveillancesReport = createAsyncThunk('site/fetchSurveillancesReport', async ({ chainId, siteId, data }, API) => {
  const state = API.getState();
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/surveillancesReport', '', data, state.auth.token);
  return response;
});

export const fetchQualityControlDeviationsReport = createAsyncThunk('site/fetchQualityControlDeviationsReport', async ({ chainId, siteId, data }, API) => {
  const state = API.getState();
  const payload = { ...data };
  let endTime = payload.endTime;
  if (!endTime) {
    endTime = dayjs().format('YYYY-MM-DD');
  }
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/qualityControlDeviationsReport', '', {
    ...payload,
    startDate: payload.startTime,
    endDate: endTime,
  }, state.auth.token);
  return response;
});

export const fetchQualityControlNoticesReport = createAsyncThunk('site/fetchQualityControlNoticesReport', async ({ chainId, siteId, data }, API) => {
  const state = API.getState();
  const response = await sendSiteRequest(chainId, siteId, 'POST', '/qualityControlNoticesReport', '', data, state.auth.token);
  return response;
});

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {},
  extraReducers: {},
});

export default reportsSlice.reducer;
