import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { setNotification } from '../../../reducers/notificationReducer';
import { updateQualityControlNotice } from '../../../reducers/qualityControlNoticesReducer';
import AddQualityControlNoticeDialog from '../../inspectionHistory/AddQualityControlNoticeDialog';

import InspectionNoticesList from './InspectionNoticesList';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.black.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  addButton: {
    height: 56,
    borderRadius: 0,
  },
  addButtonLabel: {
    '& > div': {
      display: 'flex',
      width: '100%',
      '& > div:first-child': {
        flex: 1,
        marginLeft: 24,
        fontSize: 16,
        letterSpacing: 0.5,
      },
    },
  },
}));

function InspectionNotices({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const job = useSelector(state => state.jobs.job);
  const user = useSelector(state => state.auth.user);
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);

  const { id: userId, name } = user;

  const handleCloseAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleSubmit = async (description) => {
    if (!description) {
      return false;
    }

    setIsSubmitting(true);
    try {
      await dispatch(updateQualityControlNotice({
        chainId: selectedChainId,
        payload: {
          siteId: selectedSiteId,
          jobId: job.id,
          createdByUserId: userId,
          description,
          createdByName: name,
        },
      })).unwrap();
      dispatch(setNotification({ message: t('dataSaved'), type: 'success' }));
      handleCloseAddDialog();
      return true;
    } catch (error) {
      console.error('Failed to add quality control notice:', error);
      dispatch(setNotification({ message: t('saveFailed'), type: 'error' }));
    } finally {
      setIsSubmitting(false);
    }
    return false;
  };  

  if (!job) {
    return null;
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
          <div className={classes.title}>{t('qualityControlView.notices')}</div>
        </div>

        <div className={classes.list}>
          <InspectionNoticesList />
        </div>

        <Button
          variant='contained'
          color='primary'
          disableElevation
          className={classes.addButton}
          classes={{ label: classes.addButtonLabel }}
          onClick={() => setIsAddDialogOpen(true)}
        >
          <div>
            <div>{t('qualityControlView.addNotice')}</div>
            <AddRoundedIcon />
          </div>
        </Button>
      </div>
      <AddQualityControlNoticeDialog
        open={isAddDialogOpen}
        onClose={handleCloseAddDialog}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </Drawer>
  );
}

InspectionNotices.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default InspectionNotices;
