export default new Map([
  [0, ['Ei mitään', 'Ingenting', 
    [0, 'Molemmat', 'Båda'],
  ]],
  [1, ['Vasemmalla takana', 'Bak vänster', 
    [1, 'Vasemmalla takana ulompi', 'Bak vänster yttre'],
    [2, 'Vasemmalla takana alempi', 'Bak vänster nedre'],
    [3, 'Takana poikittainen', 'Bak tvärsgående'],
    [5, 'Vasemmalla takana sisempi', 'Bak vänster inre'],
    [7, 'Vasemmalla pitkittäinen', 'Vänster längsgående'],
    [8, 'Vasemmalla takana ylempi', 'Vänster bak övre'],
    [23, 'Takana vas. ja oik.', 'Bak vänster och höger'],
    [39, 'V. ja o. taka ja oik etu', 'V. och h. bak och h. fram'],
    [47, 'Vas. takana ja edessä', 'Vänster bak och fram'],
    [59, 'Vas takana ja oik edessä', 'Vänst. bak och höger fram'],
    [79, 'V. ja o. etu ja vas taka', 'V. och h. fram och v. bak'],
    [397, 'Kaikki', 'Alla'],
    [793, 'Kaikki', 'Alla'],
    [888, 'Vas takan etummainen', 'Vänster bak längst fram'],
  ]],
  [2, ['Takana', 'Bak', 
    [2, 'Alempi', 'Nedre'],
    [5, 'Takana sisempi', 'Bak inre'],
    [8, 'Pitkittäinen', 'Längsgående'],
    [22, 'Takimmainen', 'Längst bak'],
    [55, 'Takana keskellä', 'Bak i mitten'],
    [58, 'Takana ja edessä', 'Bak och fram'],
    [2588, 'Alhaalla ja ylhäällä', 'Upptill och nedtill'],
  ]],
  [3, ['Oikealla takana', 'Höger bak', 
    [1, 'Takana poikittainen', 'Höger tvärsgående'],
    [2, 'Oikealla takana alempi', 'Höger bak nedre'],
    [3, 'Oikealla takana ulompi', 'Höger bak yttre'],
    [5, 'Oikealla takana sisempi', 'Höger bak inre'],
    [8, 'Oikealla takana ylempi', 'Höger bak övre'],
    [9, 'Oikealla pitkittäin', 'Höger längsgående'],
    [17, 'V. ja o. taka ja vas etu', 'V. och h. bak och v. fram'],
    [21, 'Takana oik. ja vas.', 'Bak höger och vänster'],
    [57, 'Oik takana ja vas edessä', 'Höger bak och vänst. fram'],
    [69, 'Oik. takana ja edessä', 'Höger bak och fram'],
    [97, 'V. ja o. etu ja oik taka', 'V. och h. fram och h. bak'],
    [179, 'Kaikki', 'Alla'],
    [888, 'Oik. takana etummainen', 'Höger bak längst fram'],
    [971, 'Kaikki', 'Alla'],
  ]],
  [4, ['Vasemmalla', 'Vänster', 
    [1, 'Vasemmalla takimmainen', 'Vänster bakre'],
    [2, 'Vasemmalla alempi', 'Vänster nedre'],
    [4, 'Vasemmalla ulompi', 'Vänster yttre'],
    [5, 'Vasemmalla sisempi', 'Vänster inre'],
    [6, 'Poikittainen', 'Tvärsgående'],
    [7, 'Vasemmalla etummainen', 'Vänster främre'],
    [8, 'Vasemmalla ylempi', 'Vänster övre'],
    [56, 'Vasemmalla ja oikealla', 'Vänster och höger'],
    [562, 'Vas. ja oik. takimmainen', 'Vänster och höger bakre'],
    [568, 'Vas. ja oik. etummainen', 'Vänster och höger främre'],
    [8542, 'Vas. etumm. ja takimm.', 'Vänstra främre och bakre'],
  ]],
  [5, ['Sisempi', 'Inre', 
    [5, 'Keskellä', 'I mitten'],
    [54, 'Keskellä vasen', 'I mitten vänster'],
    [56, 'Keskellä oikea', 'I mitten höger'],
  ]],
  [6, ['Oikealla', 'Höger', 
    [2, 'Oikealla alempi', 'Höger nedre'],
    [3, 'Oikealla takimmainen', 'Höger bakre'],
    [4, 'Poikittainen', 'Tvärsgående'],
    [5, 'Oikealla sisempi', 'Höger inre'],
    [6, 'Oikealla ulompi', 'Höger yttre'],
    [8, 'Oikealla ylempi', 'Höger övre'],
    [9, 'Oikealla etummainen', 'Höger främre'],
    [54, 'Oikealla ja vasemmalla', 'Höger och vänster'],
    [542, 'Oik. ja vas. takimmainen', 'Höger och vänster bakre'],
    [548, 'Oik. ja vas. etummainen', 'H. och v. främre'],
    [8562, 'Oik. etumm. ja takimm.', 'Högra främre och bakre'],
  ]],
  [7, ['Vasemmalla edessä', 'Vänster fram', 
    [1, 'Vasemmalla pitkittäinen', 'Vänster längsgående'],
    [2, 'Vasemmalla edessä alempi', 'Vänster fram nedre'],
    [5, 'Vasemmalla edessä sisempi', 'Vänster fram inre'],
    [7, 'Vasemmalla edessä ulompi', 'Vänster fram yttre'],
    [8, 'Vasemmalla edessä ylempi', 'Vänster fram övre'],
    [9, 'Edessä poikittainen', 'Fram tvärsgående'],
    [13, 'V. ja o. taka ja vas etu', 'V. och h. bak och v. fram'],
    [41, 'Vas. edessä ja takana', 'Vänster fram och bak'],
    [53, 'Vas edessä ja oik takana', 'Vänst. fram och höger bak'],
    [89, 'Edessä vas. ja oik.', 'Fram vänster och höger'],
    [93, 'V. ja o. etu ja oik taka', 'V. och h. fram och h. bak'],
    [139, 'Kaikki', 'Alla'],
    [222, 'Vas edessä takimmainen', 'Vänster fram längst bak'],
    [913, 'Kaikki', 'Alla'],
    [931, 'Kaikki', 'Alla'],
  ]],
  [8, ['Edessä', 'Framtill', 
    [2, 'Pitkittäinen', 'Längsgående'],
    [5, 'Edessä sisempi', 'Fram inre'],
    [8, 'Ylempi', 'Ã–vre'],
    [52, 'Edessä ja takana', 'Fram och bak'],
    [55, 'Edessä keskellä', 'Fram i mitten'],
    [88, 'Etummainen', 'Längst fram'],
    [8522, 'Ylhäällä ja alhaalla', 'Upptill och nedtill'],
  ]],
  [9, ['Edessä oikealla', 'Höger fram', 
    [2, 'Oikealla edessä alempi', 'Höger fram nedre'],
    [3, 'Oikealla pitkittäinen', 'Höger längsgående'],
    [5, 'Oikealla edessä sisempi', 'Höger fram inre'],
    [7, 'Edessä poikittainen', 'Fram tvärsgående'],
    [8, 'Oikealla edessä ylempi', 'Höger fram övre'],
    [9, 'Oikealla edessä ulompi', 'Höger fram yttre'],
    [31, 'V. ja o. taka ja oik etu', 'V. och h. bak och h. fram'],
    [51, 'Oik edessä ja vas takana', 'Höger fram och vänst. bak'],
    [63, 'Oik. edessä ja takana', 'Höger fram och bak'],
    [71, 'V. ja o. etu ja vas taka', 'V. och h. fram och v. bak'],
    [87, 'Edessä oik. ja vas.', 'Fram höger och vänster'],
    [222, 'Oik edessä takimmainen', 'Höger fram längst bak'],
    [317, 'Kaikki', 'Alla'],
    [713, 'Kaikki', 'Alla'],
  ]],
  [-1, ['1-akselilla', 'På 1-axeln', 
    [456, '1-akselilla vas. ja oik.', 'På 1-axeln vänster och höger'],
    [69, '1-akseli oik. etummainen', 'På 1-axeln höger främre'],
    [63, '1-akseli oik. takimmainen', 'På 1-axeln höger bakre'],
    [47, '1-akseli vas. etummainen', 'På 1-axeln vänster främre'],
    [41, '1-akseli vas. takimmainen', 'På 1-axeln vänster bakre'],
    [6, '1-akselilla oikealla', 'På 1-axeln höger'],
    [4, '1-akselilla vasemmalla', 'På 1-axeln vänster'],
  ]],
  [-2, ['2-akselilla', 'På 2-axeln', 
    [456, '2-akselilla vas. ja oik.', 'På 2-axeln vänster och höger'],
    [69, '2-akseli oik. etummainen', 'På 2-axeln höger främre'],
    [63, '2-akseli oik. takimmainen', 'På 2-axeln höger bakre'],
    [47, '2-akseli vas. etummainen', 'På 2-axeln vänster främre'],
    [41, '2-akseli vas. takimmainen', 'På 2-axeln vänster bakre'],
    [6, '2-akselilla oikealla', 'På 2-axeln höger'],
    [4, '2-akselilla vasemmalla', 'På 2-axeln vänster'],
  ]],
  [-3, ['3-akselilla', 'På 3-axeln', 
    [456, '3-akseli vas. ja oik.', 'På 3-axeln vänster och höger'],
    [69, '3-akseli oik. etummainen', 'På 3-axeln höger främre'],
    [63, '3-akseli oik. takimmainen', 'På 3-axeln höger bakre'],
    [47, '3-akseli vas. etummainen', 'På 3-axeln vänster främre'],
    [41, '3-akseli vas. takimmainen', 'På 3-axeln vänster bakre'],
    [6, '3-akselilla oikealla', 'På 3-axeln höger'],
    [4, '3-akselilla vasemmalla', 'På 3-axeln vänster'],
  ]],
  [-4, ['4-akselilla', 'På 4-axeln', 
    [456, '4-akseli vas. ja oik.', 'På 4-axeln vänster och höger'],
    [69, '4-akseli oik. etummainen', 'På 4-axeln höger främre'],
    [63, '4-akseli oik. takimmainen', 'På 4-axeln höger bakre'],
    [47, '4-akseli vas. etummainen', 'På 4-axeln vänster främre'],
    [41, '4-akseli vas. takimmainen', 'På 4-axeln vänster bakre'],
    [6, '4-akselilla oikealla', 'På 4-axeln höger'],
    [4, '4-akselilla vasemmalla', 'På 4-axeln vänster'],
  ]],
  [-5, ['5-akselilla', 'På 5-axeln', 
    [456, '5-akseli vas. ja oik.', 'På 5-axeln vänster och höger'],
    [69, '5-akseli oik. etummainen', 'På 5-axeln höger främre'],
    [63, '5-akseli oik. takimmainen', 'På 5-axeln höger bakre'],
    [47, '5-akseli vas. etummainen', 'På 5-axeln vänster främre'],
    [41, '5-akseli vas. takimmainen', 'På 5-axeln vänster bakre'],
    [6, '5-akselilla oikealla', 'På 5-axeln höger'],
    [4, '5-akselilla vasemmalla', 'På 5-axeln vänster'],
  ]],
]);

// Displayed along with main locations.
export const HIGHLIGHTED_LOCATIONS = ['00', '1793', '22', '28', '222', '46', '55', '88', '888'];
