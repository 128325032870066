import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import FaultList from './FaultList';
import { openAddFault } from '../../../reducers/jobReducer';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.black.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  addButton: {
    height: 56,
    borderRadius: 0,
  },
  addButtonLabel: {
    '& > div': {
      display: 'flex',
      width: '100%',
      '& > div:first-child': {
        flex: 1,
        marginLeft: 24,
        fontSize: 16,
        letterSpacing: 0.5,
      },
    },
  },
}));

function InspectionFaults({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const job = useSelector(state => state.jobs.job);

  const handleAddFault = () => dispatch(openAddFault());

  // No job.
  if (!job) {
    return null;
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
        onKeyDown={onClose}
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
          <div className={classes.title}>{t('inspectionView.faults')}</div>
        </div>

        <FaultList />

        <Button
          variant='contained'
          color='primary'
          disableElevation
          className={classes.addButton}
          classes={{ label: classes.addButtonLabel }}
          onClick={handleAddFault}
        >
          <div>
            <div>{t('faults.addFault')}</div>
            <AddRoundedIcon />
          </div>
        </Button>
      </div>
    </Drawer>
  );
}

InspectionFaults.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default InspectionFaults;
