import css from './confirmation-dialog-css';

/**
 * Simple dialog with text and accept & cancel buttons. Append to your component to display.
 *
 * Properties:
 * content - Text shown on dialog
 * onAccept - Click handler for accept button
 * acceptButtonText - Text for accept button (default = accept (translated))
 * onCancel - Click handler for cancel button
 * cancelButtonText - Text for cancel button (default = cancel (translated))
 */
window.customElements.define('confirmation-dialog', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => word };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
    };

    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    this._state = {
      content: '',
      onAccept: null,
      acceptButtonText: null,
      onCancel: null,
      cancelButtonText: null,
    }

    this.build();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      window.requestAnimationFrame(this.refreshData);
    }
  }

  disconnectedCallback() {
    this._state.content = '';
    this.refreshData();
  }

  set onCancel(value) {
    this.setState('onCancel', value);
  }

  set cancelButtonText(value) {
    this.setState('cancelButtonText', value);
  }

  set onAccept(value) {
    this.setState('onAccept', value);
  }

  set acceptButtonText(value) {
    this.setState('acceptButtonText', value);
  }

  set content(value) {
    this.setState('content', value);
  }

  set values(values) {
    this._state = {
      ...this._state,
      content: values.content || '',
      onAccept: values.onAccept,
      acceptButtonText: values.acceptButtonText,
      onCancel: values.onCancel,
      cancelButtonText: values.cancelButtonText,
    };
    window.requestAnimationFrame(this.refreshData);
  }

  setState = (property, value) => {
    this._state[property] = value;
    window.requestAnimationFrame(this.refreshData);
  }

  build = async () => {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'confirmation-dialog');
    this.shadowRoot.appendChild(this.root);

    const backdrop = document.createElement('div');
    backdrop.setAttribute('class', 'dialog-backdrop');
    this.root.appendChild(backdrop);

    const dialogContainer = document.createElement('div');
    dialogContainer.setAttribute('class', 'dialog-container');
    backdrop.appendChild(dialogContainer);

    this.contentContainer = document.createElement('div');
    this.contentContainer.setAttribute('class', 'content-container');
    dialogContainer.appendChild(this.contentContainer);

    this.buttonsContainer = document.createElement('div');
    this.buttonsContainer.setAttribute('class', 'buttons-container');
    dialogContainer.appendChild(this.buttonsContainer);

    window.requestAnimationFrame(this.render);
  }

  renderContent = () => {
    this.contentContainer.textContent = this._state.content;
  }

  renderButtons = () => {
    const cancelButton = this.cancelButton = document.createElement('button');
    cancelButton.textContent = this._state.cancelButtonText || this.translationService.translate('cancel');
    if (this._state.onCancel) {
      cancelButton.onclick = this._state.onCancel;
    }
    this.buttonsContainer.appendChild(cancelButton);

    const acceptButton = this.acceptButton = document.createElement('button');
    acceptButton.setAttribute('class', 'primary');
    acceptButton.textContent = this._state.acceptButtonText || this.translationService.translate('accept');
    if (this._state.onAccept) {
      acceptButton.onclick = this._state.onAccept;
    }
    this.buttonsContainer.appendChild(acceptButton);
  }

  render = () => {
    this.renderContent();
    this.renderButtons();
  }

  refreshButtons = () => {
    this.cancelButton.textContent = this._state.cancelButtonText || this.translationService.translate('cancel');
    this.cancelButton.onclick = this._state.onCancel;
    this.acceptButton.textContent = this._state.acceptButtonText || this.translationService.translate('accept');
    this.acceptButton.onclick = this._state.onAccept;
  }

  refreshData = () => {
    if (!this.contentContainer) {
      return;
    }
    while (this.contentContainer.childNodes.length > 0) {
      this.contentContainer.removeChild(this.contentContainer.firstChild);
    }
    this.renderContent();
    this.refreshButtons();
  }
});
