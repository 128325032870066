const fi = require('../../localization/fi-FI.json');
const sv = require('../../localization/sv-FI.json');

export class TranslationService {
  // Constructor takes continueCallback. This way we can first load resource we need
  // to construct our app, like translations.
  constructor(continueCallback) {
    this.language = window.preferredLanguage || 'fi-FI';
    this.requestControlState();
    this.fetchTranslation(continueCallback);
  }

  fetchTranslation = (callback) => {
    if (this.language === 'fi-FI') {
      this.vocabulary = fi;
    }
    if (this.language === 'sv-FI') {
      this.vocabulary = sv;
    }
    if (callback) {
      callback();
    }
  }

  onControlStateChanged = (controlState) => {
    this.cS = controlState;
    if(controlState.lastAction === 'wantedLanguage')
    {
      if(this.language !== controlState.wantedLanguage){
        this.language = controlState.wantedLanguage;
        this.fetchTranslation(this.newVocabularyLoaded);
      }
    }
  }

  newVocabularyLoaded = () => {
    this.cS.proposeChange('languageChanged',this.language);
  }

  requestControlState = () => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: 'controlState',
          callback: this.onControlStateChanged,
        }});
      window.dispatchEvent(requestServiceEvent);
    };

  // Simply access our vocabulary object. If property doesn't exist, return
  // indication that given word isn't in vocabulary.
  translate = (word) => {
    return word.split('.').reduce(((vocabulary, pathPart) => vocabulary && vocabulary[pathPart]), this.vocabulary) || `#${word}#`;
  }
}