import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendUsersRequest } from '../api/api';

const INITIAL_STATE = {
  name: '',
  username: '',
  email: '',
  phoneNumber: '',
  password: '',
  passwordRepeat: '',
  preferredLanguage: '',
};

export const fetchMyInfo = createAsyncThunk('myInfo/fetchMyInfo', async (id, API) => {
  const state = API.getState();
  const myInfo = await sendUsersRequest({ id }, state.auth.token, 'MyInfo', 'POST');
  return {
    ...INITIAL_STATE,
    id,
    name: myInfo.name,
    username: myInfo.username,
    email: myInfo.email,
    phoneNumber: myInfo.phoneNumber,
    preferredLanguage: myInfo.preferredLanguage,
  };
});

export const updateMyInfo = createAsyncThunk('myInfo/updateMyInfo', async (data, API) => {
  const state = API.getState();
  const myInfo = await sendUsersRequest(data, state.auth.token, 'MyInfo', 'POST');
  return myInfo;
});

export const fetchAvailableDefaultDevices = createAsyncThunk('myInfo/fetchAvailableDefaultDevices', async (_, API) => {
  const state = API.getState();
  const devices = await sendUsersRequest({}, state.auth.token, 'MyAvailableDefaultDevices');
  return devices;
});

const myInfoSlice = createSlice({
  name: 'myInfo',
  initialState: {
    myInfo: null,
    isFetching: false,
    isSaving: false,
    defaultDeviceOptions: [],
  },
  extraReducers: {
    [fetchMyInfo.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchMyInfo.fulfilled]: (state, { payload }) => {
      state.myInfo = payload;
      state.isFetching = false;
    },
    [fetchMyInfo.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },
    [updateMyInfo.pending]: (state) => {
      state.isSaving = true;
    },
    [updateMyInfo.fulfilled]: (state, { payload }) => {
      state.myInfo = payload;
      state.isSaving = false;
    },
    [updateMyInfo.rejected]: (state, { payload }) => {
      state.isSaving = false;
    },
    [fetchAvailableDefaultDevices.fulfilled]: (state, { payload }) => {
      state.defaultDeviceOptions = payload;
    },
  },
});

export default myInfoSlice.reducer;
