import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { useMsal } from '@azure/msal-react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import { setNotification } from '../../reducers/notificationReducer';
import { fetchChains } from '../../reducers/chainReducer';
import { fetchSites, setSelectedSiteId } from '../../reducers/siteReducer';
import { getUser, updateLastSiteId } from '../../reducers/authReducer';

import LoadingView from '../navigation/LoadingView';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
   },
  container: {
    backgroundColor: theme.palette.white.main,
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: 4,
    padding: 20,
    minWidth: 300,
  },
  titleContainer: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: 18,
    letterSpacing: 0,
    marginTop: '40px',
    marginBottom: '15px'
  },
  site: {
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  siteName: {
    color: '#7800F2',
    fontFamily: 'Jost',
    fontSize: 14,
  },
  icon: {
    color: '#7800F2',
    fontSize: 18,
  },
  sites: {
    textDecoration: 'none',
    marginTop: '20px',
    marginBottom: '15px'
  },
  logout: {
    textDecoration: 'none',
  },
}));

function SiteItem({ site, handleSelection }) {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.site} onClick={() => handleSelection(site.id)}>
      <div className={classes.siteName}>{site.name}</div>
      <ArrowForwardRoundedIcon className={classes.icon} />
    </ButtonBase>
  )
}

SiteItem.propTypes = {
  site: PropTypes.object.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

function SiteSelectionContainer() {
  const classes = useStyles();
  const { instance } = useMsal();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const token = useSelector(state => state.auth.token);
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const sites = useSelector(state => state.sites.sites);
  const lastSite = sites.find(s => s.id === user.lastSiteId);  // Last used site will not be shown to superusers, if that site does not belong to chains[0], see NOTE1 in chainReducer.
  const isFetching = useSelector(state => state.myInfo.isFetching || state.auth.isFetching || state.chains.isFetching || state.sites.isFetching);

  const userId = user?.id;
  const showSitesLink = user && user.userType === 'admin' && sites.length < 1;

  useEffect(() => {
    if(token) {
      Promise.all([
        dispatch(fetchChains({})).then(unwrapResult),
        dispatch(getUser()).then(unwrapResult),
      ])
      .then((resp) => {
        const lang = (resp[1] && resp[1].preferredLanguage) || 'fi-FI';
        i18n.changeLanguage(lang);
      })
      .catch((error) => {
        console.warn('Failed to fetch data:', error);
        dispatch(setNotification({ message: t('fetchDataFailed'), type: 'error' }));
      });
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (selectedChainId && userId && token) {
      dispatch(fetchSites({ chainId: selectedChainId })).then(unwrapResult)
      .catch((error) => {
        console.warn('Failed to fetch data:', error);
        dispatch(setNotification({ message: t('fetchDataFailed'), type: 'error' }));
      });
    }
  }, [dispatch, selectedChainId, userId, token]);

  // Handle log out.
  const handleLogOut = (e) => {
    e.preventDefault();
    console.log('handleLogOut');
    instance.logoutRedirect({
        postLogoutRedirectUri: process.env.REACT_APP_KAPA_BASE_URL ? `${process.env.REACT_APP_KAPA_BASE_URL}/logout` : '/',
    });
  }

  // Handles site selection.
  const handleSelectSite = (siteId) => {
    dispatch(setSelectedSiteId(siteId));
    dispatch(updateLastSiteId({ lastSiteId: siteId }))
      .then(unwrapResult)
      .then(() => {
        history.push(`/home`);
      })
      .catch(() => dispatch(setNotification({ message: t('siteSelection.saveFailed'), type: 'error' })));
  }

  // Handle single site case.
  if(!isFetching && sites.length === 1) {
    handleSelectSite(sites[0].id);
  }

  // Contents.
  const SitesLink = () => <div className={classes.sites}><NavLink className={clsx(classes.siteName, classes.sites)} to='/sites'>{t('sites')}</NavLink></div>;
  const content = (
    <div className={classes.root}>
      <Box className={classes.container}>
        <div className={classes.titleContainer}>
          <div>{t('siteSelection.title')}</div>
        </div>
        {showSitesLink ? <SitesLink /> : <div>
          <Typography className={classes.sectionTitle} variant="subtitle1">{t('siteSelection.lastSiteUsed')}</Typography>
          {lastSite && <SiteItem site={lastSite} handleSelection={handleSelectSite} />}
          <Typography className={classes.sectionTitle} variant="subtitle1">{t('siteSelection.allSites')}</Typography>
          {sites.map(site => <SiteItem key={site.id} site={site} handleSelection={handleSelectSite} />)}
        </div>}
      </Box>
      <div><a className={clsx(classes.siteName, classes.logout)} href='#' onClick={handleLogOut}>{t('logout')}</a></div>
    </div>
    );

  // Render.
  return (
    isFetching ? <LoadingView /> : content
  );
}

SiteSelectionContainer.propTypes = {};

export default SiteSelectionContainer;
