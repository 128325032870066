import { SiteGroupStore } from './siteGroupStore.js';
import css from './site-groups-list-view-css';
import searchImage from '../../assets/icons/search.svg';
import addWhiteImage from '../../assets/icons/add_white.svg';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';

const NAME = 'name';
const DESCRIPTION = 'description';
const SITE_COUNT = 'siteCount';
const COLUMN_IDS = [NAME, DESCRIPTION, SITE_COUNT];

const ASC = 'asc';

class SiteGroupssListView extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {};

    // Element store & state.
    this.store = new SiteGroupStore(this.translationService);
    this._state = {
      isBuilt: false,
      sortBy: NAME,
      sortDirection: ASC,
      data: [],
      index: 0,
      itemsPerPage: 10,
      filterTimeout: null,
      filterValue: '',
    };

    // Request services.
    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ]
    );
  }

  // Requests needed services.
  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      // Using window.dispatchEvent, because this element is not added to the DOM still.
      window.dispatchEvent(requestServiceEvent);
    });
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
    this.store.setTranslationService(this.translationService);
  }

  // Listeners for control state changes.
  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    const { lastAction, chainId, user } = controlState;
    if (['chainIdChanged', 'userChanged', 'languageChanged'].includes(lastAction)) {
      if (chainId && user && this._state.isBuilt) {
        this.refreshData();
      }
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  // Life cycle callback, invoked when the custom element is first connected to the document's DOM.
  connectedCallback() {
    // Listen for table events.
    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
    this.addEventListener('dataTableRowClick', this.goToSiteGroupView);

    if (!this._state.isBuilt) {
      this.loadResources().then(() => {
        this.addRoot();
        this.refreshData();
        this._state.isBuilt = true;
      });
      return;
    }
    this.refreshData();
  }

  // Free store.
  disconnectedCallback() {
    this.store.clearData();
  }

  // Handles data table events.
  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
    this.refreshData();
  }

  // Go to details / new page.
  goToSiteGroupView = (event) => {
    const navEvent = new CustomEvent('siteGroupsNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/site-groups-detail',
        data: event.detail ? event.detail.data : {},
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  loadResources = async () => {
    const viewRoot = document.createElement('div');
    this.shadowRoot.appendChild(viewRoot);
    viewRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'site-groups-list-view');
    this.root.setAttribute('class', 'main-container');

    this.toolbar = this.createToolbar();
    this.root.appendChild(this.toolbar);
    this.dataTable = document.createElement('data-table');
    this.root.appendChild(this.dataTable);
    this.shadow.appendChild(this.root);
  }

  createToolbar = () => {
    const toolbar = document.createElement('div');
    toolbar.setAttribute('class', 'toolbar');

    // Title.
    const title = document.createElement('div')
    title.setAttribute('class', 'title');
    title.appendChild(document.createTextNode(this.translationService.translate('siteGroups')));
    toolbar.appendChild(title);

    // Add actions.
    this.addSearchInput(toolbar);
    this.addNewSiteButton(toolbar);

    // Toolbar element.
    return toolbar;
  }

  addSearchInput = (parent) => {
    const filterContainer = document.createElement('div');
    filterContainer.setAttribute('class', 'filter-container');
    parent.appendChild(filterContainer);
    const filterIcon = document.createElement('img');
    filterIcon.setAttribute('src', searchImage);
    filterContainer.appendChild(filterIcon);
    const filterInput = document.createElement('input');
    filterInput.setAttribute('placeholder', this.translationService.translate('siteGroupsView.searchForGroup'));
    filterInput.oninput = this.doSearch;
    filterContainer.appendChild(filterInput);
  }

  addNewSiteButton = (parent) => {
    const addButton = document.createElement('button');
    addButton.setAttribute('class', 'button');
    addButton.onclick = this.goToSiteGroupView;
    parent.appendChild(addButton);
    const addIcon = document.createElement('img');
    addIcon.setAttribute('src', addWhiteImage);
    addButton.appendChild(addIcon);
    const buttonText = document.createElement('div');
    buttonText.textContent = this.translationService.translate('siteGroupsView.newGroup');
    addButton.appendChild(buttonText);
  }

  // Search site, with the given text.
  doSearch = (event) => {
    const filterValue = event.target.value;
    if (this._state.filterTimeout) {
      clearTimeout(this._state.filterTimeout);
    }
    this._state.filterTimeout = setTimeout(() => {
      window.requestAnimationFrame(() => {
        this._state.filterValue = filterValue;
        this.dataTable.index = 0;
        this.refreshData();
      });
    }, 1000);
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`siteGroupsView.${columnId}`),
        sortable: true,
        primary: columnId === NAME,
      };
    });
  }

  refreshData = async () => {
    const { chainId, user } = this.controlState;
    if (!chainId || !user) {
      console.warn(`SiteGroupsListView.refreshData: chainId: ${chainId}, user: `, user);
      return;
    }
    const { sortBy, sortDirection, filterValue, index, itemsPerPage } = this._state;
    const sortInfo = { sortBy, sortDirection, secondarySortBy: NAME };
    const paginationInfo = { index, itemsPerPage };
    try {
      const data = await this.store.getSiteGroupData(chainId, sortInfo, filterValue, [NAME, DESCRIPTION], paginationInfo);
      this._state.data = data;
      this.dataTable.dataAndLayout = {
        data,
        columns: this.getTableColumnDefinitions(),
        sortBy: sortBy,
        sortDirection: sortDirection,
        paginated: true,
      };
      this.dataTable.totalItemCount = this.store.dataLength;
    } catch (error) {
      console.log('Failed to fetch sites', error);
      sendNotificationEvent(this.translationService.translate('siteGroupsView.fetchFailed'), 'error');
    }
  }
}
window.customElements.define('site-groups-list-view', SiteGroupssListView);
