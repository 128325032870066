import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

/**
 * Fetches chain condition inspection job types from the server.
 */
export const fetchConditionInspectionJobTypes = createAsyncThunk('conditionInspectionJobType/fetchConditionInspectionJobTypes', async ({ chainId }, API) => {
    const query = {
      procName: 'GetConditionInspectionJobTypesInChain',
      data: {},
    };
    const state = API.getState();
    const conditionInspectionJobTypes = await sendRequest(chainId, query, 'POST', state.auth.token);
    return conditionInspectionJobTypes;
});

/**
 * Fetches a condition inspection job type from the server.
 * @param {number} chainId The chain ID.
 * @param {number} jtId The job type ID.
 */
export const fetchConditionInspectionJobType = createAsyncThunk('conditionInspectionJobType/fetchConditionInspectionJobType', async ({ chainId, jtId }, API) => {
    const query = {
      procName: 'GetConditionInspectionJobTypeDetails',
      data: { jobTypeId: jtId },
    };
    const state = API.getState();
    const data = await sendRequest(chainId, query, 'POST', state.auth.token);
    // inspectionItems field should be parsed as JSON.
    if (data.inspectionItems) {
      data.inspectionItems = JSON.parse(data.inspectionItems);
    }
    return data;
});

/**
 * Adds or updates a condition inspection job type.
 * @param {number} chainId The chain ID.
 * @param {object} data The job type data, contains the ID of the job type to update.
 */
export const addOrUpdateConditionInspectionJobType = createAsyncThunk('conditionInspectionJobType/addOrUpdateConditionInspectionJobType', async ({ chainId, data }, API) => {
  // InspectionItems need to JSON serialized before sending to the server.
  data.inspectionItems = JSON.stringify(data.inspectionItems);
  const query = {
    procName: 'UpsertConditionInspectionJobType',
    data,
  };
  const state = API.getState();
  const result = await sendRequest(chainId, query, 'POST', state.auth.token);
  return result;
});

export const deleteConditionInspectionJobType = createAsyncThunk('conditionInspectionJobType/deleteConditionInspectionJobType', async ({ chainId, jtId }, API) => {
  const query = {
    procName: 'DeleteConditionInspectionJobType',
    data: { jobTypeId: jtId },
  };
  const state = API.getState();
  await sendRequest(chainId, query, 'POST', state.auth.token);
});

const conditionInspectionJobTypesSlice = createSlice({
  name: 'conditionInspectionJobTypes',
  initialState: {
    conditionInspectionJobTypes: [],
    conditionInspectionJobType: null,
    isRequesting: false,
  },
  extraReducers: {
    [fetchConditionInspectionJobTypes.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchConditionInspectionJobTypes.fulfilled]: (state, { payload }) => {
      state.conditionInspectionJobTypes = payload;
      state.isRequesting = false;
    },
    [fetchConditionInspectionJobTypes.rejected]: (state) => {
      state.isRequesting = false;
    },
    [fetchConditionInspectionJobType.pending]: (state) => {
      state.isRequesting = true;
    },
    [fetchConditionInspectionJobType.fulfilled]: (state, { payload }) => {
      state.conditionInspectionJobType = payload;
      state.isRequesting = false;
    },
    [fetchConditionInspectionJobType.rejected]: (state) => {
      state.isRequesting = false;
    },
  },
});

export const actions = conditionInspectionJobTypesSlice.actions;
export default conditionInspectionJobTypesSlice.reducer;
