export default `
<div id="contact-info-section">
  <div id="form-container" class="form-container">
    <div class="form-column">
      <div id="address-info-title" class="secondary-title"></div>

      <div>
        <div id="address-label" class="input-label"></div>
        <input id="address" />
      </div>

      <div class="input-row">
        <div>
          <div id="zipCode-label" class="input-label"></div>
          <input id="zipCode" />
        </div>
        <div>
          <div id="city-label" class="input-label"></div>
          <input id="city" />
        </div>
      </div>
    </div>

    <div class="form-column">
      <div id="other-contact-info-title" class="secondary-title"></div>

      <div>
        <div id="contactPersonName-label" class="input-label"></div>
        <input id="contactPersonName" />
      </div>
      
      <div class="input-row">
        <div>
          <div id="phoneNumber-label" class="input-label"></div>
          <input id="phoneNumber" />
        </div>
        <div>
          <div id="faxNumber-label" class="input-label"></div>
          <input id="faxNumber" />
        </div>
      </div>

      <div>
        <div id="email-label" class="input-label"></div>
        <input id="email" />
      </div>

      <div>
        <div id="wwwAddress-label" class="input-label"></div>
        <input id="wwwAddress" />
      </div>
    </div>
  </div>
</div>
`;