const TRIM_DECIMALS_PATTERN = /,?0*$/

export const roundNumber = (value, decimals) => Math.round(value * (10 ** decimals)) / (10 ** decimals);

export const formatDecimal = (value, decimals = 2, trimDecimals = true) => {
  if (value === null || value === undefined || value === '') {
      return null
  }

  const result = new Intl.NumberFormat('fi-FI', {minimumFractionDigits: decimals, maximumFractionDigits: decimals, useGrouping: true}).format(value)
      .replace(' ', ' ')
      .replace('−', '-')

  if (trimDecimals) {
      return result.replace(TRIM_DECIMALS_PATTERN, '')
  } else {
      return result
  }
}
