import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  infoMessage: {
    backgroundColor: '#F4F8FF',
    borderRadius: 4,
    border: '1px solid rgba(0,71,179,0.5)',
    padding: '10px 16px',
    fontSize: 16,
    color: '#0047B3',
  },
  warningMessage: {
    backgroundColor: '#FFF5EC',
    borderColor: '#B34E00',
    color: '#B34E00',
  },
  errorMessage: {
    backgroundColor: 'rgba(197,0,0,0.1)',
    border: '1px solid #C50000',
    color: '#C50000',
  },
}));

function InfoMessage({ message, type = 'default' }) {
  const classes = useStyle();
  return (
    <div className={clsx(classes.infoMessage, { [classes.warningMessage]: type === 'warning', [classes.errorMessage]: type === 'error' })}>
      {message}
    </div>
  );
}

InfoMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  type: PropTypes.oneOf(['default', 'warning', 'error']),
};

export default InfoMessage;
