import React, { useRef, useEffect, useCallback } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSites } from './reducers/siteReducer';
import { fetchSiteGroups } from './reducers/siteGroupReducer';
import { fetchVehicleGroups } from './reducers/vehicleGroupReducer';
import { fetchUsers } from './reducers/userReducer';
import { fetchJobTypes } from './reducers/jobTypeReducer';
import { setNotification } from './reducers/notificationReducer';
import './webComponents/components/root-app/root-app.js';

// Used for rendering content displayed by web components.
function RootContainer() {
  const appRef = useRef(null);
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const chains = useSelector(state => state.chains.chains);
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const sites = useSelector(state => state.sites.sites);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);

  // Forward Redux actions from root-app.
  const handleReduxEvent = useCallback(
    (event) => {
      const { action, data } = event.detail;
      if (action === 'fetchSites') {
        dispatch(fetchSites({ chainId: data.chainId }));
      } else if (action === 'fetchSiteGroups') {
        dispatch(fetchSiteGroups(data.chainId));
      } else if (action === 'fetchVehicleGroups') {
        dispatch(fetchVehicleGroups(data.chainId));
      } else if (action === 'fetchUsers') {
        dispatch(fetchUsers({ chainId: data.chainId }));
      } else if (action === 'fetchJobTypes') {
        dispatch(fetchJobTypes(data.chainId));
      } else if (action === 'setNotification') {
        dispatch(setNotification({ message: data.message, type: data.type }));
      }
    }, [dispatch],
  );

  // Forward navigation actions from root-app.
  const handleNavigationEvent = useCallback(
    (event) => {
      history.push(event.detail.route);
    }, [history],
  );

  // Add/remove event listeners.
  useEffect(() => {
    window.addEventListener('reduxEvent', handleReduxEvent);
    window.addEventListener('navigationEvent', handleNavigationEvent);
    return () => {
      window.removeEventListener('reduxEvent', handleReduxEvent);
      window.removeEventListener('navigationEvent', handleNavigationEvent);
    }
  }, [handleReduxEvent, handleNavigationEvent]);

  // Forward Redux data to root-app.
  useEffect(() => {
    appRef.current.sites = sites;
  }, [appRef, sites]);

  useEffect(() => {
    appRef.current.chains = chains;
  }, [appRef, chains]);

  useEffect(() => {
    if(token) {
      appRef.current.accessToken = token;
    }
  }, [token]);

  // Forward route to root-app without selected ID.
  const route = params.id ? location.pathname.replace(`/${params.id}`, '') : location.pathname;

  return (
    <root-app ref={appRef} current-route={route} item-id={params.id} chain-id={selectedChainId} site-id={selectedSiteId} />
  );
}

export default RootContainer;
