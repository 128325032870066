export const b2cPolicies = {
  names: {
    signIn: "B2C_1A_SIGNIN"
  },
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_LOGIN_AUTHORITY,
    }
  },
  authorityDomain: process.env.REACT_APP_LOGIN_AUTHORITY_DOMAIN
}