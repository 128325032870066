import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ImageDetails from './ImageDetails';
import NewImageDetails from './NewImageDetails';
import { uploadImage, fetchJobImages, deleteImage } from '../../../reducers/jobReducer';
import { setNotification } from '../../../reducers/notificationReducer';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { MAX_IMAGE_WIDTH_HEIGHT, MAX_THUMBNAIL_WIDTH_HEIGHT, resizeImage } from '../../../helpers/image';


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.black.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
  },
  imageRow: {
    marginTop: 10,
    height: 75,
    display: 'flex',
    '& > button:nth-child(2)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      paddingLeft: 16,
      paddingRight: 16,
      color: 'rgba(0,0,0,0.6)',
    },
  },
  imageButton: {
    height: 75,
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    flex: 1,
    '& > div:nth-child(2)': {
      display: 'flex',
      flex: 1,
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      fontSize: 14,
      height: '100%',
      color: '#091300',
      paddingTop: 15,
      lineHeight: '20px',
      paddingRight: 10,
    },
  },
  image: {
    width: 100,
    height: 55,
    marginRight: 16,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  addButton: {
    height: 56,
    borderRadius: 0,
  },
  addButtonLabel: {
    '& > div': {
      display: 'flex',
      width: '100%',
      '& > div:first-child': {
        flex: 1,
        marginLeft: 24,
        fontSize: 16,
        letterSpacing: 0.5,
      },
    },
  },
}));

function ImageButton({ description, thumbnail, onOpen, onDelete }) {
  const classes = useStyles();
  return (
    <div className={classes.imageRow}>
      <ButtonBase className={classes.imageButton} onClick={onOpen}>
        <div className={classes.image} style={{ backgroundImage: `url(${thumbnail})` }} />
        <div>{description}</div>
      </ButtonBase>
      <ButtonBase onClick={onDelete}>
        <CloseRoundedIcon />
      </ButtonBase>
    </div>
  );
}

ImageButton.propTypes = {
  description: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function InspectionImages({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch('/inspection/:jobId/:phaseId');

  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);
  const job = useSelector(state => state.jobs.job);
  const jobImages = useSelector(state => state.jobs.jobImages);
  const user = useSelector(state => state.auth.user);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [resizedNewImage, setResizedNewImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null);

  const inputRef = useRef(null);
  const jobId = parseInt(match.params.jobId, 10);

  const openFileSelect = () => inputRef.current.click();

  const handleOpenImage = (image) => () => setSelectedImage(image);

  const handleDeleteImage = () => {
    if (!imageToBeDeleted) {
      return;
    }
    dispatch(deleteImage({
      chainId: selectedChainId,
      jobId,
      imageId: imageToBeDeleted.id,
    }))
      .then(unwrapResult)
      .then(() => {
        setImageToBeDeleted(null);
        dispatch(setNotification({ message: t('inspectionView.imageDeleted') }));
      })
      .catch((error) => {
        console.warn('Failed to delete image', error);
        dispatch(setNotification({ message: t('inspectionView.imageDeletionFailed'), type: 'error' }));
      });
  };

  const handleImageChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files || event.target.files.length === 0) {
      console.warn('Image data is missing!');
      return;
    }
    const file = event.target.files[0];
    if (!file.type.match(/image.*/)) {
      console.warn('File is not an image!');
      return;
    }
    resizeImage(file, MAX_IMAGE_WIDTH_HEIGHT, (blob) => {
      setResizedNewImage(blob);
      setNewImage(file);
    });
    resizeImage(file, MAX_THUMBNAIL_WIDTH_HEIGHT, (blob) => {
      setThumbnailImage(blob);
    });
  };

  const deleteNewImage = () => {
    inputRef.current.value = null;
    setNewImage(null);
    setResizedNewImage(null);
    setThumbnailImage(null);
  };

  const saveImage = (description) => {
    if (!newImage || !resizedNewImage || !thumbnailImage) {
      console.warn('Image data is missing!');
      return;
    }

    // Upload images.
    dispatch(uploadImage({
      chainId: selectedChainId,
      siteId: selectedSiteId,
      jobId,
      userId: user.id,
      image: newImage,
      description: description || '',
      resizedImage: resizedNewImage,
      thumbnailImage,
    }))
      .then(unwrapResult)
      .then(() => {
        deleteNewImage();
        dispatch(fetchJobImages({ chainId: selectedChainId, jobId }));
      })
      .catch((error) => {
        console.warn('Failed to save image', error);
        dispatch(setNotification({ message: t('inspectionView.imageSaveFailed'), type: 'error' }));
      });
  }

  if (!job) {
    return null;
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
        onKeyDown={onClose}
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
          <div className={classes.title}>{t('inspectionView.images')}</div>
        </div>
        <div className={classes.list}>
          {jobImages.map((image) => (
            <ImageButton
              key={image.id}
              description={image.description || ''}
              thumbnail={image.thumbnailLocation}
              onOpen={handleOpenImage(image)}
              onDelete={() => setImageToBeDeleted(image)}
            />
          ))}
        </div>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          className={classes.addButton}
          classes={{ label: classes.addButtonLabel }}
          onClick={openFileSelect}
        >
          <div>
            <div>{t('inspectionView.addNewImage')}</div>
            <AddRoundedIcon />
          </div>
        </Button>
        <input
          ref={inputRef}
          onChange={handleImageChange}
          hidden
          type='file'
          accept='image/*'
          capture='environment'
        />
      </div>
      <ImageDetails
        open={Boolean(selectedImage)}
        onClose={() => setSelectedImage(null)}
        image={selectedImage}
      />
      {resizedNewImage && (
        <NewImageDetails
          open={Boolean(resizedNewImage)}
          onClose={deleteNewImage}
          onSave={saveImage}
          image={resizedNewImage}
        />
      )}
      <ConfirmationDialog
        open={Boolean(imageToBeDeleted)}
        onClose={() => setImageToBeDeleted(null)}
        onAccept={handleDeleteImage}
        acceptText={t('delete')}
        text={t('inspectionView.deleteImageConfirmation')}
      />
    </Drawer>
  );
}

InspectionImages.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InspectionImages;