export default `.main-container {
  margin: 0px 20px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 4px;
  min-height: 620px;
}

.title {
  font-size: 24px;
  color: rgba(0,0,0,0.87);
  margin: 20px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.11);
}

.tab {
  padding: 15px 25px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  text-align: center;
  min-width: 160px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab:hover {
  color: var(--primaryColor);
}

.tab[active] {
  color: var(--primaryColor);
  border-color: var(---primaryColor);
}

chain-info-section {
  width: 100%;
}
`;