import React from 'react';

// Catches errors and displays error message to prevent crashing UI.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    // Display error.
    if (Boolean(this.state.error)) {
      return (
        <div style={{ padding: 20 }}>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error?.toString() || '-'}
            <br />
            {this.state.errorInfo?.componentStack || '-'}
          </details>
        </div>
      );
    }

    // Return normal UI.
    return this.props.children;
  }
}

export default ErrorBoundary;
