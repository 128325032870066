const sendRequest = (chainId, procName, data, accessToken) => {
  const body = {
    procName,
    data,
  };
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + accessToken
    },
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Request failed:', response.statusText);
    }
    return response.json();
  });
}

export const getCompaniesInChain = (chainId, accessToken) => {
  return sendRequest(chainId, 'GetCompaniesInChain', { chainId }, accessToken);
}

export const createCompany = (chainId, values, accessToken) => {
  return sendRequest(chainId, 'CreateCompany', values, accessToken);
}

export const getSiteGroups = (chainId, accessToken) => {
  return sendRequest(chainId, 'GetSiteGroupsInChain', { chainId }, accessToken);
}
