export function refreshInputField(shadowRoot, inputDef, value, onChange, disabled) {
  const input = shadowRoot.getElementById(inputDef.id);
  input.value = value || '';
  if (inputDef.maxLength) {
    input.setAttribute('maxlength', inputDef.maxLength);
  }
  if (disabled) {
    input.setAttribute('disabled', '');
  } else {
    input.removeAttribute('disabled');
  }
  input.oninput = onChange;
}
