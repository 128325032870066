import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

function SignOutContainer() {
  const { instance } = useMsal();
  const [logoutInProgress, setlogoutInProgress] = useState(false);
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    if (logoutInProgress) {
      console.warn('#Logout alread in progress#');
      return;
    }

    // Invoke logout.
    if (instance && queryParams && !logoutInProgress) {
      setlogoutInProgress(true);
      console.log('#LOGIN OUT#', queryParams);
      instance.logoutRedirect({
        postLogoutRedirectUri: process.env.REACT_APP_KAPA_BASE_URL ? `${process.env.REACT_APP_KAPA_BASE_URL}/logout` : '/',
      }).then(() => console.log('#LOGOUT SUCCESS!#'));
    }
  }, [instance, queryParams, logoutInProgress]);

  return (
    <div>Signing you out...</div>
  )
}

export default SignOutContainer;