import dayjs from 'dayjs';
import * as jobConstants from './jobConstants';
import * as deviceConstants from './deviceConstants';

/**
 * Returns measurement phases which have at least one required measurement type.
 * @param {Object} inspectionLine - Current inspection line.
 * @param {Object[]} requiredMeasurements - Required measurements of job.
 * @returns {Object[]} - Phases with required measurements.
 */
export function getPhasesWithRequiredMeasurements(inspectionLine, requiredMeasurements) {
  if (!inspectionLine) {
    return [];
  }
  const allPhases = inspectionLine.measurementTypeAndDeviceItems;
  return allPhases.filter((phase) => {
    const measurementTypeIdsInPhase = [phase.measurementTypeId, ...phase.subMeasurementItems.map(item => item.measurementTypeId)];
    const phaseHasRequiredMeasurements = measurementTypeIdsInPhase
      .some(id => requiredMeasurements.some(m => m.measurementType.measurementTypeId === id));
    return phaseHasRequiredMeasurements;
  });
}

/**
 * Returns required measurements which belong to the given phase.
 * @param {Object} inspectionLine - Current inspection line.
 * @param {Object[]} requiredMeasurements - Required measurements of job.
 * @param {Object[]} devices - Devices in site.
 * @param {string} phaseName - Name of current measurement phase.
 */
export function getRequiredMeasurementsInPhase(inspectionLine, requiredMeasurements, phaseName, devices, vehicleInformation) {
  if (!inspectionLine || !phaseName || !devices) {
    return [];
  }

  const currentPhase = inspectionLine.measurementTypeAndDeviceItems.find(item => item.name.toLowerCase() === phaseName.toLowerCase());
  if (!currentPhase) {
    return [];
  }

  // Find required measurements in current phase.
  const measurementsInPhase = [{ ...currentPhase }, ...(currentPhase.subMeasurementItems || [])];
  const requiredMeasurementsInPhase = measurementsInPhase
    .map((phaseMeasurement) => {
      const requiredMeasurement = requiredMeasurements.find(required => required.measurementType.measurementTypeId === phaseMeasurement.measurementTypeId);
      if (!requiredMeasurement) {
        return null;
      }
      return { ...requiredMeasurement, deviceId: phaseMeasurement.deviceId };
    })
    .filter(measurement => !!measurement);

  // Initialize filtered measurements.
  let filteredMeasurementsInPhase = [...requiredMeasurementsInPhase];

  // Filter emission measurements if there are multiple.
  const emissionMeasurements = requiredMeasurementsInPhase.filter(measurement => compareCaseInsensitive(measurement.measurementType.name, jobConstants.EMISSION));
  if (emissionMeasurements.length > 1) {
    // Determine if we need diesel or gasoline measurements.
    const isDiesel = isDieselVehicle(vehicleInformation);

    // Initialize index with 'not found' value.
    let selectedEmissionIndex = -1;

    // First try to find measurement with valid default device.
    selectedEmissionIndex = filteredMeasurementsInPhase.findIndex((measurement) => {
      if (!measurement.deviceId) {
        return false;
      }
      const measurementDevice = devices.find(device => device.id === measurement.deviceId);
      if (!measurementDevice || !measurementDevice.data) {
        return false;
      }
      const deviceData = JSON.parse(measurementDevice.data);
      return deviceData.fuelTypes && deviceData.fuelTypes.includes(isDiesel ? 'diesel' : 'gasoline');
    });

    // If no correct device found, use measurement with no device selected.
    if (selectedEmissionIndex === -1) {
      selectedEmissionIndex = filteredMeasurementsInPhase.findIndex((measurement) => {
        return compareCaseInsensitive(measurement.measurementType.name, jobConstants.EMISSION) && !measurement.deviceId;
      });
    }

    // If there are no other emission measurements available, use one with any default device.
    if (selectedEmissionIndex === -1) {
      selectedEmissionIndex = filteredMeasurementsInPhase.findIndex((measurement) => {
        return compareCaseInsensitive(measurement.measurementType.name, jobConstants.EMISSION);
      });
    }

    // Remove other emission measurements.
    if (selectedEmissionIndex !== -1) {
      filteredMeasurementsInPhase = filteredMeasurementsInPhase.filter((measurement, index) => {
        const isSelectedMeasurement = index === selectedEmissionIndex;
        const isEmissionMeasurement = compareCaseInsensitive(measurement.measurementType.name, jobConstants.EMISSION);
        return isSelectedMeasurement || !isEmissionMeasurement;
      });
    }
  }

  return filteredMeasurementsInPhase;
}

/**
 * Filter measurements by name.
 * @param {Object[]} measurements - Measurements.
 * @param {string} name - Measurement name.
 * @returns {Object[]} - Filtered measurements.
 */
export function filterMeasurementsByName(measurements, name) {
  return measurements.filter((m) => m.measurement === name);
}

/**
 * Determines model ID value from vehicle commissiong day.
 * @param {string} commissioningDay - Datetime of vehicle commissioning day.
 */
export function getModelId(commissioningDay) {
  const commissioningDate = dayjs(commissioningDay);
  const modelOptions = [
    { id: deviceConstants.MODEL_31121977, min: null, max: '1977-12-31' },
    { id: deviceConstants.MODEL_01011978_30091986, min: '1978-01-01', max: '1986-09-30' },
    { id: deviceConstants.MODEL_01101986_31121989, min: '1986-10-01', max: '1989-12-31' },
    { id: deviceConstants.MODEL_01011990_31122000, min: '1990-01-01', max: '2000-12-31' },
    { id: deviceConstants.MODEL_01012001_10Y, min: '2001-01-01', max: dayjs().subtract(10, 'year') },
  ];

  const model = modelOptions.find(option => {
    if (!option.min) {
      return commissioningDate.isSameOrBefore(option.max);
    }
    return commissioningDate.isBetween(option.min, option.max, null, '[]');
  });

  return model ? model.id : '';
}

/**
 * Returns default value in deviceData for measurementName and modelId.
 * @param {Object} deviceData - Emission device data.
 * @param {string} modelId - Vehicle model ID.
 * @param {string} measurementName - Name of the measurement.
 */
export function getEmissionDeviceDefaultValue(deviceData, modelId, measurementName) {
  if (!deviceData) {
    return null;
  }

  // Handle diesel device data.
  if (measurementName === jobConstants.EMISSIONS_KVALUE && deviceData[deviceConstants.DIESEL]) {
    return parseFloatFromText(deviceData[deviceConstants.DIESEL][deviceConstants.K_VALUE].defaultValue);
  }

  // Handle gasoline device data.
  const idleData = deviceData[`${modelId}_idle`];
  const revData = deviceData[`${modelId}_rev`];

  const idleMapping = [
    { formField: jobConstants.EMISSIONS_CO_IDLE, deviceField: deviceConstants.CARBON_MONOXIDE },
    { formField: jobConstants.EMISSIONS_HC_IDLE, deviceField: deviceConstants.HYDRO_CARBON },
    { formField: jobConstants.EMISSIONS_CO2_IDLE, deviceField: deviceConstants.CARBON_DIOXIDE },
    { formField: jobConstants.EMISSIONS_O2_IDLE, deviceField: deviceConstants.OXYGEN },
  ];
  const idleResult = idleMapping.find(idleField => idleField.formField === measurementName);
  if (idleResult && idleData && idleData[idleResult.deviceField]) {
    return parseFloatFromText(idleData[idleResult.deviceField].defaultValue);
  }
  const revMapping = [
    { formField: jobConstants.EMISSIONS_CO_REV, deviceField: deviceConstants.CARBON_MONOXIDE },
    { formField: jobConstants.EMISSIONS_HC_REV, deviceField: deviceConstants.HYDRO_CARBON },
    { formField: jobConstants.EMISSIONS_CO2_REV, deviceField: deviceConstants.CARBON_DIOXIDE },
    { formField: jobConstants.EMISSIONS_O2_REV, deviceField: deviceConstants.OXYGEN },
    { formField: jobConstants.EMISSIONS_LAMBDA_REV, deviceField: deviceConstants.LAMBDA },
  ];
  const revResult = revMapping.find(revField => revField.formField === measurementName);
  if (revResult && revData && revData[revResult.deviceField]) {
    return parseFloatFromText(revData[revResult.deviceField].defaultValue);
  }
}

/**
 * Finds default emission device ID according to inspection line and emission measurement.
 * @param {Object} emissionMeasurement - Emission measurement.
 * @param {Object} inspectionLine - Selected inspection line.
 * @param {number} measurementTypeId - Measurement type ID.
 * @param {Object[]} devices - Devices list.
 */
export function findDefaultEmissionDeviceId(vehicleInformation, inspectionLine, measurementTypeId, devices) {
  // Find out if diesel or gasoline type is needed.
  const isDiesel = isDieselVehicle(vehicleInformation);

  // List all devices in inspection line.
  const lineTypesAndDevices = inspectionLine.measurementTypeAndDeviceItems
    .map(item => [
      { measurementTypeId: item.measurementTypeId, deviceId: item.deviceId },
      ...(item.subMeasurementItems || []).map(subItem => ({ measurementTypeId: subItem.measurementTypeId, deviceId: subItem.deviceId })),
    ])
    .flat();

  // Find appropriate emission device.
  const emissionDevice = lineTypesAndDevices.find((item) => {
    if (item.measurementTypeId !== measurementTypeId) {
      return false;
    }
    const deviceInItem = devices.find(device => device.id === item.deviceId);
    if (!deviceInItem || !deviceInItem.data) {
      return false;
    }
    const deviceInItemData = JSON.parse(deviceInItem.data);
    return deviceInItemData.fuelTypes && deviceInItemData.fuelTypes.includes(isDiesel ? deviceConstants.DIESEL : deviceConstants.GASOLINE);
  });

  return (emissionDevice || {}).deviceId;
}

/**
 * Initializes emission form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @param {Object} requiredMeasurement - Required emission measurements.
 * @param {string} modelId - String ID of vehicle model according to vehicle commissioning day.
 * @param {Object} deviceData - Emission measurement device default values and limits.
 * @returns {Object} - Initialized emission form data.
 */
export function initializeEmissionData(previousMeasurementValues, vehicleInformation, modelId, deviceData) {
  const measurementValues = {};
  const isDiesel = isDieselVehicle(vehicleInformation);
  const emissionFields = isDiesel ? jobConstants.DIESEL_FIELDS : jobConstants.EMISSION_IDLE_FIELDS.concat(jobConstants.EMISSION_REV_FIELDS);

  // Set each measurement field value using previous value or device default value.
  emissionFields.forEach((emissionField) => {
    const previousValue = previousMeasurementValues ? previousMeasurementValues[emissionField] : null;
    if (isDefined(previousValue)) {
      measurementValues[emissionField] = previousValue;
    } else {
      if (emissionField === jobConstants.EMISSIONS_DESCRIPTION) {
        measurementValues[emissionField] = ''; // No default device value.
      } else if (emissionField === jobConstants.EMISSIONS_RPM_IDLE) {
        measurementValues[emissionField] = 0;// No default device value.
      } else {
        const deviceDefaultValue = getEmissionDeviceDefaultValue(deviceData, modelId, emissionField);
        measurementValues[emissionField] = deviceDefaultValue || 0;
      }
    }
  });

  return measurementValues;
}

/**
 * Initializes Consumption form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @param {Object} requiredMeasurement - Required consumption measurements.
 * @returns {Object} - Initialized consumption form data.
 */
export function initializeConsumptionData(previousMeasurementValues, requiredMeasurement, prePerformedMeasurement, job) {
  const prePerformedValues = JSON.parse(prePerformedMeasurement?.measurementValues ?? '{}');
  const measurementValues = {}
  const previous = previousMeasurementValues ?? {}
  const defaultDenied = prePerformedValues?.[jobConstants.CONSUMPTION_DENIED] ?? false
  const defaultInformed = prePerformedValues?.[jobConstants.CONSUMPTION_INFORMED] ?? true
  const defaultPrintout = prePerformedValues?.[jobConstants.CONSUMPTION_PRINTOUT] ?? false

  const convertValue = (value, decimals) => value ? convertNumberToNumberInputValue(value, decimals) : 0;

  measurementValues[jobConstants.CONSUMPTION_CHARGE_TOTAL] = convertValue(previous[jobConstants.CONSUMPTION_CHARGE_TOTAL], 1);
  measurementValues[jobConstants.CONSUMPTION_DENIED] = previous[jobConstants.CONSUMPTION_DENIED] ?? defaultDenied;
  measurementValues[jobConstants.CONSUMPTION_FUEL_CHARGING] = convertValue(previous[jobConstants.CONSUMPTION_FUEL_CHARGING], 2);
  measurementValues[jobConstants.CONSUMPTION_FUEL_ENGINE_ON] = convertValue(previous[jobConstants.CONSUMPTION_FUEL_ENGINE_ON], 2);
  measurementValues[jobConstants.CONSUMPTION_FUEL_TOTAL] = convertValue(previous[jobConstants.CONSUMPTION_FUEL_TOTAL], 2);
  measurementValues[jobConstants.CONSUMPTION_INFORMED] = previous[jobConstants.CONSUMPTION_INFORMED] ?? defaultInformed;
  measurementValues[jobConstants.CONSUMPTION_KM_CHARGING] = convertValue(previous[jobConstants.CONSUMPTION_KM_CHARGING], 1);
  measurementValues[jobConstants.CONSUMPTION_KM_ENGINE_OFF] = convertValue(previous[jobConstants.CONSUMPTION_KM_ENGINE_OFF], 1);
  measurementValues[jobConstants.CONSUMPTION_KM_ENGINE_ON] = convertValue(previous[jobConstants.CONSUMPTION_KM_ENGINE_ON], 1);
  measurementValues[jobConstants.CONSUMPTION_KM_TOTAL] = convertValue(previous[jobConstants.CONSUMPTION_KM_TOTAL], 1);
  measurementValues[jobConstants.CONSUMPTION_PRINTOUT] = previous[jobConstants.CONSUMPTION_PRINTOUT] ?? defaultPrintout;
  measurementValues[jobConstants.CONSUMPTION_READ_AT] = previous[jobConstants.CONSUMPTION_READ_AT];
  measurementValues[jobConstants.CONSUMPTION_SENT_AT] = previous[jobConstants.CONSUMPTION_SENT_AT];
  measurementValues[jobConstants.CONSUMPTION_READ_ERROR_CODE] = previous[jobConstants.CONSUMPTION_READ_ERROR_CODE];
  measurementValues[jobConstants.CONSUMPTION_TRAFICOM_ERROR_CODE] = previous[jobConstants.CONSUMPTION_TRAFICOM_ERROR_CODE];
  measurementValues[jobConstants.CONSUMPTION_VIN] = previous[jobConstants.CONSUMPTION_VIN] ?? job?.vehicleInformation?.vin ?? '';
  measurementValues[jobConstants.CONSUMPTION_REGISTRATION] = previous[jobConstants.CONSUMPTION_REGISTRATION] ?? job?.registrationNumber ?? '';

  return measurementValues;
}

/**
 * Initializes OBD form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @param {Object} requiredMeasurement - Required OBD measurements.
 * @returns {Object} - Initialized OBD form data.
 */
export function initializeObdData(previousMeasurementValues, requiredMeasurement) {
  const measurementValues = {};

  // Handle obd.test + obd.errorcodes.
  const previousObdTest = previousMeasurementValues && previousMeasurementValues[jobConstants.OBD_TEST];

  if (typeof previousObdTest === 'boolean') {
    // Save previous value if found.
    measurementValues[jobConstants.OBD_TEST] = previousObdTest;

    // Add error codes if test failed previously.
    if (previousObdTest === false) {
      const previousErrorCodes = previousMeasurementValues[jobConstants.OBD_ERROR_CODES];
      measurementValues[jobConstants.OBD_ERROR_CODES] = previousErrorCodes
        ? previousErrorCodes.map((code) => ({ value: code }))
        : [{ value: '' }];
    } else {
      measurementValues[jobConstants.OBD_ERROR_CODES] = [{ value: '' }];
    }
  } else {
    // If value is missing, set initial value to 'true'.
    measurementValues[jobConstants.OBD_TEST] = true;
    measurementValues[jobConstants.OBD_ERROR_CODES] = [{ value: '' }];
  }

  // Handle obd.indicator.
  const previousObdIndicator = previousMeasurementValues && previousMeasurementValues[jobConstants.OBD_INDICATOR];
  measurementValues[jobConstants.OBD_INDICATOR] = previousObdIndicator || false;

  return measurementValues;
}

/**
 * Initializes limiter form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @returns {Object} - Initialized limiter form data.
 */
export function initializeLimiterData(previousMeasurementValues) {
  const measurementValues = {};

  // Handle limiter.date (in YYYY-MM-DD format).
  const previousLimiterDate = previousMeasurementValues?.[jobConstants.LIMITER_DATE];
  measurementValues[jobConstants.LIMITER_DATE] = previousLimiterDate ? dayjs(previousLimiterDate) : dayjs();

  // Handle limiter.speed.
  const previousLimiterSpeed = previousMeasurementValues?.[jobConstants.LIMITER_SPEED];
  measurementValues[jobConstants.LIMITER_SPEED] = previousLimiterSpeed || 0;

  return measurementValues;
}

/**
 * Initializes brake form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @param {Object} requiredMeasurement - Required brake measurements.
 * @param {Object} vehicleInformation - Vehicle information of job.
 * @param {Object} deviceData - Brake device default values.
 * @param {Object} measurementSettings - Brake measurement settings.
 * @returns {Object} - Initialized brake form data.
 */
export function initializeBrakeData(previousMeasurementValues, requiredMeasurement, vehicleInformation, deviceData, measurementSettings) {
  const measurementValues = {};
  const axles = vehicleInformation.axles || [];

  // Check if previously saved data exists, otherwise initialize an empty usage brakes array.
  if (previousMeasurementValues && previousMeasurementValues[jobConstants.BRAKE_USAGE]) {
    measurementValues[jobConstants.BRAKE_USAGE] = axles
      .map((axle, index) => {
        const position = index + 1;
        const prevAxle = previousMeasurementValues[jobConstants.BRAKE_USAGE].find(a => a.position === position);
        let values = prevAxle;

        // Use device default values if prev data is missing.
        if (!prevAxle) {
          const deviceBrakeData = position === 1 ? deviceData?.frontBrake : deviceData?.rearBrake;
          const defaultValue = deviceBrakeData?.defaultValue ? parseFloatFromText(deviceBrakeData.defaultValue) : 0;
          values = { [jobConstants.LEFT]: defaultValue, [jobConstants.RIGHT]: defaultValue };
        }

        return { ...axle, ...values, position };
      });
  } else {
    measurementValues[jobConstants.BRAKE_USAGE] = filterMeasurementsByName(requiredMeasurement.measurements, jobConstants.BRAKE_USAGE)
      .map((m) => {
        const position = m.identifier;
        const axle = axles.find(a => a.position === position);
        let defaultValue = 0;

        // Find default values using device data.
        if (deviceData) {
          const brakeData = position === 1 ? deviceData.frontBrake : deviceData.rearBrake;
          if (brakeData && brakeData.defaultValue) {
            defaultValue = parseFloatFromText(brakeData.defaultValue);
          }
        }

        return { ...axle, [jobConstants.LEFT]: defaultValue, [jobConstants.RIGHT]: defaultValue };
      });
  }

  // Check if previously saved data exists, otherwise initialize an empty park brakes array.
  if (previousMeasurementValues && previousMeasurementValues[jobConstants.BRAKE_PARK]) {
    const parkBrakeCount = measurementSettings?.parkBrakesTested?.length || previousMeasurementValues[jobConstants.BRAKE_PARK].length;
    measurementValues[jobConstants.BRAKE_PARK] = Array.from({ length: axles.length < parkBrakeCount ? axles.length : parkBrakeCount })
      .map((_, index) => {
        const position = index + 1;
        const axle = axles.find(a => a.position === position);
        const parkBrake = previousMeasurementValues[jobConstants.BRAKE_PARK].find(a => a.position === position);
        let values = parkBrake;

        // Use device default values if prev data is missing.
        if (!parkBrake) {
          const defaultValue = deviceData?.parkBrake?.defaultValue ? parseFloatFromText(deviceData.parkBrake.defaultValue) : 0;
          values = { [jobConstants.LEFT]: defaultValue, [jobConstants.RIGHT]: defaultValue };
        }

        return { ...axle, ...values, position };
      });
  } else {
    const requiredParkBrakeMeasurements = filterMeasurementsByName(requiredMeasurement.measurements, jobConstants.BRAKE_PARK);
    const parkBrakeCount = measurementSettings?.parkBrakesTested?.length || requiredParkBrakeMeasurements.length;
    measurementValues[jobConstants.BRAKE_PARK] = Array.from({ length: axles.length < parkBrakeCount ? axles.length : parkBrakeCount })
      .map((_, index) => {
        const position = index + 1;
        const axle = axles.find(a => a.position === position);
        let defaultValue = 0;

        // Find default values using device data.
        if (deviceData && deviceData.parkBrake && deviceData.parkBrake.defaultValue) {
          defaultValue = parseFloatFromText(deviceData.parkBrake.defaultValue);
        }

        return { ...axle, [jobConstants.LEFT]: defaultValue, [jobConstants.RIGHT]: defaultValue };
      });
  }

  const defaultDynolockValue = true;
  const totalMass = (previousMeasurementValues && previousMeasurementValues[jobConstants.TOTAL_MASS]) || vehicleInformation[jobConstants.TOTAL_MASS];
  measurementValues[jobConstants.TOTAL_MASS] = totalMass;
  measurementValues[jobConstants.BRAKE_USAGE_DYNOLOCK] = isDefined(previousMeasurementValues?.[jobConstants.BRAKE_USAGE_DYNOLOCK])
    ? previousMeasurementValues[jobConstants.BRAKE_USAGE_DYNOLOCK] : defaultDynolockValue;
  measurementValues[jobConstants.BRAKE_PARK_DYNOLOCK] = isDefined(previousMeasurementValues?.[jobConstants.BRAKE_PARK_DYNOLOCK])
    ? previousMeasurementValues[jobConstants.BRAKE_PARK_DYNOLOCK] : defaultDynolockValue;

  return measurementValues;
}

/**
 * Initializes absorber form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @param {Object} vehicleInformation - Vehicle information.
 * @param {Object} deviceData - Absorber device default values.
 * @returns {Object} - Initialized absorber form data.
 */
export function initializeAbsorberData(previousMeasurementValues, vehicleInformation, deviceData) {
  const measurementValues = {};

  // Check if previously saved data exists, otherwise initialize an empty absorber array.
  if (previousMeasurementValues && previousMeasurementValues[jobConstants.ABSORBER]) {
    measurementValues[jobConstants.ABSORBER] = previousMeasurementValues[jobConstants.ABSORBER]
      .map((item, index) => ({ ...item, position: index + 1 }));
  } else {
    const absorberCount = vehicleInformation.axles?.length || 0;
    const absorbers = Array.from({ length: absorberCount }).map((_, index) => {
      if (deviceData && deviceData.shock) {
        const defaultValue = parseInt(deviceData.shock.defaultValue, 10);
        return { position: index + 1, left: defaultValue, right: defaultValue };
      }
      return { position: index + 1, left: 0, right: 0 };
    });
    measurementValues[jobConstants.ABSORBER] = absorbers;
  }

  return measurementValues;
}

/**
 * Initializes tyre groove form data.
 * @param {Object} previousMeasurementValues - Previously saved form data.
 * @param {Object} vehicleInformation - Vehicle information of job.
 * @param {Object} deviceData - Tyre groove device default values.
 * @returns {Object} - Initialized tyre groove form data.
 */
export function initializeTyreGrooveData(previousMeasurementValues, vehicleInformation, deviceData) {
  const measurementValues = {};
  const axles = vehicleInformation.axles;

  // Check if previously saved data exists, otherwise initialize an empty tyre grooves array.
  if (previousMeasurementValues?.[jobConstants.TYRE_GROOVE_DEPTH]) {
    measurementValues[jobConstants.TYRE_GROOVE_DEPTH] = axles
      .map((axle) => {
        const prevAxle = previousMeasurementValues[jobConstants.TYRE_GROOVE_DEPTH].find(a => a.position === axle.position);
        let values = prevAxle;

        // Use device default values if prev data is missing.
        if (!prevAxle) {
          const deviceTyreGrooveData = deviceData?.tyreGroove;
          const defaultValue = deviceTyreGrooveData?.defaultValue ? parseInt(deviceTyreGrooveData.defaultValue, 10) : 0;
          values = { [jobConstants.LEFT]: defaultValue, [jobConstants.RIGHT]: defaultValue };
        }

        return { ...axle, ...values };
      });
  } else {
    measurementValues[jobConstants.TYRE_GROOVE_DEPTH] = axles
      .map((axle) => {
        let defaultValue = 0;

        // Find default values using device data.
        if (deviceData) {
          const deviceTyreGrooveData = deviceData?.tyreGroove;
          if (deviceTyreGrooveData?.defaultValue) {
            defaultValue = parseInt(deviceTyreGrooveData.defaultValue, 10);
          }
        }

        return { ...axle, [jobConstants.LEFT]: defaultValue, [jobConstants.RIGHT]: defaultValue };
      });
  }

  // Set tyre season value.
  const defaultValue = isWinterTyreSeason() ? jobConstants.WINTER : jobConstants.SUMMER;
  measurementValues[jobConstants.TYRE_GROOVE_SEASON] = previousMeasurementValues?.[jobConstants.TYRE_GROOVE_SEASON] || defaultValue;

  return measurementValues;
}

/**
 * Returns gasoline/diesel emission devices.
 * @param {Object[]} devices - All devices.
 * @param {boolean} isDiesel - Diesel emissions required.
 */
export function getEmissionDevices(devices, isDiesel) {
  return devices.filter((device) => {
    if (device.type !== jobConstants.EMISSION || !device.data || device.status !== deviceConstants.IN_USE) {
      return false;
    }
    const deviceData = JSON.parse(device.data);
    return deviceData.fuelTypes && deviceData.fuelTypes.includes(isDiesel ? deviceConstants.DIESEL : deviceConstants.GASOLINE);
  });
}

/**
* Get devices for measurement type. NOTE: use getEmissionDevices for emission measurements.
* @param {Object[]} devices - All devices.
* @param {string} measurementType - Measurement type name.
* @returns {Object[]} - Device options for measurement.
*/
export function getMeasurementDevices(devices, measurementType) {
  return devices.filter(device => device.type === measurementType && device.status === deviceConstants.IN_USE);
}

/**
 * Determines if measurement is performed by using previously saved value or job status.
 * @param {Object} measurementTypeInformation - Job measurement information.
 * @param {boolean} measurementTypeInformation.isPerformed - Previously saved value.
 * @param {Object} requiredMeasurement - Required measurement information.
 * @param {number} requiredMeasurements.status - Measurement status.
 * @returns {boolean} - True if measurement is performed.
 */
export function getMeasurementIsPerformed(measurementTypeInformation, requiredMeasurement) {
  if (measurementTypeInformation && typeof measurementTypeInformation[jobConstants.IS_PERFORMED] === 'boolean') {
    return measurementTypeInformation[jobConstants.IS_PERFORMED];
  }
  return requiredMeasurement ? requiredMeasurement.status !== jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED] : false;
}

/**
 * Determines if two string values are the same (case insensitive).
 * @param {string} string1 - First value.
 * @param {string} string2 - Second value.
 * @returns {boolean}
 */
export function compareCaseInsensitive(string1, string2) {
  if (!string1 || !string2) {
    return false;
  }
  return string1.toLowerCase() === string2.toLowerCase();
}

/**
 * Returns parsed int value or the given default value.
 * @param {string} value value to parse
 * @param {number} defaultValue default value
 * @returns parsed value or default.
 */
export function parseIntOrDefault(value, defaultValue) {
  const parsedValue = parseInt(value, 10);
  return isNaN(parsedValue) ? defaultValue : parsedValue;
}

/**
 * Returns limit of the given measurement from given array of measurement limits.
 * @param {Array} measurementLimits measurement limits.
 * @param {string} name measurement name
 * @param {Number} identifier measurement identifier
 * @returns measurement limit or null.
 */
export function getMeasurementLimit(measurementLimits = [], name, identifier) {
  const measurement = measurementLimits?.find(item => item.measurement === name && (!identifier || item.identifier === identifier));
  return measurement ? measurement.limit : null;
};

/**
 * Returns the percentual difference of the given two numbers.
 * @param {Number} value1 value 1
 * @param {Number} value2 value 2
 */
export function getDifferenceInPercentage(value1, value2) {
  const largerValue = Math.max(value1, value2);
  const difference = value1 - value2;
  return Math.round(Math.abs(difference / largerValue) * 100);
}

/**
 * Returns true, if the given value is not null or undefined.
 */
export function isDefined(value) {
  return !(value === null || value === undefined);
}

/**
 * Calculates brake force of given brake values.
 * @param {Array} brakes array of brake values.
 * @returns {number} brake force in kilonewtons.
 */
export function calculateBrakeForceSumInKiloNewtons(brakes = []) {
  const brakeInputSum = brakes.reduce((prev, curr) => prev + curr.left + curr.right, 0);
  return convertNumberInputValueToNumber(brakeInputSum, jobConstants.FIELD_DECIMAL_PLACES[jobConstants.BRAKE_USAGE]);
}

/**
 * Calculates brake force of given brake values.
 * @param {Array} brakes array of brake values.
 * @returns brake force in Newton
 */
export function calculateBrakeForceSumInNewtons(brakes = []) {
  return calculateBrakeForceSumInKiloNewtons(brakes) * 1000;
}

/**
 * Calculates deceleration percentage, with the given vehicle mass and brake values.
 * @param {Number} vehicleMass vehicle mass
 * @param {Array} brakes brake values
 * @returns deceleration value or null
 */
export function calculateDecelerationPercent(vehicleMass, brakes = []) {
  if (!vehicleMass || !brakes || !Number.isInteger(vehicleMass)) {
    return null;
  }

  // Calculate deceleration.
  const brakeForceInNewtons = calculateBrakeForceSumInNewtons(brakes);
  const deceleration = (brakeForceInNewtons / 9.81) / vehicleMass;
  return Math.round(deceleration * 100);
}


/**
 * Calculates brake force percentage, with the given vehicle mass and brake values.
 * @param {Number} vehicleMass vehicle mass
 * @param {Array} brakes brake values
 * @returns deceleration value or null
 */
export function calculateBrakeForcePercent(vehicleMass, brakes = []) {
  if (!vehicleMass || !brakes || !Number.isInteger(vehicleMass)) {
    return null;
  }
  const brakeForceInNewtons = calculateBrakeForceSumInNewtons(brakes);
  return Math.floor((brakeForceInNewtons / (vehicleMass * 9.81)) * 100);
}

/**
 * Calculates vehicle's brake force limit in kilo newtons.
 * @param {Number} vehicleMass vehicle mass
 * @param {Number} limit limit value
 * @returns brake force in kN.
 */
export function calculateBrakeForceLimitInKiloNewtons(vehicleMass, limit) {
  if (!vehicleMass || !limit) {
    return null;
  }
  return (((vehicleMass * 9.81) * (limit / 100)) / 1000).toFixed(1);
}

/**
 * Determines new measurement status.
 * @param {number} status - Current measurement status
 * @param {boolean} [isAutosaving=false] - Is form autosaving
 * @param {boolean} [isSkipped=false] - True if measurement is skipped
 */
export function getUpdatedMeasurementStatus(status, isAutosaving = false, isSkipped = false) {
  // Handle skipped measurements.
  if (isSkipped) {
    return jobConstants.MEASUREMENT_STATUS[jobConstants.SKIPPED];
  }

  // Preserve not required status.
  if (status === jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED]) {
    return jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED];
  }

  // Handle autosaving.
  return isAutosaving && status !== jobConstants.MEASUREMENT_STATUS[jobConstants.DONE]
    ? jobConstants.MEASUREMENT_STATUS[jobConstants.IN_PROGRESS]
    : jobConstants.MEASUREMENT_STATUS[jobConstants.DONE];
}

/**
 * Returns measurement status according to given measurement type ID or name.
 * @param {Object[]} requiredMeasurements - Required inspection measurements.
 * @param {Object} job - Inspection job data.
 * @param {number} id - ID of measurement type.
 * @param {string} name - Name of measurement type (overrides ID).
 * @returns {number} - Measurement status.
 */
export function getMeasurementStatus(requiredMeasurements, job, id, name) {
  // Function used with 'find' method.
  const isRequestedMeasurement = (measurement) => {
    return name
      ? compareCaseInsensitive(measurement.measurementType.name, name)
      : measurement.measurementType.measurementTypeId === id;
  };

  // Find up-to-date status using job data.
  const jobMeasurement = job.measurementInformation?.find(isRequestedMeasurement);
  const updatedStatus = jobMeasurement ? jobMeasurement.status : null;
  const notPerformedReasonCode = jobMeasurement?.notPerformedReasonCode;
  if (updatedStatus) {
    return notPerformedReasonCode ? jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED] : updatedStatus;
  }

  // If measurement has not yet been saved in job.measurementInformation:
  // Find initial status using requiredMeasurements.
  const requiredMeasurement = requiredMeasurements.find(isRequestedMeasurement);
  return requiredMeasurement ? requiredMeasurement.status : jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED];
}

/**
 * Returns odometer reading from job's ATJ information.
 * @param {Object} job - Inspection job data.
 * @returns {number} - Odometer reading or null.
 */
export function getAtjOdometerReading(job) {
  return job.atjInformation?.sanoma?.ajoneuvo?.teknisettiedot?.matkamittari?.matkamittarilukema;
}

/**
 * Returns odometer reading difference to job's ATJ information.
 * @param {Object} job - Inspection job data.
 * @param {number} newReading - New odometer reading.
 * @returns {number} - Odometer reading or null.
 */
export function getAtjOdometerReadingDifference(job, newReading) {
  const atjReading = getAtjOdometerReading(job) ?? 0;

  return atjReading && newReading ? `(${newReading >= atjReading ? '+' : ''}${newReading - atjReading})` : null;
}

/**
 * Returns odometer type reading from job's ATJ information.
 * @param {Object} job - Inspection job data.
 * @returns {number} - Odometer type or null.
 */
export function getAtjOdometerType(job) {
  let matkamittari = job.atjInformation?.sanoma?.ajoneuvo?.teknisettiedot?.matkamittari;

  if (!isDefined(matkamittari)) {
    return null;
  }

  if (matkamittari.eiMatkamittaria === true) {
    return jobConstants.NO_ODOMETER;
  }
  if (matkamittari.mittarilukemaMaileina === true) {
    if (matkamittari.viisinumeroinenMittari === true) {
      return jobConstants.FIVE_DIGIT_METER_IN_MILES;
    }
    return jobConstants.SIX_DIGIT_METER_IN_MILES;
  }
  if (matkamittari.viisinumeroinenMittari === true) {
    return jobConstants.FIVE_DIGIT_METER_IN_KILOMETERS;
  }

  return jobConstants.SIX_DIGIT_METER_IN_KILOMETERS;
}

/**
 * Returns commissioning day from job's ATJ information.
 * @param {object} atjInformation - Job ATJ information.
 * @returns {number} - Commissioning day or null.
 */
export function getAtjCommissioningDay(atjInformation) {
  const atjValue = atjInformation?.sanoma?.ajoneuvo?.teknisettiedot?.kayttoonottopaiva;
  if (!isDefined(atjValue)) {
    return null;
  }
  // Date is formatted as 20110000.
  return `${atjValue.slice(6, 8)}.${atjValue.slice(4, 6)}.${atjValue.slice(0, 4)}`;
}

/**
 * Formats given date 01.01.2011 -> 2011-01-01.
 */
export function formatDateLocaleToIso(date) {
  if (!date) {
    return null;
  }
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}`;
}

/**
 * Formats given date 2011-01-01 -> 01.01.2011.
 */
export function formatDateIsoToLocale(date) {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split('T')[0].split('-');
  return `${day}.${month}.${year}`;
}

/**
 * Validates the given commissioning day.
 * @param {object} value - date as string.
 * @returns {boolean} - True or false.
 */
export function validateCommissioningDay(value) {
  // Allow empty value or null.
  if (value === '' || value === null) {
    return true;
  }
  if (/^\d{2}.\d{2}.\d{4}$/.test(value) === false) {
    return false;
  }

  // Validate date, month and year.
  const [day, month, year] = value.split('.');
  return (Number(day) >= 0 && Number(day) < 32) && (Number(month) >= 0 && Number(month) < 13) && (Number(year) >= 0 && Number(year) < 9999);
}

/**
 * Returns true if this job contains only condition inspection job.
 */
export function isConditionInspectionJob(job) {
  return job.productInformation?.length === 1 && job.productInformation[0].baseJobTypeId === jobConstants.CONDITION_INSPECTION_ID;
}

/**
 * Returns inspection phases with their statuses.
 * If no inspection line is not selected or no required measurements, returns fixed phases.
 * @param {Object} job - Job data.
 * @param {Object[]} requiredMeasurements - Required inspection measurements.
 * @param {Object} inspectionLine - Inspection line data.
 * @returns {Object[]} - Measurement phases with names and statuses.
 */
export function getPhasesWithStatuses(job, requiredMeasurements, inspectionLine) {
  if (!job || !requiredMeasurements) {
    return [];
  }

  const TODO = jobConstants.MEASUREMENT_STATUS[jobConstants.TODO];
  const IN_PROGRESS = jobConstants.MEASUREMENT_STATUS[jobConstants.IN_PROGRESS];
  const DONE = jobConstants.MEASUREMENT_STATUS[jobConstants.DONE];
  const NOT_REQUIRED = jobConstants.MEASUREMENT_STATUS[jobConstants.NOT_REQUIRED];
  const SKIPPED = jobConstants.MEASUREMENT_STATUS[jobConstants.SKIPPED];

  // Find measurement phases which have required measurements.
  const phasesWithRequiredMeasurements = inspectionLine ? getPhasesWithRequiredMeasurements(inspectionLine, requiredMeasurements) : [];

  // Find statuses for fixed starting phases.
  const fixedPhasesStart = [jobConstants.IDENTIFICATION, jobConstants.INFORMATION].map((name) => ({
    name,
    status: getMeasurementStatus(requiredMeasurements, job, null, name),
    fixed: true,
  }));

  // List custom measurement phases with their names and statuses.
  // Determine phase status using all measurement statuses in phase.
  const measurementPhases = phasesWithRequiredMeasurements.map((phase) => {
    const measurementTypeIds = [phase.measurementTypeId, ...(phase.subMeasurementItems || []).map(item => item.measurementTypeId)];
    const statusValues = measurementTypeIds.map((id) => getMeasurementStatus(requiredMeasurements, job, id));

    let status = NOT_REQUIRED;
    if (statusValues.includes(TODO)) {
      status = TODO;
    }
    if (statusValues.includes(IN_PROGRESS)) {
      status = IN_PROGRESS;
    }
    if (statusValues.every(val => val === DONE || val === NOT_REQUIRED || val === SKIPPED)) {
      status = DONE;
    }
    if (statusValues.every(val => val === NOT_REQUIRED)) {
      status = NOT_REQUIRED;
    }
    return {
      name: phase.name,
      status,
      fixed: false,
    };
  });

  // Check if this is a condition inspection job.
  const isConditionInspection = isConditionInspectionJob(job);

  // Handle fixed ending phases. Skip faults phase if this is a condition inspection job.
  let fixedPhasesEnd = isConditionInspection ? [jobConstants.RESULT] : [jobConstants.FAULTS, jobConstants.RESULT];
  fixedPhasesEnd = fixedPhasesEnd.map((name) => ({
    name,
    status: getMeasurementStatus(requiredMeasurements, job, null, name),
    fixed: true,
  }));

  return fixedPhasesStart.concat(measurementPhases, fixedPhasesEnd);
}

/**
 * Converts regular number to NumberInput value.
 * @param {number} value - Value from NumberInput.
 * @param {number} precision - Decimal precision.
 * @returns {number}
 */
export function convertNumberToNumberInputValue(value, precision) {
  if (!isDefined(precision)) {
    console.error('Precision parameter missing!');
    return 0;
  }
  return value * (10 ** precision);
}

/**
 * Converts value used in NumberInput to regular number.
 * @param {number} value - Value from NumberInput.
 * @param {number} precision - Decimal precision.
 * @returns {number}
 */
export function convertNumberInputValueToNumber(value, precision) {
  if (!isDefined(precision)) {
    console.error('Precision parameter missing!');
    return 0;
  }
  return value / (10 ** precision);
}

/**
 * Get list of ATJ warnings in job data.
 * @param {Object} atjInformation - ATJ information of job.
 * @returns {Object[]} - List of ATJ warnings.
 */
export function getAtjWarnings(atjInformation) {
  const details = atjInformation?.sanoma?.ajoneuvo?.teknisettiedot;
  if (!details) {
    return []
  }

  const inspectionRestrictions = (details.katsastusrajoitukset ?? []);
  const drivingBanRestrictions = (details.kayttokieltorajoitukset ?? [])
    .filter((item) => item && (item.kayttokieltomaarkats === true || item.kayttokieltovalvkats === true));
  const registrationRestrictions = (details.rekisterointirajoitukset ?? []);
  const previousScheduledInspection = details.edellinenmak?.katsastuspaatos === 3 ? [details.edellinenmak] : [];

  let result = [];
  result = result.concat(inspectionRestrictions);
  result = result.concat(drivingBanRestrictions);
  result = result.concat(registrationRestrictions);
  result = result.concat(previousScheduledInspection);
  return result;
}

/**
 * Returns comma-separated list of product names.
 * @param {Object[]} productInformation - Job product data.
 * @param {boolean} [preferShort=false] - Prefer short versions of product names.
 * @returns {string} - Product names.
 */
export function getProductNames(productInformation, preferShort = false) {
  if (!productInformation) {
    return '';
  }
  // Filter out products that don't have a name.
  return productInformation
    .filter(info => info.shortName || info.name)
    .map(info => (preferShort && info.shortName) || info.name)
    .join(',');
}

/**
 * Determines if vehicle uses diesel fuel.
 * @param {Object} vehicleInformation - Information on the vehicle.
 * @param {string} vehicleInformation.fuel - Vehicle fuel type.
 * @returns {boolean} - True if diesel vehicle.
 */
export function isDieselVehicle(vehicleInformation) {
  if (!vehicleInformation || !vehicleInformation.fuel) {
    return false;
  }
  return jobConstants.DIESEL_BASED_FUEL_PREFIXES.some(prefix => vehicleInformation.fuel.indexOf(prefix) === 0);
}

/**
 * Checks if inspection line has given measurement type ID.
 * @param {Object} inspectionLine - Inspection line data.
 * @param {number} measurementTypeId - Requested measurement type ID.
 * @returns {boolean} - True if measurement type ID is included in inspection line.
 */
export function lineHasMeasurementTypeId(line, measurementTypeId) {
  return line.measurementTypeAndDeviceItems
    .some((item) => item.measurementTypeId === measurementTypeId || item.subMeasurementItems.some((subItem) => subItem.measurementTypeId === measurementTypeId));
}

/**
 * Determines if inspection line has all required measurements.
 * @param {Object} inspectionLine - Inspection line data.
 * @param {Object[]} requiredMeasurements - Required measurements for job.
 * @returns {boolean} - True if inspection line is valid for job.
 */
export function inspectionLineIsValid(inspectionLine, requiredMeasurements) {
  // Find IDs for measurements that are not fixed measurements (e.g. identification).
  const requiredMeasurementIds = requiredMeasurements
    .filter(m => m.measurementType.measurementTypeId !== 0 && (m.status === jobConstants.MEASUREMENT_STATUS[jobConstants.TODO] || m.status === jobConstants.MEASUREMENT_STATUS[jobConstants.IN_PROGRESS]))
    .map(m => m.measurementType.measurementTypeId);

  // Find out if inspection line has all of the required measurements.
  return requiredMeasurementIds.every((requiredId) => lineHasMeasurementTypeId(inspectionLine, requiredId));
}

export function buildMeasurementRequest(params, measurementTypeOverride)
{
  const measurementId = [
    params.chainId,
    params.jobId,
    params.deviceId,
    measurementTypeOverride ?? params.measurementType,
    params.vin ?? 'v',
  ].join('.');

  return {
    ...params,
    measurementId,
  };
}

/**
 * Determines if job is finished.
 * @param {Object} job - Job data.
 * @returns {boolean} - True if job is finished.
 */
export function isJobDone(job) {
  return job?.status === jobConstants.JOB_STATUS[jobConstants.DONE];
}

/**
 * Determines if job is annulled.
 * @param {Object} job - Job data.
 * @returns {boolean} - True if job is annulled.
 */
export function isJobAnnulled(job) {
  return job?.status === jobConstants.JOB_STATUS[jobConstants.ANNULLED];
}

/**
 * Calculates if winter tyre season is in effect (november-march).
 * @returns {boolean} - True if is winter season.
 */
export function isWinterTyreSeason() {
  const currentMonth = dayjs().month();
  const novemberIndex = 10;
  const marchIndex = 2;
  return currentMonth >= novemberIndex || currentMonth <= marchIndex;
}

/**
 * Lowercase the first letter of the given word / sentence.
 * @param {string} text - Word or sentence.
 * @returns {string} - Word or sentence with first letter changed to lowercase.
 */
export function lowerCaseFirstLetter(text) {
  if (!text) {
    return text;
  }
  return text.charAt(0).toLowerCase() + text.slice(1);
}

/**
 * Returns true if the given string is 'true'.
 * @param {string} value
 * @returns true, if the given string is 'true', false otherwise.
 */
export function isStringBooleanTrue(value) {
  if (!value || typeof value !== 'string') {
    return false;
  }
  return value.toLowerCase() === 'true';
}

/**
 * Returns true if all the given axles are drive axles
 * @param {string} axles array of axles
 * @returns true, if all the given axles are drive axles, false otherwise.
 */
export function isFourWheelDrive(axles) {
  if (!axles || typeof axles !== 'object' || axles.length !== 2) {
    return false;
  }
  return axles.every(axle => axle.vetava === 'true');
}

/**
 * Parses the given number to string.
 * @param {number} value float value
 * @returns
 */
export function parseTextFromFloat(value) {
  return value ? value.toFixed(2).toString().replace('.', ',') : '';
}

/**
 * Parses float from the given string.
 * @param {string} value float value as string
 * @returns
 */
export function parseFloatFromText(value) {
  return value ? parseFloat(value.replace(',', '.')) : null;
}

/**
 * Returns date in D.M.YYYY format.
 * @param {string} date - Timestamp
 * @returns {string} - Formatted date.
 */
export function formatDate(date) {
  return date ? dayjs(date).format('D.M.YYYY, HH:mm') : '-';
}

/**
 * True, if the given jobType is a sosoMAK job type.
 * @param {int} jobTypeId - baseJobType id.
 * @returns {bool} - True/false.
 */
export function isSosoMakJobType(jobTypeId) {
  return jobConstants.SOSO_MAK_JOB_TYPES.includes(jobTypeId);
}

function addDaysUntilWeekday(startDay) {
  const weekday = startDay.day();
  return [0, 6].includes(weekday) ? addDaysUntilWeekday(startDay.add(1, 'day')) : startDay;
}

/**
 * Returns after inspection day, which is one month from now.
 * @param {boolean} format - If true, returns date in D.M.YYYY format.
 */
export function getAfterInspectionDate(format = false) {
  var afterInspectionDate = addDaysUntilWeekday(dayjs().add(1, 'month'));
  return format ? afterInspectionDate.format('DD.MM.YYYY') : afterInspectionDate;
}

/**
 * Returns baseJobTypeId from the given productInformation.
 */
export function getBaseJobTypeId(productInformation) {
  // Perform Select, Sort in ascending order, Take and SingleOrDefault operations
  const sortedIds = productInformation.map(p => p.baseJobTypeId)
                                      .sort((a, b) => a - b)
                                      .slice(0, 1);

  return sortedIds.pop() || null; // return null if the array is empty
}

/**
 * Returns the jobTypeId of the condition inspection job type if it's included in this job.
 */
export function getConditionInspectionJobTypeId(job) {
  // Find item with baseJobTypeId 8 (Condition inspection) from the given productInformation
  return job.productInformation?.find(pi => pi.baseJobTypeId === jobConstants.CONDITION_INSPECTION_ID)?.jobTypeId;
}

/**
 * Returns true if the given measurement type is a condition measurement item.
 */
export function isConditionMeasurementItem(measurementTypeId) {
  return 100 <= measurementTypeId && measurementTypeId <= 1000;
}

/**
 * Formats the given regNumber by adding alphabetic and numbers separator.
 * @param {string} regNumber - Registration number.
 * @returns {string} - formatted regNumber.
 */
export function formatRegistrationNumber(regNumber) {
  if (regNumber) {
    if (regNumber.includes('-')) {
      return regNumber.toUpperCase();
    } else {
      return regNumber.replace(/^([a-zA-ZÖÄÅöäå]{1,5})([0-9]+)$/, '$1-$2')
        .replace(/^([0-9]{1,5})([a-zA-ZÖÄÅöäå]+)$/, '$1-$2')
        .toUpperCase();
    }
  }
  return '';
}

/**
 * Returns true if the given measurement id should use a custom input field for its UI (e.g. is part of CUSTOM_INPUT_MEASUREMENTS).
 */
export function isCustomInputMeasurement(measurementId) {
  return jobConstants.CUSTOM_INPUT_MEASUREMENTS.includes(measurementId);
}

/**
 * Returns true if the given measurement id is a surface damage measurement.
 */
export function isSurfaceDamageMeasurement(measurementId) {
  return measurementId === jobConstants.SURFACE_DAMAGE_MEASUREMENT_ID;
}

export function isBrakeDiscsOrPadsMeasurement(measurementId) {
  return [
    jobConstants.BRAKE_DISCS,
    jobConstants.BRAKE_PADS,
  ].includes(measurementId);
}

/**
 * Returns true if the given measurement id is a tyre tread depth measurement.
 */
export function isTyreTreadDepthMeasurement(measurementId) {
  return [
    jobConstants.TIRE_TREAD_DEPTH_MEASUREMENT_ID,
    jobConstants.WINTER_TIRE_TREAD_DEPTH_MEASUREMENT_ID,
    jobConstants.SUMMER_TIRE_TREAD_DEPTH_MEASUREMENT_ID,
  ].includes(measurementId);
}

/**
 * Returns true if the given measurement id is a tyres ore wheels measurement.
 */
export function isTyresOrWheelsMeasurement(measurementId) {
  return [
    jobConstants.TIRES_MEASUREMENT_ID,
    jobConstants.WHEELS_MEASUREMENT_ID,
  ].includes(measurementId);
}

/**
 * Returns true if the given measurement id is services or inspections measurement.
 */
export function isServicesOrInspectionsMeasurement(measurementId) {
  return [
    jobConstants.SERVICES_ID,
    jobConstants.INSPECTIONS_ID,
  ].includes(measurementId);
}

/**
 * Returns measurement unit by measurement id.
 */
export function getMeasurementUnitByMeasurementId(measurementId) {
  switch (measurementId) {
    case jobConstants.COOLANT_FREEZING_POINT_ID:
    case jobConstants.BRAKE_FLUID_BOILING_POINT_ID:
      return '(°C)';
    case jobConstants.GAS_TANK_INSPECTION_DATE_ID:
      return '(dd.mm.yyyy)';
    case jobConstants.BRAKE_DISCS:
    case jobConstants.BRAKE_PADS:
      return '(mm)';
    default:
      return '';
  }
}