/**
 * Changes object property names to camel case when used with JSON.parse.
 * @param {string} key - Property name.
 * @param {any} value - Property value.
 * @returns {any} - Updated value.
 */
export function toCamelCase(key, value) {
  // Skip other values than the resulting object.
  if (!key && value && typeof value === 'object') {
    for (var objectKey in value) {
      if (/^[A-Z]/.test(objectKey) && Object.hasOwnProperty.call(value, objectKey)) {
        const camelCaseName = objectKey.charAt(0).toLowerCase() + objectKey.substring(1);
        value[camelCaseName] = value[objectKey];
        delete value[objectKey];
      }
    }
  }
  return value;
}

/**
 * Returns key for value in given map.
 * @param {Object} collection - Map object.
 * @param {any} value - Value to be searched.
 * @returns {any} - Key.
 */
export function getMapKeyForValue(collection, value) {
  const item = [...collection].find(([itemValue, itemText]) => itemText === value);
  const [key] = (item || []);
  return key;
}
