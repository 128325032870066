import css from './job-type-detail-css';
import { JobTypeStore } from './jobTypeStore.js';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';

const NAME = 'name';

window.customElements.define('job-type-detail-view', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this.store = new JobTypeStore();
    this._state = {
      isNew: false,
      data: [],
      formData: {},
      jobTypeId: null,
      jobTypeData: null,
      sortBy: NAME,
      sortDirection: 'asc' ,
      isBuilt: false,
      measurementsData: []
     };

    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
  }

  set jobTypeId(val) {
    this._state.isNew = false;
    this._state.jobTypeId = val;
  }

  set isNew(val) {
    this._state.isNew = val;
  }

  connectedCallback() {
    this.addRoot();
    this.refreshData().then( () =>
    window.requestAnimationFrame( async () => {
      await this.build();
      await this.render();
    }));
  }

  disconnectedCallback() {
    this._state.isNew = false;
    this.resetState();
  }

  resetState() {
    this.store.clearData();
    this._state.isNew = false;
    this._state.data = [];
    this._state.formData = {};
    this._state.jobTypeId = null;
    this._state.jobTypeData = null;
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    if (!this.controlState.chainId && controlState.chainId) {
      this.refreshData();
    }
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
  }

  build = async () => {
      this.addTitle();
      this.addForm();
      this.addTableTitle();
      this.addDataTable();
      this.addButtons();
      this._state.isBuilt = true;
  }

  addTitle() {
    this.titleContainer = document.createElement('div');
    this.titleContainer.setAttribute('class', 'title');
    this.titleContainer.textContent = this._state.isNew ? this.translationService.translate( 'jobTypesView.addJobType' )
                                                        : this._state.jobTypeData.name;
  }

  addTableTitle() {
    this.tableTitleContainer = document.createElement('div');
    this.tableTitleContainer.setAttribute('class', 'table-title');
    this.tableTitleContainer.textContent = this.translationService.translate( 'jobTypesView.chooseMeasurementTypes' )
  }

  addDataTable() {
    this.dataTable = document.createElement('data-table');
    this.dataTable.selectable = true;
    this.dataTable.selectedIds = this._state.jobTypeData?.measurements != null ? this._state.jobTypeData.measurements.map( m => m.measurementTypeId) : [];
    this.dataTable.dataAndLayout = {
      data: this.getTranslatedMeasurements(),
      columns: this.getTableColumnDefinitions(),
      sortBy: this._state.sortBy,
      sortDirection: this._state.sortDirection,
    };
  }

  getTranslatedMeasurements()
  {
    const nameFieldName = this.controlState.language === 'sv-FI' ? 'nameSv' : 'nameFi';
    const getMeasurementName = (m) => {
      // If category is missing, return this.translationService.translate('measurementTypes.' + m.name)
      if( !m.category) {
        return this.translationService.translate('measurementTypes.' + m.name);
      }
      return m[nameFieldName]
    }
    return this._state.measurementsData.map( m => ({
      id: m.id,
      name: getMeasurementName(m),
    }));
  }

  addForm() {
    this.formContainer = document.createElement('div');
    this.formContainer.setAttribute('id', 'form-container');
    this.formContainer.setAttribute('class', 'form-container');
    this.addCategorySelector();
    this.addBasicJobTypeInfoFields();
  }

  addCategorySelector() {
    const categoryContainer = document.createElement('div')
    categoryContainer.setAttribute('class','category-container');
    const categoryLabel = document.createElement('div');
    categoryLabel.setAttribute('class', 'input-label');
    categoryLabel.textContent = this.translationService.translate('jobTypesView.category');
    categoryContainer.appendChild(categoryLabel);
    this.categorySelect = document.createElement('select');
    this.categorySelect.setAttribute('class', 'dropdown');
    this.categorySelect.onchange = this.handleCategoryChange;
    categoryContainer.appendChild(this.categorySelect);
    this.populateCategories();
    this.formContainer.appendChild(categoryContainer);
  }

  addButtons() {
    this.buttonContainer = document.createElement('div');
    this.buttonContainer.setAttribute('class', 'buttons-container');
    const saveButton = document.createElement('button');
    saveButton.setAttribute('id', 'save-button');
    saveButton.setAttribute('class', 'primary');
    saveButton.textContent = this.translationService.translate('save');
    saveButton.onclick = this.handleSave;
    this.buttonContainer.appendChild(saveButton);
    const cancelButton = document.createElement('button');
    cancelButton.setAttribute('id', 'cancel-button');
    cancelButton.textContent = this.translationService.translate('cancel');
    cancelButton.onclick = () => { this.goToJobTypesView(); }
    this.buttonContainer.appendChild(cancelButton);
    const deleteButton = document.createElement('button');
    deleteButton.setAttribute('class', 'delete-button');
    deleteButton.textContent = this.translationService.translate('jobTypesView.deleteJobType');
    deleteButton.onclick = () => { this.addDeleteConfirmationDialog(); }
    this.buttonContainer.appendChild(deleteButton);
  }

  addRoot = async () => {
    const root = document.createElement('div');
    root.setAttribute('id', 'job-type-detail-view');
    root.setAttribute('class', 'main-container');
    this.root = root;
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.shadowRoot.appendChild(root);
    this.confirmationDialog = document.createElement('confirmation-dialog');
  }

  getTableColumnDefinitions = () => {
    return [{id: NAME, sortable: true, 'text': this.translationService.translate('jobTypesView.measurementType')}];
  }

  getBasicInfoInputDefinitions = () => [
    {
      id: NAME,
      label: this.translationService.translate('jobTypesView.name'),
      maxLength: 200,
    }
  ];

  addBasicJobTypeInfoFields = () => {
    const basicInfoContainer = document.createElement('div');
    basicInfoContainer.setAttribute('class', 'form-column');
    this.formContainer.appendChild(basicInfoContainer);

    this.getBasicInfoInputDefinitions().forEach(inputDef => {
      const inputContainer = document.createElement('div');
      basicInfoContainer.appendChild(inputContainer);

      const inputLabel = document.createElement('div');
      inputLabel.setAttribute('class', 'input-label');
      inputLabel.textContent = inputDef.label;
      inputContainer.appendChild(inputLabel);

      // Set the actual data in inputs
      const input = document.createElement('input');
      input.setAttribute('id', inputDef.id);
      input.setAttribute('maxlength', inputDef.maxLength);
      if( !this._state.isNew) {
        input.setAttribute('value', this._state.jobTypeData[inputDef.id]);
      }

      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
      inputContainer.appendChild(input);

    });
  }

  addDeleteConfirmationDialog = () => {
    this.addConfirmationDialog(
      `${this.translationService.translate('jobTypesView.deleteConfirmation')} '${this._state.jobTypeData.name}'?`,
      this.deleteGroup,
      this.translationService.translate('jobTypesView.deleteJobType'),
      this.translationService.translate('cancel')
    );
  }

  addConfirmationDialog = (content, onAccept, acceptButtonText, cancelButtonText) => {
    window.requestAnimationFrame(() => {
      this.root.appendChild(this.confirmationDialog);
      this.confirmationDialog.values = {
        content,
        onAccept,
        acceptButtonText,
        onCancel: this.removeConfirmationDialog,
        cancelButtonText: cancelButtonText
      };
    });
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.confirmationDialog));
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
  }

  goToJobTypesView() {
    const navEvent = new CustomEvent('jobTypeNavigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: 'jobTypes',
        data: {},
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  render = async () => {
    this.root.appendChild(this.titleContainer);
    this.root.appendChild(this.formContainer);
    this.root.appendChild(this.tableTitleContainer);
    this.root.appendChild(this.dataTable);
    this.root.appendChild(this.buttonContainer);
  }

  handleSave = async () => {
    if(this._state.isNew) {
      await this.createJobType();
    }
    else {
      await this.updateJobType();
    }
  }

  handleCategoryChange = (event) => {
    var category = event.target.value;
    this._state.formData.category = category;
  }

  updateJobType = async () => {
    try {
      this._fetchingData = true;
      await this.store.updateJobType(this.controlState.chainId, {
        jobTypeId: this._state.jobTypeId,
        baseJobTypeId: this._state.formData['category'],
        name: this._state.formData['name'],
        measurements: this.dataTable.selectedIds.map( (i) => { return { measurementTypeId: i} })
      });
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('jobTypesView.jobTypeUpdated'));
      this.goToJobTypesView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('jobTypesView.saveFailed'), 'error');
      this.goToJobTypesView();
      this._fetchingData = false;
    }
  }

  deleteGroup = async () => {
    try {
      this._fetchingData = true;
      await this.store.deleteJobType(this.controlState.chainId, this._state.jobTypeId);
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('jobTypesView.jobTypeDeleted'));
      this.goToJobTypesView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('jobTypesView.saveFailed'), 'error');
      this.goToJobTypesView();
      this._fetchingData = false;
    }
  }

  createJobType = async () => {
    try {
      this._fetchingData = true;
      await this.store.createJobType(this.controlState.chainId, {
        chainId: this.controlState.chainId,
        baseJobTypeId: this._state.formData['category'],
        name: this._state.formData['name'],
        measurements: this.dataTable.selectedIds.map( (i) => { return { measurementTypeId: i} })
      });
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('jobTypesView.jobTypeCreated'));
      this.goToJobTypesView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('jobTypesView.saveFailed'), 'error');
      this.goToJobTypesView();
      this._fetchingData = false;
    }
  }

  refreshData = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    if (!this._state.isNew) {
      await this.refreshJobType();
    }

    await this.refreshMeasurementTypes();
    await this.refreshCategories();
  }

  refreshJobType = async () => {
    try {
      const jobType = await this.store.getJobType(this.controlState.chainId, this._state.jobTypeId, this._state.sortBy, this._state.sortDirection);
      this._state.jobTypeData = jobType;
      this._state.formData = jobType;
      this._state.formData.category = jobType.baseJobType[0].id;
      if (this._state.isBuilt) {
        this.dataTable.selectedIds = this._state.jobTypeData.measurements != null ? this._state.jobTypeData.measurements.map( m => m.id) : [];
        this.dataTable.dataAndLayout = {
          data: this.getTranslatedMeasurements(),
          columns: this.getTableColumnDefinitions(),
          sortBy: this._state.sortBy,
          sortDirection: this._state.sortDirection,
        };
      }
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('error'), 'error');
      this.goToJobTypesView();
      this._fetchingData = false;
    }
  }

  refreshCategories = async () => {
    const data = await this.store.getCategories(this.controlState.chainId, this.translationService);
    this._state.categoryData = data;
  }

  refreshMeasurementTypes = async () => {
    const measurements = await this.store.getMeasurementTypes(this.controlState.chainId, this.translationService);
    // Select only measurements that have id less than 100.
    this._state.measurementsData = measurements.filter( m => m.id < 100);
  }

  populateCategories() {
    // Remove existing children.
    while (this.categorySelect.firstChild) {
      this.categorySelect.removeChild(this.categorySelect.firstChild);
    }

    this._state.categoryData.forEach( (d) => {
      const category = document.createElement('option');
      category.textContent = d.name;
      category.setAttribute( 'id', 'category'+d.id);
      category.setAttribute( 'value', d.id );
      if( !this._state.isNew && this._state.formData.category === d.id)
      {
        category.setAttribute( 'selected','');
      }
      this.categorySelect.appendChild(category);
    });

    if( this._state.isNew ){
      //Set the selected category in memory as the first one in the list
      this._state.formData.category = this.categorySelect.firstChild.value;
    }
  }
});