export default 
`.main-container {
  margin: 0px 20px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 4px;
}

.title {
  font-size: 24px;
  color: rgba(0,0,0,0.87);
  margin: 20px;
  display: flex;
}

.tabs {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.11);
}

.tab {
  padding: 15px 25px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  text-align: center;
  min-width: 160px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  letter-spacing: 1px;
}

.tab:hover {
  color: var(--primaryColor);
}

.tab[active] {
  color: var(--primaryColor);
  border-color: var(---primaryColor);
}

.tab[disabled] {
  color: rgba(0,0,0,0.3);
}

.tab[disabled]:hover {
  color: rgba(0,0,0,0.3);
  cursor: default;
}

.to-list-view {
  color: #6200EE;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 27px;
  display: flex;
  align-items: center;
}

.to-list-view > img {
  margin-right: 8px;
}

.to-list-view[hidden] {
  display: none;
}

.buttons-container {
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.05);
  display: flex;
}

.buttons-container > div:first-child {
  flex: 1;
}

.buttons-container > div:first-child > button:not(:last-child) {
  margin-right: 20px;
}

button {
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--buttonColor);
  background-color: var(--buttonBackground);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 140px;
  cursor: pointer;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button:focus {
  outline: none;
}

button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button[disabled].primary:hover {
  background-color: var(--primaryButtonBackground);
}

.form-container {
  display: flex;
  padding: 20px;
  padding-bottom: 60px;
}`;