export default `
<div id="site-group-info-section">
  <div id="form-container" class="form-container">
    <div class="form-column">
      <div>
        <div id="name-label" class="input-label"></div>
        <input id="name" />
      </div>

      <div>
        <div id="description-label" class="input-label"></div>
        <input id="description" />
      </div>
    </div>
  </div>
</div>
`;