export function datesAreOnSameDay(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

export function getTimeDifferenceInMinutes(time1, time2) {
  return (time1 - time2) / 60000;
}

// Returns time in HH:mm format.
export function getTime(date) {
  if (!date) {
    return '';
  }
  return date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' });
}

// Returns date and time in D.M.YYYY klo HH:MM format.
export function getDateAndTime(dateTime, translate) {
  if (!dateTime) {
    return '';
  }
  const date = dateTime.getDate();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();
  const time = getTime(dateTime);
  return `${date}.${month}.${year} ${translate('clockShort')} ${time}`;
}

// Returns date in HH.MM.YYYY format.
export function getDate(date) {
  if (!date || !((date instanceof Date) || (typeof(date) === 'string'))) {
    return '';
  }
  if(typeof(date) === 'string')
  {
    date = new Date(date);
  }
  return date.toLocaleDateString(['fi-FI'], { day: '2-digit', month: '2-digit', year: 'numeric' });
}