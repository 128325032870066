import { BlobServiceClient } from '@azure/storage-blob';

function getHeaders(accessToken, contentType = 'application/json') {
  const headers = {};
  if(contentType) {
    headers['Content-Type'] = contentType;
  }
  if(accessToken) {
    headers[process.env.REACT_APP_AUTH_HEADER_NAME] = 'Bearer ' + accessToken;
  }
  return headers;
}

export function sendRequest(chainId, data = {}, method = 'POST', accessToken) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method,
    mode: 'cors',
    headers: getHeaders(accessToken),
    body: JSON.stringify(data),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export async function sendChainsRequest(path, data, method = 'GET', accessToken) {
  const options = {
    method,
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chains${path || ''}?code=${process.env.REACT_APP_API_CODE}`, options);
  if (!response.ok) {
    return response.json()
      .then((json) => {
        throw new Error(JSON.stringify(json));
      })
      .catch((error) => {
        throw new Error(error.message || response.statusText);
      });
  }
  return response.json();
}

export async function sendProfileRequest(accessToken) {
  const requestData = {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(accessToken),
  };

  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/profile?code=${process.env.REACT_APP_API_CODE}`, requestData);
  const json = await response.json();
  return {
    id: json.id,
    name: json.name,
    userType: json.userType,
    preferredLanguage: json.preferredLanguage,
    permissions: JSON.parse(json.permissions),
    siteSettings: json.siteSettings,
    accessToken: '',
    sites: json.sites,
    lastSiteId: json.lastSiteId,
    atjUpdateDisabled: json.atjUpdateDisabled,
  };
}

export async function sendLogoutRequest(userId, accessToken) {
  const requestData = {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/logout/${userId}?code=${process.env.REACT_APP_API_CODE}`, requestData);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
}

export async function sendUsersRequest(data, accessToken, operationName = 'List', method = 'GET') {
  const requestInfo = {
    method,
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  if (method === 'POST') {
    requestInfo.body = JSON.stringify(data);
  }
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/User/${operationName}?code=${process.env.REACT_APP_API_CODE}`, requestInfo);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
}

export async function getUsers(chainId, accessToken) {
  const requestInfo = {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/userList?code=${process.env.REACT_APP_API_CODE}`, requestInfo);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

/**
 * Send job API request.
 * @param {number} chainId - Chain ID.
 * @param {number} jobId - Job ID.
 * @param {string} method - HTTP verb, default = 'GET'.
 * @param {string} path - Added after default API URL.
 * @param {object} data - Request body data.
 * @param {string} extraParams - Extra query parameters.
 */
export function sendJobRequest(chainId, jobId, method = 'GET', path = '', data, extraParams = '', accessToken) {
  const options = {
    method,
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  if (data) {
    options.body = JSON.stringify(data);
  }
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/job/${jobId}${path}?code=${process.env.REACT_APP_API_CODE}${extraParams}`, options)
    .then((response) => {
      if (!response.ok) {
        return response.json()
          .then((json) => {
            throw new Error(json.message);
          })
          .catch((error) => {
            throw new Error(error.message || response.statusText);
          });
      }
      return response.json()
        .then(data => data)
        .catch(() => {
          // Empty response - skip error.
          return;
        });
    });
}

export function getDeviceDocuments(chainId, deviceId, accessToken) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/device/${deviceId}/documents?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'GET',
    mode: 'cors',
    headers: getHeaders(accessToken),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export function sendChainRequest(chainId, method = 'GET', path = '', query = '', data, accessToken) {
  const options = {
    method,
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  if (data) {
    options.body = JSON.stringify(data);
  }
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}${path}?code=${process.env.REACT_APP_API_CODE}${query}`, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      if (response.headers.get('content-type').startsWith('text/plain')) {
        return response.text().then(data => JSON.parse(data))
      }
      return response.json();
    });
}

export function sendSiteRequest(chainId, siteId, method = 'GET', path = '', query = '', data, accessToken) {
  const options = {
    method,
    mode: 'cors',
    headers: getHeaders(accessToken),
  };
  if (data) {
    options.body = JSON.stringify(data);
  }
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/site/${siteId}${path}?code=${process.env.REACT_APP_API_CODE}${query}`, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json()
        .then(data => data)
        .catch(() => {
          // Empty response - skip error.
          return;
        });
    });
}

/**
 * Uploads given blob to Azure.
 * @param {string} account - Storage account name.
 * @param {string} container - Storage container name.
 * @param {string} sasToken - SAS token.
 * @param {string} blobName - Blob name.
 * @param {object} blob - Blob to be uploaded.
 */
export async function uploadBlobToAzure(account, container, sasToken, blobName, blob) {
  const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sasToken}`);
  const containerClient = blobServiceClient.getContainerClient(container);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  const options = { blobHTTPHeaders: { blobContentType: blob.type } };
  return await blockBlobClient.uploadData(blob, options);
}
