const MAX_IMAGE_WIDTH_HEIGHT = 1000;
const MAX_THUMBNAIL_WIDTH_HEIGHT = 100;

function resizeImage(file, maxHeightWidth, callback) {
  const reader = new FileReader();
  reader.onload = function (readerEvent) {
    const img = document.createElement('img');

    // Resize image before upload.
    img.onload = function () {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > maxHeightWidth) {
          height *= maxHeightWidth / width;
          width = maxHeightWidth;
        }
      } else {
        if (height > maxHeightWidth) {
          width *= maxHeightWidth / height;
          height = maxHeightWidth;
        }
      }
      canvas.width = width;
      canvas.height = height;
      var context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        callback(blob);

        // Clean up the created elements
        canvas.remove();
        context = null;
      }, file.type);

      // Clean up the image element
      img.remove();
    }

    img.src = readerEvent.target.result;
  }
  reader.readAsDataURL(file);

  // Optionally, clean up the FileReader instance if you won't use it anymore
  reader.onloadend = () => {
    reader.abort();
  }
}

export {
  MAX_IMAGE_WIDTH_HEIGHT,
  MAX_THUMBNAIL_WIDTH_HEIGHT,
  resizeImage,
}