// Action types.
const WANTED_LANGUAGE = 'wantedLanguage';
const LANGUAGE_CHANGED = 'languageChanged';
const PROPOSED_USER = 'proposedUser';
const USER_CHANGED = 'userChanged';
const CHAIN_ID_CHANGED = 'chainIdChanged';
const SITE_ID_CHANGED = 'siteIdChanged';
const CHAINS_CHANGED = 'chainsChanged';
const SITES_CHANGED = 'sitesChanged';
const ACCESS_TOKEN_CHANGED = 'accessTokenChanged';

// TODO: Remove when fetching actual user permissions.
const testPermissionNames = [
    'Reception',
    'InspectionHistory',
    'Booking',
    'Timesheet',
    'Marketing',
    'Atj',
    'SiteProducts',
    'VehicleGroups',
    'Devices',
    'InspectionLines',
    'Chain',
    'ChainProducts',
    'Sites',
    'SiteGroups',
    'Users',
    'Reports',
];

export class ControlStateManager {
    constructor() {
        this._controlState = {
            language : window.preferredLanguage || 'fi-FI',
            chainId: null,
            siteId: null,
            user: {},
            chains: [],
            sites: [],
        };

        window.addEventListener('actionEvent', this.handleActionEvent);
        this.clientCallbacks = [];
    }

    //Creates new instance of control state.
    //This way clients (web components) can't modidy global state (this.cS)
    createControlStateInstance = (lastAction) => {
        const csInstance = {};
        Object.assign(csInstance, this._controlState);
        csInstance.proposeChange = this.proposeChange;
        csInstance.lastAction = lastAction;
        return csInstance;
    }

    //This function is given inside the (semi-immutable) control state object as helper
    //for clients to send control state change proposals (=actionEvents)
    proposeChange(actionName, data) {
        const actionEvent = new CustomEvent('actionEvent', {
            bubbles: true,
            composed: true,
            detail: {
              actionName: actionName,
              data: data,
            },
          });
          window.dispatchEvent(actionEvent);
    }

    broadCastMutatedControlState = (controlState) => {
        this.clientCallbacks.forEach(ccb => ccb(controlState));
    }

    //This is called by service broker
    addControlStateClient = (callback) => {
        this.clientCallbacks.push(callback);
        callback(this.createControlStateInstance('clientAdded'));
    }

    //This is the place where state is mutated based on proposals coming from clients
    handleActionEvent = (event) => {
        if (event.detail.actionName === LANGUAGE_CHANGED) {
            this._controlState.language = event.detail.data;
        }

        if (event.detail.actionName === WANTED_LANGUAGE) {
            this._controlState.wantedLanguage = event.detail.data;
        }

        if (event.detail.actionName === USER_CHANGED) {
            this._controlState.user = event.detail.data;
        }

        if (event.detail.actionEvent === PROPOSED_USER) {
            this._controlState.proposedUser = event.detail.data;
        }

        if (event.detail.actionName === CHAIN_ID_CHANGED) {
            this._controlState.chainId = event.detail.data;
        }

        if (event.detail.actionName === SITE_ID_CHANGED) {
            this._controlState.siteId = event.detail.data;
        }

        if (event.detail.actionName === CHAINS_CHANGED) {
            this._controlState.chains = event.detail.data;
        }

        if (event.detail.actionName === SITES_CHANGED) {
            this._controlState.sites = event.detail.data;
        }

        if (event.detail.actionName === ACCESS_TOKEN_CHANGED) {
            this._controlState.accessToken = event.detail.data;
        }

        const controlStateInstance = this.createControlStateInstance(event.detail.actionName);
        this.broadCastMutatedControlState(controlStateInstance);
    }
}