import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
    backgroundColor: theme.palette.black.main,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.white.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    maxHeight: '100%',
    marginTop: 10,
    marginBottom: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxHeight: '100%',
      width: 'auto',
      maxWidth: '100%',
    },
  },
  description: {
    color: theme.palette.white.main,
    fontSize: 16,
    lineHeight: '23px',
    textAlign: 'center',
    height: 98,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    '& > div:first-child': {
      flex: 1,
    },
  },
  input: {
    backgroundColor: theme.palette.white.main,
  },
  saveButton: {
    borderRadius: '50%',
    width: 38,
    minWidth: 38,
    height: 38,
    padding: 6,
    marginLeft: 16,
  },
  loadingIndicator: {
    marginLeft: 16,
    height: 38,
    width: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function ImageDetails({ open, onClose, onSave, image }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isUploading = useSelector(state => state.jobs.isUploading);
  const [imageUrl, setImageUrl] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    // Create object URL when component mounts.
    if (image && !imageUrl) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
    }
    // Revoke object URL when component unmounts.
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
        setImageUrl(null);
      }
    };
  }, [image, imageUrl]);

  const handleSave = () => {
    onSave(description);
  };

  if (!imageUrl) {
    return null;
  }
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            <img src={imageUrl} alt='Vehicle' />
          </div>
        </div>
        <div className={classes.description}>
          <div>
            <OutlinedInput
              placeholder={t('inspectionView.addDescription')}
              onChange={(event) => setDescription(event.target.value)}
              className={classes.input}
              margin='dense'
              fullWidth
            />
          </div>
          {isUploading ? (
            <div className={classes.loadingIndicator}>
              <CircularProgress color='primary' size={30} />
            </div>
          ) : (
            <Button
              color='primary'
              variant='contained'
              className={classes.saveButton}
              onClick={handleSave}
              disabled={isUploading}
            >
              <ArrowForwardRoundedIcon fontSize='inherit' />
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
}

ImageDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
};

export default ImageDetails;