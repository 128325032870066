import { createSlice } from '@reduxjs/toolkit';

const serviceWorkerSlice = createSlice({
  name: 'serviceWorker',
  initialState: {
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  },
  reducers: {
    swUpdated(state, { payload }) {
      state.serviceWorkerUpdated = true;
      state.serviceWorkerRegistration = payload;
    },
  },
});

export const {
  swUpdated,
} = serviceWorkerSlice.actions;

export default serviceWorkerSlice.reducer;
