import { stableSort } from '../../assets/lib/commonFunctions'

const sendUserRequest = async (chainId, accessToken, method = 'GET', userId = '', data = {}) => {
  const requestInfo = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: `Bearer ${accessToken}`
    },
  };
  if (method === 'POST' || method === 'PUT') {
    requestInfo.body = JSON.stringify(data);
  }
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/user/${userId}?code=${process.env.REACT_APP_API_CODE}`, requestInfo);

  if (!response.ok) {
    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
    throw new Error(response.statusText);
  }
  return response.json();
}

const getUsers = async (chainId, accessToken) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/userList?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      [process.env.REACT_APP_AUTH_HEADER_NAME]: `Bearer ${accessToken}`
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
}

const sendCrudRequest = (chainId, procName, data, accessToken) => {
  const body = {
    procName,
    data,
  };
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: `Bearer ${accessToken}`
    },
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Request failed:', response.statusText);
    }
    return response.json();
  });
}

/**
 * Chain users store.
 */
export class ChainUserStore {
  constructor(translationService) {
    this.data = [];
    this.translate = translationService.translate;
  }

  setTranslationService(service) {
    this.translate = service.translate;
  }

  clearData() {
    this.data = [];
  }

  get dataLength() {
    return this.data ? this.data.length : 0;
  }

  mapUserData = (data) => {
    const activeLabel = this.translate(`active`);
    const inActiveLabel = this.translate(`inActive`);
    return data.map(user => ({
      ...user,
      name: `${user.firstName} ${user.lastName}`,
      state: user.isActive ? activeLabel : inActiveLabel,
    }));
  }

  getChainUsers = async (chainId, sortBy, sortDirection, searchText) => {
    // Event to Redux.
    const token = this.accessToken;
    const reduxEvent = new CustomEvent('reduxEvent', {
      bubbles: true,
      composed: true,
      detail: {
        action: 'fetchUsers',
        data: { chainId, token },
      },
    });
    window.dispatchEvent(reduxEvent);

    this.data = await getUsers(chainId, this.accessToken);
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    const data = this.mapUserData(this.data).filter(user => !searchText || `${user.firstName} ${user.lastName} ${user.phoneNumber} ${user.email}`.toLowerCase().includes(searchText));
    return stableSort(data, sortBy, sortDirection)
      
  }

  getChainUser = async (chainId, userId) => {
    // Event to Redux.
    const reduxEvent = new CustomEvent('reduxEvent', {
      bubbles: true,
      composed: true,
      detail: {
        action: 'fetchUser',
        data: { chainId, userId },
      },
    });
    window.dispatchEvent(reduxEvent);

    return sendUserRequest(chainId, this.accessToken, 'GET', userId);
  }

  updateUser = async (chainId, userId, data) => {
    return sendUserRequest(chainId, this.accessToken, 'PUT', userId, data);
  }

  addUser = async (chainId, data) => {
    return sendUserRequest(chainId, this.accessToken, 'POST', '', data);
  }

  getPermissionScopes = async (chainId) => {
    return sendCrudRequest(chainId, 'GetPermissionScopeNames', {}, this.accessToken);
  }

  sendCrudRequest = async (chainId, proc, data) => {
    return sendCrudRequest(chainId, proc, data, this.accessToken);
  }

  deleteUser = async (chainId, userId) => {
    return sendUserRequest(chainId, this.accessToken, 'DELETE', userId);
  }

  resetPassword = async (chainId, data) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/user/resetPwd?code=${process.env.REACT_APP_API_CODE}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        [process.env.REACT_APP_AUTH_HEADER_NAME]: `Bearer ${this.accessToken}`
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Request failed:', response.statusText);
      }
    });
  }
}