import { stableSort } from '../../assets/lib/commonFunctions'

export class JobTypeStore {
    constructor() {
      this.data = null;
      this.chainId = null;
    }

    clearData() {
      this.data = null;
      this.chainId = null;
    }

    get dataLength() {
      return this.data ? this.data.length : 0;
    }

    postData(chainId, data = {}) {
      return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + this.accessToken
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
       return response.json()
        .then(res => res)
        .catch((err) =>{
          // Empty response - skip.
          return;
        });
      });
    }

    mapJobTypesData = (data, translationService) => {
      return data.map((item) => ({
        ...item,
        category: translationService.translate( `categories.${item.baseJobType[0].name}` )
      }));
    }

    mapJobTypeData = (data, translationService, sortBy, sortDirection) => {
      return Object.assign( { measurements: stableSort( data.measurements, sortBy, sortDirection) }, data );
    }

    mapMeasurementsData = (data, selectedGroupId, translationService) => {
      return data.map((item) => ({
        ...item,
        jobTypeId: item.jobType[0]?.id ?? '',
        jobTypeName: item.jobType[0]?.name ?? '',
      }));
    }

    mapCategoryData = (data, translationService) => {
      return data.map((item) => ({
        id: item.id,
        name: translationService.translate(`categories.${item.name}`)
        }));
    }

    getMeasurementTypes = async (chainId) => {
      const queryObject = {
        procName: 'GetMeasurementTypes',
        data: {}
      }
      return this.postData(chainId, queryObject);
    }

    getCategories = async (chainId, translationService) => {
      const queryObject = {
        procName: 'GetBaseJobTypes',
        data: {}
      }
      const serverData = await this.postData(chainId, queryObject);

      // Filter out 8 - condition inspection category.
      // Condition inspection job types are now managed by the condition inspection job types app,
      // so there is no need to show it here.
      const filteredData = serverData.filter((item) => item.id !== 8);

      // Map category data.
      return this.mapCategoryData(filteredData, translationService);
    }

    getJobTypes = async (translationService, chainId, sortBy, sortDirection) => {
      // Event to Redux.
      const reduxEvent = new CustomEvent('reduxEvent', {
        bubbles: true,
        composed: true,
        detail: {
          action: 'fetchJobTypes',
          data: { chainId },
        },
      });
      window.dispatchEvent(reduxEvent);

      const queryObject = {
        procName: 'GetJobTypesInChain',
        data: { chainId },
      };

      if (this.chainId !== chainId) {
        const serverData = await this.postData(chainId, queryObject);
        this.data = this.mapJobTypesData(serverData, translationService);
        this.chainId = chainId;
      }
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.data = stableSort(this.data, sortBy, sortDirection);
      return this.data;
    }

    getJobType = async (chainId, jobTypeId, sortBy, sortDirection) => {
      const queryObject = {
        procName: 'GetJobType',
        data: { jobTypeId },
      };

      return this.postData(chainId, queryObject);
    }

    updateJobType = async ( chainId, jobTypeData ) =>
    {
      const queryObject = {
        procName: 'UpdateJobType',
        data: {
          jobTypeId: jobTypeData.jobTypeId,
          baseJobTypeId: jobTypeData.baseJobTypeId,
          name: jobTypeData.name,
          measurements: jobTypeData.measurements
        }
      }
      return this.postData(chainId, queryObject);
    }

    createJobType = async ( chainId, jobTypeData ) =>
    {
      const queryObject = {
        procName: 'CreateJobType',
        data: jobTypeData
      }
      return this.postData(chainId, queryObject);
    }

    deleteJobType = async ( chainId, jobTypeId ) =>
    {
      const queryObject = {
        procName: 'DeleteJobType',
        data: { jobTypeId }
      }
      return this.postData(chainId, queryObject);
    }

    getVehicleGroups = async (translationService, chainId, sortBy, sortDirection) => {
      const queryObject = {
        procName: 'GetVehicleGroupsInChain',
        data: { chainId },
      };
      return this.postData(chainId, queryObject);
    }
  }