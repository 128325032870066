export default `
<style type="text/css">
.form-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.item {
  margin-bottom: 20px;
}

.buttons-container {
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.11);
  justify-content: flex-end;
}

.notice {
  box-sizing: border-box;
  /* height: 80px; */
  width: 500px;
  border: 1px solid rgba(0,71,179,0.5);
  border-radius: 4px;
  background-color: #F4F8FF;
  padding: 10px;
}

button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3;
  text-transform: uppercase;
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  min-width: 140px;
  cursor: pointer;
}

button > img {
  margin-right: 6px;
}

button:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button:focus {
  outline: none;
}

button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}

button.secondary {
  color: var(--buttonColor);
  background-color: var(--buttonBackground);
  border: 1px solid var(--primaryColor);
}

table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
}

tr {
    height: 43px;
}

th {
    border-bottom: 1px solid rgba(209,209,209,0.25);
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0,0,0,0.6);
    padding: 0px 20px;
    text-align: left;
}

td {
  padding: 0px 20px;
}

</style>
<div id="sites-section">
  <div class="form-container">
    <div class="item toolbar">
      <button id="button-add-site" class="primary">
        <img id="add-icon" src="" />
        <div></div>
      </button>
      <button id="button-add-site-group" class="primary">
        <img id="add-icon" src="" />
        <div></div>
      </button>
    </div>
    <div class="item notice"></div>

  </div>
  <div class="buttons-container">
    <button class="secondary" id="button-delete-user"></button>
  </div>
</div>
`;