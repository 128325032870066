export default new Map([
  [0, ['Ajoneuvon tunnistus', 'Fordonets identifiering', new Map([
    [0, ['Valmistajan kilpi', 'Tillverkarens skylt',
      [0, '(0.2) Valmistajan kilpi', '(0.2) Tillverkarens skylt', [0, 17, 21, 95, 108, 221, 644], [0, 2, 2, 2, 2, 2, 2]]]],
    [1, ['Valmistenumero', 'Tillverkningsnummer',
      [0, '(0.2) Valmistenumero', '(0.2) Tillverkningsnr.', [0, 17, 18, 19, 21, 22, 108, 644, 715], [0, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [2, ['Rekisterimerkinnät', 'Registeranteckningar',
      [0, '(0.2) Rekisterimerkinnät', '(0.2) Reg.anteckningar', [0, 23, 30, 34, 42, 43, 44, 45, 46, 47, 48, 49, 361, 362, 363, 549, 644, 713, 782], [0, 2, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0]]]],
    [3, ['Asiapaperit', 'Fordonshandlingar',
      [0, '(0.1) Asiapaperit', '(0.1) Fordonshandlingar', [0, 30, 31, 32, 33, 314, 336, 377, 378, 398, 423, 424, 431, 432, 433, 438, 439, 440, 441, 442, 443, 444, 459, 460, 461, 464, 465, 493, 503, 504, 538, 551, 552, 553, 644, 702, 709, 745, 999], [0, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [4, ['Rekisterikilpi', 'Registerskylt',
      [0, '(0.1) Rekisterikilpi', '(0.1) Registerplåt', [0, 56, 95, 102, 103, 108, 215, 554, 555, 642, 644, 701, 743], [0, 1, 1, 1, 1, 2, 1, 2, 1, 1, 1, 1, 1]]]],
    [5, ['Luvanvaraisen liikenteen tunnisteet', 'Igenkänningstecken för bil med trafiktillstånd',
      [0, '(FIN.A2.1) Liikenneluvan haltijan nimi', '(FIN.A2.1) Trafiktillståndets innehavare', [0, 17, 56, 99, 108, 495, 644, 712], [0, 0, 0, 0, 0, 0, 0, 0]],
      [1, '(0.1) Rekisteritunnuksen kehys', '(0.1) Registerplåtens ram', [0, 95, 108, 295], [0, 1, 1, 1]]]],
  ])]],
  [1, ['Jarrujärjestelmät', 'Bromslänksystemen', new Map([
    [0, ['Käyttöjarru', 'Färdbroms',
      [0, '(1.1) Käyttöjarru', '(1.1) Färdbroms', [0, 108, 408, 644, 714, 717, 720, 721, 722, 724, 732, 734, 748, 765, 766, 781], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 3]],
      [1, '(1.1.12) Jarruletku', '(1.1.12) Bromsslang', [0, 95, 138, 140, 183, 278, 332, 343, 455, 501, 559, 718], [0, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(1.1.12) Jarruletkun tuenta', '(1.1.12) Bromsslangens upphängning', [0, 68, 88, 95, 99, 100, 221], [0, 2, 1, 1, 2, 2, 2]],
      [3, '(1.1.12) Jarruletkun liitos', '(1.1.12) Bromsslangens koppling', [0, 68, 95, 183, 325, 559, 561], [0, 2, 2, 3, 2, 2, 2]],
      [4, '(1.1.11) Jarruputken liitos', '(1.1.11) Bromsrörets anslutning', [0, 68, 95, 183, 325, 559], [0, 2, 2, 3, 2, 2]],
      [5, '(1.1.11) Jarruputki', '(1.1.11) Bromsrör', [0, 68, 89, 95, 139, 183, 325, 559, 753], [0, 2, 2, 2, 2, 3, 2, 2, 2]],
      [6, '(1.1.11) Jarruputken tuenta', '(1.1.11) Bromsrörets fastsättning', [0, 68, 95, 99, 100, 221], [0, 2, 2, 2, 2, 2]],
      [7, '(1.1.14) Jarrulevy', '(1.1.14) Bromsskiva', [0, 68, 89, 95, 100, 115, 325, 331, 502], [2, 2, 2, 2, 0, 2, 2, 2, 2]],
      [8, '(1.1.13) Jarrupalat', '(1.1.13) Bromsklossar', [0, 68, 234, 293, 294, 731], [0, 2, 2, 2, 2, 2]],
      [9, '(1.1.13) Jarrupalojen kiinnitys', '(1.1.13) Bromsklossarnas fastsättning', [0, 68, 79, 95, 221, 234], [0, 2, 2, 2, 2, 2]],
      [10, '(1.1.16) Jarrusatula', '(1.1.16) Bromshus', [0, 68, 95, 183], [0, 2, 2, 3]],
      [11, '(1.1.16) Jarrusatulan kiinnitys', '(1.1.16) Bromshusets fästning', [0, 79, 95, 115, 221], [0, 2, 2, 2, 2]],
      [12, '(1.1.14) Jarrukilpi', '(1.1.14) Bromssköld', [0, 68, 88, 95, 108], [0, 1, 1, 2, 2]],
      [13, '(1.1.14) Jarrukilven kiinnitys', '(1.1.14) Bromssköldens fästning', [0, 68, 95, 99, 100, 221], [0, 1, 1, 1, 1, 1]],
      [14, '(1.1.17) Kuorman tunteva jarruvoimansäädin', '(1.1.17) Bromskraftregulator som beaktar last', [0, 68, 74, 89, 95, 96, 183, 405, 419, 425, 489, 490, 491, 492], [0, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2]],
      [15, '(1.1.10) Jarrutehostin', '(1.1.10) Servobroms', [0, 74, 95, 108, 183, 704], [0, 2, 2, 2, 2, 2]],
      [16, '(1.1.10) Jarrutehostimen kiinnitys', '(1.1.10) Servobromsens fästning', [0, 95, 100, 221], [0, 2, 2, 2]],
      [17, '(1.1.10) Jarrupääsylinteri', '(1.1.10) Huvudcylinder', [0, 100, 183, 558], [0, 2, 3, 2]],
      [18, '(1.1.10) Jarrupääsylinterin kiinnitys', '(1.1.10) Huvudcylinders fästning', [0, 95, 100, 221], [0, 2, 2, 2]],
      [19, '(1.8)(1.1.10) Jarruneste', '(1.8)(1.1.10) Bromsvätska', [0, 206, 207, 527, 528, 719], [0, 2, 3, 2, 2, 2]],
      [20, '(1.1.21) Ilmankuivain', '(1.1.21) Lufttorkare', [0, 74, 102, 108, 183], [0, 2, 2, 2, 2]],
      [21, '(1.1.3) Paineilmasäiliö', '(1.1.3) Pressluftbehållare', [0, 95, 103, 183, 269, 760], [0, 2, 1, 2, 2, 2]],
      [22, '(1.1.3) Paineenrajoitin', '(1.1.3) Bromskraftbegränsare', [0, 326, 327], [0, 2, 2]],
      [23, '(1.1.7) Poljinventtiili', '(1.1.7) Pedalventil', [0, 103, 183], [0, 2, 2]],
      [24, '(0.2) Todistus säädöstenmukaisuudesta', '(0.2) Intyg för uppfyllande av bestämmelse', [0, 108], [2, 2]],
      [25, '(1.1.12)(1.1.8) Pv:n jarruletku', '(1.1.12)(1.1.8) Släpvagnens bromsslang', [0, 95, 138, 140, 183, 278, 332, 343, 455, 501, 559, 718], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [26, '(1.1.8) Pv:n jarruletkun liittimet', '(1.1.8) Släpvagnens bromsslangens fästningar', [0, 68, 95, 183, 325, 559, 561], [0, 2, 2, 2, 2, 2, 2]],
      [27, '(1.1.13) Jarruhihnat', '(1.1.13) Bromsbanden', [0, 68, 293, 294, 328, 329], [0, 2, 2, 2, 2, 2]],
      [28, '(1.1.7) Siirtelyventtiili', '(1.1.7) Övergångsventil', [0, 74, 96], [0, 2, 2]],
      [29, '(1.1.7) Pikapäästöventtiili', '(1.1.7) Snabblossningsventil', [0, 74, 183], [0, 2, 2]],
      [30, '(1.1.18) Autom. säätyvä jarruvipu', '(1.1.18) Automatiskt justerbar bromsarm', [0, 68, 79, 95, 96, 100, 450, 560, 565], [0, 2, 2, 2, 2, 2, 2, 2, 2]],
      [31, '(1.1.14) Jarrurumpu', '(1.1.14) Bromstrumma', [0, 68, 89, 95, 115, 121, 330], [0, 2, 2, 2, 2, 2, 2]],
      [32, '(1.1.15) Jarruvipu', '(1.1.15) Bromsarm', [0, 79, 95, 115, 278, 332, 333, 419, 451, 560], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [33, '(1.1.16) Jarrusylinteri', '(1.1.16) Bromscylinder', [0, 88, 103, 183, 374, 562, 563, 564, 747], [0, 2, 2, 2, 3, 2, 2, 2, 2]],
      [34, '(1.1.16) Ilmausruuvi', '(1.1.16) Luftningsskruv', [0, 374], [0, 3]],
      [35, '(1.6) Lukkiutumaton jarrujärjestelmä', '(1.6) Låsningsfritt bromssystem', [0, 74, 272, 393], [2, 2, 2, 2]],
      [36, '(1.1.22) Mittausliitin', '(1.1.22) Mätningsanslutning', [0, 183, 402, 403], [0, 2, 2, 2]],
      [37, '(1.1.7) Releventtiili', '(1.1.7) Reläventil', [0, 74, 103, 183], [0, 2, 2, 2]],
      [38, '(1.1.8) Duo-Matic liitin', '(1.1.8) Duo-Matic', [0, 56, 74, 103, 183, 230, 265], [2, 2, 2, 2, 2, 2, 2]],
      [39, '(1.1.15) Levittäjäakseli', '(1.1.15) S-kam', [0, 95, 96, 115, 409], [0, 2, 2, 2, 2]],
      [40, '(1.1.14) Jarrulevyn suojakilpi', '(1.1.14) Bromsskivans skyddsplåt', [0, 68, 88, 95, 108], [0, 1, 1, 1, 1]],
      [41, '(1.1.17) ALB:n säätökilpi', '(1.1.17) Justerskylt för ALB', [0, 95, 108, 762], [0, 2, 2, 2]],
      [42, '(1.1.5) Käsikäyttöinen pv-jarruohjausventtiili', '(1.1.5) Handmanövrerad sv-bromsventil', [0, 69, 103, 183, 557, 727, 769, 770], [0, 2, 2, 2, 2, 2, 2, 2]],
      [43, '(1.2) Käsikäyttöinen etujarrutehon puolittaja', '(1.2) Handmanövrerad bromskrafthalverare för frambroms', [0, 69, 103, 183, 557], [0, 2, 2, 2, 2]],
      [44, '(1.7) Elektronisesti ohjattu jarrujärjestelmä', '(1.7) Elektronisk styrt bromssystem', [0, 272, 393], [0, 2, 2]],
      [45, '(1.1.20) Perävaunun jarruventtiilli', '(1.1.20) Släpvagnens bromsventil', [0, 103, 183, 596], [0, 2, 2, 2]],
      [46, '(1.1.7) Pv:n jarrujen ohjausventtiili autossa', '(1.1.7) Släpvagnsbromsens styrventil på bilen', [0, 69, 74, 103, 183, 597], [0, 2, 2, 2, 2, 2]],
      [47, '(1.1.7) EBS modulaattoriventtiili', '(1.1.7) EBS modulatorventil', [0, 69, 103, 183], [0, 2, 2, 2]],
      [48, '(1.1.12) Jarruletkun liitosholkki', '(1.1.12) Bromsslangens presskoppling', [0, 88, 95, 625], [2, 2, 2, 2]],
      [49, '(1.2) Paine-ennakko', '(1.2) Förtryck', [0, 99, 585], [0, 2, 2]]]],
    [1, ['Käyttöjarrun dynamometritesti', 'Färdbromsens dynamometertest',
      [1, '(1.2) Etujarru', '(1.2) Frambroms', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [0, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(1.2) Takajarru', '(1.2) Bakbroms', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [3, '(1.2) Poljintuntuma', '(1.2) Pedalkänsla', [0, 95, 273, 274, 275, 276, 284, 285], [0, 2, 2, 3, 2, 2, 2, 2]],
      [4, '(1.2) Ajoneuvon jarrutussuhde', '(1.2) Fordonets bromsförhållande', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [5, '(1.2) Akselin 1 jarrutussuhde', '(1.2) 1. axelns bromsförhållande', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [6, '(1.2) Akselin 2 jarrutussuhde', '(1.2) 2. axelns bromsförhållande', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [7, '(1.2) Akselin 3 jarrutussuhde', '(1.2) 3. axelns bromsförhållande', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [8, '(1.2) Akselin 4 jarrutussuhde', '(1.2) 4. axelns bromsförhållande', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [9, '(1.2) Akselin 5 jarrutussuhde', '(1.2) 5. axelns bromsförhållande', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [11, '(1.2) Dyn arvo 1. akseli', '(1.2) Dyn. värde på 1. axeln', [0], [0]],
      [12, '(1.2) Dyn arvo 2. akseli', '(1.2) Dyn. värde på 2. axeln', [0], [0]],
      [13, '(1.2) Dyn arvo 3. akseli', '(1.2) Dyn. värde på 3. axeln', [0], [0]],
      [14, '(1.2) Dyn arvo 4. akseli', '(1.2) Dyn. värde på 4. axeln', [0], [0]],
      [15, '(1.2) Dyn arvo 5. akseli', '(1.2) Dyn. värde på 5. axeln', [0], [0]],
      [20, '(1.2) Telin jarrutussuhde', '(1.2) Bromsförhållande på boggie', [0, 334, 335, 375, 376, 452, 453], [0, 2, 2, 2, 2, 2, 2]],
      [21, '(1.2) Jarruvoiman jakautuminen', '(1.2) Bromskraftens fördelning', [0, 99], [0, 2]],
      [22, '(1.2) 1. akselin jarru', '(1.2) 1. axelns bromsar', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [23, '(1.2) 2. akselin jarru', '(1.2) 2. axelns bromsar', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [24, '(1.2) 3. akselin jarru', '(1.2) 3. axelns bromsar', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [25, '(1.2) 4. akselin jarru', '(1.2) 4. axelns bromsar', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [26, '(1.2) 5. akselin jarru', '(1.2) 5. axelns bromsar', [0, 74, 133, 192, 193, 194, 202, 208, 209, 210, 211, 220, 309, 324, 466, 566], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [30, '(1.2) Jarruviive', '(1.2) Bromsfördröjning', [0, 278, 468, 469], [0, 2, 2, 2]],
      [31, '(1.2) Havahtumispaine', '(1.2) Öppningstryck', [0, 481], [0, 2]]]],
    [2, ['Seisontajarrun dynamometritesti', 'Parkeringsbromsens dynamometertest',
      [1, '(1.4)(1.1.6) Seisontajarru', '(1.4)(1.1.6) Parkeringsbroms', [0, 74, 108, 133, 193, 196, 197, 198, 199, 200, 209, 210, 212, 217, 219, 272, 309, 324, 392, 399], [0, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [11, '(1.4) Dyn arvo 1.aks seisontajarru', '(1.4) Dyn. värde på 1. axelns parkeringsbroms', [0], [0]],
      [12, '(1.4) Dyn arvo 2.aks seisontajarru', '(1.4) Dyn. värde på 2. axelns parkeringsbroms', [0], [0]],
      [13, '(1.4) Dyn arvo 3.aks seisontajarru', '(1.4) Dyn. värde på 3. axelns parkeringsbroms', [0], [0]],
      [14, '(1.4) Dyn arvo 4.aks seisontajarru', '(1.4) Dyn. värde på 4. axelns parkeringsbroms', [0], [0]],
      [15, '(1.4) Dyn arvo 5. aks seisontajarru', '(1.4) Dyn. värde på 5. axelns parkeringsbroms', [0], [0]],
      [22, '(1.4) 1. akselin seisontajarru', '(1.4) 1. axelns parkeringsbroms', [0, 74, 108, 133, 193, 196, 197, 198, 199, 200, 209, 210, 212, 217, 219, 272, 309, 324, 392, 399], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [23, '(1.4) 2. akselin seisontajarru', '(1.4) 2. axelns parkeringsbroms', [0, 74, 108, 133, 193, 196, 197, 198, 199, 200, 209, 210, 212, 217, 219, 272, 309, 324, 392, 399], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [24, '(1.4) 3. akselin seisontajarru', '(1.4) 3. axelns parkeringsbroms', [0, 74, 108, 133, 193, 196, 197, 198, 199, 200, 209, 210, 212, 217, 219, 272, 309, 324, 392, 399], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [25, '(1.4) 4. akselin seisontajarru', '(1.4) 4. axelns parkeringsbroms', [0, 74, 108, 133, 193, 196, 197, 198, 199, 200, 209, 210, 212, 217, 219, 272, 309, 324, 392, 399], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [26, '(1.4) 5. akselin seisontajarru', '(1.4) 5. axelns parkeringsbroms', [0, 74, 108, 133, 193, 196, 197, 198, 199, 200, 209, 210, 212, 217, 219, 272, 309, 324, 392, 399], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [3, ['Seisontajarru', 'Parkeringsbroms',
      [1, '(1.1.16) Seisontajarrun vaijerit/tangot', '(1.1.16) Parkeringsbromsens vajer/stag', [0, 95, 102, 115, 139], [0, 2, 2, 2, 2]],
      [2, '(1.1.16) Seisontajarrun vaijereiden/tankojen kiinnitys', '(1.1.16) Parkeringsbromsens vajer/stagens fastsättning', [0, 95, 100], [0, 2, 2]],
      [3, '(1.1.7) Seisontajarruventtiili', '(1.1.7) Parkeringsbromsventil', [0, 74, 183], [0, 2, 2]],
      [4, '(1.1.16) Seisontajarrusylinteri', '(1.1.16) Handbromscylinder', [0, 71, 95, 103, 183], [0, 2, 2, 2, 2]],
      [5, '(1.1.6) Seisontajarru', '(1.1.6) Parkeringsbroms', [0, 95, 644], [1, 2, 2]]]],
    [4, ['Muu jarru ja hidastin', 'Annan broms och retarder',
      [1, '(1.1.19) Muu jarru', '(1.1.19) Annan broms', [0, 221, 644, 724], [0, 2, 2, 2]],
      [2, '(1.5)(1.1.19) Hidastin', '(1.5)(1.1.19) Retarder', [0, 19, 315, 316, 317, 568], [0, 2, 2, 2, 2, 2]],
      [3, '(1.5) Pakokaasujarru', '(1.5) Avgasbroms', [0, 19, 315, 316, 317, 568], [0, 2, 2, 2, 2, 2]],
      [4, '(1.1.23) Työntöjarru', '(1.1.23) Påskjutbroms', [0, 96, 217, 273, 379, 380, 381, 399, 420, 421, 454, 567], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [5, '(1.3) (1.1.20) Katastrofijarru', '(1.3) (1.1.20) Katastrofbroms', [0, 72, 74, 108], [0, 2, 2, 2]],
      [6, '(1.) Sähköjarru', '(1.) Elbroms', [0, 172, 477], [0, 2, 2]]]],
    [5, ['Ajovakautusjärjestelmä', 'Kör stabiliserings system',
      [1, '(7.12) Ajovakautusjärjestelmä', '(7.12) Körstabiliseringssystem', [0, 272, 393, 574], [0, 2, 2, 2]]]],
  ])]],
  [2, ['Valaisimet ja sähkölaitteet', 'Belysning och elektrisk utrustningar', new Map([
    [0, ['Lähivalo', 'Närljus',
      [0, '(4.1) Lähivalo', '(4.1) Närljus', [0, 58, 69, 74, 95, 108, 257, 259, 269, 295, 390, 429, 445, 480, 643, 644, 740, 774, 803, 804], [0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 2, 1]],
      [1, '(4.1.2) Lähivalon suuntaus', '(4.1.2) Närljusets inställning', [0, 99, 244, 245, 246, 251, 254, 570], [1, 1, 1, 1, 2, 1, 1, 2]],
      [2, '(4.1.1) Lähivalon kuvio', '(4.1.1) Närljusets ljusbild', [0, 253, 255, 256, 269], [0, 1, 1, 1, 1]],
      [3, '(4.1.3)(4.1.4) Lähivalon sijoitus ja kytkentä', '(4.1.3)(4.1.4) Närljusets placering och koppling', [0, 56, 265, 266], [0, 1, 1, 1]],
      [4, '(4.1.1) Lähivalon kiinnitys', '(4.1.1) Närljusets fastsättning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [5, '(4.1.1) Lähivaloumpio', '(4.1.1) Närljus lyktinsats', [0, 95, 247, 269, 422], [0, 1, 1, 1, 1]],
      [6, '(4.1.1) Lähivalon lasi', '(4.1.1) Närljusets glas', [0, 95, 121, 248], [0, 1, 1, 1]],
      [7, '(4.1.1) Lähivalon heijastin', '(4.1.1) Närljusets reflektor', [0, 89, 95, 249, 325], [0, 1, 1, 1, 1]],
      [8, '(4.12) Kahdennuslähivalo', '(4.12) Dubbleringsnärljus', [0, 58, 74, 95, 108, 257, 259, 269, 270, 295, 390], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [9, '(4.12) Kahdennuslähivalon suuntaus', '(4.12) Dubbleringsnärljusets inställning', [0, 99, 244, 245, 246, 251, 254], [0, 1, 1, 1, 2, 1, 1]],
      [10, '(4.12) Kahdennuslähivalon kuvio', '(4.12) Dubbleringsnärljusets ljusbild', [0, 253, 255, 256, 269], [0, 1, 1, 1, 1]],
      [11, '(4.12) Kahdennuslähivalon sijoitus ja kytkentä', '(4.12) Dubbleringsnärljusets placering och koppling', [0, 56, 265, 266], [0, 1, 1, 1]],
      [12, '(4.12) Kahdennuslähivalon kiinnitys', '(4.12) Dubbleringsnärljusets fastsättning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [13, '(4.12) Kahdennuslähivalon umpio', '(4.12) Dubbleringsnärljusets lyktinsats', [0, 95, 247, 269, 422], [0, 1, 1, 1, 1]],
      [14, '(4.12) Kahdennuslähivaloumpion lasi', '(4.12) Dubbleringsnärljusets glas', [0, 95, 121, 248], [0, 1, 1, 1]],
      [15, '(4.12) Kahdennuslähivaloumpion heijastin', '(4.12) Dubbleringsnärljusets reflektor', [0, 89, 95, 249], [0, 1, 1, 1]]]],
    [1, ['Kaukovalo', 'Fjärrljus',
      [0, '(4.1.1) Kaukovalo', '(4.1.1) Fjärrljus', [0, 58, 74, 95, 100, 108, 249, 257, 258, 259, 269, 295, 390, 474, 480, 643, 644, 729, 774, 803], [0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [1, '(4.1.3)(4.1.4) Kaukovalon sijoitus ja kytkentä', '(4.1.3)(4.1.4) Fjärrljusets placering och koppling', [0, 56, 265, 266], [0, 1, 1, 1]],
      [2, '(4.1.1) Kaukovalon kiinnitys', '(4.1.1) Fjärrljusets infästning', [0, 95, 99, 100], [1, 1, 1, 1]],
      [3, '(4.1.1) Kaukovaloumpio', '(4.1.1) Fjärrljus lyktinsats', [0, 95, 247, 269, 422], [0, 1, 1, 1, 1]],
      [4, '(4.1.1) Kaukovaloumpion lasi', '(4.1.1) Fjärrljus lyktinsatsens glas', [0, 95, 121, 248], [0, 1, 1, 1]],
      [5, '(4.1.1) Kaukovaloumpion heijastin', '(4.1.1) Fjärrlus lyktinsatsens reflektor', [0, 89, 95, 249], [0, 1, 1, 1]],
      [6, '(4.12) Lisäkaukovalo', '(4.12) Tilläggsfjärrljus', [0, 58, 74, 95, 108, 249, 257, 258, 259, 269, 295, 390, 474, 480, 644, 774, 803], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [7, '(4.12) Lisäkaukovalon sijoitus ja kytkentä', '(4.12) Tilläggsfjärrljusets placering och koppling', [0, 56, 265, 266], [0, 1, 1, 1]],
      [8, '(4.12) Lisäkaukovalon kiinnitys', '(4.12) Tilläggsfjärrljusets infästning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [9, '(4.12) Lisäkaukovaloumpio', '(4.12) Tilläggsfjärrljusets lyktinsats', [0, 95, 247, 269, 422], [0, 1, 1, 1, 1]],
      [10, '(4.12) Lisäkaukovaloumpion lasi', '(4.12) Tilläggsfjärrljusets lyktinsatsens glas', [0, 95, 121, 248], [0, 1, 1, 1]],
      [11, '(4.12) Lisäkaukovaloumpion heijastin', '(4.12) Tilläggsfjärrljusets lyktinsatsens reflektor', [0, 89, 95, 249], [0, 1, 1, 1]]]],
    [2, ['Etuvalo', 'Framljus',
      [0, '(4.2) Etuvalo', '(4.2) Framljus (parkeringsljus)', [0, 58, 74, 95, 108, 257, 259, 269, 295, 296, 390, 422, 429, 445, 644, 741, 803], [0, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1]],
      [1, '(4.2.2)(4.2.3) Etuvalon sijoitus ja kytkentä', '(4.2.2)(4.2.3) Framljusets placering och koppling (parkeringsljus)', [0, 56, 265, 266], [0, 1, 1, 1]],
      [2, '(4.2.1) Etuvalon kiinnitys', '(4.2.1) Framljusets fastsättning (parkeringsljus)', [0, 95, 99, 100, 221], [0, 1, 1, 1, 1]],
      [3, '(4.2.1) Etuvalon lasi', '(4.2.1) Framljusets glas (parkeringsljus)', [0, 95, 121, 248, 295, 429, 445], [0, 1, 1, 1, 1, 1, 1]]]],
    [3, ['Lisävalot', 'Tilläggsljus',
      [0, '(4.12) Lisävalot', '(4.12) Tilläggsljus', [0, 803], [0, 1]],
      [1, '(4.5) Etusumuvalot', '(4.5) Fram dimljus', [0, 58, 74, 77, 95, 100, 108, 257, 259, 269, 295, 390, 474, 480, 644, 768], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(4.5.2) Etusumuvalon suuntaus', '(4.5.2) Fram dimljusets inställning', [0, 99, 244, 245, 246, 251, 254], [0, 1, 1, 1, 1, 1, 1]],
      [3, '(4.5.2) Etusumuvalon kuvio', '(4.5.2) Fram dimljusets ljusbild', [0, 253, 255, 256, 269], [0, 1, 1, 1, 1]],
      [4, '(4.5.3)(4.5.4) Etusumuvalon sijoitus ja kytkentä', '(4.5.3)(4.5.4) Framdimljusets placering och koppling', [0, 56, 265, 266], [0, 1, 1, 1]],
      [5, '(4.5.1) Etusumuvalon kiinnitys', '(4.5.1) Framdimljusets infästning', [0, 95, 99, 100, 221], [0, 1, 1, 1, 1]],
      [6, '(4.5.1) Etusumuvaloumpio', '(4.5.1) Fram dimljusets lyktinsats', [0, 95, 247, 269, 422], [0, 1, 1, 1, 1]],
      [7, '(4.5.1) Etusumuvaloumpion lasi', '(4.5.1) Fram dimljus lyktinsatsens glas', [0, 95, 121, 248], [0, 1, 1, 1]],
      [8, '(4.5.1) Etusumuvaloumpion heijastin', '(4.5.1) Fram dimljus lyktinsatsens reflektor', [0, 89, 95, 249], [0, 1, 1, 1]],
      [9, '(4.5) Takasumuvalo', '(4.5) Bakdimljus', [0, 58, 74, 77, 95, 100, 108, 257, 259, 269, 295, 296, 422, 633, 644, 768], [0, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [10, '(4.5.3)(4.5.4) Takasumuvalon sijoitus ja kytkentä', '(4.5.3)(4.5.4) Bakdimljusets placering och koppling', [0, 56, 265, 266], [0, 1, 1, 1]],
      [11, '(4.5.1) Takasumuvalon kiinnitys', '(4.5.1) Bakdimljusets fastsättning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [12, '(4.5.1) Takasumuvalon lasi', '(4.5.1) Bakdimljusets glas', [0, 95, 121, 248], [0, 1, 1, 1]],
      [13, '(4.2) Huomiovalo', '(4.2) Varselljus', [0, 58, 74, 95, 108, 257, 259, 269, 295, 296, 390, 422], [0, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1]],
      [14, '(4.2.2)(4.2.3) Huomiovalon sijoitus ja kytkentä', '(4.2.2)(4.2.3) Varselljusets placering och koppling', [0, 56, 265, 266, 267], [0, 1, 1, 1, 1]],
      [15, '(4.2.1) Huomiovalon kiinnitys', '(4.2.1) Varselljusets infästning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [16, '(4.2.1) Huomiovalon lasi', '(4.2.1) Varselljusets glas', [0, 95, 121, 248], [0, 1, 1, 1]]]],
    [4, ['Takavalo', 'Bakljus',
      [0, '(FIN) Ei mitään', '(FIN) Ingenting', [0], [0]],
      [1, '(4.2) Takavalo', '(4.2) Bakljus', [0, 58, 69, 74, 77, 95, 108, 257, 259, 269, 295, 296, 390, 422, 429, 445, 467, 644, 803], [0, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(4.2.2)(4.2.3) Takavalon sijoitus ja kytkentä', '(4.2.2)(4.2.3) Bakljusets placering och koppling', [0, 56, 265, 266, 449], [0, 1, 1, 1, 1]],
      [3, '(4.2.1) Takavalon kiinnitys', '(4.2.1) Bakljusets infästning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [4, '(4.2.1) Takavalon lasi', '(4.2.1) Bakljusets glas', [0, 95, 108, 121, 248, 295, 429, 445, 467, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [5, '(FIN) Ei mitään', '(FIN) Ingenting', [0], [0]]]],
    [5, ['Jarruvalo', 'Bromsljus',
      [0, '(4.3) Jarruvalo', '(4.3) Bromsljus', [0, 58, 69, 74, 77, 95, 100, 108, 257, 259, 269, 295, 296, 390, 422, 429, 466, 500, 644, 761, 773, 803], [0, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [1, '(4.3.2)(4.3.3) Jarruvalon sijoitus ja kytkentä', '(4.3.2)(4.3.3) Bromsljusets placering och koppling', [0, 56, 264, 265, 266], [0, 1, 1, 1, 1]],
      [2, '(4.3.1) Jarruvalon kiinnitys', '(4.3.1) Bromsljusets infästning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [3, '(4.3.1) Jarruvalon lasi', '(4.3.1) Bromsljusets glas', [0, 95, 121, 248, 429, 467, 644], [0, 1, 1, 1, 1, 1, 1]],
      [4, '(4.3) Keskijarruvalo', '(4.3) Tilläggsbromsljus', [0, 56, 58, 69, 74, 77, 95, 103, 108, 257, 259, 269, 295, 296, 500, 644], [0, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1]],
      [5, '(4.3.2)(4.3.3) Keskijarruvalon sijoitus ja kytkentä', '(4.3.2)(4.3.3) Tilläggsbromsljusets placering och koppling', [0, 56, 264, 265, 266], [0, 1, 1, 1, 1]],
      [6, '(4.3.1) Keskijarruvalon kiinnitys', '(4.3.1) Tilläggsbromsljusets infästning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [7, '(4.3.1) Lisäjarruvalon lasi', '(4.3.1) Keskijarruvalon lasi', [0, 95, 121, 248], [0, 1, 1, 1]]]],
    [6, ['Suuntavalo', 'Körriktningsljus',
      [0, '(4.4) Suuntavalo', '(4.4) Körriktningsljus', [0, 58, 69, 74, 77, 95, 108, 242, 257, 259, 269, 295, 296, 390, 422, 429, 445, 644, 803], [0, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [1, '(4.4.2)(4.4.3) Suuntavalon sijoitus ja kytkentä', '(4.4.2)(4.4.3) Körriktningsljusets placering och koppling', [0, 56, 242, 265, 266], [0, 1, 1, 1, 1]],
      [2, '(4.4.1) Suuntavalon kiinnitys', '(4.4.1) Körriktningsljusets infästning', [0, 95, 99, 100], [0, 1, 1, 1]],
      [3, '(4.4.1) Suuntavalon lasi', '(4.4.1) Körriktningsljusets glas', [0, 95, 121, 248, 467], [0, 1, 1, 1, 1]],
      [5, '(4.4) Sivusuuntavalo', '(4.4) Sidoblinkers', [0, 56, 58, 69, 74, 95, 108, 257, 259, 265, 269, 295, 296, 383, 429, 445, 644, 803], [0, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]]]],
    [7, ['Rekisterikilven valo', 'Registerskyltensljus',
      [0, '(4.7) Rekisterikilven valo', '(4.7) Registerskyltens ljus', [0, 58, 74, 95, 108, 257, 269, 295, 296, 644, 803], [0, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1]],
      [1, '(4.7.2) Rekisterikilven valon sijoitus ja kytkentä', '(4.7.2) Registerskyltljusets placering och koppling', [0, 56, 250, 265, 266], [0, 1, 1, 1, 1]],
      [2, '(4.7.1) Rekisterikilven valon kiinnitys', '(4.7.1) Registerskyltljusets infästning', [0, 103], [0, 1]],
      [3, '(4.7) Rekisterikilven valon lasi', '(4.7) Registerskyltljusets glas', [0, 95, 108, 121, 248], [0, 1, 1, 1, 1]]]],
    [8, ['Muut valot ja valaistut kilvet', 'Övr. ljus och upplysta skyltar',
      [0, '(4.2) Äärivalo', '(4.2) Positionsljus', [0, 56, 58, 74, 103, 108, 295, 633, 644], [0, 1, 1, 1, 1, 2, 1, 1, 1]],
      [1, '(4.6) Peruutusvalo', '(4.6) Backljus', [0, 58, 69, 74, 77, 95, 100, 108, 257, 259, 269, 295, 296, 422, 644, 768], [0, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(4.6.2) Peruutusvalon sijoitus', '(4.6.2) Backljusets placering', [0, 56, 265, 266, 268, 644], [0, 1, 1, 1, 1, 1]],
      [3, '(4.6.1) Peruutusvalon kiinnitys', '(4.6.1) Backljusets infästning', [0, 95, 99, 100, 644], [0, 1, 1, 1, 1]],
      [4, '(4.6.1) Peruutusvalon lasi', '(4.6.1) Backljusets glas', [0, 95, 121, 248, 644], [0, 1, 1, 1, 1]],
      [5, '(4.2) Sivuvalo', '(4.2) Sidoljus', [0, 56, 58, 69, 74, 95, 108, 265, 269, 295, 422, 633, 644], [0, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1]],
      [6, '(4.12) Valaistut kilvet', '(4.12) Belysta skyltar', [0, 58, 74, 95, 259, 265, 269, 644], [0, 1, 1, 1, 1, 1, 1, 1]],
      [7, '(4.12) Tunnusvalo', '(4.12) Igenkänningsljus', [0, 58, 74, 95, 103, 108, 259, 265, 269, 295, 644], [0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1]],
      [8, '(4.12) Työ- ja apuvalo', '(4.12) Arbets- och hjälpbelysning', [0, 74, 103, 313, 644], [0, 1, 1, 1, 1]],
      [9, '(4.4) Sivusuuntavalo', '(4.4) Sidkörriktningsljus', [0, 56, 58, 69, 74, 95, 108, 257, 259, 265, 269, 295, 296, 383, 429, 445, 644], [0, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [10, '(4.1)(4.12) Aurauslähivalo', '(4.1)(4.12) Körljus för plogbil', [0, 74, 103, 251, 254, 255, 269, 383, 480, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [11, '(4.1)(4.12) Aurauskaukovalo', '(4.1)(4.12) Fjärrljus för plogbil', [0, 74, 103, 269, 383, 480, 644], [0, 1, 1, 1, 1, 1, 1]],
      [12, '(4.2)(4.12) Aurausetuvalo', '(4.2)(4.12) Framljus för plogbil', [0, 74, 103, 644], [0, 1, 1, 1]],
      [13, '(4.4)(4.12) Auraussuuntavalo', '(4.4)(4.12) Körriktningsljus för plogbil', [0, 74, 383, 644], [0, 1, 1, 1]],
      [14, '(4.12) Työvalo', '(4.12) Arbetsbelysning', [0, 74, 103, 313, 644], [0, 1, 1, 1, 1]],
      [15, '(4.12) Valaistut kilvet ja mainokset', '(4.12) Belysta skyltar och reklam', [0, 179, 644], [0, 1, 1]],
      [16, '(4.12) Muut valaisimet', '(4.12) Övriga lampor', [0, 644], [1, 1]],
      [17, '(4.12) Kulmavalaisin (cornering lamp)', '(4.12) Kurvtagningslykta (cornering lamp)', [0, 74, 77, 95, 103, 644, 768], [1, 1, 1, 1, 1, 1, 1]],
      [18, '(4.12) Ulkovalaisin (exterior courtesy lamp)', '(4.12) Lyktor för exteriör omgivningsbelysning (exterior courtesy lamp)', [0, 269, 644], [1, 1, 1]],
      [19, '(4.12) Apuvalaisin (manoeuvring lamp)', '(4.12) Manövreringslykta (manoeuvring lamp)', [0, 58, 474, 644], [0, 1, 1, 1]]]],
    [9, ['Akku ja johtimet', 'Batteri och ledningar',
      [0, '(4.11)(4.13) Akku ja sähkökytkennät', '(4.11)(4.13) Ackumulatorn och elkopplingarna', [0, 95, 173, 174, 221, 391, 644, 767], [0, 1, 1, 1, 1, 1, 1, 1]],
      [1, '(4.13) Akku', '(4.13) Ackumulatorn', [0, 95, 100, 103, 172, 516], [0, 1, 1, 1, 1, 1]],
      [2, '(4.11) Sähkökytkennät', '(4.11) Elkopplingarna', [0, 172, 181, 644], [0, 1, 1, 1]],
      [3, '(4.11) Konetilan sähkökytkennät', '(4.11) Motorrummets elkopplingar', [0, 172, 181, 644], [0, 1, 1, 1]],
      [4, '(4.10) Perävaunun sähköliitäntä', '(4.10) Dragkopplingens elkopplingar', [0, 172, 181, 182, 265, 477, 478, 479], [0, 1, 1, 1, 1, 1, 1, 1]]]],
    [10, ['Hätävilkut', 'Nödblinkers',
      [1, '(4.4) Hätävilkut', '(4.4) Varningsblinkers', [0, 74, 108], [0, 1, 1]]]],
    [11, ['Heijastin ja heijastavat pinnat', 'Reflektor',
      [1, '(4.8)(4.12) Heijastin', '(4.8)(4.12) Reflex', [0, 56, 95, 103, 108, 269, 295, 467, 569, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(4.8)(4.12) Etuheijastin', '(4.8)(4.12) Framreflex', [0, 56, 95, 103, 108, 269, 295, 467, 569, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [3, '(4.8)(4.12) Sivuheijastin', '(4.8)(4.12) Sidoreflex', [0, 56, 95, 103, 108, 269, 295, 467, 569, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [4, '(4.8)(4.12) Takaheijastin', '(4.8)(4.12) Bakreflex', [0, 56, 95, 103, 108, 269, 295, 467, 569, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [5, '(4.8)(4.12) Heijastavat merkinnät ja mainokset', '(4.8)(4.12) Reflekterande märken och reklam', [0, 103, 108, 644], [0, 1, 2, 1]]]],
    [12, ['Äänimerkinantolaite', 'Signalanordning',
      [1, '(7.7) Äänimerkinantolaite', '(7.7) Signalanordning', [0, 69, 74, 103, 108, 309, 644], [0, 1, 1, 1, 2, 1, 1]],
      [2, '(FIN.C) Peruutushälytin', '(FIN.C) Backningsvarnare', [0, 69, 74, 170, 265, 313], [0, 1, 1, 1, 1, 1]]]],
    [13, ['Ajovalo', 'Körljus',
      [1, '(4.1.1) Ajovaloumpio', '(4.1.1) Lyktinsats', [0, 95, 103, 247, 249, 269, 383, 422, 429], [0, 1, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(4.1.6) Ajovalon pesulaite', '(4.1.6) Lykt tvätt', [0, 69, 74, 95, 103, 108, 506, 644], [0, 1, 1, 1, 1, 1, 1, 1]],
      [3, '(4.1.5) Lähivalon korkeudensäätölaite', '(4.1.5) Närljusets höjdjusteringsanordning', [0, 69, 74, 108, 570, 644, 711, 749, 750, 775], [0, 1, 1, 1, 2, 1, 2, 1, 1, 1]]]],
  ])]],
  [3, ['Ympäristöhaitat', 'Miljöskador', new Map([
    [0, ['Bensiinimoottorin pakokaasumittaus', 'Avgasutsläppning bensinmotor',
      [0, '(8.2) Pakokaasumittaus', '(8.2) Avgasmätning', [0, 319, 320, 321, 322, 323, 486], [0, 2, 2, 2, 2, 2, 2]],
      [1, '(8.2.1.2) CO-pitoisuus, joutokäynnillä', '(8.2.1.2) CO-värde, tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(8.2.1.2) CO-pitoisuus, kierroksilla', '(8.2.1.2) CO-värde, högre tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [3, '(8.2) HC-pitoisuus, joutokäynnillä', '(8.2) HC-värde, tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [4, '(8.2) HC-pitoisuus, kierroksilla', '(8.2) HC-värde, högre tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [5, '(8.2.1.2) Lambda-arvo', '(8.2.1.2) Lambda-värde', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [6, '(8.2) CO2-pitoisuus, joutokäynnillä', '(8.2) CO2-värde, tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [7, '(8.2) CO2-pitoisuus, kierroksilla', '(8.2) CO2-värde, högre tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [8, '(8.2) O2-pitoisuus, joutokäynnillä', '(8.2) O2-värde, tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [9, '(8.2) O2-pitoisuus, kierroksilla', '(8.2) O2-värde, högre tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [10, '(8.2.1.2) Lambda-arvo, joutokäynnillä', '(8.2.1.2) Lambda-värde, tomgång', [0, 142, 143, 148, 149, 150, 152, 153, 154, 155, 156, 157, 158, 159, 160, 526, 621], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [1, ['Dieselmoottorin pakokaasumittaus', 'Avgasutsläppning dieselmotor',
      [0, '(8.2.2.2) Savutus, K-arvo', '(8.2.2.2) Rök, K-värde', [0, 144, 145, 146, 150, 151, 162, 163, 164, 165, 166, 319, 387, 397, 406], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(8.2.2.2) Joutokäynti rpm', '(8.2.2.2) Tomgång rpm', [0, 144, 145, 146, 150, 151, 162, 163, 164, 165, 166, 319, 387, 397, 406], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(8.2.2.2) Maksimi rpm', '(8.2.2.2) Max rpm', [0, 144, 145, 146, 150, 151, 162, 163, 164, 165, 166, 319, 387, 397, 406], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [3, '(8.2.2) Savutusmittaus', '(8.2.2) Rökmätning', [0, 144, 145, 146, 150, 151, 162, 163, 164, 165, 166, 319, 387, 397, 406], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [2, ['Melu', 'Buller',
      [0, '(8.1) Melu', '(8.1) Buller', [0, 336, 371, 576, 577, 578, 644], [0, 2, 2, 2, 2, 2, 2]]]],
    [3, ['Öljyvuodot', 'Oljeläckor',
      [0, '(8.4.1) Öljyvuodot', '(8.4.1) Oljeläckor', [0], [0]],
      [1, '(8.4.1) Moottori', '(8.4.1) Motor', [0, 133, 185, 186, 487, 509, 510, 511, 512, 532], [0, 1, 1, 3, 2, 1, 1, 1, 1, 1]],
      [2, '(8.4.1) Vaihteisto', '(8.4.1) Växellåda', [0, 185, 186], [0, 1, 3]],
      [3, '(8.4.1) Vetopyörästö', '(8.4.1) Differential', [0, 185, 186], [0, 1, 3]],
      [4, '(8.4.1) Ohjaustehostin', '(8.4.1) Styrservo', [0, 185, 186], [0, 1, 3]],
      [5, '(8.4.1) Telinnostaja', '(8.4.1) Boggielyft', [0, 185], [0, 1]],
      [6, '(8.4.1) Kippi', '(8.4.1) Kipp', [0, 185], [0, 1]],
      [7, '(8.4.1) Ohjaustehostimen pumppu', '(8.4.1) Styrservopump', [0, 185, 186], [0, 1, 3]],
      [8, '(8.4.1) Jakovaihteisto', '(8.4.1) Fördelningsväxellåda', [0, 185, 186], [0, 1, 3]]]],
    [4, ['Radiohäiriöt', 'Radiostörningar',
      [1, '(8.3) Radiohäiriöt', '(8.3) Radiostörningar', [0, 19, 336], [0, 1, 1]]]],
    [5, ['Pakokaasupäästöt', 'Avgasutsläpp',
      [1, '(8.2) Pakokaasupäästöt', '(8.2) Avgasutsläpp', [0, 336, 337, 575, 710], [0, 2, 2, 1, 2]],
      [2, '(8.2.1.2) OBD -järjestelmä', '(8.2.1.2) OBD -system', [0, 74, 108, 301, 393, 574], [0, 0, 2, 2, 2, 2]]]],
    [6, ['Polttoainevuodot', 'Bränsleläckor',
      [1, '(6.1.3) Polttoainevuodot', '(6.1.3) Bränsleläckage', [0, 187, 188, 189, 191], [0, 2, 2, 3, 3]]]],
    [7, ['Nestevuodot', 'Vätskeläckor',
      [1, '(8.4.1) Nestevuodot', '(8.4.1) Vätskeläckage', [0, 206, 447, 473, 579, 580], [0, 1, 1, 1, 3, 1]]]],
    [8, ['Sisäinen valvontajärjestelmä (OBD)', 'Fordonsburna övervakningssystemet (OBD)',
      [1, '(8.2.1.2) OBD - järjestelmä / -tiedot', '(8.2.1.2) OBD -system / -data', [0, 108, 393, 574, 617, 618, 619, 620, 622, 623, 624], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
  ])]],
  [4, ['Alusta ja kori', 'Chassi och karosseri', new Map([
    [0, ['Alustan kotelot ja pohjalevy', 'Chassiets balkar och bottenplatta',
      [0, '(6.1.1) Alustan kotelot ja pohjalevy', '(6.1.1) Chassiets balkar och bottenplatta', [0, 65, 88, 90, 92, 93, 520, 644, 703, 705, 733, 744, 751, 752], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2]],
      [1, '(6.1.1) Alustan korjaus', '(6.1.1) Chassiets reparation', [0, 65], [0, 2]],
      [2, '(6.1.1) Helmakotelo', '(6.1.1) Tröskellåda', [0, 88, 95], [0, 2, 2]],
      [3, '(6.1.1) Helmakotelon korjaus', '(6.1.1) Tröskellådans reparation', [0, 63, 65], [0, 2, 2]],
      [4, '(6.1.1) Pohjalevy', '(6.1.1) Bottenplatta', [0, 88, 95], [0, 2, 2]],
      [5, '(6.1.1) Pohjalevyn korjaus', '(6.1.1) Bottenplattans reparation', [0, 63, 65], [0, 2, 2]],
      [6, '(6.1.1) Alustan pitkittäiskotelo', '(6.1.1) Chassiets längsgående balkar', [0, 88, 90, 95, 117], [0, 2, 2, 2, 2]],
      [7, '(6.1.1) Alustan pitkittäiskotelon korjaus', '(6.1.1) Chassiets längsgående balkars reparation', [0, 63, 65, 90], [0, 2, 2, 2]],
      [8, '(6.1.1) Alustan poikittaiskotelo', '(6.1.1) Chassiets tvärgående balkar', [0, 88, 90, 95, 117], [0, 2, 2, 2, 2]],
      [9, '(6.1.1) Alustan poikittaiskotelon korjaus', '(6.1.1) Chassiets tvärgående balkars reparation', [0, 63, 65], [0, 2, 2]],
      [10, '(6.1.1) Pyörän kotelo', '(6.1.1) Hjulhus', [0, 88, 95, 118], [0, 2, 2, 2]],
      [11, '(6.1.1) Pyörän kotelon korjaus', '(6.1.1) Hjulhusets reparation', [0, 63, 65], [0, 2, 2]],
      [12, '(6.1.1) Pitkittäinen takahelmakotelo', '(6.1.1) Längsgående bakre balk', [0, 88, 95], [0, 2, 2]],
      [13, '(6.1.1) Pitkittäisen takahelmakotelon korjaus', '(6.1.1) Längsgående bakre balks reparation', [0, 63, 65], [0, 2, 2]],
      [14, '(6.1.1) Poikittainen takahelmakotelo', '(6.1.1) Tvärsgående bakre balk', [0, 88, 95], [0, 2, 2]],
      [15, '(6.1.1) Poikittaisen takahelmakotelon korjaus', '(6.1.1) Tvärgående bakre balks reparation', [0, 63, 65], [0, 2, 2]],
      [16, '(6.1.1) Poikittainen etuhelmakotelo', '(6.1.1) Tvärsgående främre balk', [0, 88, 95], [0, 2, 2]],
      [17, '(6.1.1) Poikittaisen etuhelmakotelon korjaus', '(6.1.1) Tvärgående främre balks reparation', [0, 63, 65], [0, 2, 2]],
      [18, '(6.1.1) Varapyöräkotelo', '(6.1.1) Reservhjulslåda', [0, 88, 95], [0, 2, 2]]]],
    [1, ['Runko', 'Chassi',
      [0, '(6.1.1) Runko', '(6.1.1) Ram', [0, 64, 88, 90, 95], [0, 2, 2, 2, 2]],
      [1, '(6.1.1) Rungon korjaus', '(6.1.1) Ramens reparation', [0, 64, 65], [0, 2, 2]],
      [2, '(6.1.1) Pitkittäinen runkopalkki', '(6.1.1) Längsgående rambalk', [0, 88, 95, 138, 139], [0, 2, 2, 2, 2]],
      [3, '(6.1.1) Pitkittäisen runkopalkin korjaus', '(6.1.1) Längsgående rambalkens reparation', [0, 63, 65, 66], [0, 2, 2, 2]],
      [4, '(6.1.1) Pitkittäisen runkopalkin kiinnitys', '(6.1.1) Längsgående rambalkens infästning', [0, 63, 65, 66], [0, 2, 2, 2]],
      [5, '(6.1.1) Poikittainen runkopalkki', '(6.1.1) Tvärgående rambalk', [0, 88, 95, 138, 139], [0, 2, 2, 2, 2]],
      [6, '(6.1.1) Poikittaisen runkopalkin korjaus', '(6.1.1) Tvärgående rambalkens reparation', [0, 63, 65, 66], [0, 2, 2, 2]],
      [7, '(6.1.1) Poikittaisen runkopalkin kiinnitys', '(6.1.1) Tvärgående rambalkens infästning', [0, 63, 65, 66], [0, 2, 2, 2]],
      [8, '(6.1.1) Apurunko', '(6.1.1) Hjälpram', [0, 64, 88, 90, 95], [0, 2, 2, 2, 2]],
      [9, '(6.1.1) Apurungon kiinnitys', '(6.1.1) Hjälpramens fastsättning', [0, 15, 95, 100, 293], [0, 2, 2, 2, 2]],
      [10, '(6.1.1) Apurungon korjaus', '(6.1.1) Hjälpramens reparation', [0, 64, 65], [0, 2, 2]]]],
    [2, ['Akseliston kiinnityspalkit', 'Axlarnas infästningsbalkar',
      [0, '(6.1.1) Akseliston kiinnityspalkit', '(6.1.1) Axlarnas infästningsbalkar', [0], [0]],
      [1, '(6.1.1) Etuakselipalkki', '(6.1.1) Framaxelbalk', [0, 88, 95, 117, 138], [0, 2, 2, 2, 2]],
      [2, '(6.1.1) Etuakselipalkin korjaus', '(6.1.1) Framaxelbalkens reparation', [0, 63, 65, 67], [0, 2, 2, 2]],
      [3, '(6.1.1) Etuakselipalkin kiinnitys', '(6.1.1) Framaxelbalkens infästning', [0, 79, 95, 100, 138, 498], [0, 2, 2, 2, 2, 2]],
      [4, '(6.1.1) Taka-akselipalkki', '(6.1.1) Bakaxelbalk', [0, 88, 95, 117, 138], [0, 2, 2, 2, 2]],
      [5, '(6.1.1) Taka-akselipalkin korjaus', '(6.1.1) Bakaxelbalkens reparation', [0, 63, 65, 67], [0, 2, 2, 2]],
      [6, '(6.1.1) Taka-akselipalkin kiinnitys', '(6.1.1) Bakaxelbalkens infästning', [0, 79, 95, 100, 138, 498], [0, 2, 2, 2, 2, 2]],
      [7, '(6.1.1) Vaihteiston palkki', '(6.1.1) Växellådsbalken', [0, 88, 95, 117, 138], [0, 2, 2, 2, 2]],
      [8, '(6.1.1) Vaihteistopalkin korjaus', '(6.1.1) Växellådbalkens reparation', [0, 63, 65, 67], [0, 2, 2, 2]],
      [9, '(6.1.1) Vaihteistopalkin kiinnitys', '(6.1.1) Växellådbalkens infästning', [0, 79, 95, 100, 138], [0, 2, 2, 2, 2]]]],
    [3, ['Pakoputkisto', 'Avgassystem',
      [0, '(6.1.2) Pakoputkisto', '(6.1.2) Avgasrör', [0, 88, 95, 139, 167, 169, 183, 184, 644, 754, 759], [0, 1, 1, 2, 1, 1, 2, 2, 2, 1, 1]],
      [1, '(6.1.2) Pakoputkiston korjaus', '(6.1.2) Avgasrörets reparation', [0, 63, 65, 67], [0, 1, 1, 1]],
      [2, '(6.1.2) Pakoputkiston liitos', '(6.1.2) Avgasrörets anslutning', [0, 63, 183], [0, 1, 2]],
      [4, '(6.1.2) Pakoputkiston kiinnitys', '(6.1.2) Avgasrörets fastsätning', [0, 95, 100, 103, 138, 168], [0, 1, 1, 1, 1, 2]],
      [5, '(6.1.2) Äänenvaimennin', '(6.1.2) Ljuddämpare', [0, 95, 183, 184, 371, 644], [0, 1, 2, 2, 2, 2]],
      [6, '(6.1.2) Katalysaattori', '(6.1.2) Katalysator', [0, 95, 108, 183, 184, 644], [0, 2, 2, 2, 2, 2]],
      [7, '(6.1.2) Pakosarja', '(6.1.2) Avgasserie', [0, 138, 183, 184], [0, 1, 1, 1]]]],
    [4, ['Kori', 'Karosseri',
      [0, '(6.1.1) Kori', '(6.1.1) Karosseri', [0, 88, 90, 92, 93, 95, 231, 522, 523, 524, 571, 587, 644, 751], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1]],
      [1, '(6.1.1) Korin korjaus', '(6.1.1) Karosseriets reparation', [0, 63, 65], [0, 2, 2]],
      [2, '(6.1) Korin kiinnitys', '(6.1) Karosseriets infästning', [0, 88, 95, 99, 100, 427], [0, 1, 1, 1, 2, 2]],
      [3, '(6.1.1) A-pilari', '(6.1.1) A-pelare', [0, 88, 95], [0, 2, 2]],
      [4, '(6.1.1) A-pilarin korjaus', '(6.1.1) A-pelarens reparation', [0, 63, 65], [0, 2, 2]],
      [5, '(6.1.1) B-pilari', '(6.1.1) B-pelare', [0, 88, 95], [0, 2, 2]],
      [6, '(6.1.1) B-pilarin korjaus', '(6.1.1) B-pelarens reparation', [0, 63, 65], [0, 2, 2]],
      [7, '(6.1.1) C-pilari', '(6.1.1) C-pelare', [0, 88, 95], [0, 2, 2]],
      [8, '(6.1.1) C-pilarin korjaus', '(6.1.1) C-pelarens reparation', [0, 63, 65], [0, 2, 2]],
      [9, '(6.1.1) Helmakotelo', '(6.1.1) Tröskellåda', [0, 88, 95, 248], [0, 2, 2, 1]],
      [10, '(6.1.1) Helmakotelon korjaus', '(6.1.1) Tröskellådans reparation', [0, 63, 65], [0, 2, 2]],
      [11, '(6.2.3) Ovi ja saranat', '(6.2.3) Dörr och gångjärnen', [0, 88, 95, 106, 107, 230, 231, 476], [0, 2, 1, 1, 1, 1, 1, 2]],
      [12, '(6.2.3) Oven lukko', '(6.2.3) Dörrlås', [0, 69, 72, 108, 233], [0, 1, 1, 2, 1]],
      [13, '(6.2.10) Roiskesuoja', '(6.2.10) Stänkskärm', [0, 65, 88, 89, 95, 100, 103, 108, 221, 231, 232, 382, 521, 587, 644], [0, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1]],
      [14, '(6.2.10) Roiskesuojan korjaus', '(6.2.10) Stänkskärmens reparation', [0, 63, 65], [0, 1, 1]],
      [15, '(6.1.4) Puskuri', '(6.1.4) Stötfångare', [0, 88, 95, 100, 105, 108, 179, 231, 235], [0, 1, 1, 1, 2, 2, 1, 2, 2]],
      [16, '(6.2.9) Nokkapellin lukitus/turvasalpa', '(6.2.9) Framhuvens låsning/säkerhetsspärr', [0, 74, 108], [0, 1, 1]],
      [17, '(6.2.9) Etukansi', '(6.2.9) Framlucka', [0, 95, 100, 106, 107, 179, 231, 482], [0, 1, 1, 1, 1, 1, 1, 1]],
      [18, '(6.2.9) Takakansi', '(6.2.9) Baklucka', [0, 95, 100, 106, 107, 179, 231, 482], [0, 1, 1, 1, 1, 1, 1, 1]],
      [19, '(6.1.5) Varapyöräteline', '(6.1.5) Reservhjulsställning', [0, 103, 179, 370], [0, 1, 2, 1]],
      [20, '(6.1) Sisälokasuoja', '(6.1) Innerstänkskärm', [0, 95, 100, 179, 446], [0, 2, 1, 1, 1]],
      [21, '(6.1) Sisälokasuojan korjaus', '(6.1) Innerstänkskärms reparation', [0, 63, 446], [1, 1, 2]],
      [22, '(6.1) Joustintuen kotelo', '(6.1) Fjäderbenets infästning', [0, 88, 95, 118], [0, 2, 2, 2]],
      [23, '(6.1.6) Hinauslaite', '(6.1.6) Bogseranordning', [0, 95, 108], [0, 1, 1]],
      [24, '(9.1.2) Hätäuloskäynti', '(9.1.2) Reservutgång', [0, 108, 221], [0, 2, 1]],
      [25, '(6.2.10) Roiskeenestojärjestelmä', '(6.2.10) Stänkskyddsanordningar', [0, 95, 100, 103, 108, 221, 644], [1, 1, 1, 1, 1, 1, 1]],
      [26, '(6.2) Lasikatto', '(6.2) Glastak', [0, 108, 771, 825], [0, 2, 2, 1]]]],
    [5, ['Polttoainesäiliö ja -putket', 'Bränsletank och -ledningar',
      [0, '(6.1.3) Polttoainesäiliö ja -putket', '(6.1.3) Bränsletank och -ledningar', [0], [0]],
      [1, '(6.1.3) Polttoainesäiliö', '(6.1.3) Bränsletank', [0, 88, 95, 103, 174, 183, 187, 189, 190, 585, 644, 784], [0, 1, 1, 1, 3, 2, 2, 3, 1, 2, 2, 2]],
      [2, '(6.1.3) Polttoainesäiliön kiinnitys', '(6.1.3) Bränsletankens fastsättning', [0, 68, 88, 95, 100], [0, 1, 1, 1, 2]],
      [3, '(6.1.3) Polttoaineputkisto', '(6.1.3) Bränsle rörsystem', [0, 103, 174, 183, 187, 188, 189, 191, 644, 784], [0, 1, 3, 2, 2, 2, 3, 3, 2, 2]],
      [4, '(6.1.3) Polttoaineputkiston kiinnitys', '(6.1.3) Bränsle rörsystemets fastsättning', [0, 68, 95, 100], [0, 1, 1, 1]]]],
    [6, ['Suojarakenteet', 'Skyddskonstruktioner',
      [1, '(6.1.4) Puskuri', '(6.1.4) Stötfångare', [0, 88, 95, 100, 105, 108, 179, 231, 235], [0, 1, 1, 1, 2, 2, 1, 1, 2]],
      [2, '(6.1.4) Alleajosuoja', '(6.1.4) Underkörningsskydd', [0, 95, 103, 108, 336, 644], [0, 2, 2, 2, 2, 2]],
      [3, '(6.1.4) Sivusuoja', '(6.1.4) Sidoskydd', [0, 95, 103, 108, 336, 644], [0, 2, 2, 2, 2, 2]],
      [4, '(6.2.9) Äänieristyssuojaus', '(6.2.9) Ljudisoleringsskydd', [0, 95, 108, 221, 577], [0, 1, 1, 1, 1]],
      [5, '(6.1) Pohjapanssari', '(6.1) Bottenpansar', [0, 103, 179], [0, 1, 1]],
      [6, '(6.1) Karjapuskuri', '(6.1) Hjortbåge', [0, 179, 313], [0, 1, 2]]]],
    [7, ['Kuormakori', 'Lastkorg',
      [0, '(6.2) Kuormakori', '(6.2) Lastutrymme', [0, 95, 231, 235, 338, 339, 408, 414, 644], [0, 2, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(6.2) Kuormakorin kiinnitys', '(6.2) Lastutrymmets fastsättning', [0, 79, 88, 95, 99, 100, 103, 233, 336], [0, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(6.2) Kuormansidontapiste', '(6.2) Lastfästningspunkt', [0, 95, 108, 336], [0, 2, 2, 2]],
      [3, '(6.2) Kuormakorin etuseinä', '(6.2) Lastkorgens framvägg', [0, 95, 108, 221, 336], [0, 2, 2, 2, 2]],
      [4, '(6.2) Kilpi', '(6.2) Skylt', [0, 95, 108], [0, 2, 2]]]],
    [8, ['Muut alustan ja korin rakenteet', 'Chassiets och karosseriets övriga konstruktioner',
      [0, '(6.1) Muut alustan ja korin rakenteet', '(6.1) Chassiets och karosseriets övriga konstruktioner', [0], [0]],
      [1, '(6.2.10) Roiskeläppä', '(6.2.10) Stänkskydd', [0, 37, 100, 103, 108, 232, 278, 332, 340, 358], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]]]],
    [9, ['Alustan suojaus', 'Chassiets rostskydd',
      [0, '(FIN.D10) Alustan suojaus', '(FIN.D10) Underredets rostskyddsbehandling', [0, 95, 221], [0, 0, 0]]]],
  ])]],
  [5, ['Akselistot, pyörät ja jousitus', 'Axlar, däck och fjädring', new Map([
    [0, ['Etuakselisto', 'Framvagn',
      [0, '(5.1) Etuakselisto', '(5.1) Framvagn', [0, 79, 80, 81, 82, 85, 87, 644, 735], [0, 2, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(5.3) Alatukivarren pallonivel', '(5.3) Nedre länkarmens kulled', [0, 79, 217], [0, 2, 2]],
      [2, '(5.3) Alatukivarren pallonivelen kiinnitys', '(5.3) Nedre länkarmens kulleds infästning', [0, 15, 99, 100, 104, 179], [0, 2, 2, 2, 1, 2]],
      [3, '(5.3) Alatukivarren pallonivelen suojakumi', '(5.3) Nedre länkarmens kulleds skyddsgummi', [0, 95, 100, 108], [0, 1, 1, 1]],
      [4, '(5.3) Alatukivarren pallonivelen suojakumin kiinnitys', '(5.3) Nedre länkarmens kulleds skyddsgummits fastsättning', [0, 95, 100], [0, 1, 1]],
      [5, '(5.3.3) Alatukivarsi', '(5.3.3) Nedre länkarm', [0, 6, 63, 66, 88, 89, 94, 95, 118, 138, 139], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3]],
      [6, '(5.3) Alatukivarren laakerointi', '(5.3) Nedre länkarmens lagring', [0, 79, 88, 95, 179], [0, 2, 2, 2, 2]],
      [7, '(5.3.3) Alatukivarren pitkittäistuenta', '(5.3.3) Nedre länkarmens lagrings infästning', [0, 79, 88, 95, 100, 101, 179], [0, 2, 2, 2, 3, 2, 2]],
      [8, '(5.3.3) Alatukivarren vinotuenta', '(5.3.3) Nedre länkarmens snedstag', [0, 79, 88, 95, 100, 101, 179], [0, 2, 2, 2, 2, 2, 2]],
      [9, '(5.3.3) Alatukivarren kiinnityspiste', '(5.3.3) Nedre länkarmens fästpunkt', [0, 63, 88, 95, 118, 138], [0, 2, 2, 2, 2, 2]],
      [10, '(5.3) Ylätukivarren pallonivel', '(5.3) Övre länkarmens kulled', [0, 79, 217], [0, 2, 2]],
      [11, '(5.3) Ylätukivarren pallonivelen kiinnitys', '(5.3) Övre länkarmens kulleds infästning', [0, 15, 99, 100, 104, 179], [0, 2, 2, 2, 1, 2]],
      [12, '(5.3) Ylätukivarren pallonivelen suojakumi', '(5.3) Övre länkarmens kulleds skyddsgummi', [0, 95, 100, 108], [0, 1, 1, 1]],
      [13, '(5.3) Ylätukivarren pallonivelen suojakumin kiinnitys', '(5.3) Övre länkarmens kulleds skyddsgummits fastsättning', [0, 15, 99, 100, 104, 179], [0, 1, 1, 1, 1, 1]],
      [14, '(5.3.3) Ylätukivarsi', '(5.3.3) Övre länkarm', [0, 6, 63, 66, 88, 89, 94, 95, 118, 138, 139], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3]],
      [15, '(5.3) Ylätukivarren laakerointi', '(5.3) Övre länkarmens lagring', [0, 79, 88, 95, 179], [0, 2, 2, 2, 2]],
      [16, '(5.3.3) Ylätukivarren pitkittäistuenta', '(5.3.3) Övre länkarmens längsgående infästning', [0, 79, 88, 95, 100, 101, 179], [0, 2, 2, 2, 2, 2, 2]],
      [18, '(5.3.3) Ylätukivarren kiinnityspiste', '(5.3.3) Övre länkarmens fästpunkt', [0, 63, 88, 95, 118, 138], [0, 2, 2, 2, 2, 2]],
      [19, '(5.3.3) Kallistuksen vakaaja', '(5.3.3) Krängningsstag', [0, 95, 108, 138, 139, 286], [0, 1, 2, 1, 1, 1]],
      [20, '(5.3.1) Kallistuksen vakaajan kiinnitys', '(5.3.1) Krängningsstagets infästning', [0, 79, 99, 100], [0, 1, 1, 1]],
      [21, '(5.1.3) Etupyörän laakerointi', '(5.1.3) Framhjulets lagring', [0, 79, 114, 133, 217], [0, 2, 1, 2, 2]],
      [22, '(5.2.2) Etupyörä', '(5.2.2) Framhjul', [0, 60, 61, 62, 135, 136], [0, 2, 2, 1, 2, 2]],
      [23, '(5.1.2) Olkatappi', '(5.1.2) Kungsbult', [0, 79, 95, 217], [0, 2, 2, 2]],
      [24, '(5.3.3) Yhdysvarsi', '(5.3.3) Förbindelstag', [0, 95, 409], [0, 2, 2]],
      [25, '(2.4)(5.1)(5.3) Pyörän asentokulma', '(2.4)(5.1)(5.3) Hjulets inställningsvinkel', [0, 475], [0, 2]]]],
    [1, ['Taka-akselisto', 'Bakvagn',
      [0, '(5.1) Taka-akselisto', '(5.1) Bakvagn', [0, 60, 61, 79, 474, 475, 644, 735], [0, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(5.3.3) Takatukivarsi', '(5.3.3) Baklänkarm', [0, 63, 66, 88, 89, 94, 95, 118, 138, 139], [0, 2, 2, 2, 2, 2, 2, 2, 2, 3]],
      [2, '(5.3) Takatukivarren laakerointi', '(5.3) Baklänkarmens lagring', [0, 79, 88, 95, 179], [0, 2, 2, 2, 2]],
      [3, '(5.3.3) Takatukivarren kiinnityspiste', '(5.3.3) Baklänkarmens fästpunkt', [0, 63, 88, 95, 138], [0, 2, 2, 2, 2]],
      [4, '(5.3.3) Taka-akselin tukitanko', '(5.3.3) Bakaxelns stödstång', [0, 63, 88, 89, 95, 118, 138, 139], [0, 2, 2, 2, 2, 2, 2, 2]],
      [5, '(5.3) Taka-akselin tukitangon laakerointi', '(5.3) Bakaxel stödstångens lagring', [0, 79, 99, 100], [0, 2, 2, 3]],
      [6, '(5.3.3) Taka-akselin tukitangon kiinnityspiste', '(5.3.3) Bakaxel Stödstångens fästpunkt', [0, 63, 88, 95, 118, 138], [0, 2, 2, 2, 2, 2]],
      [7, '(5.1) Taka-akseli', '(5.1) Bakaxel', [0, 26, 59, 60, 61, 88, 95, 99, 130, 474, 475], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [8, '(5.1.1) Taka-akselin tuenta', '(5.1.1) Bakaxelns stödning', [0, 79, 88, 89, 95, 100, 118], [0, 2, 2, 2, 2, 3, 2]],
      [9, '(5.3) Taka-akselin tuennan laakerointi', '(5.3) Bakaxel stödningens lagring', [0, 79, 100], [0, 2, 3]],
      [10, '(5.3.3) Taka-akselin tuennan kiinnityspiste', '(5.3.3) Bakaxel stödningens fästpunkt', [0, 63, 88, 95, 118], [0, 2, 2, 2, 2]],
      [11, '(5.3) Takatukivarren pallonivel', '(5.3) Bak länkarmens kulled', [0, 79, 217], [0, 2, 2]],
      [12, '(5.3) Takatukivarren pallonivelen kiinnitys', '(5.3) Bak länkarm kulledens infästning', [0, 95, 100], [0, 2, 3]],
      [13, '(5.3) Takatukivarren pallonivelen suojakumi', '(5.3) Bak länkarm kulledens skyddsgummi', [0, 95, 100, 108], [0, 1, 1, 1]],
      [14, '(5.3) Takatukivarren pallonivelen suojakumin kiinnitys', '(5.3) Bak länkarm kulled skyddsgummits infästning', [0, 79, 217], [0, 1, 1]],
      [15, '(5.1.3) Takapyörän laakerointi', '(5.1.3) Bakhjulets lagring', [0, 79, 95, 114, 133, 217, 269], [0, 2, 2, 1, 2, 2, 2]],
      [16, '(5.2.2) Takapyörä', '(5.2.2) Bakhjul', [0, 60, 61, 62, 135, 136], [0, 2, 2, 1, 2, 2]],
      [17, '(5.3.3) Kallistuksen vakaaja', '(5.3.3) Krängningsstag', [0, 95, 108, 138, 139, 286], [0, 1, 2, 1, 1, 1]],
      [18, '(5.3.1) Kallistuksen vakaajan kiinnitys', '(5.3.1) Krängningsstagets infästning', [0, 79, 99, 100], [0, 1, 1, 1]],
      [19, '(5.3.3) Alatukivarsi', '(5.3.3) Nedre bärarm', [0, 63, 88, 102, 103, 108, 356, 505], [0, 2, 2, 2, 2, 3, 2, 2]],
      [20, '(5.3.3) Vinotukivarsi', '(5.3.3) Snedstag', [0, 63, 88, 102, 103, 108, 356, 505], [0, 2, 2, 2, 2, 2, 2, 2]],
      [21, '(5.3.3) Ylätukivarsi', '(5.3.3) Övre bärarm', [0, 63, 88, 102, 103, 108, 356, 505], [0, 2, 2, 2, 2, 3, 2, 2]],
      [22, '(5.3.3) Poikittainen tukivarsi', '(5.3.3) Tvärgående stödstag', [0, 26, 88, 91, 95, 103, 108, 356, 409, 505], [0, 2, 2, 2, 2, 2, 3, 2, 2, 2]],
      [23, '(2.4)(5.1)(5.3) Pyörän asentokulma', '(2.4)(5.1)(5.3) Hjulvinklar', [0, 475], [0, 2]],
      [24, '(5.3.3) Pitkittäinen tukivarsi', '(5.3.3) Längsgående stödstag', [0, 26, 88, 91, 95, 103, 108, 356, 409, 505], [0, 2, 2, 2, 2, 2, 3, 2, 2, 2]]]],
    [2, ['Jousitus ja iskunvaimennus', 'Fjädring och stötdämpning',
      [0, '(5.3) Jousitus ja iskunvaimennus', '(5.3) Fjädring och stötdämpning', [0, 25, 74, 132, 137, 168, 217, 220, 407], [1, 2, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(5.3.1) Jousi', '(5.3.1) Fjäder', [0, 25, 26, 74, 95, 130, 137, 139, 141, 178, 214, 217, 277, 407, 427, 428, 475, 548, 644, 725, 738, 739, 742, 755, 764], [0, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 1, 2]],
      [2, '(5.3.1) Jousilehti', '(5.3.1) Fjäderblad', [0, 25, 115, 139, 141, 178, 427, 428, 475, 581, 582], [0, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2]],
      [3, '(5.3.1) Päälehti', '(5.3.1) Huvudblad', [0, 25, 115, 139, 141, 178], [0, 2, 2, 3, 2, 2]],
      [4, '(5.3.1) U-pultti', '(5.3.1) U-bult', [0, 15, 108, 139], [0, 2, 2, 2]],
      [5, '(5.3.4) Jousituksen laakerointi', '(5.3.4) Fjädringens lagring', [0, 79, 95, 99, 100, 344, 345], [0, 2, 2, 2, 2, 2, 2]],
      [6, '(5.3) Jousen lautanen', '(5.3) Fjäder tallrik', [0, 64, 66, 88, 89, 95, 214], [0, 2, 2, 2, 2, 2, 2]],
      [7, '(5.3) Joustintuki', '(5.3) Fjäderben', [0, 79, 88, 95, 100, 138], [0, 2, 2, 2, 2, 2]],
      [8, '(5.3) Joustintuen laakerointi', '(5.3) Fjäderbenets lagring', [0, 79, 95, 217], [0, 2, 2, 2]],
      [9, '(5.3) Joustonrajoitin', '(5.3) Fjädringsbegränsare', [0, 91, 95, 108, 548], [0, 1, 1, 1, 1]],
      [10, '(5.3.1) Kaasu-nestejousitus', '(5.3.1) Gas/vätskefjäder', [0, 74, 183, 217, 245, 407, 425], [0, 2, 2, 2, 2, 2, 2]],
      [11, '(5.3.1) Kaasu-nestejousituksen putkisto', '(5.3.1) Gas/vätskefjädringens rörsystem', [0, 64, 88, 89, 95, 183], [0, 2, 2, 2, 2, 2]],
      [14, '(5.3.2) Heilahduksenvaimennin', '(5.3.2) Stötdämpare', [0, 96, 100, 108, 132, 139, 183, 217, 408, 583, 644, 756, 758], [0, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2]],
      [15, '(5.3.2) Heilahduksenvaimentimen kiinnitys', '(5.3.2) Stötdämparnas infästning', [0, 79, 91, 99, 100], [0, 2, 2, 2, 2]],
      [16, '(5.3.5) Ilmajousi', '(5.3.5) Luftfjäder', [0, 103, 183, 340, 341, 342, 343, 407], [0, 2, 2, 2, 2, 2, 2, 2]],
      [17, '(5.3.4) Riipuke', '(5.3.4) Fjäderhänke', [0, 95, 108, 408, 409], [0, 3, 3, 2, 2]],
      [18, '(5.3.2) Iskunvaimentimen männänvarsi', '(5.3.2) Stötdämparns kolvstång', [0, 95, 356, 409, 583], [0, 2, 2, 2, 2]]]],
    [3, ['Renkaat ja vanteet', 'Däck och fälgar',
      [0, '(5.2) Pyörä', '(5.2) Hjul', [0, 103, 346, 400, 644, 730], [0, 2, 2, 2, 2, 2]],
      [1, '(5.2) Rengas', '(5.2) Däck', [0, 95, 122, 123, 126, 127, 128, 129, 131, 391, 430, 501, 595, 644, 666, 746, 763], [0, 2, 2, 2, 3, 2, 2, 2, 2, 2, 1, 2, 2, 2, 1, 2, 1]],
      [2, '(5.2.2) Vanne', '(5.2.2) Fälg', [0, 95, 118, 124, 125, 286, 391, 644], [0, 2, 2, 2, 2, 2, 2, 2]]]],
    [4, ['Akselisto', 'Axel',
      [0, '(5.3.3) Reaktiotanko', '(5.3.3) Reaktionsstag', [0, 88, 95, 139, 356], [0, 2, 2, 2, 2]],
      [1, '(5.3) Reaktiotangon laakerointi', '(5.3) Reaktionsstagets lagring', [0, 79, 95], [0, 2, 2]],
      [2, '(2.4)(5.1)(5.3) Pyörän asentokulmat', '(2.4)(5.1)(5.3) Hjulets inställningsvinkel', [0, 475], [0, 2]]]],
    [5, ['Teli', 'Boggi',
      [1, '(5.1) Teli', '(5.1) Boggi', [0], [0]],
      [2, '(5.1) Nostosylinteri', '(5.1) Lyftcylinder', [0, 103, 601], [0, 1, 2]]]],
  ])]],
  [6, ['Ohjauslaitteet', 'Styrinrättning', new Map([
    [0, ['Ohjausnivelet ja -tangot', 'Styrleder och -stag',
      [0, '(2.1.3) Ohjausnivelet ja tangot', '(2.1.3) Styrleder och -stag', [0, 79, 83, 84, 86, 391, 628, 630], [0, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(2.1.3) Raidetangon pää', '(2.1.3) Styrstagets led', [0, 79, 95, 217, 391, 628, 630], [0, 2, 2, 2, 2, 2, 2]],
      [2, '(2.1.3) Raidetangon pään kiinnitys', '(2.1.3) Styrstagsledens fastsättning', [0, 79, 95, 100, 104], [0, 2, 2, 2, 1]],
      [3, '(2.1.3) Raidetangon pään suojakumi', '(2.1.3) Styrstagsledens skyddsgummi', [0, 95, 100, 108, 118], [0, 1, 1, 1, 1]],
      [4, '(2.1.3) Raidetangon pään suojakumin kiinnitys', '(2.1.3) Styrstagsledens skyddsgummits fastsättning', [0, 95, 100, 108, 118], [0, 1, 1, 1, 1]],
      [5, '(2.1.3) Raidetanko', '(2.1.3) Styrstag', [0, 95, 286, 391, 628, 630, 644], [0, 2, 2, 2, 2, 2, 2]],
      [6, '(2.3)(2.1.3) Keskimmäisen välitangon nivel', '(2.3)(2.1.3) Mittersta styrstagets led', [0, 79, 95, 391], [0, 2, 2, 2]],
      [7, '(2.1.3) Keskimmäisen välitangon nivelen kiinnitys', '(2.1.3) Mittersta styrstagsledens fastsättning', [0, 79, 95, 391], [0, 2, 2, 2]],
      [8, '(2.1.3) Keskimmäinen välitanko', '(2.1.3) Mittersta mellanstag', [0, 95, 286, 391], [0, 2, 2, 2]],
      [9, '(2.1.3) Keskimmäisen välitangon nivelen suojakumi', '(2.1.3) Mittersta mellanstag kulledens skyddsgummi', [0, 79, 95, 391], [0, 1, 1, 1]],
      [10, '(2.1.3) Keskimmäisen välitangon nivelen suojakumin kiinnitys', '(2.1.3) Mittersta mellanstag kulled skyddsgummits fastsättning', [0, 79, 95, 391], [0, 1, 1, 1]],
      [11, '(2.1.3) Apusimpukka', '(2.1.3) Hjälpsnäcka', [0, 79, 95, 217], [0, 2, 2, 2]],
      [12, '(2.1.3) Apusimpukan kiinnitys', '(2.1.3) Hjälpsnäckans infästning', [0, 15, 100], [0, 2, 2]],
      [13, '(2.1.3) Apusimpukan kiinnityspisteet', '(2.1.3) Hjälpsnäckans fästpunkter', [0, 63, 88, 95, 118, 391], [0, 2, 2, 2, 2, 2]],
      [14, '(2.1.3) Ohjausvarsi', '(2.1.3) Styrarm', [0, 6, 64, 67, 95, 101, 103, 118, 286, 391], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [15, '(2.1.3) Ohjausakseli', '(2.1.3) Rattaxel', [0, 6, 67, 95, 99, 217, 220, 391, 448, 644], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [16, '(2.3)(2.1.3) Ohjausakselin ristikkonivel', '(2.3)(2.1.3) Rattaxelns korsled', [0, 79, 95, 644], [0, 2, 2, 2]],
      [17, '(2.1.3) Ohjausakselin ristikkonivelen kiinnitys', '(2.1.3) Rattaxelns korsledens fastsättning', [0, 79, 95, 644], [0, 2, 2, 2]],
      [18, '(2.3)(2.1.3) Ohjausakselin kuminivel', '(2.3)(2.1.3) Rattaxelns gummiled', [0, 79, 95, 99, 118], [0, 2, 2, 2, 2]],
      [19, '(2.1.3) Ohjausakselin kuminivelen kiinnitys', '(2.1.3) Rattaxel gummiledens fastsättning', [0, 79, 99, 100], [0, 2, 2, 2]],
      [20, '(2.1.3) Ohjauksen välitanko', '(2.1.3) Styrinrättningens mellanstag', [0, 95, 286, 391], [0, 2, 2, 2]],
      [21, '(2.3)(2.1.3) Ohjauksen välitangon nivel', '(2.3)(2.1.3) Styrinrättningens mellanstagets led', [0, 79, 95], [0, 2, 2]],
      [22, '(2.1.3) Ohjauksen välitangon nivelen kiinnitys', '(2.1.3) Styrinrättning mellanstag ledens fastsättning', [0, 79, 95], [0, 2, 2]],
      [23, '(2.1.3) Ohjauksen välitangon nivelen suojakumi', '(2.1.3) Styrinrättning mellanstag ledens skyddsgummi', [0, 79, 95], [0, 1, 1]],
      [24, '(2.1.3) Ohjauksen välitangon nivelen suojakumin kiinnitys', '(2.1.3) Styrinrättning mellanstag led skyddsgummits fastsättning', [0, 79, 95], [0, 1, 1]],
      [25, '(2.1.4) Ohjauksen rajoitin', '(2.1.4) Styrningsbegränsare', [0, 69, 108, 135, 136], [0, 2, 2, 2, 2]],
      [26, '(2.1.3) Kääntövarsi', '(2.1.3) Svängarm', [0, 6, 95, 100, 101, 103, 286, 391], [0, 2, 2, 2, 2, 2, 2, 2]],
      [27, '(2.2.2) Ohjausvaimennin', '(2.2.2) Styrdämpare', [0, 95, 108, 132, 183], [0, 2, 2, 2, 2]],
      [28, '(2.2.2) Ohjausvaimentimen kiinnitys', '(2.2.2) Styrdämparens fastsättning', [0, 15, 95, 99, 100], [0, 2, 2, 2, 2]]]],
    [1, ['Ohjausvaihde', 'Styrväxel',
      [0, '(2.1.1)(2.1.2) Ohjausvaihde', '(2.1.1)(2.1.2) Styrväxel', [0, 79, 95, 133, 185, 217, 220, 644], [0, 2, 2, 1, 1, 2, 2, 2]],
      [1, '(2.1.1) Hammastanko', '(2.1.1) Styrväxel av kuggstångstyp', [0, 79, 95, 133, 185, 217, 220, 644], [0, 2, 2, 1, 1, 2, 2, 2]],
      [2, '(2.1.1) Hammastangon kiinnitys', '(2.1.1) Kuggstångens fastsättning', [0, 95, 100, 101], [0, 2, 2, 2]],
      [3, '(2.1.1) Hammastangon suojakumi', '(2.1.1) Kuggstångens skyddsgummi', [0, 95, 108, 118], [0, 1, 1, 1]],
      [4, '(2.1.1) Hammastangon suojakumin kiinnitys', '(2.1.1) Kuggstångens skyddsgummits fastsättning', [0, 100], [0, 1]],
      [5, '(2.1.1) Hammastangon kiinnityspisteet', '(2.1.1) Kuggstångens fästpunkter', [0, 63, 88, 95, 118], [0, 2, 2, 2, 2]],
      [6, '(2.1.1) Simpukka', '(2.1.1) Styrväxel av snäcktyp', [0, 79, 95, 133, 185, 217, 220, 644], [0, 2, 2, 1, 1, 2, 2, 2]],
      [7, '(2.1.2) Simpukan kiinnitys', '(2.1.2) Styrväxelns fastsättning', [0, 79, 100], [0, 2, 2]],
      [8, '(2.1.1) Simpukan kiinnityspisteet', '(2.1.1) Styrväxelns fästpunkter', [0, 79, 100], [0, 2, 2]],
      [9, '(2.1.5)(2.6) Ohjaustehostin', '(2.1.5)(2.6) Styrservo', [0, 69, 74, 133, 185, 186, 217, 292, 393, 574, 644], [0, 2, 2, 1, 1, 3, 2, 2, 2, 2, 2]],
      [10, '(2.1.1) Ohjaustehostimen putkisto', '(2.1.1) Styrservons rörsystem', [0, 95, 118, 185], [0, 1, 1, 1]],
      [11, '(2.1.1) Ohjauslaitteet', '(2.1.1) Styrinrättning', [0, 24, 25, 26, 37, 628, 630, 644], [0, 2, 2, 2, 2, 2, 2, 2]]]],
    [2, ['Ohjauslaitteet', 'Styrinrättning',
      [1, '(2.5) Kääntökehä', '(2.5) Svängkrans', [0, 79, 95, 103, 115, 346, 348, 644, 736], [0, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(2.1.3) Ohjauslaitteisto', '(2.1.3) Styrinrättning', [0, 83, 84, 272, 393, 584, 629, 630, 644], [0, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [3, ['Ohjautuva akselisto', 'Styrande axel',
      [1, '(2.1) Ohjautuva akseli', '(2.1) Styrande axel', [0, 233, 287, 482, 598, 644], [0, 2, 2, 2, 2, 2]]]],
  ])]],
  [7, ['Muut laitteet ja varusteet', 'Övriga anordningar', new Map([
    [0, ['Muut laitteet ja varusteet', 'Övriga anordningar',
      [1, '(6.2.9) Takalaitanostin', '(6.2.9) Baklämslyft', [0, 95, 102, 103, 185, 233, 234, 644, 700, 771], [0, 2, 2, 2, 1, 2, 2, 2, 1, 2]],
      [2, '(6.2.9) Sivulaitanostin', '(6.2.9) Sidlämslyft', [0, 95, 102, 103, 185, 233, 234, 644, 700, 771], [0, 2, 2, 2, 1, 1, 1, 2, 1, 2]],
      [3, '(6.2.9) Kuormausnosturi', '(6.2.9) Lastkran', [0, 95, 102, 103, 185, 233, 234, 644, 700, 771], [0, 2, 2, 2, 1, 1, 1, 2, 1, 2]],
      [4, '(FIN.C14.6) Pyörätuolinostin ja kulkuluiska', '(FIN.C14.6 ) Rullstolslyft och ramper', [0, 95, 103, 644, 771, 805, 806, 807], [0, 1, 1, 2, 2, 2, 1, 2]]]],
    [1, ['Voimansiirto', 'Kraftöverföring',
      [0, '(6.1.7) Voimansiirto', '(6.1.7) Kraftöverföring', [0, 133, 222, 223, 224, 225, 226, 227, 228, 364, 365, 372, 373, 418, 457, 513, 514, 515, 535, 649, 650], [0, 1, 2, 2, 2, 2, 2, 2, 2, 1, 1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 2]],
      [1, '(6.1.7) Vetonivel', '(6.1.7) Drivled', [0, 79, 133], [0, 2, 2]],
      [2, '(6.1.7) Vetonivelen suojakumi', '(6.1.7) Drivledens skyddsgummi', [0, 95, 108, 343], [0, 1, 1, 1]],
      [3, '(6.1.7) Vetonivelen suojakumin kiinnitys', '(6.1.7) Drivled skyddsgummits fastsättning', [0, 95, 100], [0, 1, 1]],
      [4, '(6.1.7) Vetoakseli', '(6.1.7) Drivaxel', [0, 95], [0, 2]],
      [5, '(6.1.7) Vetoakselin kiinnitys', '(6.1.7) Drivaxelns fastsättning', [0, 15, 100, 101], [0, 2, 2, 2]],
      [6, '(6.1.7) Kardaaniakselin tukilaakeri', '(6.1.7) Kardanaxelns bärlager', [0, 79, 95], [0, 2, 2]],
      [7, '(6.1.7) Kardaaniakselin nivel', '(6.1.7) Kardanaxelns kardanknut', [0, 79, 95, 133], [0, 2, 2, 1]],
      [8, '(6.1.7) Vaihteiston kiinnitys', '(6.1.7) Växellådans fastsättning', [0, 95, 221], [0, 2, 2]],
      [9, '(6.1.7) Vetopyörästön kiinnitys', '(6.1.7) Diffrentialväxelns fastsättning', [0, 95, 221], [0, 2, 2]]]],
    [2, ['Turvavyöt ja -varusteet', 'Säkerhetsbälten och -utrustning',
      [0, '(7.1) Turvavyöt ja -varusteet', '(7.1) Säkerhetsbälten och -utrustning', [0], [0]],
      [1, '(7.1) Turvavyö', '(7.1) Säkerhetsbälte', [0, 95, 108, 269, 336, 644], [0, 2, 2, 2, 2, 2]],
      [2, '(7.1.1) Turvavyön kiinnitys', '(7.1.1) Säkerhetsbältets fastsättning', [0, 15, 95, 98, 99, 336], [0, 2, 2, 2, 2, 2]],
      [3, '(7.1.2) Kelauslaite', '(7.1.2) Rullanordning', [0, 70, 74, 95], [0, 2, 2, 2]],
      [4, '(7.1.2) Turvavyön nauha', '(7.1.2) Säkerhetsbältets rem', [0, 95, 97, 139], [0, 2, 2, 2]],
      [5, '(7.1.2) Turvavyön lukko', '(7.1.2) Säkerhetsbältets lås', [0, 69, 72, 74, 108], [0, 2, 2, 2, 2]],
      [6, '(7.1.4) Turvavyön kiristimet', '(7.1.4) Säkerhetsbältets spännare', [0, 19, 71, 130, 272, 297, 300, 301, 442, 444], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [7, '(7.1.5) Turvatyyny', '(7.1.5) Krockkudde', [0, 19, 71, 95, 108, 130, 272, 297, 300, 301, 393, 442, 443, 644], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [8, '(7.1.5) Sivuturvatyyny', '(7.1.5) Sido krockkudde', [0, 19, 71, 95, 108, 130, 272, 297, 300, 301, 393, 442, 443, 644], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [9, '(7.1.1) Turvavyön kiinnityspiste', '(7.1.1) Säkerhetsbältets fastsättningspunkt', [0, 70, 88, 108, 336, 644], [0, 2, 2, 2, 2, 2]]]],
    [3, ['Kytkentälaitteet', 'Kopplingsanordningar',
      [0, '(6.1.6) Vetopää', '(6.1.6) Dragkoppling', [0, 101, 115, 233, 269, 598], [0, 2, 2, 2, 2, 2]],
      [1, '(6.1.6) Vetokoukun kiinnitys', '(6.1.6) Dragkrokens fastsättning', [0, 15, 56, 63, 65, 88, 95, 99, 100, 138], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(6.1.6) Vetokoukun kiinnityksen korjaus', '(6.1.6) Dragkroks fastsättningens reparation', [0, 63, 65, 66], [0, 2, 2, 2]],
      [3, '(6.1.6) Vetopalkki', '(6.1.6) Dragbalk', [0, 19, 95, 102, 291], [0, 2, 2, 2, 2]],
      [4, '(6.1.6) Vetokytkin', '(6.1.6) Dragkoppling', [0, 19, 56, 95, 102, 115, 291, 353, 601, 602], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [5, '(6.1.6) Vetokytkimen tappi', '(6.1.6) Dragkopplingens låstapp', [0, 115], [0, 2]],
      [6, '(6.1.6) Vetokytkimen kara', '(6.1.6) Dragkopplingens fäst axel', [0, 352, 354, 355, 599, 600], [0, 2, 2, 2, 2, 2]],
      [7, '(6.1.6) Vetokytkimen mekanismi', '(6.1.6) Dragkopplingens mekanism', [0, 115, 217, 291, 350], [0, 2, 2, 2, 2]],
      [8, '(6.1.6) Vetokytkimen kumit', '(6.1.6) Dragkopplingens gummidynor', [0, 95, 115, 291], [0, 2, 2, 2]],
      [9, '(6.1.6) Vetotappi', '(6.1.6) Dragtapp', [0, 19, 95, 101, 115, 291], [0, 2, 2, 2, 2, 2]],
      [10, '(6.1.6) Vetoaisa', '(6.1.6) Dragbom', [0, 19, 95, 291, 349, 356, 598, 802], [0, 2, 2, 2, 2, 2, 2, 2]],
      [11, '(6.1.6) Vetopöytä', '(6.1.6) Svängbord', [0, 19, 56, 95, 102, 115, 291, 353, 601, 602], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [12, '(6.1.6) Vetolaite', '(6.1.6) Draganordning', [0, 79, 336, 644, 728, 776, 777, 778], [0, 2, 2, 2, 2, 2, 2, 2]],
      [13, '(6.1.6) Vetokoukku', '(6.1.6) Dragkrok', [0, 56, 63, 88, 95, 421], [0, 2, 2, 2, 2, 2]],
      [14, '(6.1.6) Kilpi', '(6.1.6) Skylt', [0, 95, 108], [0, 2, 2]]]],
    [4, ['Varoituskolmio', 'Varningstriangel',
      [0, '(7.4) Varoituskolmio', '(7.4) Varningstriangel', [0, 95, 108, 269, 644], [0, 1, 1, 1, 1]]]],
    [5, ['Kilvet', 'Skyltar',
      [0, '(FIN.C14.5) Nopeuskilpi', '(FIN.C14.5) Hastighetsskylt', [0, 56, 95, 99, 108, 644], [0, 1, 1, 1, 1, 1]],
      [1, '(4.8) Pitkän ajoneuvoyhdistelmän kilpi', '(4.8) Skylt för lång fordonskombination', [0, 95, 102, 103, 108, 495], [0, 1, 1, 1, 1, 1]]]],
    [6, ['Moottori', 'Motor',
      [1, '(6.1.8)(8.4.1)(6.1.9) Moottori', '(6.1.8)(8.4.1)(6.1.9) Motor', [0, 102, 103, 130, 133, 185, 186, 487, 509, 510, 511, 512, 532, 644, 723, 726], [0, 2, 2, 2, 1, 1, 3, 2, 1, 1, 1, 1, 1, 2, 2, 2]],
      [2, '(6.1.8) Moottorin kumityyny', '(6.1.8) Motordyna', [0, 15, 95, 100, 108, 340, 341], [0, 0, 0, 1, 0, 0, 0]],
      [3, '(4.) Latausgeneraattori', '(4.) Generator', [0, 103, 179, 517, 518], [0, 1, 1, 0, 0]],
      [4, '(FIN.C15.2) Jäähdytin', '(FIN.C15.2) Kylar', [0, 103, 179, 517, 518], [0, 1, 1, 0, 0]],
      [5, '(4.) Käynnistysmoottori', '(4.) Startmotor', [0, 103, 179, 517, 518], [0, 1, 1, 0, 0]],
      [6, '(FIN.C15.2) Jäähdytysneste', '(FIN.C15.2) Kylarvätska', [0, 529, 530, 531], [0, 0, 0, 0]]]],
    [7, ['Lukkolaite, luvattoman käytön esto', 'Låsanordning',
      [1, '(7.3) Ohjauslukko', '(7.3) Rattlås', [0, 69, 70, 74, 75, 78, 108, 302, 303, 644, 645, 646], [0, 1, 1, 1, 1, 1, 2, 1, 1, 2, 3, 2]],
      [2, '(7.3) Vaihdelukko', '(7.3) Växellås', [0, 69, 70, 74, 75, 78, 108, 302, 303, 644, 645, 646], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [3, '(7.3) Ajonestolaite', '(7.3) Körspärr', [0, 69, 70, 74, 75, 78, 108, 302, 303, 644, 645, 646], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]]]],
    [8, ['Energiantuottolaitteet', 'Energiproduktionsapparat',
      [0, '(1.1.3)(1.1.7) Kompressori', '(1.1.3)(1.1.7) Kompressor', [0, 74, 183, 309, 494, 556], [0, 2, 1, 2, 2, 2]],
      [1, '(1.1) Paineilmajärjestelmä', '(1.1) Tryckluftsystem', [0, 183], [1, 2]]]],
    [9, ['Turvajärjestelmät', 'Säkerhetssystem',
      [1, '(FIN.E) Ajoavustimet', '(FIN.E) Körassistenter', [0, 95, 108, 272, 393, 442, 644, 772], [0, 2, 2, 2, 2, 2, 2, 1]],
      [2, '(7.13) eCall- järjestelmä', '(7.13) eCall-system', [0, 95, 644, 804, 808], [0, 1, 1, 1, 2]]]],
  ])]],
  [8, ['Koeajo ja näkyvyys', 'Provkörning och sikt', new Map([
    [0, ['Tuulilasi ja puhdistuslaitteet', 'Vindruta och vindruteanordningar',
      [0, '(3.4)(3.5) Tuulilasin puhdistuslaitteet', '(3.4)(3.5) Vindrutans rengöringsanordningar', [0, 74, 108, 238, 239, 240, 241, 405, 435], [0, 2, 2, 1, 1, 1, 1, 1, 1]],
      [1, '(3.2) Tuulilasi', '(3.2) Vindruta', [0, 95, 103, 108, 115, 119, 120, 121, 260, 261, 388, 389, 589, 590], [0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(3.5) Pesulaite', '(3.5) Spolare', [0, 74, 108, 239], [0, 2, 2, 1]],
      [3, '(3.4) Pyyhkimet', '(3.4) Torkare', [0, 74, 108, 238, 239, 240, 241, 405, 435], [0, 2, 2, 1, 1, 1, 1, 1, 1]],
      [4, '(3.4) Pyyhkijän sulka', '(3.4) Torkarblad', [0, 95, 108, 115, 239], [0, 1, 2, 1, 1]],
      [5, '(3.6) Huurteenpoisto', '(3.6) Defroster', [0, 74, 108, 309, 312, 435], [0, 2, 2, 1, 1, 0]]]],
    [1, ['Muut ikkunat', 'Övriga rutor',
      [0, '(3.2) Sivu- ja takalasit', '(3.2) Sido- och bakrutor', [0], [0]],
      [1, '(3.2) Sivulasi', '(3.2) Sidoruta', [0, 95, 103, 108, 230, 260, 261, 262, 590, 591, 592, 644], [0, 1, 2, 2, 1, 2, 2, 2, 2, 1, 1, 2]],
      [2, '(3.2) Takalasi', '(3.2) Bakruta', [0, 95, 103, 108, 260, 261, 262, 590, 591, 592, 644], [0, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2]],
      [3, '(3.6) Takalasin huurteenpoisto', '(3.6) Bakrutans defroster', [0, 74], [0, 0]]]],
    [2, ['Peilit', 'Speglar',
      [0, '(3.3) Peili', '(3.3) Spegel', [0, 15, 56, 95, 100, 108, 249, 644], [0, 1, 1, 1, 1, 1, 1, 1]],
      [1, '(3.3) Ulkotaustapeili', '(3.3) Utebackspegel', [0, 15, 56, 95, 100, 108, 249, 644], [0, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(3.3) Ulkotaustapeilin kiinnitys', '(3.3) Utebackspegelns fastsättning', [0, 15, 100], [1, 1, 1]],
      [3, '(3.3) Sisätaustapeili', '(3.3) Innerbackspegel', [0, 95, 108, 249], [1, 1, 1, 1]]]],
    [3, ['Näkökenttä', 'Synfält',
      [0, '(3.1) Näkökenttä', '(3.1) Synfält', [0, 263, 667], [1, 1, 1]]]],
    [4, ['Koeajo', 'Provkörning',
      [0, '(FIN) Koeajo', '(FIN) Provkörning', [0], [0]],
      [1, '(2.1) Ohjattavuus', '(2.1) Styrbarhet', [0, 202, 203, 204, 205, 217, 218, 219, 220], [0, 2, 2, 2, 2, 2, 2, 2, 2]],
      [2, '(1.1) Jarrut', '(1.1) Bromsar', [0, 133, 193, 201, 202, 203, 204, 205], [0, 2, 2, 2, 2, 2, 2, 2]],
      [3, '(1.1) Jarrupoljintuntuma', '(1.1) Bromspedalkänsla', [0, 95, 273, 274, 275, 276, 284, 285], [0, 2, 2, 3, 2, 2, 2, 2]]]],
    [5, ['Korin sisustus', 'Karosseri inredning',
      [0, '(6.1) Korin sisustus', '(6.1) Karosseriets inredning', [0, 26, 130, 231, 235, 533, 534], [0, 1, 2, 1, 1, 1, 0]],
      [1, '(6.2.5)(6.2.6) Istuin', '(6.2.5)(6.2.6) Säte', [0, 27, 95, 110, 533, 534, 539, 543, 544, 545, 546, 644, 800], [0, 2, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 2]],
      [2, '(6.2.5)(6.2.6) Istuimen kiinnitys', '(6.2.5)(6.2.6) Sätets fastsättning', [0, 98, 99, 100, 103, 336, 644], [0, 2, 2, 2, 1, 2, 2]],
      [3, '(6.2.5)(6.2.6) Istuimen lukitus', '(6.2.5)(6.2.6) Sätets låsning', [0, 69, 233], [0, 1, 1]],
      [4, '(6.2.5)(6.2.6) Tilapäisistuin', '(6.2.5)(6.2.6) Tillfälligt säte', [0, 57, 68, 102, 103], [0, 1, 1, 2, 2]],
      [5, '(6.2.5)(6.2.6) Lisäistuin', '(6.2.5)(6.2.6) Tilläggs säte', [0, 57, 68, 102], [0, 2, 2, 2]],
      [6, '(FIN.E3) Turvakaari', '(FIN.E3) Störtbåge', [0, 68, 108, 231, 801], [0, 1, 2, 1, 2]]]],
    [6, ['Hallintalaitteet ja ilmaisimet', 'Manöverorgan och indikatorer',
      [0, '(6.2.7) Hallintalaitteet', '(6.2.7) Manöverorgan', [0, 21, 115, 644], [0, 2, 1, 1]],
      [1, '(2.2) Ohjauspyörä', '(2.2) Ratt', [0, 24, 95, 279, 280, 281, 434, 586], [0, 2, 1, 1, 1, 1, 1, 1]],
      [2, '(2.2.1) Ohjauspyörän kiinnitys', '(2.2.1) Rattens fastsättning', [0, 15, 95, 99, 100], [0, 2, 2, 2, 2]],
      [3, '(1.1) Jarrupoljin', '(1.1) Bromspedal', [0, 6, 24, 79, 95, 100, 220, 282], [0, 2, 2, 2, 2, 2, 2, 1]],
      [4, '(1.1.6) Seisontajarrun käyttölaite', '(1.1.6) Parkeringsbromsens bruksanordning', [0, 24, 79, 95, 220, 287, 288], [2, 2, 2, 2, 0, 2, 2]],
      [5, '(6.2.7) Kytkinpoljin', '(6.2.7) Kopplingspedal', [0, 24, 95, 282], [0, 1, 1, 1]],
      [6, '(6.2.7) Kaasupoljin', '(6.2.7) Gaspedal', [0, 24, 95, 220], [1, 1, 1, 1]],
      [7, '(7.7) Äänimerkinantolaitteen käyttökytkin', '(7.7) Signalanordningens reglage', [0, 56, 69, 70, 74, 217, 385], [0, 1, 1, 1, 1, 1, 1]],
      [8, '(6.2.7) Tuulilasinpyyhkijöiden käyttökytkin', '(6.2.7) Vindrutetorkarnas reglage', [0, 69, 74, 95, 108, 385], [1, 1, 1, 1, 1, 1]],
      [9, '(6.2.7) Tuulilasinpesulaitteen käyttökytkin', '(6.2.7) Vindrutespolarens reglage', [0, 74, 385], [0, 1, 1]],
      [10, '(4.4.2) Suuntavalojen käyttökytkin', '(4.4.2) Körriktningsljusens reglage', [0, 74, 95, 108, 283, 385], [0, 1, 1, 1, 1, 1]],
      [11, '(4.1.3) Ajovalojen käyttökytkin', '(4.1.3) Körljusens reglage', [0, 69, 70, 74, 95, 108, 385], [0, 1, 1, 1, 1, 1, 1]],
      [12, '(4.1.3) Valonvaihtokytkin', '(4.1.3) Körljusens omkopplare', [0, 74, 95, 108, 385], [1, 1, 1, 1, 1]],
      [13, '(4.9) Suuntavalojen merkkivalo', '(4.9) Körriktningsljusens kontrollampa', [0, 74, 108, 385], [1, 1, 1, 1]],
      [14, '(4.9) Kaukovalojen merkkivalo', '(4.9) Fjärrljusets kontrollampa', [0, 74, 108, 385], [0, 1, 1, 1]],
      [15, '(4.9) Takasumuvalon merkkivalo', '(4.9) Bakdimljusets kontrollampa', [0, 74, 108, 385], [1, 1, 1, 1]],
      [16, '(4.9) Muiden valojen ja valaistujen kilpien merkkivalo', '(4.9) Övriga belysningars och belysta skyltars kontrollampa', [0, 74, 108, 385], [1, 1, 1, 1]],
      [17, '(1.1.4) Alhaisen paineen varoitusvalo', '(1.1.4) Varningslampa för lågt tryck', [0, 69, 74, 108], [1, 1, 1, 1]],
      [18, '(1.1.4) Alhaisen paineen summeri', '(1.1.4) Varningssummer för lågt tryck', [0, 69, 74, 108], [1, 1, 1, 1]],
      [19, '(FIN.E8) Vaihteen valitsin', '(FIN.E8) Växelspak', [0, 76, 171, 222, 384, 385, 386, 456, 458], [0, 1, 1, 1, 1, 1, 1, 1, 1]],
      [20, '(FIN.C14) Muu käyttökytkin', '(FIN.C14) Övrigt reglage', [0, 69, 74, 385], [0, 1, 1, 1]],
      [21, '(6.2.7) Muu hallintalaite', '(6.2.7) Övrigt manoverorg', [0, 69, 74, 95, 385], [0, 1, 1, 1, 1]],
      [22, '(4.9) Aurausvalojen vaihtokytkin', '(4.9) Plogningsljusens omkopplingsbrytare', [0, 56, 385], [0, 1, 1]],
      [23, '(4.9) Seisontajarrun merkkivalo / varoituslaite', '(4.9) Handbromsens märklamppa', [0, 69, 74], [0, 1, 1]],
      [24, '(7.1.6) Turvavyön merkkivalo', '(7.1.6) Kontrollampa för säkerhetsbälte', [0, 108, 647, 648], [0, 1, 1, 1]],
      [25, '(7.1.6) Turvatyynyn merkkivalo', '(7.1.6) Kontrollampa för krockkudde', [0, 108, 647, 648], [0, 2, 2, 1]],
      [26, '(5.2.3) Rengaspaineen merkkivalo', '(5.2.3) Kontrollampa för lufttryck i däck', [0, 108, 647, 648], [0, 0, 0, 0]],
      [27, '(8.2.1.2) OBD-merkkivalo', '(8.2.1.2) Kontrollampa för OBD', [0, 108, 647, 648], [0, 2, 2, 2]],
      [28, '(7.12) Ajovakauden hallinnan merkkivalo', '(7.12) Kontrollampa för körstabilisator', [0, 108, 647, 648], [2, 2, 2, 2]],
      [29, '(4.9) Etuvalojen merkkivalo', '(4.9) Kontrollampa för framljusen', [0, 108, 647, 648], [1, 1, 1, 1]]]],
    [7, ['Nopeusmittari ja ajopiirturi', 'Hastighetsmätare och färdskrivare',
      [0, '(7.8) Nopeusmittarin valaistus', '(7.8) Hastighetsmätares belysning', [0, 74, 309], [1, 1, 1]],
      [1, '(7.8) Nopeusmittari', '(7.8) Hastighetsmätare', [0, 19, 74, 108, 220, 221, 271, 385, 644], [0, 2, 2, 2, 1, 2, 2, 1, 2]],
      [2, '(7.9) Ajopiirturi', '(7.9) Färdskrivare', [0, 50, 55, 74, 108, 385, 644, 707, 737, 757, 783], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [8, ['Nopeudenrajoitin', 'Hastighetsbegränsare',
      [1, '(7.10) Nopeudenrajoitin', '(7.10) Hastighetsbegränsare', [0, 74, 108, 304, 305, 311, 606, 616, 644, 708, 779], [0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]]]],
    [9, ['Muut mittarit', 'Övriga mätaren',
      [1, '(1.1.4) Jarrujärjestelmän säiliöpaineen mittari', '(1.1.4) Bromssystemets tryckmätare för behållartrycket', [0, 69, 74, 108], [0, 2, 2, 2]]]],
  ])]],
  [9, ['Lisätarkastukset', 'Övriga inspektioner', new Map([
    [0, ['Taksivarustus', 'Taxiutrustning',
      [0, '(FIN.C14.1) Taksamittari', '(FIN.C14.1) Taxamätare', [0, 54, 55, 69, 108, 221, 269, 271, 304, 305, 306, 550, 644, 706, 716], [0, 2, 2, 2, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]],
      [1, '(FIN.C14.1) Taksatiedote , tarra', '(FIN.C14.1) Prislista, tarra', [0, 95, 99, 108], [0, 1, 1, 1]],
      [2, '(4.12) Taksivalo (kupu)', '(4.12) Taxilampa (kupa)', [0, 74, 95, 108, 269], [0, 1, 1, 1, 1]],
      [3, '(FIN.D10) Invataksitunnus', '(FIN.D10) Igenkänningsmärke för Invataxi', [0, 95, 108], [1, 1, 1]]]],
    [1, ['Koulu- ja opetusajoneuvovarustus', 'Skolfordonutrustning',
      [0, '(FIN.D10) Kouluajoneuvovarustus', '(FIN.D10) Utrustning för skolelevstransportbil', [0, 111, 112, 366, 367, 368, 369, 394, 395, 396, 536, 537, 547, 780], [1, 1, 1, 1, 2, 2, 1, 2, 2, 2, 2, 1, 2, 2]],
      [1, '(FIN.D10) Opetusajoneuvovarustus', '(FIN.D10) Utrustning för körundervisningsbil', [0, 111, 112, 366, 367, 368, 369, 394, 395, 396, 536, 537, 547, 780], [0, 1, 1, 1, 2, 2, 1, 2, 2, 2, 2, 1, 2, 2]]]],
    [2, ['Matkailuajoneuvovarustus', 'Husvagnutrustning',
      [0, '(FIN.D10) Matkailuautovarustus', '(FIN.D10) Utrustning för husbil', [0, 496, 497], [0, 2, 2]],
      [1, '(7.2) Tulensammutin', '(7.2) Brandsläckare', [0, 99, 103, 108, 221, 357, 573, 644], [0, 1, 1, 1, 1, 1, 1, 1]],
      [2, '(6.1.3) Nestekaasun pääsulkuventtiiliä osoittava kilpi', '(6.1.3) Skylt för flytgasens huvudkran', [0, 95, 108], [0, 1, 1]],
      [3, '(6.1.3) Tupakoinnin nestekaasupullojen läheisyydessä kieltävä kilpi', '(6.1.3) Skylt för förbjuden rökning, i närheten av flytgasflaskorna', [0, 95, 108], [0, 0, 1]],
      [4, '(6.1.3) Nestekaasulaitteiston liitäntäpaineen ilmoittava kilpi', '(6.1.3) Skylt angående flytgasanordningens anslutningstryck', [0, 95, 108], [0, 0, 0]],
      [5, '(FIN.D10) Vähimmäisilmanvaihdon aukkojen sulkemisen kieltävä kilpi', '(FIN.D10) Skylt som förbjuder stängning av minimiluftväxlingsöppning', [0, 95, 108], [0, 0, 0]],
      [6, '(6.1.3) Kaasulaitteiston asennustarkastus- tai tyyppihyväksyntäkilpi', '(6.1.3) Gasanordningens installationsgransknings- eller typgodkänningsskylt', [0, 95, 108], [0, 0, 0]],
      [7, '(6.1.3) Nestekaasulaitteiston CE-merkintä', '(6.1.3) Flytgasanordningens CE-märkning', [0, 95, 108], [0, 1, 2]],
      [8, '(FIN.C14.2) Sähkölaitteiden CE-merkintä', '(FIN.C14.2) Elanordningarnas CE-märkning', [0, 95, 108], [0, 1, 2]],
      [9, '(FIN.C14.2) Sähkölaitteiden asennustarkastusta osoittava tarra', '(FIN.C14.2) Elanordningarnas installationsgranskningsdekal', [0, 95, 108], [0, 1, 2]]]],
    [3, ['Ruumisautovarustus', 'Likbilutrustning',
      [0, '(6.2.9) Väliseinä', '(6.2.9) Mellanvägg', [0, 103, 408, 410, 412], [0, 1, 1, 1, 1]],
      [1, '(FIN.A2) Väri', '(FIN.A2) Färg', [0, 644], [0, 2]],
      [2, '(FIN.D10) Arkkutila', '(FIN.D10) Kistplats', [0, 332, 412], [0, 2, 2]],
      [3, '(6.2.6) Arkkutilan istuin', '(6.2.6) Sittplats', [0, 414], [0, 2]],
      [4, '(FIN.D10) Vetolava', '(FIN.D10) Dragflak', [0, 108, 408], [0, 2, 2]]]],
    [4, ['Linja-autovarustus', 'Bussutrustning',
      [1, '(9.2)(9.3) Lämmitysjärjestelmä', '(9.2)(9.3) Värmeanordning', [0, 74, 108, 309], [0, 2, 2, 1]],
      [2, '(9.5) Sisävalaistus', '(9.5) Innerbelysning', [0, 74, 108], [0, 1, 1]],
      [3, '(9.) Linja-auton varusteet', '(9.) Bussens utrustning', [0, 111, 113, 310, 603, 604, 605], [1, 1, 2, 1, 1, 1, 1]],
      [4, '(7.2) Tulensammutin', '(7.2) Brandsläckare', [0, 99, 103, 108, 221, 357, 573, 644], [0, 1, 1, 2, 2, 1, 1, 1]],
      [5, '(7.5) Ensiapulaukku', '(7.5) Förstahjälpväska', [0, 55, 95, 99, 108, 359, 484, 644], [1, 1, 1, 1, 1, 1, 1, 1]],
      [6, '(9.) Raivausvälineet', '(9.) Röjningsutrustning', [0, 108, 358], [0, 1, 1]],
      [7, '(9.) Hinausvaijeri', '(9.) Bogservaijer', [0, 95, 108], [0, 1, 1]],
      [8, '(9.) Varapyörä', '(9.) Reservhjul', [0, 95, 108], [1, 1, 1]],
      [9, '(4.13) Päävirtakytkin', '(4.13) Huvudströmbrytare', [0, 56, 74, 108, 221], [0, 1, 1, 2, 1]]]],
    [5, ['Paloautovarustus', 'Brandbilutrustning',
      [0, '(FIN.D10) Paloautovarustus', '(FIN.D10) Brandbils utrustning', [0, 19, 644], [0, 1, 2]],
      [1, '(4.12) Tunnusvalo', '(4.12) Igenkänningsbelysning', [0, 58, 74, 95, 108, 259, 265, 269, 295, 644], [0, 1, 1, 1, 1, 1, 1, 1, 1, 1]]]],
    [6, ['Sairasautovarustus', 'Ambulansutrustning',
      [0, '(FIN.D10) Sairasautovarustus', '(FIN.D10) Ambulansutrustning', [0, 19, 644], [0, 1, 1]],
      [1, '(4.12) Tunnusvalo', '(4.12) Igenkänningsbelysning', [0, 58, 74, 95, 108, 259, 265, 269, 295, 644], [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]]]],
    [7, ['Vaarallisten aineiden kuljetus varustus', 'VAK/ADR-utrustning',
      [0, '(6.2.9) ADR/VAK varustus', '(6.2.9) ADR/VAK utrustning', [0, 19, 644], [0, 1, 1]],
      [1, '(7.2) Tulensammutin', '(7.2) Brandsläckare', [0, 99, 103, 108, 221, 357, 573, 644], [0, 1, 1, 2, 2, 1, 1, 1]],
      [2, '(7.6) Pyörän jarrukiila', '(7.6) Bromskil för däck', [0, 95, 108], [0, 1, 1]],
      [3, '(FIN.E3) Ohjaamon paloturvallisuus', '(FIN.E3) Hyttens brandsäkerthet', [0, 19, 644], [1, 1, 1]],
      [4, '(4.13) Päävirtakytkin', '(4.13) Huvudströmbrytare', [0, 56, 74, 108, 221], [0, 1, 1, 2, 1]],
      [5, '(4.11) Sähköjohtimet', '(4.11) Elledningar', [0, 103, 114, 626], [0, 1, 1, 1]],
      [6, '(6.2) Kuormapeite', '(6.2) Presenning', [0, 95, 103], [0, 0, 0]]]],
    [8, ['Erityskäyttöön varustetun ajoneuvon varustus', 'Försett för specialbruk',
      [1, '(FIN.E1) Invalidin apulaitteet', '(FIN.E1) Invalidutrustning', [0, 193, 408, 415, 416], [0, 2, 1, 1, 1]]]],
    [9, ['TIR-varustus', 'TIR-utrustning',
      [1, '(6.2) Korin laita', '(6.2) Karossens flakläm', [0, 54, 63, 95, 100, 483, 484], [0, 2, 2, 2, 2, 2, 2]],
      [2, '(6.2) Laidan saranointi', '(6.2) Flakläms gångjärn', [0, 54, 63, 95, 100, 483, 484], [0, 2, 2, 2, 2, 2, 2]],
      [3, '(6.2) Pressukate', '(6.2) Tälttak', [0, 63, 95, 295, 340, 483], [0, 2, 2, 2, 2, 2]],
      [4, '(6.2) TIR-vaijeri', '(6.2) TIR-vaijer', [0, 56, 63, 95, 100, 108, 221, 332], [0, 2, 2, 2, 2, 2, 2, 2]],
      [5, '(6.2) TIR-Kulmanauha', '(6.2) TIR-Hörnband', [0, 54, 95, 100, 108, 221, 332, 484], [0, 2, 2, 2, 2, 2, 2, 2]],
      [6, '(6.2) TIR-Kilpi', '(6.2) TIR-Skylt', [0, 54, 95, 108, 484], [0, 2, 2, 2, 2]],
      [7, '(6.2) TIR-Vaijerin lenkki', '(6.2) TIR-Vajerns länk', [0, 56, 63, 95, 100, 108, 221, 332], [2, 2, 2, 2, 2, 2, 2, 2]],
      [8, '(6.2) TIR-Todistus', '(6.2) TIR-Intyg', [0, 108, 221], [2, 2, 2]],
      [9, '(6.2) Kuormakori', '(6.2) Lastkorg', [0, 54, 63, 95, 100, 483, 484], [0, 2, 2, 2, 2, 2, 2]],
      [10, '(FIN.D10) Ilmastointikone', '(FIN.D10) Luftkonditioneringsmaskin', [0, 55, 74, 108, 484, 580], [2, 2, 2, 2, 2, 2]],
      [11, '(FIN.D10) Säiliö', '(FIN.D10) Behållare', [0, 55, 63, 95, 484, 569], [0, 2, 2, 2, 2, 2]]]],
  ])]],
  [10, ['Kaasuajoneuvojen lisätarkastukset', 'Extra kontroller för gasfordon', new Map([
    [0, ['Kaasuasennus', 'Gasinstallation',
      [0, '(FIN.G1) Kaasuasennus', '(FIN.G1) Gasinstallation', [0, 69, 74, 644], [0, 2, 2, 2]]]],
    [1, ['Käytettävän polttoaineen vaihtojärjestelmä', 'System för byte av bränslet som används',
      [0, '(FIN.G2) Käytettävän polttoaineen vaihtojärjestelmä', '(FIN.G2) System för byte av bränslet som används', [0, 74, 809], [0, 2, 2]]]],
    [2, ['Ilmanvaihtokotelo', 'Ventilationskapsel',
      [0, '(FIN.G3) Ilmanvaihtokotelo', '(FIN.G3) Ventilationskapsel', [0, 95, 103, 626, 810, 811, 812], [0, 3, 2, 2, 3, 3, 3]]]],
    [3, ['Säiliön tason mittari', 'Mätare för nivån i behållaren',
      [0, '(FIN.G4) Säiliön tason mittari', '(FIN.G4) Mätare för nivån i behållaren', [0, 95, 712], [0, 2, 2]]]],
    [4, ['Kaasun täyttöjärjestelmän osat', 'Komponenter i systemet för gaspåfyllning',
      [0, '(FIN.G5) Kaasun täyttöjärjestelmän osat', '(FIN.G5) Komponenter i systemet för gaspåfyllning', [0, 95, 103, 626, 810, 811, 812], [0, 2, 2, 2, 3, 3, 3]]]],
    [5, ['Elektroninen ohjausyksikkö', 'Elektronisk styrenhet',
      [0, '(FIN.G6) Elektroninen ohjausyksikkö', '(FIN.G6) Elektronisk styrenhet', [0, 69, 813], [0, 3, 3]]]],
    [6, ['Kaasuvuoto', 'Gasläckage',
      [0, '(FIN.G7) Kaasuvuoto', '(FIN.G7) Gasläckage', [0, 814], [0, 3]]]],
    [7, ['Merkintä', 'Märkning',
      [0, '(FIN.G8) Merkintä', '(FIN.G8) Märkning', [0, 95, 108, 644], [0, 2, 2, 2]]]],
  ])]],
  [11, ['Sähkö- ja hybridiajoneuvojen lisätarkastukset', 'Extra kontroller för el- och hybridfordon', new Map([
    [0, ['Korkejännitekomponenttien merkintä', 'Märkning på högspänningskomponenter',
      [0, '(FIN.F1) Korkejännitekomponenttien merkintä', '(FIN.F1) Märkning på högspänningskomponenter', [0, 108, 221, 644], [0, 2, 2, 2]]]],
    [1, ['Regeneratiivinen jarrujärjestelmä', 'Regenerativa bromssystem',
      [0, '(FIN.F2) Regeneratiivinen jarrujärjestelmä', '(FIN.F2) Regenerativa bromssystem', [0, 95, 108, 815, 816], [0, 2, 2, 2, 2]]]],
    [2, ['Matalajännitejohdotus', 'Dragning av lågspänningsledningar',
      [0, '(FIN.F3) Matalajännitejohdotus', '(FIN.F3) Dragning av lågspänningsledningar', [0, 95, 103, 811, 817], [0, 1, 2, 3, 3]]]],
    [3, ['Sähköinen voimalaite', 'Elektriskt drivsystem',
      [0, '(FIN.F4) Sähköinen voimalaite', '(FIN.F4) Elektriskt drivsystem', [0, 95, 644], [0, 2, 2]]]],
    [4, ['Ladattava energiavarastojärjestelmä', 'Laddningsbart elenergilagringssystem',
      [0, '(FIN.F5) Ladattava energiavarastojärjestelmä', '(FIN.F5) Laddningsbart elenergilagringssystem', [0, 95, 103, 183, 626, 811, 817, 818], [0, 3, 2, 3, 2, 3, 3, 2]]]],
    [5, ['REESS Hallintajärjestelmä', 'REESS-manöveranordning',
      [0, '(FIN.F6) REESS Hallintajärjestelmä', '(FIN.F6) REESS-manöveranordning', [0, 95, 644, 819, 820, 821, 822, 823], [0, 2, 2, 2, 2, 2, 3, 2]]]],
    [6, ['Elektroniset muuttajat', 'Elektroniska omvandlare',
      [0, '(FIN.F7) Elektroniset muuttajat', '(FIN.F7) Elektroniska omvandlare', [0, 95, 103, 626, 811, 817, 818], [0, 2, 2, 2, 3, 3, 2]]]],
    [7, ['Ajomoottori', 'Dragmotor',
      [0, '(FIN.F8) Ajomoottori', '(FIN.F8) Dragmotor', [0, 95, 103, 626, 811, 817, 818], [0, 2, 2, 2, 3, 3, 2]]]],
    [8, ['Apuvoimalaitteet', 'Reservkraftkälla',
      [0, '(FIN.F9) Apuvoimalaitteet', '(FIN.F9) Reservkraftkälla', [0, 95, 103, 626, 811, 817, 818, 824], [0, 2, 2, 2, 3, 3, 2, 3]]]],
    [9, ['Aktiivisen ajotilan ilmaisin', 'Indikator för aktivt körläge',
      [0, '(FIN.F10) Aktiivisen ajotilan ilmaisin', '(FIN.F10) Indikator för aktivt körläge', [0, 69, 74, 644], [0, 2, 2, 2]]]],
    [10, ['Ajosuunnan tila -ilmaisin', 'Indikator för körriktning',
      [0, '(FIN.F11) Ajosuunnan tila -ilmaisin', '(FIN.F11) Indikator för körriktning', [0, 69, 74, 644], [0, 2, 2, 2]]]],
    [11, ['REESS Ulkoinen latausjärjestelmä', 'Externt laddsystem för REESS',
      [0, '(FIN.F12) REESS Ulkoinen latausjärjestelmä', '(FIN.F12) Externt laddsystem för REESS', [0, 95, 103, 626, 811, 817, 818], [0, 2, 2, 3, 3, 3, 2]]]],
  ])]],
])
