import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';
import { DEFAULT_HISTORY_FILTERS } from '../lib/jobConstants';

export const fetchInspectionHistory = createAsyncThunk('inspectionHistory/fetchInspectionHistory', async ({ chainId, data }, API) => {
  const query = {
    procName: 'GetJobHistory',
    data,
  };
  const state = API.getState();
  const response = await sendRequest(chainId, query, 'POST', state.auth.token);
  return response;
});

export const fetchAtjErrorJobs = createAsyncThunk('inspectionHistory/fetchAtjErrorJobs', async ({ chainId, siteId }, API) => {
  const query = {
    procName: 'GetAtjErrorJobs',
    data: { chainId, siteId },
  };
  const state = API.getState();
  const response = await sendRequest(chainId, query, 'POST', state.auth.token);
  return response;
});

export const fetchRegistrationCertificatesInSite = createAsyncThunk('inspectionHistory/fetchRegistrationCertificatesInSite', async ({ chainId, siteId }, API) => {
  const query = {
    procName: 'GetRegistrationCertificatesInSite',
    data: { siteId },
  };
  const state = API.getState();
  const response = await sendRequest(chainId, query, 'POST', state.auth.token);
  return response;
});

const inspectionHistorySlice = createSlice({
  name: 'inspectionHistory',
  initialState: {
    isFetching: false,
    inspectionHistory: [],
    atjErrorJobs: [],
    filters: {
      ...DEFAULT_HISTORY_FILTERS,
      ts: new Date().getTime(),
    },
    registrationCertificates: [],
    selectedPage: 0,
  },
  reducers: {
    setSelectedPage: (state, { payload }) => {
      state.selectedPage = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
    },
  },
  extraReducers: {
    [fetchInspectionHistory.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchInspectionHistory.fulfilled]: (state, { payload }) => {
      state.inspectionHistory = payload;
      state.isFetching = false;
    },
    [fetchInspectionHistory.rejected]: (state) => {
      state.isFetching = false;
    },
    [fetchAtjErrorJobs.fulfilled]: (state, { payload }) => {
      state.atjErrorJobs = payload;
    },
    [fetchRegistrationCertificatesInSite.fulfilled]: (state, { payload }) => {
      state.registrationCertificates = payload;
    },
  },
});

export const { setFilters, setSelectedPage } = inspectionHistorySlice.actions;

export default inspectionHistorySlice.reducer;
