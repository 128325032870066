export default `
<style type="text/css">
.form-container {
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 10px;
}

.buttons-container {
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.11);
  justify-content: space-between;
}

.buttons-container > div {
  display: inline;
}

button {
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--buttonColor);
  background-color: var(--buttonBackground);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 140px;
  cursor: pointer;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button:focus {
  outline: none;
}

button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}


table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
}

tr {
    height: 43px;
}

th {
    border-bottom: 1px solid rgba(209,209,209,0.25);
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0,0,0,0.6);
    padding: 0px 20px;
    text-align: left;
}

td {
  padding: 0px 20px;
}

</style>
<div id="sites-section">
  <div class="form-container">
    <settings-data-table id="table"></settings-data-table>
  </div>
  <div class="buttons-container">
    <div>
      <button class="primary" id="button-save"></button>
      <button id="button-cancel"></button>
    </div>
    <button id="button-delete-user"></button>
  </div>
</div>
`;