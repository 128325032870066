import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendSiteRequest } from '../api/api';

export const fetchHaynesIdentificationTree = createAsyncThunk('haynes/fetchHaynesIdentificationTree', async ({ chainId, siteId, makeId, modelId }, API) => {
  const params = {
    id: makeId ?? null,
    items: modelId ? [{
      id: modelId,
    }] : null,
  }

  const haynesTree = await sendSiteRequest(chainId, siteId, 'POST', '/haynes/identification-tree', '', params);

  if (modelId) {
    return {
      typeOptions: haynesTree.items ?? [],
    }
  } else if (makeId) {
    return {
      modelOptions: haynesTree.items ?? [],
      typeOptions: null,
    }
  } else {
    return {
      makeOptions: haynesTree.items ?? [],
      modelOptions: null,
      typeOptions: null,
    }
  }
});

export const fetchHaynesVehicleInfo = createAsyncThunk('haynes/fetchHaynesVehicleInfo', async ({ chainId, siteId, haynesId, registration }, API) => {
  if (haynesId || registration) {
    const params = {
      haynesId,
      registration,
    }

    return await sendSiteRequest(chainId, siteId, 'POST', '/haynes/search', '', params);
  } else {
    return null;
  }
});

const chainSlice = createSlice({
  name: 'haynes',
  initialState: {
    vehicleInfo: undefined,
    makeOptions: null,
    modelOptions: null,
    typeOptions: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchHaynesIdentificationTree.pending]: (state, { meta }) => {
        if (meta.arg?.modelId) {
            state.typeOptions = null;
        } else if (meta.arg?.makeId) {
            state.modelOptions = null;
        } else {
            state.makeOptions = null;
        }
    },
    [fetchHaynesIdentificationTree.fulfilled]: (state, { payload }) => {
        state.makeOptions = payload.makeOptions === undefined ? state.makeOptions : payload.makeOptions;
        state.modelOptions = payload.modelOptions === undefined ? state.modelOptions : payload.modelOptions;
        state.typeOptions = payload.typeOptions === undefined ? state.typeOptions : payload.typeOptions;
    },
    [fetchHaynesVehicleInfo.pending]: (state, { meta }) => {
        state.vehicleInfo = null;
    },
    [fetchHaynesVehicleInfo.fulfilled]: (state, { payload }) => {
        state.vehicleInfo = payload ?? undefined;
    },
  },
});

export default chainSlice.reducer;
