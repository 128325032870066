export default `
<style>
  .root {
    min-width: 300;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    padding: 10px 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    flex-grow: 4;
  }

  .title, .count {
    font-size: 18px;
  }

  .actions > span {
    margin-left: 20px;
  }

  .content {
    padding: 10px 0px;
  }

  button > img {
    margin-right: 6px;
  }

  button:hover {
    background-color: var(--primaryButtonBackgroundHover);
  }

  button:focus {
    outline: none;
  }

  .rotate {
    transform: rotate(180deg);
  }
</style>

<div class="root">
  <div class="header">
    <span class="title"></span>
    <div class="actions">
      <span class="count"></span>
      <span id="remove" class="remove" hidden>
        <img id="close" />
      </span>
      <span class="collapse">
        <img id="openClose" />
      </span>
    </div>
  </div>

  <div class="content">
    <slot></slot>
  </div>
</div>
`;