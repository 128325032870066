import css from './vehicle-groups-css';
import { VehicleGroupStore } from './vehicleGroupStore.js';
import addWhiteImage from '../../assets/icons/add_white.svg';

const NAME = 'name';
const DESCRIPTION = 'description';
const VEHICLECLASSES = 'vehicleClasses'
const COLUMN_IDS = [NAME, DESCRIPTION, VEHICLECLASSES];

window.customElements.define('vehicle-groups-view', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };
    this.store = new VehicleGroupStore();
    this._state = {
      data: [],
      sortBy: NAME,
      sortDirection: 'asc',
      isBuilt: false
    };

    this.requestServices(
      [
        ['translationService', (instance) => {this.translationService = instance; }],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
    this.addEventListener('dataTableRowClick', this.handleDataTableRowClick);
  }

  connectedCallback() {
    if(!this._state.isBuilt)
    {
      this.addRoot().then( () =>
        window.requestAnimationFrame(() => { this.render() }));
      this._state.isBuilt = true;
    } else
    {
      window.requestAnimationFrame(() => { this.refreshData() });
    }
  }

  disconnectedCallback() {
    this.store.clearData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    if (!this.controlState.chainId && controlState.chainId) {
      this.refreshData();
    }
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged' || controlState.lastAction === 'chainIdChanged') {
      this.refreshData();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
    this.refreshData();
  }

  handleDataTableRowClick = (event) => {
    if(!event.detail.data)
    {
      return;
    }

    const vehicleGroupNavEvent = new CustomEvent( 'vehicleGroupNavigationEvent', {
      bubbles: true,
      composed: true,
      detail: { route: 'vehicleGroupDetail' , vehicleGroupId: event.detail.data.id}
    });
    this.shadowRoot.dispatchEvent(vehicleGroupNavEvent);
  }


  addRoot = async () => {
    const root = document.createElement('div');
    root.setAttribute('id', 'vehicle-groups-view');
    root.setAttribute('class', 'main-container');
    this.root = root;
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.shadowRoot.appendChild(root);
  }

  addTitle = () => {
    const title = document.createElement('div');
    title.setAttribute('class', 'title');
    title.textContent = this.translationService.translate('vehicleGroups');
    this.buttonContainer = document.createElement('div');
    this.buttonContainer.setAttribute('class', 'buttons-container');
    const addButton = document.createElement('button');
    addButton.setAttribute('id', 'add-button');
    addButton.setAttribute('class', 'primary');
    addButton.onclick = this.addVehicleGroup;
    const addIcon = document.createElement('img');
    addIcon.setAttribute('src', addWhiteImage);
    addButton.appendChild(addIcon);
    const buttonText = document.createElement('div');
    buttonText.textContent = this.translationService.translate('vehicleGroupsView.addVehicleGroup');
    addButton.appendChild(buttonText);
    this.buttonContainer.appendChild(addButton);
    title.appendChild(this.buttonContainer);
    this.root.appendChild(title);
  }


  addDataTable = () => {
    this.dataTable = document.createElement('data-table');
    this.root.appendChild(this.dataTable);
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`vehicleGroupsView.${columnId}`),
        sortable: true,
        primary: columnId === NAME,
      };
    });
  }

  render = async () => {
    this.addTitle();
    this.addDataTable();
    this.refreshData();
  }

  refreshData = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    this.refreshVehicleGroups();
  }

  addVehicleGroup = async (event) => {
    const vehicleGroupNavEvent = new CustomEvent( 'vehicleGroupNavigationEvent', {
      bubbles: true,
      composed: true,
      detail: { route: 'newVehicleGroup' }
    });
    this.shadowRoot.dispatchEvent(vehicleGroupNavEvent);
  }

  refreshVehicleGroups = async () => {
    const { sortBy, sortDirection } = this._state;
    const data = await this.store.getVehicleGroups(this.translationService, this.controlState.chainId, sortBy, sortDirection);
    this.dataTable.dataAndLayout = {
      data,
      columns: this.getTableColumnDefinitions(),
      sortBy:sortBy,
      sortDirection: sortDirection,
    };
  }
});