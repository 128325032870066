// Role types.
const ADMIN = 'admin';
const SUPERUSER = 'superuser';

const CONDITION_INSPECTION_JOB_TYPES = 'conditionInspectionJobTypes';
const JOB_TYPES = 'jobTypes';
const INSPECTION_LINES = 'inspectionLines';
const DEFAULT_LINES = 'defaultLines';
const TRAFICOM_EXTRANET = 'traficomExtranet';
const MY_INFO = 'myInfo';
const CHAIN_SALES_LEDGER_REPORTS = 'chainSalesLedgerReports';
const SALES_LEDGER_REPORTS = 'salesLedgerReports';
const QUALITY_CONTROL = 'qualityControl';

// Visible by default, without explicit permission.
const GLOBAL_VIEWS = [
  'haynesinfo',
  'help',
  'bulletin',
  'faultreport',
];

// Determines if user has read or write permission to viewName.
export function getUserHasPermissionToView(user, viewName, path) {
  if (!user.permissions) {
    return false;
  }
  if ([ADMIN, SUPERUSER].includes(user.userType) || viewName.toLowerCase() === MY_INFO.toLowerCase()) {
    return true;
  }
  let scope = viewName;
  if (viewName.toLowerCase() === DEFAULT_LINES.toLowerCase()) {
    scope = INSPECTION_LINES;
  }
  if (viewName.toLowerCase() === CONDITION_INSPECTION_JOB_TYPES.toLowerCase()) {
    scope = JOB_TYPES;
  }
  if (viewName.toLowerCase() === CHAIN_SALES_LEDGER_REPORTS.toLowerCase()) {
    scope = SALES_LEDGER_REPORTS;
  }
  if (viewName.toLowerCase() === TRAFICOM_EXTRANET.toLowerCase()) {
    return true;
  }
  if (GLOBAL_VIEWS.includes(viewName.toLowerCase())) {
    return true;
  }
  if (viewName.toLowerCase() === QUALITY_CONTROL.toLowerCase()) {
    // TODO: we need to find a better solution for this.
    if (path?.includes('/notices/')) {
      // Allow access to notice details if user has permission to access inspection history.
      return user.permissions?.some(permission => permission.scope === 'inspectionHistory' && (permission.canRead || permission.canWrite));
    }
    const tabs = [
      'surveillance',
      'deviations',
      'improvementTargets',
      'meetingMinutes',
      'notices',
    ];
    return tabs.some(tab => user.permissions?.some(permission => permission.scope.toLowerCase() === tab.toLowerCase() && (permission.canRead || permission.canWrite)));
  }
  return user.permissions
    .some(permission => permission.scope.toLowerCase() === `${scope}`.toLowerCase() && (permission.canRead || permission.canWrite));
}

/**
 * Returns true, if the given user is super user.
 * @param {object} user user
 */
export const isSuperUser = (user) => SUPERUSER === user?.userType;

/**
 * Returns true, if the given user is a privileged user.
 * @param {object} user user
 */
export const isPrivilegedUser = (user) => [ADMIN, SUPERUSER].includes(user?.userType);