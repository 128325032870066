import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import * as faultConstants from '../../../lib/faultConstants';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.black.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    fontWeight: 600,
  },
  sectionText: {
    color: '#000000',
    opacity: 0.6,
    fontSize: 16,
  },
  buttonContainer: {
    display: 'flex',
    boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    '& > button': {
      flex: 1,
      height: 56,
    },
  },
  buttonRoot: {
    borderRadius: 0,
    borderWidth: 0,
    '&:hover': {
      borderWidth: 0,
    },
    '&:first-child': {
      backgroundColor: theme.palette.white.main,
    },
  },
  buttonLabel: {
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        flex: 1,
        fontSize: 16,
        letterSpacing: 0.5,
        fontWeight: 500,
        '&:first-child': {
          marginRight: 26,
          lineHeight: '24px',
        },
        '&:last-child': {
          marginLeft: 24,
        },
      },
    },
  },
  buttonIcon: {
    fontSize: 26,
  },
}));

function SectionHeader({ text }) {
  const classes = useStyles();

  return (
    <div className={classes.sectionTitle}>
      {text}
    </div>
  );
}

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired
};

function SectionText({ text }) {
  const classes = useStyles();

  return (
    <div className={classes.sectionText}>
      {text}
    </div>
  );
}

SectionText.propTypes = {
  text: PropTypes.string.isRequired
};

function FaultDetails({ open, onClose, onDelete, fault, onEdit }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  // No fault given.
  if (!fault) {
    return null;
  }

  // Language.
  const languageId = i18n.language === 'sv-FI' ? 1 : 0;

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
          <div className={classes.title}>{t('faults.detailsTitle')}</div>
        </div>
        <div className={classes.details}>
          <div className={classes.section}>
            <SectionHeader text={t('faults.faultCode')} />
            <SectionText text={fault.faultCode} />
          </div>
          <div className={classes.section}>
            <SectionHeader text={t('faults.fault')} />
            <SectionText text={faultConstants.MAIN_FAULT_CODES.get(fault.mainFaultId)[languageId]} />
            <SectionText text={faultConstants.getSubFaultTitle(fault, languageId)} />
            <SectionText text={faultConstants.getComponentFaultTitle(fault, languageId)} />
            <SectionText text={faultConstants.getFaultTitle(fault, languageId)} />
          </div>
          <div className={classes.section}>
            <SectionHeader text={t('faults.location')} />
            <SectionText text={faultConstants.getFaultLocationTitle(fault, languageId)} />
          </div>
          <div className={classes.section}>
            <SectionHeader text={t('faults.severity')} />
            <SectionText text={faultConstants.getSeverityText(fault, languageId)} />
          </div>
          <div className={classes.section}>
            <SectionHeader text={t('faults.deadline')} />
            <SectionText text={fault.deadline || '-'} />
          </div>
          <div className={classes.section}>
            <SectionHeader text={t('faults.additionalInfo')} />
            <SectionText text={fault.additionalInformation || '-'} />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            variant='outlined'
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            onClick={() => onEdit(fault)}
          >
            <div>
              <EditIcon className={classes.buttonIcon} />
              <div>{t('edit')}</div>
            </div>
          </Button>
          <Button
            color='primary'
            variant='outlined'
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel, disabled: classes.primaryDisabled }}
            onClick={onDelete}
            type='submit'
          >
            <div>
              <CloseIcon className={classes.buttonIcon} />
              <div>{t('delete')}</div>
            </div>
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

FaultDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  fault: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
};

export default FaultDetails;
