import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaynesInformation } from '../../../reducers/jobReducer';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  summary: {
    backgroundColor: 'rgba(191,191,191,0.2)',
    paddingLeft: 16,
    paddingRight: 16,
  },
  valueRoot: {
    marginBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  valueLabel: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    fontWeight: 600,
  },
  valueText: {
    opacity: 0.6,
    color: '#000000',
    fontSize: '16px',
  },
  subGroupHeader: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  heading: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  divider: {
    height: 1,
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'end',
  },
  haynesImage: {
    maxWidth: '100%',
  },
  expandableContainer: {
    marginBottom: 8
  },
  expandableHeader: {
  },
  expandableContent: {
  },
  listItem: {
    marginLeft: 16,
    opacity: 0.6,
    color: '#000000',
  }
}));

const formatMaintenanceInterval = (program, translate) => {
  const parts = [
    program.intervalKms ? (program.intervalKms + ' km') : null,
    program.intervalMonths ? (program.intervalMonths + ' kk') : null,
    program.intervalType === 2 ? translate('haynesInformation.flexibleInterval') : null
  ].filter(x => x)

  return parts.length > 0 ? parts.join(' / ') : null;
}

function ExpandableContainer({renderHeader, renderCollapsed, children, defaultExpanded = false}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className={classes.expandableContainer}>
      <div className={classes.expandableHeader} onClick={() => setExpanded(! expanded)}>
        {renderHeader(expanded)}
      </div>
      <div className={classes.expandableContent}>
        {expanded ? children : (renderCollapsed ? renderCollapsed() : null)}
      </div>
    </div>
  );
}

function HaynesValue({ title, values, children }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.valueRoot}>
      <div>
        <Typography className={classes.valueLabel}>
          {title}
        </Typography>
      </div>
      <div>
        {values.map((valueText, index) => (
          <Typography key={index} className={classes.valueText}>
            {valueText}
          </Typography>
        ))}
        {children}
      </div>
    </Grid>
  )
}

function HaynesSuspensionValues({values}) {
  const classes = useStyles();
  const formatSuspensionValue = (value) => value?.value ? `${value.description}${value.remark ? ' (' + value.remark + ')' : ''}: ${value?.value ?? ''}${value?.unit ? ' ' + value.unit : ''}` : null;

  return (
    <div>
      {values?.map((value, index) =>
        <div key={index}>
          <div className={classes.valueText}>
            {formatSuspensionValue(value)}
          </div>
          {value.subValues?.map((subValue, subIndex) =>
            <div key={subIndex} className={classes.listItem}>
               {formatSuspensionValue(subValue)}
            </div>
          ) ?? null}
        </div>
      )}
    </div>
  );
}

function MaintenanceProgram({programKey, info, program, translate}) {
  const classes = useStyles();

  if (! program) {
    return null;
  }

  return (
    <HaynesValue
      title={translate(`haynesInformation.maintenanceProgram`)}
      values={[program.description]}
      translate={translate}
    >
      <ExpandableContainer
        renderHeader={(isExpanded) => (
          <div className={classes.valueText}>
            {program.intervalDescription ?? translate('haynesInformation.serviceInterval')}
            <IconButton size="small">{isExpanded ? <ExpandLess/> : <ExpandMore/>}</IconButton>
          </div>
        )}
        renderCollapsed={() => (
          <div className={classes.listItem}>{formatMaintenanceInterval(program, translate) ?? program.serviceAt?.[0] ?? '-'}</div>
        )}
      >
        <div>{program.serviceAt?.map((service, serviceIndex) => <div className={classes.listItem} key={serviceIndex}>{service}</div>) ?? null}</div>
      </ExpandableContainer>
      <div className={classes.valueText}>
        {info.engineDistributionType ?? translate('haynesInformation.timingBeltInfo')}
      </div>
      {program.timingBeltInfo?.map((timingBeltText, timingBeltIndex) => <div className={classes.listItem} key={timingBeltIndex}>{timingBeltText}</div>) ?? <div className={classes.listItem}>{translate('haynesInformation.informationMissing')}</div>}
    </HaynesValue>
  );
}

function HaynesInfo({ expanded, handleChange, translate, showSearchButton, title, values }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formatValue = (label, value) => value?.value ? `${translate(label)}: ${value?.value ?? ''}${value?.unit ? ' ' + value.unit : ''}${value.remark ? ' (' + value.remark + ')' : ''}` : null;

  const selectedSite = useSelector(state => state.sites?.sites?.find(s => s.id === state.sites?.selectedSiteId) ?? null);

  const updateParams = useSelector(state => ({
    chainId: state.chains?.selectedChainId,
    jobId: state.jobs?.job?.id,
    data: {
      registration: state.jobs?.job?.registrationNumber,
    }
  }));

  const onChange = (event, isExpanded) => {
    if (isExpanded && values === null) {
      dispatch(updateHaynesInformation(updateParams));
    }
    handleChange(event, isExpanded)
  };

  if (! selectedSite?.useHaynesData) {
    return null;
  }

  return (
    <Accordion expanded={expanded} onChange={onChange} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`{groupId}-content`}
        id={`{groupId}-header`}
        className={classes.summary}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {values === null ? <span>{translate('haynesInformation.fetching')}</span> : null}
            {values?.length === 0 ? <span>{translate('haynesInformation.notFound')}</span> : null}
          </Grid>
          {showSearchButton ?
            <Grid item xs={6} className={classes.buttonRow}>
              <Button color='primary' disabled={values === null} onClick={() => dispatch(updateHaynesInformation(updateParams))}>
                {translate('haynesInformation.fetch')}
              </Button>
            </Grid>
            :
            null
          }
          {values?.map((info, infoIndex) =>
            <Grid key={infoIndex} item xs={12} className={classes.valueRoot}>
              {infoIndex > 0 ? <Divider className={classes.divider} orientation='vertical' flexItem /> : null}
              <ExpandableContainer defaultExpanded={values?.length <= 1}
                renderHeader={(isExpanded) => (
                  <div className={classes.subGroupHeader}>
                    #{infoIndex + 1} {info.registration ?? ''}
                    {info.modelName ? <span> / {info.modelName}</span> : null}
                    {info.madeFrom || info.madeUntil ? <span> ({info.madeFrom ?? ''} &ndash; {info.madeUntil ?? ''})</span> : null}
                    <IconButton size="small">{isExpanded ? <ExpandLess/> : <ExpandMore/>}</IconButton>
                  </div>
                )}
              >
              <Grid container spacing={1}>
                {info.brakes?.map((brakeInfo, brakeIndex) => (
                  <HaynesValue
                    key={brakeIndex}
                    title={translate(brakeInfo.axle === 1 ? 'haynesInformation.brakeDiscFront' : 'haynesInformation.brakeDiscRear') + (brakeInfo.description ? ' (' + brakeInfo.description + ')' : '')}
                    values={[
                      formatValue('haynesInformation.discDiameter', brakeInfo.discDiameter),
                      formatValue('haynesInformation.discThickness', brakeInfo.discThickness),
                      formatValue('haynesInformation.discThicknessMin', brakeInfo.discThicknessMin),
                      formatValue('haynesInformation.discThicknessMin', brakeInfo.discThicknessMin2),
                    ]}
                    translate={translate}
                  />
                ))}
              </Grid>
              <Grid container spacing={1}>
                <HaynesValue
                  title={translate(`haynesInformation.eobdLocation`)}
                  values={[info.eobdLocationText ?? '']}
                  translate={translate}
                >
                  {info.eobdLocationUri ? <img alt={translate(`haynesInformation.eobdLocation`)} className={classes.haynesImage} src={info.eobdLocationUri} /> : null}
                </HaynesValue>
                <HaynesValue
                  title={translate(`haynesInformation.typePlateLocation`)}
                  values={(info.typePlateLocationText ?? '').split('<br>')}
                  translate={translate}
                >
                  {info.typePlateLocationUri ? <img alt={translate(`haynesInformation.typePlateLocation`)} className={classes.haynesImage} src={info.typePlateLocationUri} /> : null}
                </HaynesValue>
              </Grid>
              <Grid container spacing={1}>
                {info.kValue ? <HaynesValue
                  title={translate(`haynesInformation.kValue`)}
                  values={[formatValue('haynesInformation.kValue', info.kValue)]}
                  translate={translate}
                /> : null}
                {info.oilTemperatureDuringTest ? <HaynesValue
                  title={translate(`haynesInformation.oilTemperatureDuringTest`)}
                  values={[formatValue('haynesInformation.oilTemperatureDuringTest', info.oilTemperatureDuringTest)]}
                  translate={translate}
                /> : null}
                {info.minNoLoadGovernedRpm || info.maxNoLoadGovernedRpm ? <HaynesValue
                  title={translate(`haynesInformation.noLoadGovernedRpm`)}
                  values={[
                    formatValue('haynesInformation.min', info.minNoLoadGovernedRpm),
                    formatValue('haynesInformation.max', info.maxNoLoadGovernedRpm),
                  ]}
                  translate={translate}
                /> : null}
                {info.testTime ? <HaynesValue
                  title={translate(`haynesInformation.testTime`)}
                  values={[formatValue('haynesInformation.testTime', info.testTime)]}
                  translate={translate}
                /> : null}
              </Grid>
              <Grid container spacing={1}>
                {info.suspension?.map((suspension, suspensionIndex) => (
                  <HaynesValue
                    key={suspensionIndex}
                    title={translate(`haynesInformation.suspension`)}
                    values={[suspension.description, suspension.remark]}
                    translate={translate}
                  >
                    <HaynesSuspensionValues values={suspension.subValues} />
                  </HaynesValue>
                )) ?? null}
              </Grid>
              <Grid container spacing={1}>
                {info.maintenancePrograms?.map((program, programIndex) =>
                  <MaintenanceProgram
                    key={programIndex}
                    programKey={'info' + infoIndex + '.program' + programIndex}
                    info={info}
                    program={program}
                    translate={translate}
                  />)}
              </Grid>
              {info.liftingPointTexts?.length > 0 ? <Grid container spacing={1}>
                <HaynesValue
                    title={translate(`haynesInformation.liftingPoints`)}
                    values={info.liftingPointTexts}
                    translate={translate}
                >
                  {info.liftingPointUris?.map((uri, uriIndex) => <div key={uriIndex} ><img alt={translate(`haynesInformation.liftingPoints`)} className={classes.haynesImage} src={uri} /></div>) ?? null}
                </HaynesValue>
              </Grid> : null}
              </ExpandableContainer>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default HaynesInfo;
