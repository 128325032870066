import { createSlice } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

const siteGroupSlice = createSlice({
  name: 'siteGroup',
  initialState: {
    siteGroups: [],
    isFetching: false,
    fetchError: null,
  },
  reducers: {
    fetchSiteGroupsStart(state) {
      state.isFetching = true;
    },
    fetchSiteGroupsSuccess(state, { payload }) {
      state.siteGroups = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    fetchSiteGroupsFailure(state, { payload }) {
      state.isFetching = false;
      state.fetchError = payload;
    },
  },
});

export const {
  fetchSiteGroupsStart,
  fetchSiteGroupsSuccess,
  fetchSiteGroupsFailure,
} = siteGroupSlice.actions;

export default siteGroupSlice.reducer;

export const fetchSiteGroups = (chainId) => async (dispatch, getState) => {
  try {
    dispatch(fetchSiteGroupsStart());
    const queryData = {
      procName: 'GetSiteGroupsInChain',
      data: { chainId },
    };
    const { auth } = getState();
    const siteGroups = await sendRequest(chainId, queryData, 'POST', auth.token);
    dispatch(fetchSiteGroupsSuccess(siteGroups));
  } catch (err) {
    dispatch(fetchSiteGroupsFailure(err));
  }
}
