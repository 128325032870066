import './vehicle-groups-view';
import './vehicle-group-detail-view';
import css from './vehicle-groups-css';

window.customElements.define('vehicle-group-root-view', class extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({ mode: 'open' });
      this._state = {};
      this._state.views = {};
      this.addEventListener('vehicleGroupNavigationEvent', this.onVehicleGroupNavigationEvent);
      this.build();
    }

  build = async () => {
    this._state.views.vehicle_groups_view = document.createElement('vehicle-groups-view');
    this._state.views.vehicle_group_detail_view = document.createElement('vehicle-group-detail-view');
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style><div id="vehicle-group-root-view" class="root-container"/>';
    this.root = this.shadowRoot.getElementById('vehicle-group-root-view');
    this.root.appendChild(this._state.views['vehicle_groups_view'])
  }

  switchView = (view) => {
    this.root.removeChild(this.root.children[0]);
    this.root.appendChild(this._state.views[view]);
  }

  onVehicleGroupNavigationEvent = (event) => {
    let route = '/vehicleGroups';
    if (event.detail.route === 'newVehicleGroup') {
      route = '/vehicleGroups/new';
    } else if (event.detail.route === 'vehicleGroupDetail') {
      route = `/vehicleGroups/${event.detail.vehicleGroupId}`;
    }
    //Navigation event to React root.
    const navigationEvent = new CustomEvent('navigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route,
      },
    });
    window.dispatchEvent(navigationEvent);
    
    if(event.detail.route === 'newVehicleGroup') {
      this._state.views['vehicle_group_detail_view'].isNew = true;
      this.switchView('vehicle_group_detail_view');
    }
    else if(event.detail.route === 'vehicleGroupDetail')
    {
      this._state.views['vehicle_group_detail_view'].vehicleGroupId = event.detail.vehicleGroupId;
      this.switchView('vehicle_group_detail_view');
    }
    else if(event.detail.route === 'vehicleGroups')
    {
      this.switchView('vehicle_groups_view');
    }

  }
});
  