import './info-section/info-section';
import './contact-info-section/contact-info-section';
import './settings-section/settings-section';
import { SiteDetailStore } from './siteDetailStore';
import { getChain } from '../chain-info-section/chainStore';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';
import arrowLeftImage from '../../assets/icons/arrow-left.svg';
import css from './sites-detail-root-css';

const BASIC_INFO_FIELDS = ['name', 'sokuIdentifier', 'selfServiceAtjIdentifier', 'siteGroupId', 'logoBase64', 'companyId'];
const CONTACT_INFO_FIELDS = ['address', 'zipCode', 'city', 'contactPersonName', 'phoneNumber', 'faxNumber', 'email', 'wwwAddress'];
const SETTINGS_FIELDS = ['weekdayProvision', 'saturdayProvision', 'sundayProvision', 'provisionReduction', 'provisionReductionSaturday', 'afterInspectionPrice', 'additionalInformation', 'enableSitePrint', 'useSitePrint', 'useHaynesData', 'enableConditionInspection'];
const EMPTY_FORM_DATA = {
  name: '',
  sokuIdentifier: '',
  selfServiceAtjIdentifier: '',
  siteGroupId: null,
  logoBase64: '',
  companyId: null,
  address: '',
  zipCode: '',
  city: '',
  contactPersonName: '',
  phoneNumber: '',
  faxNumber: '',
  email: '',
  wwwAddress: '',
  weekdayProvision: '',
  saturdayProvision: '',
  sundayProvision: '',
  provisionReduction: '',
  provisionReductionSaturday: '',
  afterInspectionPrice: null,
  additionalInformation: '',
  enableSitePrint: false,
  useSitePrint: false,
  useHaynesData: false,
  enableConditionInspection: false,
};

class SitesDetailRoot extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.translationService = { translate: (word) => { return word; } };
    this.store = new SiteDetailStore();
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    // Request services.
    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    // Site data.
    this._state = {
      isBuilt: false,
      site: EMPTY_FORM_DATA,
      formData: {},
      tabs: new Map(),
      currChain: {},
    };

    // Build custom element.
    this.build();
  }

  disconnectedCallback() {
    this.setSite({});
    this.setChain({});

    // Reset selected tab view.
    const tabViews = this.contentRoot.childNodes;
    tabViews.forEach(view => view.setAttribute('hidden', ''));
    const selectedView = this.contentRoot.querySelector(`div[value=site-info-section]`);
    selectedView.hidden = false;
  }

  // Requests needed services.
  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  set navData(site) {
    this.setSite(site);
    this.setChain();
  }

  build = () => {
    this.shadow.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addModules();
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'sites-detail-root');
    this.root.setAttribute('class', 'main-container');
    window.requestAnimationFrame(() => this.initialRender());
  }

  addModules = () => {
    // Create custom elements.
    for (const tabDef of this.getTabDefinitions()) {
      const element = document.createElement(tabDef.element);
      this._state.tabs.set(tabDef.key, element);
      element.onChange = this.setFormValue;
    }
    this.confirmationDialog = document.createElement('confirmation-dialog');
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.lastAction === 'chainIdChanged') {
      this.goToListView();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  setSite = async (site) => {
    const updatedSiteData = site && site.id
      ? await this.store.fetchSite(this.controlState.chainId, site.id)
      : Object.assign({}, EMPTY_FORM_DATA);
    this._state = {
      ...this._state,
      site: updatedSiteData,
      formData: Object.assign({}, updatedSiteData),
    };
    window.requestAnimationFrame(() => {
      this.setTitle(updatedSiteData);
      this.refreshTabs();
      this.refreshButtons(updatedSiteData);
      this._state.tabs.get('site-info-section').setSite(updatedSiteData);
      this._state.tabs.get('site-contactInfo-section').setSite(updatedSiteData);
      this._state.tabs.get('site-otherSettings-section').setSite(updatedSiteData);
    });
  }

  setChain = () => {
    if (!this.controlState.chainId) {
      return;
    }
    getChain(this.controlState.chainId, this.controlState.accessToken)
      .then((result) => {
        const chainData = result[0];

        this._state.currChain = Object.assign({}, chainData);

        window.requestAnimationFrame(() => {
          this._state.tabs.get('site-otherSettings-section').setChain(this._state.currChain);
        });
      })
      .catch((error) => {
        console.error('Failed to fetch chain data', error);
        this._fetchingData = false;
        sendNotificationEvent(this.translationService.translate('chainView.fetchFailed'), 'error');
      });
  }

  addTitle = () => {
    const titleContainer = document.createElement('div');
    titleContainer.setAttribute('class', 'title');

    // Name.
    this.nameElement = document.createElement('span');
    this.nameElement.setAttribute('class', 'name-title');
    this.nameElement.appendChild(document.createTextNode(this.translationService.translate('siteView.newSite')));
    titleContainer.appendChild(this.nameElement);

    // Back to list view.
    this.toListView = document.createElement('div');
    this.toListView.setAttribute('class', 'to-list-view');
    this.toListView.onclick = () => this.goToListView();
    const arrowIcon = document.createElement('img');
    arrowIcon.src = arrowLeftImage;
    this.toListView.appendChild(arrowIcon);
    this.toListView.appendChild(document.createTextNode(this.translationService.translate('toListView')));
    titleContainer.appendChild(this.toListView);

    this.titleContainer = titleContainer;
    this.root.appendChild(titleContainer);
  }

  setTitle = (site) => {
    const siteDefined = site && site.id;
    const name = siteDefined ? site.name : this.translationService.translate('siteView.newSite');
    this.nameElement.removeChild(this.nameElement.firstChild);
    this.nameElement.appendChild(document.createTextNode(name));

    if (siteDefined) {
      this.toListView.removeAttribute('hidden');
    } else {
      this.toListView.setAttribute('hidden', '');
    }
  }

  setFormValue = (property, value) => {
    this._state = {
      ...this._state,
      formData: {
        ...this._state.formData,
        [property]: value,
      },
    };
    this.checkFormButtonStatus();
  }

  refreshTabs = () => {
    while (this.tabs.hasChildNodes()) {
      this.tabs.removeChild(this.tabs.firstChild);
    }
    this.getTabDefinitions().forEach((tabDef, index) => {
      const tab = document.createElement('div');
      if (index === 0) {
        tab.setAttribute('active', '');
      }
      if (tabDef.disabled) {
        tab.setAttribute('disabled', '');
        tab.onclick = null;
      } else {
        tab.onclick = this.setActiveTab;
      }
      tab.setAttribute('class', 'tab');
      tab.setAttribute('value', tabDef.key);
      tab.textContent = this.translationService.translate(`siteView.${tabDef.key.split('-')[1]}`);
      this.tabs.appendChild(tab);
    });
  }

  refreshButtons = (site) => {
    if (site && site.id) {
      this.deleteButton.removeAttribute('hidden');
    } else {
      this.deleteButton.setAttribute('hidden', '');
    }
  }

  addTabs = () => {
    this.tabs = document.createElement('div');
    this.getTabDefinitions().forEach((tabDef, index) => {
      const tab = document.createElement('div');
      if (index === 0) {
        tab.setAttribute('active', '');
      }
      if (tabDef.disabled) {
        tab.setAttribute('disabled', '');
        tab.onclick = null;
      } else {
        tab.onclick = this.setActiveTab;
      }
      tab.setAttribute('class', 'tab');
      tab.setAttribute('value', tabDef.key);
      tab.textContent = this.translationService.translate(`siteView.${tabDef.key.split('-')[1]}`);
      this.tabs.appendChild(tab);
    });
    this.tabs.setAttribute('class', 'tabs');
    this.root.appendChild(this.tabs);
  }

  initialRender = () => {
    this.addTitle();
    this.addTabs();

    const contentRoot = document.createElement('div');
    this.contentRoot = contentRoot;
    this.root.appendChild(contentRoot);

    for (const entry of this._state.tabs.entries()) {
      const wrapper = document.createElement('div');
      wrapper.setAttribute('value', entry[0]);
      if (entry[0] !== 'site-info-section') {
        wrapper.setAttribute('hidden', '');
      }
      wrapper.appendChild(entry[1]);
      this.contentRoot.appendChild(wrapper);
    }
    this.shadow.appendChild(this.root);

    this.addButtons();
  }

  /**
   * Sets active tab button and update displayed content according to selected tab.
   */
  setActiveTab = (event) => {
    // Reset tab styling and hide all content.
    const tabButtons = this.tabs.childNodes;
    tabButtons.forEach(button => button.removeAttribute('active'));
    const tabViews = this.contentRoot.childNodes;
    tabViews.forEach(view => view.setAttribute('hidden', ''));

    // Set selected tab active.
    event.target.setAttribute('active', '');

    // Show content according to tab value.
    const selectedValue = event.target.getAttribute('value');
    const selectedView = this.contentRoot.querySelector(`div[value=${selectedValue}]`);
    selectedView.hidden = false;
  }

  // Tab definitions.
  getTabDefinitions = () => {
    const siteSelected = this._state.site && this._state.site.id;
    return [
      {
        key: 'site-info-section',
        element: 'site-info-section',
      },
      {
        key: 'site-contactInfo-section',
        element: 'contact-info-section',
        disabled: !siteSelected,
      },
      {
        key: 'site-otherSettings-section',
        element: 'settings-section',
        disabled: !siteSelected,
      },
    ];
  }

  isDirty = () => BASIC_INFO_FIELDS.concat(CONTACT_INFO_FIELDS, SETTINGS_FIELDS)
    .some(fid => this._state.site[fid] !== this._state.formData[fid]);

  goToListView = () => {
    const navEvent = new CustomEvent('sitesNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/sites-list',
        data: {},
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  goToSiteDetails = (data) => {
    const navEvent = new CustomEvent('sitesNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/sites-detail',
        data,
      },
    });
    this.shadowRoot.dispatchEvent(navEvent);
  }

  saveForm = async () => {
    if (this._state.site.id) {
      try {
        const result = await this.store.updateSite(this.controlState.chainId, this._state.formData);
        this._state.site = result;
        this._state.formData = result;
        this.checkFormButtonStatus();
        sendNotificationEvent(this.translationService.translate('siteView.siteSaved'));
      } catch (error) {
        console.log('Failed to save data', error);
        sendNotificationEvent(this.translationService.translate('siteView.saveFailed'), 'error');
      }
      return;
    }
    try {
      const response = await this.store.createSite(this.controlState.chainId, {
        ...this._state.formData,
        chainId: this.controlState.chainId,
      });
      const id = response[0].id;
      // Update Redux site data.
      const reduxEvent = new CustomEvent('reduxEvent', {
        bubbles: true,
        composed: true,
        detail: {
          action: 'fetchSites',
          data: { chainId: this.controlState.chainId },
        },
      });
      window.dispatchEvent(reduxEvent);
      sendNotificationEvent(this.translationService.translate('siteView.newSiteCreated'));
      this.goToSiteDetails({ ...this._state.formData, id });
    } catch (error) {
      console.log('Failed to create site', error);
      sendNotificationEvent(this.translationService.translate('siteView.saveFailed'), 'error');
    }
  }

  deleteSite = async () => {
    if (!this._state.site.id) {
      console.warn('Delete site, site:', this._state.site);
      return;
    }
    try {
      await this.store.deleteSite(this.controlState.chainId, this._state.site.id);
      sendNotificationEvent(this.translationService.translate('siteView.siteDeleted'));
      this.goToListView();
    } catch (error) {
      console.log('Failed to delete site', error);
      sendNotificationEvent(this.translationService.translate('siteView.deleteFailed'), 'error');
    }
  }

  addButtons = () => {
    const buttonsContainer = document.createElement('div');
    buttonsContainer.setAttribute('class', 'buttons-container');
    this.root.appendChild(buttonsContainer);

    const leftButtonsContainer = document.createElement('div');
    buttonsContainer.appendChild(leftButtonsContainer);

    this.saveButton = document.createElement('button');
    this.saveButton.setAttribute('class', 'primary');
    this.saveButton.textContent = this.translationService.translate('save');
    this.saveButton.setAttribute('disabled', '');
    this.saveButton.onclick = this.saveForm;
    leftButtonsContainer.appendChild(this.saveButton);

    this.cancelButton = document.createElement('button');
    this.cancelButton.textContent = this.translationService.translate('cancel');
    this.cancelButton.onclick = () => {
      if (this.isDirty()) {
        this.addDiscardConfirmationDialog();
      } else {
        this.goToListView();
      }
    };
    leftButtonsContainer.appendChild(this.cancelButton);

    this.deleteButton = document.createElement('button');
    this.deleteButton.textContent = this.translationService.translate('siteView.deleteSite');
    this.deleteButton.onclick = this.addDeleteConfirmationDialog;
    buttonsContainer.appendChild(this.deleteButton);
  }

  handleDiscardChanges = () => {
    this.removeConfirmationDialog();
    this.goToListView();
  }

  handleDeleteSite = () => {
    this.removeConfirmationDialog();
    this.deleteSite();
  }

  addDiscardConfirmationDialog = () => {
    this.addConfirmationDialog(
      this.translationService.translate('leaveConfirmation'),
      this.handleDiscardChanges,
      this.translationService.translate('leavePage'),
    );
  }

  addDeleteConfirmationDialog = () => {
    this.addConfirmationDialog(
      `${this.translationService.translate('siteView.deleteConfirmation')} '${this._state.site.name}'?`,
      this.handleDeleteSite,
      this.translationService.translate('siteView.deleteSite'),
    );
  }

  addConfirmationDialog = (content, onAccept, acceptButtonText) => {
    window.requestAnimationFrame(() => {
      this.root.appendChild(this.confirmationDialog);
      this.confirmationDialog.values = {
        content,
        onAccept,
        acceptButtonText,
        onCancel: this.removeConfirmationDialog,
      };
    });
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.confirmationDialog));
  }

  /**
   * Checks if form is modified or contains any errors, and sets button statuses accordingly.
   */
  checkFormButtonStatus = () => {
    const formIsValid = this._state.tabs.get('site-info-section').isValid
      && this._state.tabs.get('site-contactInfo-section').isValid
      && this._state.tabs.get('site-otherSettings-section').isValid;
    if (!formIsValid) {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.removeAttribute('disabled');
      return;
    }
    if (this.isDirty()) {
      this.saveButton.removeAttribute('disabled');
      this.cancelButton.removeAttribute('disabled');
    } else {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.removeAttribute('disabled');
    }
  }
}
window.customElements.define('sites-detail-root', SitesDetailRoot);
