import { stableSort } from '../../assets/lib/commonFunctions'

export class VehicleGroupStore {
    constructor() {
      this.data = null;
      this.chainId = null;
    }

    clearData() {
      this.data = null;
      this.chainId = null;
    }

    get dataLength() {
      return this.data ? this.data.length : 0;
    }

    postData(chainId, data = {}) {
      return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          [process.env.REACT_APP_AUTH_HEADER_NAME]: `Bearer ${this.accessToken}`
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
       return response.json()
        .then(res => res)
        .catch((err) =>{
          // Empty response - skip.
          return;
        });
      });
    }

    mapVehicleGroupData = (data, translationService) => {
      return data.map((item) => ({
        ...item,
        vehicleClasses: item.vehicleClasses != null ? item.vehicleClasses.map((vc)=> vc.id).join() : ''
      }));
    }

    mapVehicleClassData = (data, selectedGroupId, translationService) => {
      return data.map((item) => ({
        ...item,
        vehicleGroupId: item.VehicleGroup[0]?.id ?? '',
        vehicleGroupName: item.VehicleGroup[0]?.name ?? '',
        disabled: (item.VehicleGroup[0]?.id) && (item.VehicleGroup[0]?.id !== selectedGroupId)
      }));
    }

    customClassesSort = (array, orderBy, order, selectedGroupId) => {
      //First, sort free and selected alphabetically.
      var freeAndSelected = array.filter( (item) => (item.VehicleGroup[0]?.id == null) || (item.VehicleGroup[0]?.id == selectedGroupId));
      freeAndSelected = stableSort(freeAndSelected, orderBy, order);
      //Second, sort reserved alphabetically
      var reserved = array.filter( (item) => (item.VehicleGroup[0]?.id != null) && (item.VehicleGroup[0]?.id != selectedGroupId));
      reserved = stableSort(reserved, orderBy, order);
      return freeAndSelected.concat(reserved);
    }

    getVehicleGroups = async (translationService, chainId, sortBy, sortDirection) => {
      // Event to Redux.
      const reduxEvent = new CustomEvent('reduxEvent', {
        bubbles: true,
        composed: true,
        detail: {
          action: 'fetchVehicleGroups',
          data: { chainId },
        },
      });
      window.dispatchEvent(reduxEvent);

      const queryObject = {
        procName: 'GetVehicleGroupsInChain',
        data: { chainId },
      };

      if (this.chainId !== chainId) {
        const serverData = await this.postData(chainId, queryObject);
        this.data = this.mapVehicleGroupData(serverData, translationService);
        this.chainId = chainId;
      }

      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.data = stableSort(this.data, sortBy, sortDirection);
      return this.data;
    }

    getVehicleGroup = async (chainId, ranslationService, vehicleGroupId) => {
      const queryObject = {
        procName: 'GetVehicleGroup',
        data: { vehicleGroupId },
      };
      // No need to save this object here, since it's not added to a data table
      return this.postData(chainId, queryObject);
      
    }

    getVehicleClassesInChain = async (translationService, chainId, selectedGroupId, sortBy, sortDirection) =>
    {
      const queryObject = {
        procName: 'GetVehicleClassesInChain',
        data: { chainId },
      };

      if (!this.data) {
        const serverData = await this.postData(chainId, queryObject);
        this.data = this.mapVehicleClassData(serverData, selectedGroupId, translationService);
      }

      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.data = this.customClassesSort(this.data, sortBy, sortDirection, selectedGroupId);
      return this.data;
    }

    updateVehicleGroup = async ( chainId, vehicleGroupData ) =>
    {
      const queryObject = {
        procName: 'UpdateVehicleGroup',
        data: vehicleGroupData
      }

      return this.postData(chainId, queryObject);
    }

    createVehicleGroup = async ( chainId, vehicleGroupData ) =>
    {
      const queryObject = {
        procName: 'CreateVehicleGroup',
        data: vehicleGroupData
      }

      return this.postData(chainId, queryObject);
    }

    deleteVehicleGroup = async ( chainId, vehicleGroupId ) =>
    {
      const queryObject = {
        procName: 'DeleteVehicleGroup',
        data: { vehicleGroupId }
      }

      return this.postData(chainId, queryObject);
    }
  }