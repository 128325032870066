function sendRequest(chainId, data = {}, accessToken) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + accessToken
    },
    body: JSON.stringify(data),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

function parseFloatFromText(value) {
  return value ? parseFloat(value.replace(',', '.')) : null;
}

function parseTextFromFloat(value) {
  return value ? value.toFixed(2).toString().replace('.', ',') : '';
}

function parseBooleanFromText(value) {
  return value ? !!value : false;
}

export class SiteDetailStore {
  constructor() {
    this.site = null;
  }

  clearData() {
    this.site = null;
  }

  parseApiSiteData = (data) => {
    return {
      ...data,
      weekdayProvision: parseTextFromFloat(data.weekdayProvision),
      saturdayProvision: parseTextFromFloat(data.saturdayProvision),
      sundayProvision: parseTextFromFloat(data.sundayProvision),
      provisionReduction: parseTextFromFloat(data.provisionReduction),
      provisionReductionSaturday: parseTextFromFloat(data.provisionReductionSaturday),
      afterInspectionPrice: parseTextFromFloat(data.afterInspectionPrice),
      enableSitePrint: parseBooleanFromText(data.enableSitePrint),
      useSitePrint: parseBooleanFromText(data.useSitePrint),
      useHaynesData: parseBooleanFromText(data.useHaynesData),
      enableConditionInspection: parseBooleanFromText(data.enableConditionInspection),
    };
  }

  createSite = (chainId, data) => {
    const queryData = {
      name: data.name,
      chainId: data.chainId,
      companyId: data.companyId,
      sokuIdentifier: data.sokuIdentifier,
      logoBase64: data.logoBase64,
    };
    if (data.siteGroupId) {
      queryData.siteGroupId = data.siteGroupId;
    }
    const queryObject = {
      procName: 'CreateSite',
      data: queryData,
    };
    return sendRequest(chainId, queryObject, this.accessToken);
  }

  updateSite = async (chainId, data) => {
    const queryData = {
      id: data.id,
      name: data.name,
      companyId: data.companyId,
      sokuIdentifier: data.sokuIdentifier,
      selfServiceAtjIdentifier: data.selfServiceAtjIdentifier,
      logoBase64: data.logoBase64,
      address: data.address,
      zipCode: data.zipCode,
      city: data.city,
      contactPersonName: data.contactPersonName,
      phoneNumber: data.phoneNumber,
      faxNumber: data.faxNumber,
      email: data.email,
      wwwAddress: data.wwwAddress,
      weekdayProvision: parseFloatFromText(data.weekdayProvision),
      saturdayProvision: parseFloatFromText(data.saturdayProvision),
      sundayProvision: parseFloatFromText(data.sundayProvision),
      provisionReduction: parseFloatFromText(data.provisionReduction),
      provisionReductionSaturday: parseFloatFromText(data.provisionReductionSaturday),
      afterInspectionPrice: parseFloatFromText(data.afterInspectionPrice),
      additionalInformation: data.additionalInformation,
      enableSitePrint: data.enableSitePrint,
      useSitePrint: data.useSitePrint,
      useHaynesData: data.useHaynesData,
      enableConditionInspection: data.enableConditionInspection,
    };
    if (data.siteGroupId) {
      queryData.siteGroupId = data.siteGroupId;
    }
    const queryObject = {
      procName: 'UpdateSite',
      data: queryData,
    };
    const result = await sendRequest(chainId, queryObject, this.accessToken);
    return this.parseApiSiteData(result[0]);
  }

  deleteSite = (chainId, siteId) => {
    const queryObject = {
      procName: 'DeleteSite',
      data: { siteId },
    };
    return sendRequest(chainId, queryObject, this.accessToken);
  }

  fetchSite = async (chainId, id) => {
    const queryObject = {
      procName: 'GetSite',
      data: { id },
    };
    const result = await sendRequest(chainId, queryObject, this.accessToken);
    this.site = this.parseApiSiteData(result);
    return this.site;
  }
}