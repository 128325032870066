export default `
<style type="text/css">
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.item {
  margin-bottom: 10px;
}

.buttons-container {
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.11);
  justify-content: space-between;
}

.buttons-container > div {
  display: inline;
}

button {
  display: inline-block;
  align-items: center;
  margin: 10px;
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3;
  text-transform: uppercase;
  color: var(--buttonColor);
  background-color: var(--buttonBackground);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 16px;
  min-width: 140px;
  cursor: pointer;
}

button > img {
  margin-right: 6px;
}

button[disabled] {
    opacity: 50%;
    cursor: default;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button:focus {
  outline: none;
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button[disabled].primary:hover {
  background-color: var(--primaryButtonBackground);
}

table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
}

tr {
    height: 43px;
}

th {
    border-bottom: 1px solid rgba(209,209,209,0.25);
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0,0,0,0.6);
    padding: 0px 20px;
    text-align: left;
}

td {
  padding: 0px 20px;
}

select {
  box-sizing: border-box;
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  padding: 0px 12px;
  width: 400px;
}

select:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
}

.input-label {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0,0,0,0.87);
    margin-bottom: 7px;
}
</style>
<div id="sites-section">
  <div class="form-container">
    <div class="item">
      <div id="templatesLabel" class="input-label"></div>
        <select id="roleTemplates" maxlength="200">
      </select>
    </div>
    <permissions-data-table class="item" id="table"></permissions-data-table>
  </div>
  <div class="buttons-container">
    <div>
      <button class="primary" id="button-save"></button>
      <button id="button-cancel"></button>
    </div>
    <button id="button-delete-user"></button>
  </div>
</div>
`;