import {
  refreshSelectField,
  refreshInputField,
  refreshCheckboxField,
} from '../siteHelpers.js';
import html from './settings-section-html';
import css from './settings-section-css';

// Form field IDs.
const WEEKDAY_PROVISION = 'weekdayProvision';
const SATURDAY_PROVISION = 'saturdayProvision';
const SUNDAY_PROVISION = 'sundayProvision';
const PROVISION_REDUCTION = 'provisionReduction';
const PROVISION_REDUCTION_SATURDAY = 'provisionReductionSaturday';
const AFTER_INSPECTION_PRICE = 'afterInspectionPrice';
const ADDITIONAL_INFORMATION = 'additionalInformation';
const ENABLE_SITEPRINT = 'enableSitePrint';
const USE_DORIS_SITEPRINT = 'useSitePrint';
const USE_HAYNES_DATA = 'useHaynesData';
const ENABLE_CONDITION_INSPECTION = 'enableConditionInspection';
const EMPTY_FORM_DATA = {
  [WEEKDAY_PROVISION]: '',
  [SATURDAY_PROVISION]: '',
  [SUNDAY_PROVISION]: '',
  [PROVISION_REDUCTION]: '',
  [PROVISION_REDUCTION_SATURDAY]: '',
  [AFTER_INSPECTION_PRICE]: null,
  [ADDITIONAL_INFORMATION]: '',
  [ENABLE_SITEPRINT]: false,
  [USE_DORIS_SITEPRINT]: false,
  [USE_HAYNES_DATA]: false,
  [ENABLE_CONDITION_INSPECTION]: false,
};
const FLOAT_FIELD_IDS = [WEEKDAY_PROVISION, SATURDAY_PROVISION, SUNDAY_PROVISION, PROVISION_REDUCTION, PROVISION_REDUCTION_SATURDAY];

// Field types.
const INPUT = 'input';
const SELECT = 'select';
const CHECKBOX = 'checkbox';

class SettingsSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      formData: Object.assign({}, EMPTY_FORM_DATA),
      postInspectionProducts: [],
      onChange: null,
    };

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.build();
  }

  connectedCallback() {
    // TODO: Fetch products
  }

  disconnectedCallback() {
    this._state = {
      ...this._state,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };
  }

  set onChange(val) {
    this._state.onChange = val;
  }

  get isValid() {
    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    return errorInputs.length === 0;
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
  }

  setSite(site) {
    this._state.formData = Object.assign({}, site);
    this.refreshData();
  }

  setChain(chain) {
    this._state.currChain = Object.assign({}, chain);
    this.refreshData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.lastAction === 'chainIdChanged') {
      // TODO: Fetch products.
    }
  }

  async build() {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>' + html;
    this.addRoot();
  }

  addRoot = () => {
    this.root = this.shadowRoot.getElementById('settings-section');
    this.formContainer = this.shadowRoot.getElementById('form-container');
    // const selectIcon = this.shadowRoot.getElementById('select-icon');
    // selectIcon.src = arrowDownImage;
    this._state.isBuilt = true;
    this.renderForm();
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
    const input = this.formContainer.querySelector(`input#${property}`);

    const inputDef = this.getInputDefinitions().find(def => def.id === property);
    const valueIsFloat = /^[0-9]{1,8}([.,][0-9]{1,2})?$/.test(value);
    if ((FLOAT_FIELD_IDS.includes(property) && !valueIsFloat)
      || (inputDef.required && !value)) {
      input.setAttribute('error', '');
    } else if (value) {
      input.removeAttribute('error');
    }

    // if (property === AFTER_INSPECTION_PRICE) {
    //   this.refreshData();
    // }

    this._state.onChange(property, value);
  }

  getInputDefinitions = () => [
    {
      id: WEEKDAY_PROVISION,
      label: this.translationService.translate('siteView.monFriCoefficient'),
      maxLength: 10,
      type: INPUT,
    },
    {
      id: SATURDAY_PROVISION,
      label: this.translationService.translate('siteView.satCoefficient'),
      maxLength: 10,
      type: INPUT,
    },
    {
      id: SUNDAY_PROVISION,
      label: this.translationService.translate('siteView.sunCoefficient'),
      maxLength: 10,
      type: INPUT,
    },
    {
      id: PROVISION_REDUCTION,
      label: this.translationService.translate('siteView.commissionReduction'),
      maxLength: 10,
      type: INPUT,
    },
    {
      id: PROVISION_REDUCTION_SATURDAY,
      label: this.translationService.translate('siteView.commissionReductionSaturday'),
      maxLength: 10,
      type: INPUT,
    },
    {
      id: AFTER_INSPECTION_PRICE,
      label: this.translationService.translate('siteView.afterInspectionPrice'),
      maxLength: 10,
      type: INPUT,
    },
    {
      id: ADDITIONAL_INFORMATION,
      label: this.translationService.translate('siteView.additionalInfoLabel'),
      maxLength: 120,
      type: INPUT,
    },
    {
      id: ENABLE_SITEPRINT,
      label: this.translationService.translate('siteView.enableSitePrint'),
      type: CHECKBOX,
    },
    {
      id: USE_DORIS_SITEPRINT,
      label: this.translationService.translate('siteView.useSitePrint'),
      type: CHECKBOX,
    },
    {
      id: USE_HAYNES_DATA,
      label: this.translationService.translate('siteView.useHaynesData'),
      type: CHECKBOX,
    },
    {
      id: ENABLE_CONDITION_INSPECTION,
      label: this.translationService.translate('siteView.enableConditionInspection'),
      type: CHECKBOX,
    },
  ];

  addTitles = () => {
    const commissionsTitle = this.shadowRoot.getElementById('commissions-title');
    commissionsTitle.textContent = this.translationService.translate('siteView.commissions');
    const otherSettingsTitle = this.shadowRoot.getElementById('other-settings-title');
    otherSettingsTitle.textContent = this.translationService.translate('siteView.otherSettings');

    const sitePrintEnabledOnChainTxt = this.shadowRoot.getElementById('sitePrintEnabledOnChain');
    sitePrintEnabledOnChainTxt.textContent = this.translationService.translate('siteView.enabledOnChain');
    const sitePrintInUseOnChainTxt = this.shadowRoot.getElementById('sitePrintInUseOnChain');
    sitePrintInUseOnChainTxt.textContent = this.translationService.translate('siteView.enabledOnChain');
  }

  refreshData = () => {
    this.getInputDefinitions().forEach((inputDef) => {
      const label = this.shadowRoot.getElementById(`${inputDef.id}-label`);
      label.textContent = inputDef.required ? `${inputDef.label} *` : inputDef.label;
      const value = this._state.formData[inputDef.id];

      if (inputDef.type === INPUT) {
        refreshInputField(this.shadowRoot, inputDef, value, (event) => this.setFormValue(inputDef.id, event.target.value), this._fetchingData);
        return;
      }
      if (inputDef.type === SELECT) {
        refreshSelectField(this.shadowRoot, this.translationService, inputDef, value, this.setFormValue, this.addFormDialog);
        return;
      }
      if (inputDef.type === CHECKBOX) {
        refreshCheckboxField(this.shadowRoot, inputDef, value, (event) => this.setFormValue(inputDef.id, event.target.checked), this._fetchingData);
        return;
      }
    });

    const userIsSuperAdmin = 'superuser' === this.controlState.user?.userType?.toLowerCase();

    if (!userIsSuperAdmin) {
      const useHaynesData = this.shadowRoot.getElementById('useHaynesData');
      useHaynesData.setAttribute('disabled', '');
    }

    // Enable condition inspection feature only for superusers.
    if (!userIsSuperAdmin) {
      const enableConditionInspection = this.shadowRoot.getElementById('enableConditionInspection');
      enableConditionInspection.setAttribute('disabled', '');
    }

    if (this._state.currChain !== undefined) {
      const sitePrintEnabledOnChain = this.shadowRoot.getElementById('sitePrintEnabledOnChain');
      if (this._state.currChain.enableSitePrint) {
        sitePrintEnabledOnChain.removeAttribute('hidden');
      } else {
        sitePrintEnabledOnChain.setAttribute('hidden', '');
      }
      const sitePrintInUseOnChain = this.shadowRoot.getElementById('sitePrintInUseOnChain');
      if (this._state.currChain.useSitePrint) {
        sitePrintInUseOnChain.removeAttribute('hidden');
      } else {
        sitePrintInUseOnChain.setAttribute('hidden', '');
      }
    }
  }

  renderForm = () => {
    this.addTitles();
    this.refreshData();
  }
}

window.customElements.define('settings-section', SettingsSection);
