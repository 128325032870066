import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendChainRequest } from '../api/api';

export const fetchSites = createAsyncThunk('site/fetchSites', async ({ chainId }, API) => {
  const state = API.getState();
  const user = state.auth.user;
  const token = state.auth.token;
  const sites = await sendChainRequest(chainId, 'GET', '/siteList', '', null, token);
  return { sites: sites, lastSiteId: user.lastSiteId };
});

const siteSlice = createSlice({
  name: 'site',
  initialState: {
    sites: [],
    selectedSiteId: null,
    isFetching: false,
    sitePrintEnabled: false,
    useSitePrint: false,
  },
  reducers: {
    setSelectedSiteId(state, { payload }) {
      state.selectedSiteId = payload;
    },
  },
  extraReducers: {
    [fetchSites.fulfilled]: (state, { payload: {sites, lastSiteId} }) => {
      state.sites = sites;
      state.isFetching = false;
      // Update selected site.
      if (sites.length === 0) {
        state.selectedSiteId = null;
      } else {
        if (!state.selectedSiteId) {
          state.selectedSiteId = lastSiteId ?? sites[0].id;
        }

        // selectedSiteId is probably set through URL query parameter.
        if (!sites.find(s => s.id === state.selectedSiteId)) {
          state.selectedSiteId = sites[0].id;
        }
      }
    },
    [fetchSites.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchSites.rejected]: (state) => {
      state.isFetching = false;
    },
  },
});

export const {
  setSelectedSiteId,
} = siteSlice.actions;

export default siteSlice.reducer;
