import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
    backgroundColor: theme.palette.black.main,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.white.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    maxHeight: 'calc(100% - 25px)',
    marginTop: 10,
    marginBottom: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxHeight: '100%',
      width: 'auto',
      maxWidth: '100%',
    },
  },
  description: {
    color: theme.palette.white.main,
    fontSize: 16,
    lineHeight: '23px',
    textAlign: 'center',
    height: 25,
  },
}));

function ImageDetails({ open, onClose, image }) {
  const classes = useStyles();

  if (!image) {
    return null;
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            {image.isVideo ? (
              <video controls src={image.contentLocation} style={{ width: '100%' }} />
            ) : (
              <img src={image.contentLocation} alt={image.description} />
            )}
            <div className={classes.description}>
              {image ? image.description : ''}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

ImageDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  image: PropTypes.object,
};

export default ImageDetails;