export default new Map([
  [0, ['Ei mitään', 'Ingenting']],
  [6, ['Ei saa korjata hitsaamalla, vaihdettava', 'Får inte repareras genom att svetsas, bör utbytas']],
  [15, ['Löysässä', 'Lös']],
  [17, ['Vaurioitunut tai vaikeasti luettavissa', 'Skadad eller svårt avläsbar']],
  [18, ['Väärä tai viallinen', 'Sned eller felaktig']],
  [19, ['Vaaditaan lisäselvitystä', 'Tilläggsuppgifter fordras']],
  [21, ['Ei vastaa rekisteritietoja', 'Motsvarar ej registeruppgifterna']],
  [22, ['Valmistenumeroa ei löytynyt', 'Tillverkningsnummern hittades ej']],
  [23, ['Rekisteritiedot eivät vastaa ajoneuvoa', 'Registeruppgifterna motsvarar inte fordonet']],
  [24, ['Muutettu, ajettavuus huonontunut', 'Ändrar, körbarheten försämrad']],
  [25, ['Muutettu, toimivuus huonontunut', 'Ändrad, funktionen försämrad']],
  [26, ['Muutettu', 'Ändrat']],
  [27, ['Istumapaikat eivät vastaa rekisteritietojen henkilömäärää', 'Modellen motsvarar inte registeruppgifterna']],
  [30, ['Rekisteröintitodistus puuttuu', 'Registreringsbevis fattas']],
  [31, ['Vakuutusmaksuja maksamatta', 'Försäkringspremien obetald']],
  [32, ['Käyttövoimaveroa maksamatta', 'Dieselskatten obetald']],
  [33, ['Ajoneuvovero maksamatta', 'Fordonsskatten obetald']],
  [34, ['Erikoisehdot eivät vastaa ajoneuvoa', 'De särskillda villkoren motsvarar inte fordonet']],
  [37, ['Muutettu, rakenne heikentynyt', 'Ändrad, konstruktionen försvagad']],
  [42, ['Moottori muutettu', 'Motorn ändrad']],
  [43, ['Renkaan leveyttä muutettu', 'Däckets bredd ändrad']],
  [44, ['Vanteen halkaisijaa muutettu', 'Fälgens diameter ändrad']],
  [45, ['Renkaan leveyttä ja vanteen halkaisijaa muutettu', 'Däckets bredd och diameter ändrad']],
  [46, ['Käyttövoimaa muutetttu', 'Brukskraften ändrad']],
  [47, ['Taka-akseliston rakennetta muutettu', 'Bak-axelns byggnad ändrad']],
  [48, ['Etupenkkien mallia muutettu', 'Framsätets modell ändrad']],
  [49, ['Ajoneuvon rakennetta muutettu', 'Fordonets byggnad ändrad']],
  [50, ['Tarkastusta ei ole suoritettu', 'Inte granskad']],
  [54, ['Sinetöinti tai taksan asetustuloste puuttuu', 'Plomberingen fattas']],
  [55, ['Sinetöinti vaurioitunut tai puuttuu', 'Plomberingen deformerad eller fattas']],
  [56, ['Sijoitus virheellinen', 'Felaktig placering']],
  [57, ['Sijoitus tai mitoitus virheellinen', 'Felaktig placering eller dimension']],
  [58, ['Lukumäärä virheellinen', 'Felaktigt antal']],
  [59, ['Raideleveyden muutos liian suuri', 'Hjulbasens ändring för stor']],
  [60, ['Asento virheellinen (auraus/haritus)', 'Felaktig hjulställning (plogning)']],
  [61, ['Asento virheellinen (pystykallistuma)', 'Felaktig hjulställning (camber)']],
  [62, ['Epätasapaino (ravistaa ajossa)', 'Obalans (vibrerar vid körning)']],
  [63, ['Virheellisesti korjattu', 'Felaktigt reparerad']],
  [64, ['Korjaus väärin suoritettu', 'Reparationen felaktigt utförd']],
  [65, ['Ruostevauriot virheellisesti korjattu, korjattava hitsaamalla', 'Rostskadorna felaktigt reparerade.Bör svetsas']],
  [66, ['Ruostevauriot virheellisesti korjattu. Osa uusittava, ei saa hitsata', 'Rostskadorna felaktigt reparerade.Delen bör bytas, får inte svetsas']],
  [67, ['Virheellisesti korjattu, uusittava', 'Felaktigt reparerad, bör förnyas']],
  [68, ['Asennus virheellinen', 'Felaktigt monterad']],
  [69, ['Toiminta virheellinen', 'Felaktig funktion']],
  [70, ['Toiminta määräysten vastainen/viallinen', 'Funktionen regelstridig']],
  [71, ['Ei mahdollisuutta toimia', 'Ingen möjlighet att fungera']],
  [72, ['Toiminta epävarmaa', 'Funktionen osäker']],
  [74, ['Ei toimi', 'Fungerar ej']],
  [75, ['Lukkiutuu avaimen ollessa paikoillaan', 'Låses med nyckeln på plats']],
  [76, ['Peruutussalpa ei toimi', 'Backspärren fungerar inte']],
  [77, ['Toiminta virheellinen, tarkasta maadoitus', 'Felaktig funktion,kontrollera jordanslutningen']],
  [78, ['Avain mahdollista poistaa useassa lukkolaitteen asennossa', 'Nyckeln kan avlägsnas ur låset i flera olika lägen']],
  [79, ['Välyksellinen', 'Glapp']],
  [80, ['Välyksellinen mm. alapallonivel. Tarkasta myös muut pyöränripust. osat', 'Glapp,bla nedre kulled.Granska också hjulupphängningens andra delar']],
  [81, ['Välyksellinen mm. yläpallonivel. Tarkasta myös muut pyöränripust. osat', 'Glapp,bla övre kulled.Granska också hjulupphängningens andra delar']],
  [82, ['Välyksellinen mm. alatukivarren laaker. Tarkasta muutkin kohteen osat', 'Glapp,bla nedre stödarmens lager.Granska också de andra delarna']],
  [83, ['Välyksellinen mm. uloin raidet.pää. Tarkasta muutkin ohjauksen osat', 'Glapp,bla styrstagets yttre led.Granska också de andra delarna']],
  [84, ['Välyksellinen mm. raidetangon sisäpää. Tarkasta muutkin ohjauksen osat', 'Glapp,bla styrstagets inre led. Granska också de andra delarna i framvag']],
  [85, ['Välyksellinen mm. ylätukivarren laaker. Tarkasta muutkin kohteen osat.', 'Glapp,bla övre stödarmens lager.Granska också de anrda delarna']],
  [86, ['Ohjauslaitt./pyörän tuenta välyks. Rakenteesta johtuen kohde epäselvä.', 'Styrinrättningen/hjulupphängningen glapp.Pga konstruktionen oklart vilk']],
  [87, ['Pyörän tuenta/ohjauslaitt. välyks. Rakenteesta johtuen kohde epäselvä.', 'Hjulupphängningen/styrinrättningen glapp.Pga konstruktionen oklart vilk']],
  [88, ['Ruostevaurioitunut', 'Rostskadad']],
  [89, ['Ruostunut', 'Rostig']],
  [90, ['Useita ruostevaurioita', 'Flera rostskador']],
  [91, ['Kiinnityskohdassa ruostevaurioita', 'Rostskador i fastsättningspunkterna']],
  [92, ['Useita ruostevaurioita, voidaan korjata hitsaamalla', 'Flera rostskador, kan repareras genom svetsning']],
  [93, ['Useita ruostevaurioita, korjattava hitsaamalla', 'Flera rostskador, bör repareras genom svetsning']],
  [94, ['Ruostevaurioitunut, uusittava', 'Rostskadad, bör förnyas']],
  [95, ['Vaurioitunut', 'Skadad']],
  [96, ['Juuttunut', 'Fastnad']],
  [97, ['Rispaantunut, uusittava', 'Rispad, bör förnyas']],
  [98, ['Kiinnityskohta alustassa ruostevaurioitunut', 'Fastsättningen i underredet rostskadad']],
  [99, ['Virheellinen', 'Felaktig']],
  [100, ['Irti', 'Lös']],
  [101, ['Kiinnitys välyksellinen', 'Fastsättningen glapp']],
  [102, ['Kiinnitys virheellinen', 'Fastsättningen felaktig']],
  [103, ['Kiinnitys puutteellinen', 'Fastsättningen bristfällig']],
  [104, ['Varmistussokka puuttuu', 'Säkerhetssprinten fattas']],
  [105, ['Kiinnitysraudat poistettava tai puskuri asennettava', 'Fästjärnen bör borttagas eller stötfångaren monteras']],
  [106, ['Saranointi löystynyt', 'Gångjärnet glappt']],
  [107, ['Saranointi irti', 'Gångjärnet löst']],
  [108, ['Puuttuu', 'Fattas']],
  [110, ['Pääntuki puuttuu', 'Huvudstödet fattas']],
  [111, ['Ensiapupakkaus puuttuu', 'Förstahjälpväskan fattas']],
  [112, ['Hinausköysi puuttuu', 'Bogserlinan fattas']],
  [113, ['Tulensammutin puuttuu', 'Brandsläckaren fattas']],
  [114, ['Suojus puuttuu', 'Skyddet fattas']],
  [115, ['Kulunut', 'Sliten']],
  [117, ['Murtumia', 'Brott']],
  [118, ['Murtuma', 'Brott']],
  [119, ['Halkeama näkökentässä', 'Spricka i synfältet']],
  [120, ['Kiveniskemä näkökentässä', 'Stenskott i synfältet']],
  [121, ['Halkeama', 'Spricka']],
  [122, ['Nastoitettu/Nastoittamaton (sekarengastus)', 'Dubbad/odubbad (blandad däcktyp )']],
  [123, ['Sekarengastus', 'Blandad däcktyp']],
  [124, ['Rengas/vanneyhdistelmä ei yhteensopiva', 'Däck/fälgkombinationen inte passande']],
  [125, ['Vanne epäsopiva', 'Fälgen opassande']],
  [126, ['Urasyvyys liian pieni', 'Mönsterdjupet för grunt']],
  [127, ['Renkaan kantavuus ei riitä', 'Däckets bärförmåga otillräcklig']],
  [128, ['Rengaskoko muuttunut', 'Däckdimensionen ändrats']],
  [129, ['Nastaero liian suuri', 'Dubbskillnaden för stor']],
  [130, ['Muutoskatsastus suoritettava', 'Bör ändringsbesiktigas']],
  [131, ['Erikokoiset samalla akselilla', 'Olika storlekar på samma axel']],
  [132, ['Vaimennuskyky riittämätön', 'Otillräklig dämpverkan']],
  [133, ['Epämääräinen tai asiaankuulumaton ääni', 'Obestämt eller obehörigt ljud']],
  [135, ['Pyörän pyörimistila riittämätön', 'Hjulets fria utrymme otillräckligt']],
  [136, ['Pyörä hankaa toista osaa vasten', 'Hjulet skavar mot andra delar']],
  [137, ['Joustovara liian pieni', 'Fjädringsmån för liten']],
  [138, ['Murtunut', 'Sprucken']],
  [139, ['Katkennut', 'Brusten']],
  [140, ['Hankautunut', 'Sliten']],
  [141, ['Kuoleentunut', 'Uttröttad']],
  [142, ['Pakokaasumittausta ei suoritettu', 'Avgasmätningen inte utförd']],
  [143, ['Pakokaasumittausta ei suoritettu. Joutokäyntikierrosluku virheellinen', 'Avgasmätningen inte utförd. Felaktigt tomgångsvarvtal']],
  [144, ['Säätökierrosluku virheellinen.Savumittausta ei suoritettu.', 'Utsläppsmätningen inte utförd.Varvtalet felaktigt']],
  [145, ['Moottorin kunto puutteellinen. Savumittausta ei suoritettu.', 'Utsläppsmätningen inte utförd. Motorns kondition bristfällig']],
  [146, ['Kierrosluku riittämätön. Savumittausta ei suoritettu.', 'Utsläppsmätningen inte utförd. För lågt varvtal']],
  [148, ['Runsas happipitoisuus vääristää päästöarvoja.', 'Riklig syrehalt förvränger utsläppsvärden']],
  [149, ['Pakokaasumittaustodistusta ei voitu hyväksyä', 'Avgasmätningsprotokollet kan inte godkännas']],
  [150, ['Tarkastusmittauksessa saatiin erilaiset arvot kuin todistuksessa.', 'Vid kontrollmätning erhölls avikande resultat']],
  [151, ['Savumittaustodistusta ei voitu hyväksyä', 'Utsläppsmätningsprotokollet kan inte godkännas']],
  [152, ['Kaikki mitatut arvot poikkeavat sallitusta', 'Alla uppmätta värden aviker från de tillåtna värdena']],
  [153, ['CO joutokäynnillä liian korkea', 'CO halten på tomgång för hög']],
  [154, ['HC joutokäynnillä liian korkea', 'HC halten på tomgång för hög']],
  [155, ['CO korotetulla pyörimisnopeudella liian korkea', 'CO halten på förhöjt varvtal för hög']],
  [156, ['HC korotetulla pyörimisnopeudella liian korkea', 'HC halten på förhöjt varvtal för hög']],
  [157, ['Lambda poikkeaa sallituista arvoista korotetulla pyörimisnopeudella', 'Lambdans värde inte inom toleranserna']],
  [158, ['CO ja HC joutokäynnillä liian korkeat', 'CO- och HC-halterna för höga']],
  [159, ['CO ja HC korotetulla pyörimisnopeudella liian korkeat', 'CO- och HC-halterna på förhöjt varvtal för höga']],
  [160, ['Lambda arvoa ei mitattu', 'Lambdans värde inte granskad']],
  [162, ['Savutus liian suuri', 'För kraftig rökutveckling']],
  [163, ['Mittaria ei käytettävissä. Savumittausta ei suoritettu.', 'Utsläppsmätningen inte utförd. Mätare inte tillgänglig']],
  [164, ['Joutokäyntikierrosluku virheellinen, savumittausta ei voi hyväksyä.', 'Utsläppsmätningen inte utförd.Tomgångsvarvtalet felaktigt']],
  [165, ['Käyntilämpötila liian alhainen. Savumittausta ei suoritettu.', 'Utsläppsmätningen inte utförd.Brukstemperaturen för låg']],
  [166, ['Pakoputkisto vuotaa. Savumittausta ei suoritettu.', 'Utsläppsmätningen inte utförd.Avgasröret läcker']],
  [167, ['Suuntaus määräysten vastainen', 'Riktningen mot bestämmelserna']],
  [168, ['Maavara liian pieni', 'För liten frigång']],
  [169, ['Pakoputken pään ulkohalkaisija liian suuri', 'Avgasrörets yttre diameter för stor']],
  [170, ['Äänenvoimakkuus riittämätön', 'Otillräcklig ljudstyrka']],
  [171, ['Moottorin voi käynnistää vaihteen ollessa päällä', 'Startar med växeln i kör-läge']],
  [172, ['Oikosulun mahdollisuus', 'Fara för kortslutning']],
  [173, ['Vaurioitumisriski', 'Fara för skada']],
  [174, ['Palovaara', 'Brandfara']],
  [178, ['Toiminnan pettämisvaara', 'Fara för funktionsstörning']],
  [179, ['Irtoamisvaara', 'Risk för haveri']],
  [181, ['Johtojen kytkentä epäasiallinen', 'Ledningarnas koppling osaklig']],
  [182, ['Pistorasian kansi puuttuu', 'Kopplingsdosans lock fattas']],
  [183, ['Vuotaa', 'Läckage']],
  [184, ['Vuotaa (vaara, pakokaasut voivat tulla sisälle)', 'Läckage (fara,gaserna kan komma in i bilen )']],
  [185, ['Öljyvuoto', 'Oljeläckage']],
  [186, ['Öljyvuoto, palovaara', 'Oljeläckage,brandfara']],
  [187, ['Vuotaa polttonestettä', 'Bränsleläckage']],
  [188, ['Polttoainejärjestelmä vuotaa moottoritilassa', 'Bränslesystemet läcker i motorrummet']],
  [189, ['Bensiinivuoto, palovaara', 'Bensinläckage, brandfara']],
  [190, ['Vuotaa dieselöljyä', 'Naftaläckage']],
  [191, ['Vuotaa moottoritilassa, palovaara', 'Läckage i motorutrymmet,brandfara']],
  [192, ['Puoltaa', 'Drar']],
  [193, ['Jarruvaikutus riittämätön', 'Otillräcklig bromseffekt']],
  [194, ['Jarruissa heittoa (esim. soikea rumpu/kiero levy)', 'Kast i bromsarna (tex oval bromstrumma,skev bromsskiva )']],
  [196, ['Jarruvoima alle 16% kokonaismassasta', 'Bromskraften under 16% av totalmassan']],
  [197, ['Käyttölaitteen liikematka yli 3/4 kokonaisliikevarasta', 'Rörelsemån över 3/4 av totala rörelsemån']],
  [198, ['Käyttövoima ylittää 400N käsikäyttöisenä', 'Handreglagets kraft överskrider 400N']],
  [199, ['Käyttövoima ylittää 500N jalkakäyttöisenä', 'Bromspedalens kraft överskrider 500N']],
  [200, ['Vaijerin katkeamisvaara', 'Fara för vajerbrott']],
  [201, ['Takapainoiset', 'Baktunga']],
  [202, ['Puoltaa tiellä', 'Drar vid körning']],
  [203, ['Puoltaa tiellä vasemmalle', 'Drar till vänster vid körning']],
  [204, ['Puoltaa tiellä oikealle', 'Drar till höger vid körning']],
  [205, ['Täristää ajettaessa', 'Vibrerar vid körning']],
  [206, ['Nestepinta liian alhainen', 'Vätskenivån för låg']],
  [207, ['Nestesäiliö tyhjä', 'Vätskebehållaren tom']],
  [208, ['Laahaa', 'Ligger på']],
  [209, ['Laahaa hieman', 'Ligger på litet']],
  [210, ['Laahaa, dynamometrimittausta ei suoritettu', 'Ligger på, dynamometertest inte utförd']],
  [211, ['Laahaa, tarkasta myös seisontajarru', 'Ligger på, granska också parkeringsbromsen']],
  [212, ['Laahaa, tarkasta myös käyttöjarru', 'Ligger på, granska också färdbromsen']],
  [214, ['Etuaks. ja jousitus tarkastetaan jälkitark. yht. tapaturmavaarasta. joht', 'Framaxeln och fjädringen granskas i samband med eftergranskningen']],
  [215, ['Vetokoukun kuula rekisterikilven edessä', 'Dragkroken skymmer registerskylten']],
  [217, ['Jäykkätoiminen', 'Styv']],
  [218, ['Palauttaa huonosti', 'Returnerar dåligt']],
  [219, ['Ei palaudu', 'Centrerar ej']],
  [220, ['Toiminta takertelevaa', 'Funktionen kärvar']],
  [221, ['Puutteellinen', 'Bristfällig']],
  [222, ['Vaikea saada vaihteita kytketyksi', 'Svårt att få växlarna kopplade']],
  [223, ['Kytkin luistaa', 'Kopplingen slirar']],
  [224, ['Kytkin ei irroita kunnolla', 'Kopplingen frigör inte ordentligt']],
  [225, ['I-vaihde ei pysy päällä', 'I-ans växel hålls ej på']],
  [226, ['II-vaihde ei pysy päällä', 'II-ans växel hålls ej på']],
  [227, ['III-vaihde ei pysy päällä', 'III-ans växel hålls ej på']],
  [228, ['IV-vaihde ei pysy päällä', 'IV-ans växel hålls ej på']],
  [230, ['Ei voi avata', 'Kan ej öppnas']],
  [231, ['Teräviä reunoja', 'Vassa kanter']],
  [232, ['Leveys renkaaseen nähden riittämätön', 'Bredd otillräcklig i förhållande till däcken']],
  [233, ['Lukitus epävarma', 'Låsningen osäker']],
  [234, ['Lukitus puuttuu', 'Låsningen fattas']],
  [235, ['Ulostyöntyviä osia', 'Utstående delar']],
  [238, ['Pyyhinnopeus liian pieni', 'Torkningshastigheten för låg']],
  [239, ['Puhdistusteho riittämätön', 'Rengöringseffekten otillräcklig']],
  [240, ['Pyyhintäsektori vajaa', 'Torksektorn för liten']],
  [241, ['Lepoasento näkökentässä', 'Vid viloläge i synfältet']],
  [242, ['Vilkkumistaajuus virheellinen', 'Blinkhastigheten felaktig']],
  [244, ['Liian alas', 'För lågt']],
  [245, ['Korkeudensäätö ei toimi', 'Höjdjusteringen fungerar ej']],
  [246, ['Virheellinen, eikä korkeudensäätö toimi', 'Felaktig, höjdjusteringen fungerar ej']],
  [247, ['Kosteutta', 'Fukt']],
  [248, ['Kiveniskemiä', 'Stenskott']],
  [249, ['Samentunut', 'Grumlig']],
  [250, ['Valkoista valoa näkyy taaksepäin', 'Vitt ljus syns bakåt']],
  [251, ['Suuntaus liian ylös', 'Riktat för högt']],
  [253, ['Lähivalokuvio virheellinen', 'Närbelysningens ljusbild felaktig']],
  [254, ['Sivusuuntaus virheellinen', 'Sidoriktningen felaktig']],
  [255, ['Valokuvio epäselvä', 'Ljusbilden oklar']],
  [256, ['Valokuvio virheellinen ja epäselvä', 'Ljusbilden felaktig och oklar']],
  [257, ['Valovoima heikko', 'Svag ljuseffekt']],
  [258, ['Ylittää sallitun valovoiman (referenssi)', 'Överskrider tillåten ljuseffekt']],
  [259, ['Valaisimet eri paria väriltään, kooltaan ja valovoimaltaan', 'Lyktorna skilda par av färg, storlek eller ljuseffekt']],
  [260, ['Tummennus määräysten vastainen', 'Förmörkningen mot förordningen']],
  [261, ['Materiaali määräysten vastainen', 'Materialet mot förordningen']],
  [262, ['Peilikalvo poistettava', 'Spegelfolien bör avlägsnas']],
  [263, ['Riipuke poistettava näkökentästä', 'Maskoten bör avlägsnas från synfältet']],
  [264, ['Ei syty välittömästi', 'Tänds inte omedelbart']],
  [265, ['Kytkentä virheellinen', 'Felaktig koppling']],
  [266, ['Sijoitus ja kytkentä virheellinen', 'Placering och koppling felaktig']],
  [267, ['Palaa ajovalojen ollessa kytkettynä', 'Fungerar då körljusen är kopplade']],
  [268, ['Palaa muulloinkin kuin peruutusvaihteen ollessa kytkettynä', 'Lyser också då backväxeln inte är kopplad']],
  [269, ['Ei hyväksyttyä tyyppiä', 'Inte av godkänd modell']],
  [270, ['Ei sallittu -80 tai sen jälkeen käyttöönotetuissa autoissa', 'Inte godkänd för år -80 eller senare ibruktagna fordon']],
  [271, ['Näyttää väärin', 'Visar fel']],
  [272, ['Merkkivalon mukaan ei edellytyksiä toimia', 'Enligt varningslampan ingen funktion']],
  [273, ['Jäännösliikevara vähemmän kuin 1/4 kokonaisliikevarasta', 'Resterande rörelsemån under 1/4 av totalrörelsen']],
  [274, ['Poljin painuu pohjaan, ei jarruvaikutusta', 'Pedalen sjunker i botten, ingen bromsverkan']],
  [275, ['Poljin painuu pohjaan, mutta jarruvaikutus saadaan aikaan', 'Pedalen sjunker i botten, bromsverkan']],
  [276, ['Poljinvoima liian suuri', 'För stor pedalkraft']],
  [277, ['Liian lyhyt (paikaltaan menon riski)', 'För kort (med risk för att komma ur sitt läge)']],
  [278, ['Liian pitkä', 'För lång']],
  [279, ['Halkaisija liian suuri', 'Diametern för stor']],
  [280, ['Halkaisija liian pieni', 'Diametern för liten']],
  [281, ['Kehä liian lähellä muita hallintalaitteita', 'Ratten för nära de andra manöverorganen']],
  [282, ['Liukastussuoja puuttuu', 'Halkskyddet fattas']],
  [283, ['Ei pysy päällä', 'Hålls ej på']],
  [284, ['Vajoaa', 'Sjunker']],
  [285, ['Pehmeä', 'Mjuk']],
  [286, ['Muuttanut muotoaan', 'Har ändrat sin form']],
  [287, ['Lukitus ei toimi', 'Låsningen fungerar ej']],
  [288, ['Lukitus epävarma', 'Låsningen osäker']],
  [291, ['Ei hyväksyttyä mallia', 'Inte godkänd modell']],
  [292, ['Tehostaa vasemmalle ja oikealle eri voimilla', 'Effektiverar till höger och vänster med olik stor effekt']],
  [293, ['Vaurioituneet', 'Skadade']],
  [294, ['Kuluneet', 'Slitna']],
  [295, ['Väärä väri', 'Fel färg']],
  [296, ['Valovoima virheellinen', 'Ljuseffekten felaktig']],
  [297, ['Varuste lauennut', 'Utrustningen utlöst']],
  [300, ['Varuste poistettu', 'Utrustningen avlägsnad']],
  [301, ['Järjestelmä poistettu', 'Systemet fattas']],
  [302, ['Ajonestolaite puuttuu', 'Startblockeraren fattas']],
  [303, ['Ajonestolaitteen toiminta virheellinen', 'Startblockerarens funktion felaktig']],
  [304, ['Tarkastustodistus puuttuu', 'Granskningsprotokollet fattas']],
  [305, ['Tarkastustodistus virheellinen', 'Granskningsprotokollet felaktigt']],
  [306, ['Tarkastustodistus vanhentunut', 'Granskningsprotokollet föråldrat']],
  [309, ['Tehoton', 'Ineffektiv']],
  [310, ['Työkaluvarustus puutteellinen', 'Arbetsredskapen otillräckliga']],
  [311, ['Informaatiotarra puuttuu', 'Info-etiketten fattas']],
  [312, ['Putkisto vaurioitunut', 'Rörnätet skadat']],
  [313, ['Ei sallittu tässä ajoneuvoluokassa', 'Inte tillåtet i denna klass']],
  [314, ['Poikkeuslupa, asiakirja puuttuu', 'Undantagslov, fordonshandlingar fattas']],
  [315, ['Hidastin ei toimi', 'Retardern ur funktion']],
  [316, ['Pakokaasujarru ei toimi', 'Avgasbromsen ur funktion']],
  [317, ['Hidastin puuttuu', 'Retardern fattas']],
  [319, ['Mittaus suorittamatta', 'Mätningen inte utförd']],
  [320, ['Todistus virheellinen', 'Intyget felaktigt']],
  [321, ['Moottori ei käy joutokäyntiä', 'Motorn går inte på tomgång']],
  [322, ['Pakoputkisto vuotaa', 'Avgasröret läcker']],
  [323, ['Joutokäyntinopeus liian korkea, mittaustulosta ei voi hyväksyä', 'Tomgången för hög']],
  [324, ['Jarruvoimien ero liian suuri', 'För stor avvikelse på bromskrafterna']],
  [325, ['Syöpynyt', 'Frätt']],
  [326, ['Rajoittaa paineen liian alas', 'Reglerar trycket för lågt']],
  [327, ['Sallii liian suuren paineen', 'Tillåter för stort tryck']],
  [328, ['Likaantuneet', 'Smutsig']],
  [329, ['Virheellisesti säädetty', 'Felaktigt justerad']],
  [330, ['Soikea', 'Skev']],
  [331, ['Kiero', 'Skev']],
  [332, ['Liian lyhyt', 'För kort']],
  [333, ['Pituus virheellinen', 'Felaktig längd']],
  [334, ['Liian alhainen, tyhjä ajoneuvo', 'För lågt, tomt fordon']],
  [335, ['Liian korkea, tyhjä ajoneuvo', 'För högt, tomt fordon']],
  [336, ['Todistus säädöstenmukaisuudesta puuttuu', 'Intyg över uppfyllande av bestämmelser saknas']],
  [337, ['Sinistä savua', 'Blå rök']],
  [338, ['Laita rikki', 'Sidogavel sönder']],
  [339, ['Pohja rikki', 'Bottnen sönder']],
  [340, ['Repeämiä', 'Bristningar']],
  [341, ['Revennyt', 'Uppriven']],
  [342, ['Paineeton', 'Trycklös']],
  [343, ['Pykinyt', 'Sprucken']],
  [344, ['Tappi välyksellinen', 'Tappen glapp']],
  [345, ['Liukupää kulunut', 'Glidkolven nött']],
  [346, ['Ruuveja puuttuu', 'Skruvar fattas']],
  [348, ['Ruuvit löysällä', 'Skruvarna lösa']],
  [349, ['Silmukka kulunut', 'Dragöglan nött']],
  [350, ['Ei lukkiudu', 'Låses ej']],
  [352, ['Mutteri vaurioitunut', 'Muttern deformerad']],
  [353, ['Korkeus virheellinen', 'Höjden felaktig']],
  [354, ['Mutterin lukitus löysä', 'Mutterns låsning lös']],
  [355, ['Mutterin lukitus puuttuu', 'Mutterns låsning fattas']],
  [356, ['Taipunut', 'Krokig']],
  [357, ['Tarkastuspäivämäärä vanhentunut', 'Granskningsdagen föråldrad']],
  [358, ['Puutteelliset', 'Bristfälliga']],
  [359, ['Sisältö puutteellinen', 'Innehållet bristfälligt']],
  [361, ['Ajoneuvon leveyttä muutettu', 'Fordonets bredd ändrad']],
  [362, ['Jarrujärjestelmän rakennetta muutettu', 'Bromssystemets konstruktion ändrad']],
  [363, ['Jarruvoiman jakautuminen kuljettajan säädettävissä', 'Bromskraftens förhållande justerbar av chauffören']],
  [364, ['Vaihteiston öljyvuoto', 'Oljeläckage från växellådan']],
  [365, ['Tasauspyörästön öljyvuoto', 'Oljeläkage i differentialen']],
  [366, ['Opettajan peili puuttuu', 'Lärarens spegel fattas']],
  [367, ['Opettajan polkimen rakenne epävarma', 'Lärarens pedalkonstruktion osäker']],
  [368, ['Opettajan poljin puuttuu', 'Lärarens pedal fattas']],
  [369, ['Opettajan peilin kiinnitys virheellinen', 'Lärarens spegelfastsättning felaktig']],
  [370, ['Estää valojen näkyvyyttä', 'Hindrar belysningens synbarhet']],
  [371, ['Äänenvaimennus riittämätön', 'Ljuddämpningen är otillräcklig']],
  [372, ['Peruutusvaihde ei pysy päällä', 'Backväxeln hålls inte i']],
  [373, ['Peruutusvaihde ei mene päälle', 'Backväxeln går inte att sätta i']],
  [374, ['Nestevuoto', 'Vätskeläckage']],
  [375, ['Liian alhainen, kuormattu ajoneuvo', 'För lågt, belastat fordon']],
  [376, ['Liian korkea, kuormattu ajoneuvo', 'För högt, belastat fordon']],
  [377, ['Lisäpöytäkirja puuttuu', 'Tilläggsprotokollet fattas']],
  [378, ['Rekisteriotteen jatko-osa puuttuu', 'Registerutdragets tilläggsdel fattas']],
  [379, ['Työntömäntä välyksellinen', 'Påskjutskolven glapp']],
  [380, ['Työntömännän vaimennus puutteellinen', 'Påskjutskolvens dämpning otillräcklig']],
  [381, ['Työntömäntä pohjaa', 'Påskjutskolven går i ändläge']],
  [382, ['Reuna ruostevaurioitunut', 'Kanten rostskadad']],
  [383, ['Lasi rikki', 'Glaset sönder']],
  [384, ['Vaihteen osoitin puuttuu', 'Växelindikator saknas']],
  [385, ['Symbolit virheelliset', 'Indikatorns symboler felaktiga']],
  [386, ['Vaihteensiirto jäykkätoiminen', 'Växlingsmekanismen trög']],
  [387, ['Jakohihnan kunto epävarma.Savumittausta ei suoritettu.', 'Drivremmens konditon osäker. Rökmätningen inte utförd']],
  [388, ['Mainostarra tuulilasin yläosassa', 'Reklamtejp i vindrutans övre kant']],
  [389, ['Tarra tuulilasissa', 'Självhäftande etikett på vindrutan']],
  [390, ['Vasen ja oikea eriväriset', 'Vänster och höger olika färg']],
  [391, ['Koskettaa ajoneuvon toiseen osaan', 'Tar i annan del av fordonet']],
  [392, ['Merkkivalon perusteella ei toimi', 'Fungerar inte, enligt varningslampan']],
  [393, ['Merkkivalo ei toimi', 'Kontrollampan fungerar inte']],
  [394, ['Opetuspolkimella saavutettu hidastuvuus heikko', 'Uppnådd retardation med lärarens pedal svag']],
  [395, ['Opetuspolkimen kiinnitys epävarma', 'Lärarens pedalfastsättning osäker']],
  [396, ['Vaijerin kiinnitys epävarma', 'Vajerns fastsättning osäker']],
  [397, ['Öljymäärä vähäinen. Savutusmittausta ei suoritettu', 'För lite olja. Rökmätningen inte utförd']],
  [398, ['Jarrukortti puuttuu', 'Bromskort fattas']],
  [399, ['Jää päälle', 'Lämnar på']],
  [400, ['Mutteri puuttuu', 'Muttern fattas']],
  [402, ['Juuttunut, jarrumittausta ei voitu suorittaa', 'Fastnad, bromsning inte utförd']],
  [403, ['Puuttuu, jarrumittausta ei voitu suorittaa', 'Saknas, bromstest kunde inte utföras']],
  [405, ['Vivustossa liikaa välystä', 'För mycket glapp i mekanismen']],
  [406, ['Moottorissa on liikaa öljyä. Savutusmittausta ei voitu suorittaa', 'För mycket olja i motorn. Utsläppsmätningen inte utförd']],
  [407, ['Tasonsäätö ei toimi', 'Höjdlåsningen fungerar ej']],
  [408, ['Rakenne virheellinen', 'Konstruktionen felaktig']],
  [409, ['Laakerointi välyksellinen', 'Lagringen glapp']],
  [410, ['Rakenne ei ole kiinteä', 'Konstruktionen är inte fast']],
  [412, ['Liian matala', 'För låg']],
  [414, ['Mitoitus virheellinen', 'Måttsättning felaktig']],
  [415, ['Ajamista helpottavat laitteet poistettava', 'Utrustningen för underlättande av körning bör avlägsnas']],
  [416, ['Ajamista helpottavat laitteet muutoskatsastettava', 'Utrustningen för underlättande av körning bör ändringsbesiktas']],
  [418, ['Tasauspyörästö ei toimi', 'Differentialen fungerar inte']],
  [419, ['Säätö virheellinen', 'Justeringen felaktig']],
  [420, ['Varmistusvaijeri vaurioitunut', 'Säkrings vajer skadad']],
  [421, ['Varmistusvaijeri puuttuu', 'Säkrings vajer saknas']],
  [422, ['Vettä valaisimen sisällä', 'Vatten i lykstinsats']],
  [423, ['Nestekaasujärjestelmän tarkastustodistus puuttuu', 'Flytgassystemets granskningsprotokoll fattas']],
  [424, ['Nestekaasujärjestelmän tarkastustodistus puutteellinen', 'Flytgassystemets granskningsprotokoll bristfällig']],
  [425, ['Vivusto jäykkä', 'Mekanismen styv']],
  [427, ['Korotuspalojen mitoitus säädösten vastainen', 'Förhöjningsklossarnas mått mot bestämmelserna']],
  [428, ['Epäasialliset korotuspalat', 'Osakliga förhöjningsklossar']],
  [429, ['Maalaus tai pinnoite määräysten vastainen', 'Målning eller ytbehandling mot förordningen']],
  [430, ['Rengaspaine liian alhainen', 'Däckets lufttryck för lågt']],
  [431, ['Jarrulaskelma virheellinen', 'Bromsberäkningen felaktig']],
  [432, ['Jarrulaskelma puutteellinen', 'Bromsberäkningen bristfällig']],
  [433, ['Jarrulaskelma, Mittauslaitteiston kalibrointitodistus puuttuu', 'Bromsberäkning, Mätutrustningens kalibreringsintyg saknas']],
  [434, ['Peittää nopeusmittarin', 'Skymmer hastighetsmätaren']],
  [435, ['Toinen nopeus ei toimi', 'Andra hastigheten fungerar inte']],
  [438, ['Todistus rungon mitoista puuttuu', 'Ramens måttintyg saknas']],
  [439, ['Todistus pyörien asentokulmista puuttuu', 'Hjulinställningsintyg saknas']],
  [440, ['Todistus akselien asennosta puuttuu', 'Intyg över axlarnas inställning fattas']],
  [441, ['Todistus korin liitostavoista puuttuu', 'Intyg över karossens montering fattas']],
  [442, ['Todistus turvalaitteiden toimintakyvystä puuttuu', 'Intyg över säkerhetsutrustningens funktionsduglighet saknas']],
  [443, ['Todistus turvatyynyn toimintakyvystä puuttuu', 'Intyg över krockkuddens funktionsduglighet saknas']],
  [444, ['Todistus turvavyönkiristimen toimintakyvystä puuttuu', 'Intyg över säkerhetsbältesspännares funktionsduglighet saknas']],
  [445, ['Teippipinnoite säädösten vastainen', 'Plastfilmen uppfyller inte kraven']],
  [446, ['Ottaa kiinni pyörään', 'Tar fast i däcket']],
  [447, ['Jäähdytysnestevuoto', 'Kylvätskeläckage']],
  [448, ['Akselin suuntainen välys liian suuri', 'Glapp i axelns riktning för stort']],
  [449, ['Näkyvyyskulma ei täytä vaatimusta', 'Synlighetsvinkeln uppfyller inte bestämmelserna']],
  [450, ['Säätö ei toimi', 'Justeringen fungerar inte']],
  [451, ['Säätölaite vaurioitunut', 'Justermekanismen skadad']],
  [452, ['Liian korkea, tyhjänä ja kuormattuna', 'För högt, tom och belastad']],
  [453, ['Liian alhainen, tyhjänä ja kuormattuna', 'För lågt, tom och belastad']],
  [454, ['Suojakumi vaurioitunut', 'Skyddsgummet skadat']],
  [455, ['Kiertynyt', 'Vriden']],
  [456, ['Pysäköintilukko ei toimi automaattivaihteistossa', 'Parkeringsspärren fungerar inte i automatväxellådan']],
  [457, ['Vaihteita puuttuu', 'Växlar saknas']],
  [458, ['Käynnistyy muussa kuin N-ja P-asennossa', 'Startar i annat läge än N- och P']],
  [459, ['Autoveropäätös puuttuu', 'Bilskattebeslut fattas']],
  [460, ['Vaatimustenmukaisuustodistus (COC) puuttuu', 'Intyg saknas för uppfyllande av krav (COC)']],
  [461, ['Lämpölaskelma puuttuu', 'Värmeberäkning fattas']],
  [464, ['Sähköjärjestelmän tarkastustodistus puutteellinen', 'Elektricitetsystemets granskningsprotokoll bristfällig']],
  [465, ['Sähköjärjestelmän tarkastustodistus puuttuu', 'Elektricitetssystemets granskningsprotokoll fattas']],
  [466, ['Toiminta viiveellinen', 'Funktionen fördröjd']],
  [467, ['Haalistunut', 'Blacknad']],
  [468, ['Nousuviive liian pitkä', 'Anläggningstid för lång']],
  [469, ['Vapautusviive liian pitkä', 'Lossningstid för lång']],
  [473, ['Vuotaa nestettä', 'Vätskeläckage']],
  [474, ['Suuntaus virheellinen', 'Felaktigt riktad']],
  [475, ['Asento virheellinen', 'Felaktigt läge']],
  [476, ['Kahva puuttuu', 'Handtag saknas']],
  [477, ['Pistorasia vaurioitunut', 'Kontaktdosan skadad']],
  [478, ['Pistorasian etäisyys vetokuulasta virheellinen', 'Kontaktdosans avstånd till dragkulan felaktigt']],
  [479, ['Pistorasia puuttuu', 'Kontaktdosan saknas']],
  [480, ['Polttimo ei hyväksyttyä tyyppiä', 'Brännaren inte av godkänd typ']],
  [481, ['Liian korkea', 'För högt']],
  [482, ['Lukitus puutteellinen', 'Bristfällig låsning']],
  [483, ['Reikä', 'Hål']],
  [484, ['Sinetöinti puutteellinen', 'Plomberingen bristfällig']],
  [486, ['Kampikammion tuuletusjärjestelmä muutettu, Mittaustulos ei ole luotettava', 'Vevhusets ventilationssytem ändrad, Osäkert mätresultat']],
  [487, ['Kampikammion tuuletusjärjestelmä muutettu', 'Vevhusets ventilationssytem ändrad']],
  [489, ['Vivusto irti', 'Mekanismen är lös']],
  [490, ['Vivusto vaurioitunut', 'Mekanismen är skadad']],
  [491, ['Jousi irti', 'Fjädern lös']],
  [492, ['Jousi poikki', 'Fjädern brusten']],
  [493, ['Osaluettelo puuttuu, esitettävä', 'Delförteckning bör visas']],
  [494, ['Liian hidas', 'För långsam']],
  [495, ['Väärä koko', 'Fel storlek']],
  [496, ['Rakenne ja varustus ei täytä verovapauden ehtoja', 'Konstruktionen och utrustn. fyller inte kraven för skattefrihet']],
  [497, ['Rakenne ja varustus ei täytä ajoneuvoryhmän vaatimuksia', 'Konstruktionen och utrustn. fyller inte fordringarna på fordonsgruppen']],
  [498, ['Kumityyny vaurioitunut', 'Gummidyna skadad']],
  [500, ['Palaa koko ajan', 'Lyser oavbrytet']],
  [501, ['Pullistuma', 'Utbuktning']],
  [502, ['Liian kulunut', 'För sliten']],
  [503, ['Mittauspöytäkirjan arvot eivät ole toleranssien sisällä', 'Mätningsprotokollets värden är inte inom tillåtna gränser']],
  [504, ['Todistus jarruille suoritetuista korjauksista puuttuu', 'Intyg över bromsreparation saknas']],
  [505, ['Nivel välyksellinen', 'Glapp i led']],
  [506, ['Terävä uloke, tapaturmavaara', 'Vass utstickande del, olycksrisk']],
  [509, ['Moottori öljyinen', 'Motorn oljig']],
  [510, ['Öljypohja öljyinen', 'Oljetråget oljigt']],
  [511, ['Moottori/vaihteisto öljyinen', 'Motorn/växellådan oljig']],
  [512, ['Vesitilassa pakokaasua', 'Avgaser i kylarvätskan']],
  [513, ['Vaihteiston öljymäärä alhainen', 'Låg oljenivå i växellådan']],
  [514, ['Vaihteisto luistaa', 'Slirar i växlarna']],
  [515, ['Vaihteiston toiminta virheellinen', 'Växellådans funktion felaktig']],
  [516, ['Akun varaustila heikko', 'Batteriets laddningstillstånd svagt']],
  [517, ['Käyttöhihna huonokuntoinen', 'Fläktremmen i dåligt skick']],
  [518, ['Käyttöhihna luistaa', 'Fläktremmen slirar']],
  [520, ['Alustassa ruostetta', 'Rostskador på underredet']],
  [521, ['Reuna ruostunut', 'Kanten rostig']],
  [522, ['Kolarikorjattu', 'Krockskador reparerade']],
  [523, ['Ruostetta', 'Rost']],
  [524, ['Vaurio', 'Skada']],
  [526, ['Arvo poikkeaa tavanomaisesta', 'Värdet avviker från det normala']],
  [527, ['Kiehumispiste alhainen', 'Låg kokningspunkt']],
  [528, ['Kiehumispiste liian alhainen, jarrujen toiminta epävarma', 'För låg kokningspunkt, bromsarnas funktion osäker']],
  [529, ['Pakkaskestävyys riittämätön, jäätymisvaara suuri', 'Köldbeständigheten otillräcklig, risk för frysning']],
  [530, ['Ei pakkaskestävyyttä, moottori vaurioituu pakkasella', 'Inte köldbeständigt, motorn tar skada vid köldgrager']],
  [531, ['Pakkaskestävyys melko alhainen, jäätymisvaara', 'Köldbeständigheten låg, risk för frysning']],
  [532, ['Öljymäärä riittämätön', 'För lite olja']],
  [533, ['Verhoilu rikki', 'Beklädningen sönder']],
  [534, ['Verhoilu epäsiisti', 'Beklädningen i dåligt skick']],
  [535, ['Vaihteistossa liikaa öljyä', 'För hög oljenivå i växellådan']],
  [536, ['Jarrupolkimen sijainti virheellinen, liikaa vasemmalla', 'Bromspedalens placering felaktig, för långt åt vänster']],
  [537, ['Näkyvyys opettajan peilista puutteellinen', 'Sikten via lärarens spegel bristfällig']],
  [538, ['Kuormakorin kiinnitystodistus puuttuu', 'Lastkorgens monteringsintyg saknas']],
  [539, ['Selkänoja ei käänny, kulkuyhteys takaistuimelle puutteellinen', 'Ryggstödet går inte att fälla framåt, passage till baksätet förhindrad']],
  [543, ['Istuimen säätölaite ei toimi', 'Sätets justeranordning fungerar ej']],
  [544, ['Istuimen säätölaitteen toiminta virheellinen', 'Sätets justeranordning fungerar felaktigt']],
  [545, ['Selkänojan säätölaite ei toimi', 'Ryggstödets justeranordning fungerar ej']],
  [546, ['Selkänojan säätölaitteen toiminta virheellinen', 'Ryggstödets justeranordning fungerar felaktigt']],
  [547, ['Polkimen asento virheellinen, käyttö hankalaa', 'Pedalens läge felaktigt, svår att använda']],
  [548, ['Ominaisuuksiltaan keskenään erilaiset, vasen / oikea', 'Olika egenskaper, vänster/höger']],
  [549, ['Jousitusta muutettu, muutoskatsastettava', 'Fjädringen ändrad, bör ändringsbesiktas']],
  [550, ['Renkaiden vierintäkehä pienempi kuin asennustodistuksen mukaisessa renk.', 'Däckens omkrets är mindre än de som antecknats i intyget.']],
  [551, ['Todistus korin mitoista puuttuu', 'Intyg över karossens mått fattas']],
  [552, ['Perävaunun jarrujen yhteensopivuuslaskelma puuttuu tai puutteellinen', 'Släpvagnens bromsanpassningsberäkning är bristfällig eller saknas']],
  [553, ['Todistus muutetun jousituksen hyväksyttävyydestä', 'Kravenlighetsintyg för ändrad fjädring']],
  [554, ['Kilpi tai tunnus ei kuulu ajoneuvoon', 'Skylt eller igenkänningstecken hör inte till fordonet']],
  [555, ['Kansallisuustunnus tai muu merkintä liian lähellä rekisterikilpeä', 'Nationalitetsbeteckningen eller annat märke för nära registerskylten']],
  [556, ['Kompressorissa liiallinen öljyvuoto järjestelmään', 'För stort oljeläckage till systemet från kompressorn']],
  [557, ['Toimintapainealue virheellinen', 'Felaktigt arbetstrycksområde']],
  [558, ['Sisäinen vuoto', 'Inre läkage']],
  [559, ['Ylimääräinen jatkos', 'Extra skarv']],
  [560, ['Niveltapin lukitus puuttuu / puutteellinen', 'Ledtappens låsnings saknas/bristfällig']],
  [561, ['Letkunkiristin ei ole hyväksyttyä tyyppiä', 'Slangklämmare av icke godkänd typ']],
  [562, ['Iskunpituus liian suuri, säätö virheellinen', 'För lång slaglängd, felaktig justering']],
  [563, ['Iskunpituus liian suuri, kitkapinnat liian kuluneet', 'För lång slaglängd, beläggen slitna']],
  [564, ['Iskunpituus liian suuri', 'För lång slaglängd']],
  [565, ['Automaattinen säätölaite vaihdettu käsisäätöiseksi', 'Automatiska justeranordningen utbytt mot manuell justering']],
  [566, ['Vierintävastus liian suuri', 'Rullningsmotståndet för stort']],
  [567, ['Peruuttamisen mahdollistava laitteisto ei toimi tai puuttuu', 'Anordning som möjliggör backning fungerar ej, eller saknas']],
  [568, ['Toiminta tai vaikutus ei ole portaaton', 'Funktionen eller verkan är inte steglös']],
  [569, ['Ei ole käyttötarkoitukseen hyväksyttyä tyyppiä', 'Icke godgänd typ för användningsändamålet']],
  [570, ['Kaasupurkausvalaisimen automaattinen korkeudensäätölaite puuttuu', 'Automatiska höjdjusteringsanordningen för Xenon belysningen saknas']],
  [571, ['Ruostevaurio', 'Rostskada']],
  [573, ['Tähän ajoneuvoon sopimaton', 'Olämpligt för detta fordon']],
  [574, ['Merkkivalo ilmaisee vian', 'Kontrollampa meddelar om fel']],
  [575, ['Päästötaso ei täytä vaatimuksia', 'Avgasutsläppsnivån uppfyller inte kraven']],
  [576, ['Äänenvaimennusta muutettu', 'Ljuddämpningen ändrats']],
  [577, ['Äänenvaimennusrakenteet poistettu', 'Ljuddämpningsanordning har avlägsnats']],
  [578, ['Liian voimakas', 'För kraftig']],
  [579, ['Nestevuoto, palovaara', 'Vätskeläckage, brandfara']],
  [580, ['Nestevuoto, ympäristöhaitta', 'Vätskeläckage, påverkar miljö']],
  [581, ['Side puuttuu', 'Förbindning saknas']],
  [582, ['Keskitappi vaurioitunut', 'Centrumtappen skadad']],
  [583, ['Männänvarren välys liian suuri', 'Kolvstångens glapp för stort']],
  [584, ['Laitteisto väljä. Tarkasta korjauksen yhteydessä koko laitteisto.', 'Konstruktionen glapp. Kontrollera hela konstruktionen vid service']],
  [585, ['Liian suuri', 'För stor']],
  [586, ['Suojan/pinnoitteen irtoamisvaara', 'Fara att skyddet/ytbeläggningen lossnar']],
  [587, ['Kotelorakenteen ruostevaurio', 'Lådkonstruktionen skadad']],
  [589, ['Naarmuuntunut', 'Skråmad']],
  [590, ['Hyväksymismerkintä puuttuu', 'Godkänningsmärkning saknas']],
  [591, ['Ikkuna ei pysy suljettuna', 'Fönstret hålls inte stängt']],
  [592, ['Ikkunaa ei voi nostaa', 'Fönstret kan inte stängas']],
  [595, ['Kudosvaurio , rikkoutumisvaara', 'Vävskada, fara att gå sönder']],
  [596, ['Hätäjarrutustoiminto ei toimi', 'Nödbromsfunktionen fungerar ej']],
  [597, ['Syöttöpaineen tyhjennystoiminto ei toimi', 'Matartryckets tömningsfunktion fungerar ej']],
  [598, ['Lukituslaite välyksellinen', 'Glapp i låsanordningen']],
  [599, ['Mutterin suojus puuttuu', 'Mutterskydd saknas']],
  [600, ['Kierre vaurioitunut', 'Gängan skadad']],
  [601, ['Kiinnityskohdassa vaurio', 'Skada vid fastsättningspunkten']],
  [602, ['Lisälukituslaite puuttuu', 'Tilläggslåsanordning saknas']],
  [603, ['Ensiapupakkauksen sijaintikilpi puuttuu', 'Förstahjälpförpacknings placeringsskylt saknas']],
  [604, ['Tulensammuttimen sijaintikilpi puuttuu', 'Brandsläckarens placeringsskylt saknas']],
  [605, ['Hätäuloskäytävän tunnus tai ohje puuttuu', 'Nödutgångens kännetecken elle anvisning saknas']],
  [606, ['Rajoitusnopeus liian suuri', 'Begränsade hastigheten för hög']],
  [616, ['Asetustodistus puuttuu', 'Förordningsintyg saknas']],
  [617, ['Toimintavalmiuden osatestejä ei ole suoritettu', 'Självdiagnossystemets deltester inte utförda']],
  [618, ['MIL- merkkivalon toimintahäiriö', 'MIL- funktionsstörning i märklampan']],
  [619, ['Järjestelmämuistissa on vikakoodeja', 'I systemminnet finns indikationer om fel']],
  [620, ['Testin suorittaminen ei onnistu autosta johtuvasta syystä', 'Testen kunde inte utföras pga av orsak som beror på bilen']],
  [621, ['Lambda poikkeaa sallituista arvoista joutokäynnillä', 'Lamdavärdet avviker från tillåtna värden på tomgång']],
  [622, ['Lambdaintegraattorin arvo ei vaihtele, säätö ei toimi', 'Lamdaintegratorns värde ändras inte, justeringen fungerar ej']],
  [623, ['Lambdatunnistimen signaali ei vaihtele, säätö ei toimi', 'Lamdasondens signal varierar inte, justeringen fungerar ej']],
  [624, ['Lambdatunnistimen lambda-arvo ei vaihtele, säätö ei toimi', 'Lamdasondens lamda-värde ändras inte, justeringen fungerar ej']],
  [625, ['Syöpymisvaurio', 'Frätningsskada']],
  [626, ['Suojaus puutteellinen', 'Bristfälligt skydd']],
  [628, ['Vaihdettava ennen seuraavaa käyttöä liikenteessä', 'Bör bytas före följande användande i trafik']],
  [629, ['Välyksellinen mm. raidetangon sisäpää, tarkasta myös etuakseliston osat', 'Glapp, bl.a. inre styrled, granska även framaxelns delar']],
  [630, ['Välyksellinen mm. raidetangon ulkopää, tarkasta myös etuakseliston osat', 'Glapp,bla styrstagets yttre led. Granska också de andra delarna i framva']],
  [633, ['Valon väri väärä', 'Fel färg på ljuset']],
  [642, ['Näkyvyys puutteellinen', 'Sikten bristfälligt']],
  [643, ['Polttimon kiinnitys virheellinen', 'Brännarens fastsättning felaktig']],
  [644, ['Säännösten vastainen', 'Emot reglerna']],
  [645, ['Lukkiutumismahdollisuus moottorin käydessä', 'Möjligt att låsa med motorn i gång']],
  [646, ['Lukkiutumismahdollisuus virran ollessa kytkettynä', 'Möjligt att låsa när strömmen är påkopplad']],
  [647, ['Viallinen', 'Defekt']],
  [648, ['Symboli virheellinen', 'Felaktig symbol']],
  [649, ['V-vaihde ei pysy päällä', 'V-ans växel hålls ej på']],
  [650, ['VI-vaihde ei pysy päällä', 'VI-ans växel hålls ej på']],
  [666, ['Pyörintäsuunta väärä', 'Fel rotationsriktning']],
  [667, ['Este näkökentässä', 'Hinder i synfält']],
  [700, ['Aiheuttaa tarpeetonta ympäristöhaittaa.', 'Orsakar onödig miljöolägenhet.']],
  [701, ['Ajoneuvon kiinteä rakenneosa tai varuste peittää kilven', 'En fast konstruktionsdel eller ett tillbehör på fordonet täcker skylten']],
  [702, ['Ajoneuvossa ei liikennevakuutusta.', 'Fordonet saknar trafikförsäkring.']],
  [703, ['Akseliston kiinnityskohteissa ruostevaurioita.', 'Rostskador vid axlarnas fästpunkter.']],
  [704, ['Alipainevuoto, tehottomuus.', 'Vakuumläckage, låg effekt.']],
  [705, ['Apurunkojen kiinnityskohteissa ruostevaurioita.', 'Rostskador vid hjälpramarnas fästpunkter.']],
  [706, ['Asennettu muuhun ajoneuvoon, kuin tieliikennelain mukaiseen taksiin', 'Monterad på ett annat fordon än en taxi enligt vägtrafiklagen']],
  [707, ['Asennuskilpi puuttuu tai ei luettavissa.', 'Installationsskylten fattas eller är oläsbar.']],
  [708, ['Asetuskilpi tai -tarra puuttuu.', 'Installationsskylt eller -etikett fattas.']],
  [709, ['Asiapaperi yksilöimätön.', 'Ospecificerat dokument.']],
  [710, ['Automallikohtainen yleistä raja-arvoa suurempi raja-arvo ylittyy.', 'Ett för respektive bilmodell högre gränsvärde än det allmänna gränsvärdet överskrids.']],
  [711, ['Automatiikka ei toimi (kaasupurkausvalot ja muilla tekniikoilla toteutetut valaisimet (esimerkiksi LED) joiden teho on yli 2000 lumenia 1.10.2000 jälkeen)', 'Automatiken är ur funktion (gasurladdningslampor och med andra tekniker tillverkade lampor (t.ex. LED) vars effekt överstiger 2000 lumen efter 1.10.2000).']],
  [712, ['Ei luettavissa', 'Kan inte läsas']],
  [713, ['Ei voida tarkastaa', 'Kunde inte kontrolleras']],
  [714, ['Epänormaali viive pyöräjarrun toiminnassa.', 'Onormal fördröjning i hjulbromsens funktion.']],
  [715, ['Epätäydellinen', 'Ofullständig']],
  [716, ['Erivapaus puuttuu', 'Dispens fattas']],
  [717, ['Ilmavuoto.', 'Luftläckage.']],
  [718, ['Jarruletkujen kudosvauriot ja murtumat (sisempi kudos näkyvissä).', 'Vävskador och sprickor på bromsledningar (inre vävnaden syns).']],
  [719, ['Jarruneste likaista tai saostunutta.', 'Bromsvätskan är förorenad eller innehåller sediment.']],
  [720, ['Jarrupiiri pois toiminnasta.', 'En bromskrets är ur funktion.']],
  [721, ['Jarrut eivät kytkeydy.', 'Bromsarna ansätts inte.']],
  [722, ['Jarruvoima ei muutu tasaisesti suhteessa polkimen käyttövoimaan tai liikematkaan.', 'Bromskraften förändras inte jämnt i förhållande till pedalens ansättningskraft eller slaglängden.']],
  [723, ['Jatkuva runsas savutus.', 'Kontinuerligt rikligt med rök.']],
  [724, ['Johdot huonosti kiinni tai sijoitus altis oikosululle.', 'Ledningarna är bristfälligt monterade eller placering med risk för kortslutning.']],
  [725, ['Jousi rakenteeseen sopimaton.', 'Fjädern olämplig för konstruktionen.']],
  [726, ['Järjestelmästä puuttuu komponentti.', 'Det saknas en komponent i systemet.']],
  [727, ['Kahva lukittuu.', 'Handtaget låser sig.']],
  [728, ['Kaukonäyttölaite viallinen/puuttuu.', 'Fjärrvisningsanordning felaktig/fattas.']],
  [729, ['Kaukovalojen merkkivalo ei toimi.', 'Varningslampan för helljus är ur funktion.']],
  [730, ['Kiinnitys napaan puutteellinen tai sopimaton.', 'Bristfällig eller olämplig montering på navet.']],
  [731, ['Kitkapinnoissa öljyä tms.', 'Olja e.d. på friktionsytor.']],
  [732, ['Kompressorin liiallinen öljyvuoto järjestelmään.', 'För högt oljeläckage från kompressorn till systemet.']],
  [733, ['Korin kiinnityskohdissa ruostevaurioita.', 'Rostskador vid karossens fästpunkter.']],
  [734, ['Kytkentä virheellinen.', 'Felaktig koppling.']],
  [735, ['Laakeri liian tiukka', 'Lagret är för spänt']],
  [736, ['Laakerointi takertelee.', 'Lagret fastnar.']],
  [737, ['Laitetta käsitelty vilpillisesti.', 'Anordningen har behandlats oärligt.']],
  [738, ['Lehtien siteitä puuttuu.', 'Band runt fjäderblad fattas.']],
  [739, ['Lehtijousen päiden helat löysät.', 'Glappa beslag i ändan av bladfjädern.']],
  [740, ['Lähivalaisin ja saman puolen etuvalaisin eivät toimi.', 'En halvljusstrålkastare och främre positionslyktan på samma sida ur funktion.']],
  [741, ['Etuvalaisin ja saman puolen lähivalaisin eivät toimi.', 'Främre positionslyktan och halvljusstrålkastaren på samma sida är ur funktion.']],
  [742, ['Maavara riittämätön.', 'Otillräcklig markfrigång.']],
  [743, ['Merkintöjä välittömästi kilven etupuolella.', 'Märkningar direkt på skyltens framsida.']],
  [744, ['Mittaustodistus puuttuu tai puutteellinen.', 'Mätintyg fattas eller är bristfälligt.']],
  [745, ['Muu tarvittava selvitys puutteellinen tai epäselvä.', 'Annan nödvändig utredning är bristfällig eller oklar.']],
  [746, ['Ominaisuuksiltaan erilaiset renkaat samalla akselilla.', 'Däck med olika egenskaper på samma axel.']],
  [747, ['Paineilmajärjestelmän pyöräsylinterin liikevara alle 1/3.', 'Slaglängden för tryckluftsystemets hjulcylinder är under 1/3.']],
  [748, ['Pakkassuojalaitteen säiliö tyhjä.', 'Behållaren för frostskyddsmedel är tom.']],
  [749, ['Pakollinen järjestelmä tai sen osa ei toimi ja perussäätö kunnossa.', 'Obligatoriskt system eller en systemkomponent är ur funktion och grundinställningen är korrekt.']],
  [750, ['Pakollinen järjestelmä tai sen osa ei toimi ja perussäätö virheellinen.', 'Obligatoriskt system eller en systemkomponent är ur funktion och grundinställningen är felaktig.']],
  [751, ['Pienialainen yksittäinen ruostevaurio', 'Isolerad rostskada på litet område']],
  [752, ['Poikittaispalkkeja puuttuu tai kiinnitys puutteellinen.', 'Tvärgående balkar fattas eller är bristfälligt fästa.']],
  [753, ['Putki voimakkaasti syöpynyt.', 'Kraftigt korroderat rör.']],
  [754, ['Päätyy korirakenteen alle siten, että palovaara ilmeinen.', 'Slutar under karosskonstruktionen så att brandfaran är uppenbar.']],
  [755, ['Rajoitinkumi puuttuu.', 'Gummistopp fattas.']],
  [756, ['Rakenteeseen sopimaton.', 'Olämplig för konstruktionen.']],
  [757, ['Renkaat eivät vastaa asennuskilpeä.', 'Däcken motsvarar inte installationsskylten.']],
  [758, ['Saman akselin toinen vaimennin huomattavasti heikompi kuin toinen.', 'En dämpare på samma axel har sämre effekt än den andra.']],
  [759, ['Sijoitus vaarallisen lähellä polttoainesäiliötä tai -putkea (suojaamattomana).', 'Placerad farligt nära bränsletank eller bränslerör (oskyddat).']],
  [760, ['Suuri irtoamisvaara.', 'Stor lossningsfara.']],
  [761, ['Syttymisviive.', 'Fördröjd tändning.']],
  [762, ['Säätöarvokilpi puuttuu tai on vaurioitunut (raskas kalusto).', 'Reglervärdesskölden fattas eller skadad (tung materiel).']],
  [763, ['Tasapainotus puutteellinen/virheellinen.', 'Bristfällig/felaktig balansering.']],
  [764, ['Tasonsäätöventtiili ei toimi', 'Nivåregleringsventilen är ur funktion.']],
  [765, ['Taka-akseli lukkiutuu liian herkästi.', 'Bakaxeln låser sig för lätt.']],
  [766, ['Teho ei ole portaattomasti muuttuva.', 'Effekten förändras inte gradvis.']],
  [767, ['Todistus puuttuu tai puutteellinen', 'Intyg fattas eller är bristfälligt.']],
  [768, ['Toiminnan ilmaisin ei toimi.', 'Funktionskontrollanordningen är ur bruk.']],
  [769, ['Toimintapaine muu kuin 0-50 % säiliöpaineesta.', 'Funktionstrycket är inte 0-50 % av behållartrycket.']],
  [770, ['Toimintapainealue muu kuin 50-100 % jarrutuspaineesta.', 'Funktionstryckintervallet är inte 50-100 % av bromstrycket.']],
  [771, ['Vaarantaa liikenneturvallisuutta.', 'Äventyrar trafiksäkerheten.']],
  [772, ['Vaihtamisopastin ei toimi.', 'Hjälpsystem vid filbyte ur funktion.']],
  [773, ['Valaisimen vajaa toiminta.', 'Lampan fungerar bristfälligt.']],
  [774, ['Valonlähteen teho virheellinen.', 'Ljuskällans effekt är felaktig.']],
  [775, ['Vapaaehtoinen järjestelmä ei toimi ja perussäätö virheellinen.', 'Ett frivilligt system är ur funktion och grundinställningen är felaktig.']],
  [776, ['Vetokytkimen vapaatila riittämätön.', 'Dragkopplingens fria rum otillräckligt.']],
  [777, ['Vetokytkimen varmistusnasta ei toimi.', 'Dragkopplingens säkringsstift fungerar inte.']],
  [778, ['Vetolenkit puuttuu.', 'Draglänk fattas.']],
  [779, ['Vetävän akselin renkaat eivät vastaa nopeudenrajoittimen toiminnan tarkastuksesta annettuun todistukseen tai rekisteriin merkittyjä.', 'Den dragande axelns däck motsvarar inte intyget över kontrollen av hastighetsbegränsarens funktion eller anteckningarna i registret.']],
  [780, ['Voiman suunta virheellinen.', 'Felaktig kraftriktning.']],
  [781, ['Vähimmäistason arvoista saavutetaan alle 50 %.', 'Under 50 % av minimivärdena uppnås.']],
  [782, ['Väri muuttunut', 'Färgen har ändrats.']],
  [783, ['Väärän tyyppinen (Analoginen/Digipiirturi).', 'Fel typ (Analogisk/digiskrivare).']],
  [784, ['Täyttöaukon kansi puuttuu tai ei toimi.', 'Tanklocket saknas eller är bristfälligt.']],
  [800, ['Istuinpäällinen estää turvalaitteen toiminnan', 'Sätesöverdrag förhindrar skyddsanordningens funktion']],
  [801, ['Määräyksen vastainen', 'Strider mot föreskriften']],
  [802, ['Laakerointi vaurioitunut', 'Skadad lagring']],
  [803, ['Valaisimeen soveltumaton polttimo', 'Glödlampa inte kompatibel med strålkastaren']],
  [804, ['Ajoneuvo ilmoittaa viasta', 'Fordonet meddelar om ett fel']],
  [805, ['Pakollinen varuste puuttuu', 'Obligatorisk utrustning saknas']],
  [806, ['Toiminta puutteellinen', 'Bristfällig funktion']],
  [807, ['Vaaraa aiheuttava', 'Orsakar fara']],
  [808, ['Pakollinen järjestelmä puuttuu', 'Obligatoriskt system saknas']],
  [809, ['Merkintä puutteellinen', 'Bristfällig märkning']],
  [810, ['Liittimet vaurioituneet', 'Anslutningar skadade']],
  [811, ['Aiheuttaa palovaaran', 'Orsakar brandfara']],
  [812, ['Tukkeutunut', 'Tilltäppt']],
  [813, ['Osoittaa toimintahäiriön', 'Indikerar funktionsstörning']],
  [814, ['Vuodosta johtuva kaasun läsnäolo', 'Förekomst av gas på grund av läckage']],
  [815, ['Varoituslaitteen toiminta virheellinen', 'Varningsanordningen fungerar felaktigt']],
  [816, ['Varoituslaite osoittaa toimintahäiriön', 'Varningsanordningen indikerar funktionsstörning']],
  [817, ['Johdotus tai liittimet vaurioituneet', 'Ledningar eller anslutningar skadade']],
  [818, ['Sähköeristys vaurioitunut', 'Skadad elektrisk isolering']],
  [819, ['Komponentteja puuttuu', 'Komponenter saknas']],
  [820, ['Varoituslaitteen toimintahäiriö', 'Funktionsstörning hos varningsanordningen']],
  [821, ['Järjestelmän toimintahäiriö', 'Funktionsstörning i systemet']],
  [822, ['Kriittinen toimintahäiriö', 'Kritisk funktionsstörning']],
  [823, ['Ilmanvaihdon/jäähdytyksen häiriö', 'Störning i ventilations-/kylsystemet']],
  [824, ['Huoltoerotin vaurioitunut', 'Servicebrytaren skadad']],
  [825, ['Lasikatossa halkeama reunasta reunaan', 'Spricka i glastaket från kant till kant']],
  [999, ['Recall-vika korjaamatta', 'Recall-felet är inte reparerat']],
]);
