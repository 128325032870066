import { sendNotificationEvent } from '../../appShell/notificationHelpers';

export class UserDeleteManager {
  constructor(store, user) {
    this.store = store;
    this.user = user;
  }

  setServices(controlState, translationService) {
    this.controlState = controlState;
    this.translationService = translationService;
  }

  createConfirmationDialog() {
    const user = this.user();
    const name = `${user.firstName} ${user.lastName}`;
    const content = `${this.translationService.translate('usersView.deleteUserConfirmation')} '${name}'?`
    const acceptButtonText = this.translationService.translate('usersView.deleteItem');
    const cancelButtonText = this.translationService.translate('usersView.cancel');
    const confirmationDialog = document.createElement('confirmation-dialog');
    confirmationDialog.values = {
      content,
      onAccept: this.deleteUser,
      acceptButtonText,
      onCancel: this.removeConfirmationDialog,
      cancelButtonText
    }
    this.dialog = confirmationDialog;
    return this;
  }

  addConfirmationDialog(root) {
    this.createConfirmationDialog();
    this.root = root;
    window.requestAnimationFrame(() => this.root.appendChild(this.dialog));
  }

  removeConfirmationDialog = () => window.requestAnimationFrame(() => this.root.removeChild(this.dialog));


  deleteUser = async () => {
    try {
      await this.store.deleteUser(this.controlState.chainId, this.user().id);
      sendNotificationEvent(this.translationService.translate('usersView.userDeleted'));
      this.goToListView();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('usersView.userDeletedFailed'), 'error');
      this.removeConfirmationDialog();
    }
  }

  goToListView = () => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-list',
        data: {}
      },
    });
    this.root.dispatchEvent(navEvent);
  }
}
