import './users-list-view';
import './users-detail-root';
import css from './users-app-css';

class UsersApp extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.addModules();
    this.createRoot();
    this.addEventListeners();
  }

  //Add script tags to load required child components
  addModules = () => {
    // Create child elements and store references.
    this.usersListView = document.createElement('users-list-view');
    this.usersDetailRoot = document.createElement('users-detail-root');

    this.routes = {
      '/users-list': this.usersListView,
      '/users-detail': this.usersDetailRoot,
    };
    this.currentRoute = '/users-list';
  }


  createRoot = () => {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';

    this.root = document.createElement('div');
    this.root.setAttribute('id', 'users-app');
    this.root.setAttribute('class', 'app');
    this.shadowRoot.appendChild(this.root);
    window.requestAnimationFrame(() => this.initialRender());
  }

  pushRoute = () => {
    const routeName = this.navEvent.detail.route;
    this.currentRoute = routeName;
    this.mainContent.removeChild(this.mainContent.childNodes[0]);
    const route = this.routes[routeName];
    route.navData = this.navEvent.detail.data;
    this.mainContent.appendChild(route);
  }

  usersNavEventListener = (event) => {
    // Navigation event to React root.
    const navigationEvent = new CustomEvent('navigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: event.detail.route === '/users-detail'
          ? `/users/${event.detail.data.id || 'new'}`
          : '/users',
      },
    });
    window.dispatchEvent(navigationEvent);

    this.navEvent = event;
    window.requestAnimationFrame(() => this.pushRoute());
  }

  // Add event listeners for events dispatched by modules.
  addEventListeners = () => {
    this.addEventListener('usersNavEvent', this.usersNavEventListener);
  }

  initialRender = () => {
    const mainContent = this.mainContent = document.createElement('div');
    mainContent.setAttribute('class', 'main-content');
    this.root.appendChild(mainContent);
    this.mainContent.appendChild(this.usersListView);
  }
}
window.customElements.define('users-app', UsersApp);
