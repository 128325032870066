export default
`.form-container {
  display: flex;
  padding: 20px;
}

.form-column {
  flex: 1;
}

.form-column:not(:last-child) {
  border-right: 1px solid rgba(0,0,0,0.05);
  padding-right: 50px;
}

.form-column:not(:first-child) {
  padding-left: 50px;
}

.form-column > div {
  margin-bottom: 20px;
  max-width: 400px;
}

.secondary-title {
  font-size: 18px;
  color:rgba(0,0,0,0.6);
}

.input-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  margin-bottom: 7px;
}

input {
  box-sizing: border-box;
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  padding: 0px 12px;
  width: 100%;
}

input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
}

input.small {
  width: 120px;
}

input[error] {
  border-color: var(--errorColor);
}

.logo-input {
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 81px;
  background-color: var(--gray);
  max-width: 400px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-input > img {
  max-height: 80px;
  max-width: 390px;
}

.file-input {
  display: none;
}

.commissions-container {
  display: flex;
}

.commissions-container > div:not(:last-child) {
  margin-right: 20px;
}

.buttons-container {
  padding: 20px;
  border-top: 1px solid rgba(0,0,0,0.05);
}

.buttons-container > button:not(:last-child) {
  margin-right: 20px;
}

button {
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--buttonColor);
  background-color: var(--buttonBackground);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 140px;
  cursor: pointer;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button:focus {
  outline: none;
}

button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button[disabled].primary:hover {
  background-color: var(--primaryButtonBackground);
}

button[small] {
  width: 36px;
  min-width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-select-container {
  display: flex;
  align-items: center;
}

.company-select-container > div:first-child {
  flex: 1;
}

.company-select-container > button {
  margin-right: 10px;
}

.select-container {
  display: flex;
  align-items: center;
}

.select-container > .select {
  margin-right: 20px;
}

.select-container > button {
  margin-right: 10px;
}

.select {
  position: relative;
  box-sizing: border-box;
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  padding: 0px 12px;
  width: calc(100% - 24px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  cursor: pointer;
}

.select[fullWidth] {
  max-width: 400px;
  width: 100%;
  margin-right: 0;
}

.dropdown {
  position: absolute;
  max-height: 500px;
  transform: max-height 0.3s;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  top: calc(100% + 5px);
  font-size: 16px;
  line-height: 23px;
  border-radius: 4px;
  background-color: #FFFFFF;
  cursor: pointer;
  min-width: 230px;
  transition: max-height 0.3s;
  transition: box-shadow 0.1s;
  overflow: hidden;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  z-index: 1000;
  left: 0;
}

.dropdown.collapsed {
  max-height: 0px;
  box-shadow: none;
}

.dropdown > div {
  padding: 16px;
}

.dropdown > div:hover {
  color: var(--primaryColor);
}

.dropdown > div:not(:last-child) {
  padding-bottom: 8px;
}

.dropdown > div:not(:first-child) {
  padding-top: 8px;
}

.dropdown-backdrop {
  z-index: 100;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  cursor: default;
}

.dropdown-backdrop.collapsed {
  display: none;
}
`;