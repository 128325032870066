import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    duration: undefined,
    message: '',
    type: '',
  },
  reducers: {
    setNotification(state, { payload }) {
      state.duration = payload.duration;
      state.message = payload.message;
      state.type = payload.type;
    },
    clearNotification(state) {
      state.message = '';
    },
  },
});

export const {
  setNotification,
  clearNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
