import css from './chain-view-css';
import '../chain-info-section/chain-info-section';
import '../chain-companies-section/chain-companies-section';

window.customElements.define('chain-view', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    
    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance]
      ]
    );

    this.build();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }
  
  build() {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addRoot();
    this.addModules();
    window.requestAnimationFrame(() => { this.initialRender() });
  }

  addRoot = () => {
    const chainRoot = document.createElement('div');
    chainRoot.setAttribute('id', 'chain-view');
    chainRoot.setAttribute('class', 'main-container');
    this.root = chainRoot;
    this.shadowRoot.appendChild(chainRoot);
  }

  addModules() {
    // Create child elements and store references.
    this.chainInfoSection = document.createElement('chain-info-section');
    this.chainCompaniesSection = document.createElement('chain-companies-section');
  }

  /**
   * Set active tab button and update displayed content according to selected tab.
   * @param {object} event - Click event data.
   */
  setActiveTab = (event) => {
    // Reset tab styling and hide all content.
    const tabButtons = this.tabs.childNodes;
    tabButtons.forEach(button => button.removeAttribute('active'));
    const tabViews = this.contentRoot.childNodes;
    tabViews.forEach(view => view.setAttribute('hidden', ''));

    // Set selected tab active.
    event.target.setAttribute('active', '');

    // Unhide content according to tab value.
    const selectedValue = event.target.getAttribute('value');
    const selectedView = this.contentRoot.querySelector(`div[value=${selectedValue}]`);
    selectedView.hidden = false;
  }

  /**
   * Tab options in tab bar.
   */
  getTabDefinitions = () => [
    {
      text: this.translationService.translate('chainView.basicInfoAndCommissions'),
      value: 'info',
    },
    {
      text: this.translationService.translate('chainView.companies'),
      value: 'companies',
    },
  ];

  addTitle = () => {
    const title = document.createElement('div');
    title.setAttribute('class', 'title');
    title.textContent = this.translationService.translate('chain');
    this.root.appendChild(title);
  }

  addTabs = () => {
    const tabs = document.createElement('div');
    this.tabs = tabs;
    tabs.setAttribute('class', 'tabs');
    this.root.appendChild(tabs);

    this.getTabDefinitions().forEach((tabDef, index) => {
      const tab = document.createElement('div');
      if (index === 0) {
        tab.setAttribute('active', '');
      }
      tab.setAttribute('class', 'tab');
      tab.setAttribute('value', tabDef.value);
      tab.textContent = tabDef.text;
      tab.onclick = this.setActiveTab;
      tabs.appendChild(tab);
    });
  }

  initialRender = () => {
    this.addTitle();
    this.addTabs();

    const contentRoot = document.createElement('div');
    this.contentRoot = contentRoot;
    this.root.appendChild(contentRoot);

    const infoContent = document.createElement('div');
    infoContent.setAttribute('value', 'info');
    this.infoContent = infoContent;
    this.contentRoot.appendChild(infoContent);
    this.infoContent.appendChild(this.chainInfoSection);
    
    const companiesContent = document.createElement('div');
    companiesContent.setAttribute('value', 'companies');
    companiesContent.setAttribute('hidden', '');
    this.contentRoot.appendChild(companiesContent);
    this.companiesContent = companiesContent;
    this.companiesContent.appendChild(this.chainCompaniesSection);
  }
});
