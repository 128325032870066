import { sendNotificationEvent } from '../../../appShell/notificationHelpers';
import layout from './layout-html';
import addWhiteImage from '../../../assets/icons/add_white.svg';

const NAME = 'name';
const SITE_GROUP = 'siteGroup';
const ADDRESS = 'address';
const BUSINESS_IDENTIFIER = 'businessIdentifier';
const DESCRIPTION = 'description';
const SITE_COUNT = 'siteCount';
const COLUMN_IDS = [NAME, SITE_GROUP, ADDRESS, BUSINESS_IDENTIFIER];

class UserSitesSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      user: null,
      isBuilt: false,
      sites: [],
      siteGroups: [],
      userSites: [],
      userSiteGroups: [],
      selectedItem: null,
    };
  }

  // Sets translation service.
  setServices = (controlState, translationService) => {
    this.controlState = controlState;
    this.translationService = translationService;
  }

  build = async () => {
    this.shadowRoot.innerHTML = layout;

    // Confirm dialog.
    this.confirmationDialog = document.createElement('confirmation-dialog');

    // Form container.
    this.rootElement = this.shadowRoot.querySelector('.form-container');

    // Add sites button.
    this.addSitesButton = this.shadowRoot.getElementById('button-add-site');
    this.addSitesButton.lastElementChild.textContent = this.translationService.translate('usersView.addSites');
    this.addSitesButton.onclick = this.onAddSites;
    this.addSitesButton.querySelector('#add-icon').src = addWhiteImage;

    // Add site groups button.
    this.addSiteGroupButton = this.shadowRoot.getElementById('button-add-site-group');
    this.addSiteGroupButton.lastElementChild.textContent = this.translationService.translate('usersView.addSiteGroup');
    this.addSiteGroupButton.onclick = this.onAddSiteGroup;
    this.addSiteGroupButton.querySelector('#add-icon').src = addWhiteImage;

    // Delete user button.
    this.deleteUserButton = this.shadowRoot.getElementById('button-delete-user');
    this.deleteUserButton.appendChild(document.createTextNode(this.translationService.translate('usersView.delete')));
    this.deleteUserButton.onclick = this.onDeleteUser

    // Notice.
    this.shadowRoot.querySelector('.notice').appendChild(document.createTextNode(this.translationService.translate('usersView.notice')));

    this.refreshData();
    this._state.isBuilt = true;
  }

  connectedCallback() {
    if(!this._state.isBuilt) {
      this.build().then(() => this.fetchData());
    }

    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
  }

  // Handles data table events.
  handleDataTableEvent = (event) => {
    const payload = event.detail;
    if(payload.deleteRowData) {
      this.addConfirmationDialog(payload.deleteRowData);
    }
  }

  // Performs tab initialization with the given user data.
  init(store, user, sites, siteGroups, deleteManager) {
    this.store = store;
    this._state = {
      ...this._state,
      user,
      sites,
      siteGroups
    };
    this.deleteManager = deleteManager;
  }

  handleAcceptDeletion = async () => {
    this.removeConfirmationDialog();
    const selectedItem = this._state.selectedItem;
    this._state.selectedItem = null; // Remove selection.
    const isGroup = selectedItem.hasOwnProperty('siteCount');
    if(isGroup) {
      const userSiteGroups = this._state.userSiteGroups.filter(g => g !== Number(selectedItem.id));
      this._state.userSiteGroups = userSiteGroups;
      const data = {
        ...this._state.userData,
        siteGroups: JSON.stringify(userSiteGroups)
      };
      this.saveData(data);
      return;
    }

    // Remove site.
    const userSites = this._state.userSites.filter(g => g !== Number(selectedItem.id));
    this._state.userSites = userSites;
    const data = {
      ...this._state.userData,
      sites: JSON.stringify(userSites)
    };
    this.saveData(data);
    return;
  }

  addConfirmationDialog = (item) => {
    this._state.selectedItem = item;
    window.requestAnimationFrame(() => {
      this.confirmationDialog.values = {
        content: `${this.translationService.translate('usersView.deleteItemConfirmation')} '${item.name}'?`,
        onAccept: this.handleAcceptDeletion,
        acceptButtonText: this.translationService.translate('usersView.deleteItem'),
        onCancel: this.removeConfirmationDialog,
      };
      this.shadowRoot.appendChild(this.confirmationDialog);
    });
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.shadowRoot.removeChild(this.confirmationDialog));
  }

  onAddSites = () => {
    const sites = this._state.sites;
    let selectedSites = [...this._state.userSites];

    const dialog = document.createElement('data-select-dialog');
    dialog.setAttribute('title', this.translationService.translate('usersView.addSitesTitle'));
    dialog.setAttribute('cancel', this.translationService.translate('usersView.cancel'));
    dialog.setAttribute('ok', this.translationService.translate('usersView.add'));
    dialog.setAttribute('active', selectedSites.length ? 'true' : 'false');

    const dataTable = document.createElement('data-table');
    dataTable.dataAndLayout = {
      data: sites,
      columns: this.getTableColumnDefinitions(),
      sortBy: 'name',
      sortDirection: 'desc',
      paginated: false,
      selectable: true,
      selectedIds: selectedSites
    };
    dataTable.totalItemCount = sites.length;
    dialog.appendChild(dataTable);
    dataTable.addEventListener('dataTableEvent', (event) => {
      selectedSites = event.detail.selectedIds;
      dialog.setAttribute('active', 'true');
    });
    dialog.addEventListener('dDataSelect', (event) => {
      if(event.detail.flag) {
        window.requestAnimationFrame(() => this.shadowRoot.removeChild(dialog));
        selectedSites.sort();
        this._state.userSites = selectedSites;
        const data = {
          ...this._state.userData,
          sites: JSON.stringify(selectedSites)
        };
        this.saveData(data);
      } else {
        window.requestAnimationFrame(() => this.shadowRoot.removeChild(dialog));
      }
    })
    this.shadowRoot.appendChild(dialog);
  }

  onAddSiteGroup = () => {
    const siteGroups = this._state.siteGroups;
    let selectedSiteGroups = [...this._state.userSiteGroups];

    const dialog = document.createElement('data-select-dialog');
    dialog.setAttribute('title', this.translationService.translate('usersView.addSiteGroupsTitle'));
    dialog.setAttribute('cancel', this.translationService.translate('usersView.cancel'));
    dialog.setAttribute('ok', this.translationService.translate('usersView.add'));
    dialog.setAttribute('active', selectedSiteGroups.length ? 'true' : 'false');

    const dataTable = document.createElement('data-table');
    dataTable.dataAndLayout = {
      data: siteGroups,
      columns: [NAME, DESCRIPTION, SITE_COUNT].map((columnId) => {
        return {
          id: columnId,
          text: this.translationService.translate(`usersView.${columnId}`),
          sortable: false,
          primary: columnId === NAME,
        };
      }),
      sortBy: 'name',
      sortDirection: 'desc',
      paginated: false,
      selectable: true,
      selectedIds: selectedSiteGroups
    };
    dataTable.totalItemCount = siteGroups.length;
    dialog.appendChild(dataTable);
    dataTable.addEventListener('dataTableEvent', (event) => {
      selectedSiteGroups = event.detail.selectedIds;
      dialog.setAttribute('active', 'true');
    });
    dialog.addEventListener('dDataSelect', (event) => {
      if(event.detail.flag) {
        window.requestAnimationFrame(() => this.shadowRoot.removeChild(dialog));
        selectedSiteGroups.sort();
        this._state.userSiteGroups = selectedSiteGroups;
        const data = {
          ...this._state.userData,
          siteGroups: JSON.stringify(selectedSiteGroups)
        };
        this.saveData(data);
      } else {
        window.requestAnimationFrame(() => this.shadowRoot.removeChild(dialog));
      }
    })
    this.shadowRoot.appendChild(dialog);
  }

  onDeleteUser = () => this.deleteManager.addConfirmationDialog(this.shadowRoot);

  updateUserData = (userData) => {
    if (userData) {
      this._state.userSites = userData.sites;
      this._state.userSiteGroups = userData.siteGroups;
    }
    this._state.userData = userData;
  }

  fetchData = async () => {
    try {
      const data = { userId: this._state.user.id };
      const userData = await this.store.sendCrudRequest(this.controlState.chainId, 'GetUser', data);
      this.updateUserData(userData);
      this.refreshData();
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      sendNotificationEvent(this.translationService.translate('usersView.fetchFailed'), 'error');
    }
  }

  saveData = async (data) => {
    try {
      const userData = await this.store.sendCrudRequest(this.controlState.chainId, 'UpdateUser', {
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        preferredLanguage: data.preferredLanguage,
        sites: data.sites,
        siteGroups: data.siteGroups,
        siteSettings: data.siteSettings,
        permissions: data.permissions,
        lastSiteId: data.lastSiteId,
      });
      this.updateUserData({ ...userData, sites: userData.sites, preferredLanguage: data.preferredLanguage });
      this.refreshData();
      sendNotificationEvent(this.translationService.translate('chainView.updateSuccessful'));
    } catch (error) {
      console.error('Failed to save user data:', error);
      sendNotificationEvent(this.translationService.translate('chainView.updateFailed'), 'error');
    }
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`usersView.${columnId}`),
        sortable: false,
        primary: columnId === NAME,
      };
    });
  }

  removeGroup = (gid) => {
    const group = this._state.siteGroups.find(g => g.id === Number(gid));
    this.addConfirmationDialog(group);
  }

  refreshData = () => {
    if(!this.rootElement) {
      return;
    }

    // Remove current dynamic elements.
    this.rootElement.querySelectorAll('.content').forEach(n => n.remove());

    // Add site groups.
    for (const sgId of this._state.userSiteGroups) {
      const siteGroup = this._state.siteGroups.find(sg => sg.id === sgId);
      const sites = this._state.sites.filter(site => site.siteGroup === siteGroup.name);
      const collapsible = document.createElement('collapsible-section');
      collapsible.setAttribute('class', 'item content');
      collapsible.setAttribute('open', 'true');
      collapsible.setAttribute('count', `${sites.length}`);
      collapsible.setAttribute('title', siteGroup.name);
      collapsible.setAttribute('value', siteGroup.id);

      const dataTable = document.createElement('data-table');
      dataTable.dataAndLayout = {
        data: sites,
        columns: this.getTableColumnDefinitions(),
        sortBy: 'name',
        sortDirection: 'desc',
        paginated: false,
      };
      dataTable.totalItemCount = sites.length;
      collapsible.appendChild(dataTable);
      collapsible.addEventListener('dCollapsibleRemove', (event) => {
        // Remove group.
        this.removeGroup(event.detail.value);
      })
      this.rootElement.appendChild(collapsible);
    }

    // Single sites.
    const sites = this._state.sites.filter(site => this._state.userSites.includes(site.id));
    const collapsible = document.createElement('collapsible-section');
    collapsible.setAttribute('class', 'item content');
    collapsible.setAttribute('removable', 'false');
    collapsible.setAttribute('open', 'true');
    collapsible.setAttribute('count', `${sites.length}`);
    collapsible.setAttribute('title', this.translationService.translate('usersView.singleSites'));

    const dataTable = document.createElement('data-table');
    dataTable.dataAndLayout = {
      data: sites,
      columns: this.getTableColumnDefinitions(),
      sortBy: 'name',
      sortDirection: 'desc',
      paginated: false,
      removable: true,
    };
    dataTable.totalItemCount = sites.length;
    collapsible.appendChild(dataTable);
    this.rootElement.appendChild(collapsible);
  }
}

window.customElements.define('user-sites-section', UserSitesSection);
