export default `
.root-container {
  margin: 0px 20px;
}

.main-container {
  margin: 0px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 4px;
  min-height: 620px;
}

.title-container {
  display: flex;
  align-items: center;
  padding: 20px;
}

.title {
  font-size: 24px;
  color: rgba(0,0,0,0.87);
  margin: 20px;
  flex: 1;
}

.filter-container {
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  height: 36px;
  max-width: 340px;
  width: 100%;
  border-radius: 4px;
  padding: 0 10px;
  margin-right: 20px;
  margin-left: 20px;
}

.filter-container > input {
  background-color: transparent;
  outline: none;
  border: none;
  margin-left: 10px;
  font-family: var(--font);
  font-size: 16px;
}

.filter-container > input::placeholder {
  color: rgba(0,0,0,0.6);
}

.buttons-container {
  float: right;
}

.buttons-container > button:not(:last-child) {
  margin-right: 20px;
}

button {
  display: flex;
  align-items: center;
  height: 36px;
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3;
  text-transform: uppercase;
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 16px;
  min-width: 140px;
  cursor: pointer;
}

button > img {
  margin-right: 6px;
}

button:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button:focus {
  outline: none;
}
button[disabled] {
  opacity: 50%;
  cursor: default;
}

button[disabled]:hover {
  background-color: var(--buttonBackground);
}

button.primary {
  background-color: var(--primaryButtonBackground);
  color: var(--primaryButtonColor);
}

button.primary:hover {
  background-color: var(--primaryButtonBackgroundHover);
}

button[disabled].primary:hover {
  background-color: var(--primaryButtonBackground);
}

button[small] {
  width: 36px;
  min-width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;