import dayjs from 'dayjs';


export const SELF_MONITORING = 1;
export const INTERNAL_DEVIATION = 2;
export const CUSTOMER_FEEDBACK = 3;
export const AUTHORITY_FEEDBACK = 4;

export const DEVIATION_TYPES = new Map([
  [SELF_MONITORING, 'selfMonitoring'],
  [INTERNAL_DEVIATION, 'internalDeviation'],
  [CUSTOMER_FEEDBACK, 'customerFeedback'],
  [AUTHORITY_FEEDBACK, 'authorityFeedback'],
]);

export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const DEVIATION_TYPE_ID = 'deviationTypeId';
export const ALL_DEVIATION_TYPES = 'all';
export const ADDITION_INFO_FILTER = 'additionInfoFilter';
export const DEFAULT_QUALITY_CONTROL_DEVIATION_FILTERS = {
  [ADDITION_INFO_FILTER]: '',
  [START_TIME]: dayjs().subtract(1, 'month').startOf('day').format(),
  [END_TIME]: dayjs().endOf('day').format(),
  [DEVIATION_TYPE_ID]: ALL_DEVIATION_TYPES,
};