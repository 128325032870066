export class ProfileService {
  constructor() {
    this.user = {};
    this.getProfileCallCounter = 1;
    this.requestControlState();
    // Delay profile query, because access token is not available first time when this constructor is called.
    // TODO: find a better way to solve this.
    setTimeout(() => {
        this.getProfile();
      }, 500);
  }

  getProfile = async () => {
    // Check that access token is available.
    if (!this.accessToken) {
      console.warn('ProfileService: access token not available');
      this.getProfileCallCounter = this.getProfileCallCounter + 1;
      if (this.getProfileCallCounter <= 10) {
        setTimeout(() => {
            this.getProfile();
          }, 500);
      } else {
        console.error('ProfileService: no access token and max retry is exceeded');
      }
      return;
    }

    // Fetch user profile.
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/profile?code=${process.env.REACT_APP_API_CODE}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        [process.env.REACT_APP_AUTH_HEADER_NAME]: `Bearer ${this.accessToken}`
      },
    });
    const json = await response.json();
    this.user = {name: json.name, userType: json.userType, permissions: JSON.parse(json.permissions), token: '' };
    this.userProfileLoaded();
  }

  onControlStateChanged = (controlState) => {
    this.controlState = controlState;
    if (controlState.accessToken) {
      this.accessToken = controlState.accessToken;
    }
  }

  userProfileLoaded = () => {
    this.controlState.proposeChange('userChanged', this.user);
  }

  requestControlState = () => {
    const requestServiceEvent = new CustomEvent('requestService', {
      bubbles: true,
      composed: true,
      detail: {
        name: 'controlState',
        callback: this.onControlStateChanged,
      }
    });
    window.dispatchEvent(requestServiceEvent);
  };
}