import '../../company-form-dialog/company-form-dialog';
import { getCompaniesInChain, getSiteGroups } from './siteInfoStore';
import {
  refreshSelectField,
  refreshImageField,
  refreshInputField,
} from '../siteHelpers';
import { sendNotificationEvent } from '../../../appShell/notificationHelpers';
import css from './info-section-css';
import html from './info-section-html';
import { resizeImageAsBase64 } from '../../../assets/lib/commonFunctions';

// Form field IDs.
const NAME = 'name';
const SELF_SERVICE_ATJ_IDENTIFIER = 'selfServiceAtjIdentifier';
const SOKU_IDENTIFIER = 'sokuIdentifier';
const SITE_GROUP_ID = 'siteGroupId';
const LOGO_BASE_64 = 'logoBase64';
const COMPANY_ID = 'companyId';
const COMPANY_BUSINESS_IDENTIFIER = 'companyBusinessIdentifier';
const COMPANY_SOKU_IDENTIFIER = 'companySokuIdentifier';
const EMPTY_FORM_DATA = {
  [NAME]: '',
  [SOKU_IDENTIFIER]: '',
  [SELF_SERVICE_ATJ_IDENTIFIER]: '',
  [SITE_GROUP_ID]: null,
  [LOGO_BASE_64]: '',
  [COMPANY_ID]: null,
};

// Field types.
const INPUT = 'input';
const SELECT = 'select';
const IMAGE = 'image';
const FIXED_TEXT = 'fixedText';

class SiteInfoSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      onChange: null,
      formData: Object.assign({}, EMPTY_FORM_DATA),
      companies: [],
      siteGroups: [],
    };

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.build();
  }

  connectedCallback() {
    this.fetchCompanies();
    this.fetchSiteGroups();
  }

  disconnectedCallback() {
    this._state = {
      ...this._state,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };
  }

  set onChange(val) {
    this._state.onChange = val;
  }

  get isValid() {
    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    const requiredFieldsEmpty = this.getInputDefinitions()
      .some(def => def.required && !this._state.formData[def.id]);
    return errorInputs.length === 0 && !requiredFieldsEmpty;
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
  }

  setSite(site) {
    this._state.formData = Object.assign({}, site);
    this.refreshData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.lastAction === 'chainIdChanged') {
      this.fetchCompanies();
      this.fetchSiteGroups();
    }
  }

  build() {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>' + html;
    this.addModules();
    this.addRoot();
  }

  addRoot = () => {
    this.root = this.shadowRoot.getElementById('site-info-section');
    this.formContainer = this.shadowRoot.getElementById('form-container');
    this.fileInput = this.shadowRoot.getElementById('logoBase64-input');
    this.fileInput.addEventListener('change', this.uploadFile);
    this._state.isBuilt = true;
    this.renderForm();
  }

  addModules() {
    this.companyFormDialog = document.createElement('company-form-dialog');
  }

  fetchCompanies = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    try {
      this._fetchingData = true;
      const result = await getCompaniesInChain(this.controlState.chainId, this.controlState.accessToken);
      this._state.companies = result;
      this._fetchingData = false;
      window.requestAnimationFrame(this.refreshData);
    } catch (error) {
      console.log('Failed to fetch companies', error);
      sendNotificationEvent(this.translationService.translate('fetchFailed'), 'error');
      this._fetchingData = false;
    }
  }

  fetchSiteGroups = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    try {
      this._fetchingData = true;
      const result = await getSiteGroups(this.controlState.chainId, this.controlState.accessToken);
      this._state.siteGroups = result;
      this._fetchingData = false;
      window.requestAnimationFrame(this.refreshData);
    } catch (error) {
      console.log('Failed to fetch site groups', error);
      sendNotificationEvent(this.translationService.translate('fetchFailed'), 'error');
      this._fetchingData = false;
    }
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
    if (property === LOGO_BASE_64) {
      this._state.onChange(property, value);
      return;
    }
    // Set error attribute if value is invalid.
    const input = property === COMPANY_ID || property === SITE_GROUP_ID
      ? this.formContainer.querySelector(`div#${property}`)
      : this.formContainer.querySelector(`input#${property}`);
    const inputDef = this.getInputDefinitions().find(def => def.id === property);
    if (inputDef.required && !value) {
      input.setAttribute('error', '');
    } else if (value) {
      input.removeAttribute('error');
    }
    this._state.onChange(property, value);
    // If property uses select element. refresh content.
    if (property === COMPANY_ID || property === SITE_GROUP_ID) {
      this.refreshData();
    }
  }

  uploadFile = () => {
    const file = this.fileInput.files[0];
    if (!file) {
      return;
    }
    resizeImageAsBase64(file, (dataUrl) => {
      this.setFormValue(LOGO_BASE_64, dataUrl);
      this.refreshData();
    });
  }

  openFileInput = () => {
    this.fileInput.click();
  }

  getInputDefinitions = () => [
    {
      id: NAME,
      label: this.translationService.translate('siteView.name'),
      maxLength: 200,
      required: true,
      type: INPUT,
    },
    {
      id: SOKU_IDENTIFIER,
      label: this.translationService.translate('siteView.siteSokuIdentifier'),
      maxLength: 2000,
      required: true,
      type: INPUT,
    },
    {
      id: SELF_SERVICE_ATJ_IDENTIFIER,
      label: this.translationService.translate('siteView.selfServiceAtjIdentifier'),
      maxLength: 2000,
      required: false,
      type: INPUT,
    },
    {
      id: SITE_GROUP_ID,
      label: this.translationService.translate('siteView.siteGroup'),
      type: SELECT,
      options: this._state.siteGroups,
    },
    {
      id: LOGO_BASE_64,
      label: this.translationService.translate('siteView.logo'),
      type: IMAGE,
    },
    {
      id: COMPANY_ID,
      label: this.translationService.translate('siteView.company'),
      required: true,
      type: SELECT,
      options: this._state.companies,
    },
    {
      id: COMPANY_BUSINESS_IDENTIFIER,
      label: this.translationService.translate('siteView.businessIdentifier'),
      type: FIXED_TEXT,
    },
    {
      id: COMPANY_SOKU_IDENTIFIER,
      label: this.translationService.translate('siteView.companySokuIdentifier'),
      type: FIXED_TEXT,
    },
  ];

  addTitles = () => {
    const basicInfoTitle = this.shadowRoot.getElementById('basic-info-title');
    basicInfoTitle.textContent = this.translationService.translate('siteView.siteInfo');
    const companyInfoTitle = this.shadowRoot.getElementById('company-info-title');
    companyInfoTitle.textContent = this.translationService.translate('siteView.companyInfo');
  }

  refreshData = () => {
    if (!this.root) return;
    this.getInputDefinitions().forEach((inputDef) => {
      const label = this.shadowRoot.getElementById(`${inputDef.id}-label`);
      label.textContent = inputDef.required ? `${inputDef.label} *` : inputDef.label;
      const value = this._state.formData[inputDef.id];

      if (inputDef.type === INPUT) {
        refreshInputField(this.shadowRoot, inputDef, value, (event) => this.setFormValue(inputDef.id, event.target.value), this._fetchingData);
        return;
      }
      if (inputDef.type === SELECT) {
        refreshSelectField(this.shadowRoot, this.translationService, inputDef, value, this.setFormValue, this.addFormDialog);
        return;
      }
      if (inputDef.type === IMAGE) {
        refreshImageField(this.shadowRoot, this.translationService, inputDef, value, this.openFileInput, this._fetchingData);
        return;
      }
      if (inputDef.type === FIXED_TEXT) {
        const selectedCompany = this._state.formData.companyId && this._state.companies
          ? this._state.companies.find(company => company.id === this._state.formData.companyId)
          : null;
        let textValue = '-';
        if (inputDef.id === COMPANY_BUSINESS_IDENTIFIER) {
          textValue = (selectedCompany && selectedCompany.businessIdentifier) || '-';
        }
        if (inputDef.id === COMPANY_SOKU_IDENTIFIER) {
          textValue = (selectedCompany && selectedCompany.sokuIdentifier) || '-';
        }
        this.shadowRoot.getElementById(inputDef.id).textContent = textValue;
        return;
      }
    });
  }

  goToSitesList = () => {
    const navEvent = new CustomEvent('sitesNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/sites-list',
        data: {},
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  onDeleteCompany = (companyId) => {
    if (this._state.formData.companyId === companyId) {
      // Clear selected company if it was deleted.
      this.setFormValue(COMPANY_ID, null);
    }
  }

  addFormDialog = (companyId) => {
    window.requestAnimationFrame(() => {
      this.root.appendChild(this.companyFormDialog);
      if (companyId) {
        const company = this._state.companies.find(company => company.id === companyId);
        this.companyFormDialog.data = company;
        this.companyFormDialog.onDelete = this.onDeleteCompany;
      }
      this.companyFormDialog.onClose = this.removeFormDialog;
    });
  }

  removeFormDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.companyFormDialog));
    this.fetchCompanies();
  }

  renderForm = () => {
    this.addTitles();
    this.refreshData();
  }
}

window.customElements.define('site-info-section', SiteInfoSection);
