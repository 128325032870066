import { stableSort } from '../../assets/lib/commonFunctions'

function sendRequest(chainId, data = {}, accessToken) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + accessToken
    },
    body: JSON.stringify(data),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export class SiteGroupDetailStore {
  constructor() {
    this.siteGroup = null;
  }

  clearData() {
    this.siteGroup = null;
  }

  getSitesLength() {
    return this.siteGroup && this.siteGroup.sites
      ? this.siteGroup.sites.length
      : 0;
  }

  createSiteGroup = (chainId, data) => {
    const queryData = {
      chainId: data.chainId,
      name: data.name,
      description: data.description,
    };
    const queryObject = {
      procName: 'CreateSiteGroup',
      data: queryData,
    };
    return sendRequest(chainId, queryObject, this.accessToken);
  }

  updateSiteGroup = async (chainId, data) => {
    const queryData = {
      siteGroupId: data.id,
      name: data.name,
      description: data.description,
    };
    const queryObject = {
      procName: 'UpdateSiteGroup',
      data: queryData,
    };
    const result = await sendRequest(chainId, queryObject, this.accessToken);
    return result[0];
  }

  fetchSiteGroup = async (chainId, siteGroupId) => {
    const queryObject = {
      procName: 'GetSiteGroup',
      data: { siteGroupId },
    };
    const result = await sendRequest(chainId, queryObject, this.accessToken);
    this.siteGroup = result[0];
    return this.siteGroup;
  }

  getSiteGroup = (sortBy, sortDirection, index, itemsPerPage) => {
    const sites = this.siteGroup.sites || []
    let sortedSites = stableSort(sites, sortBy, sortDirection);
    sortedSites = sortedSites.slice(index * itemsPerPage, (index + 1) * itemsPerPage);
    return {
      ...this.siteGroup,
      sites: sortedSites,
    };
  }

  deleteSiteFromSiteGroup = async (chainId, siteId) => {
    const queryObject = {
      procName: 'UpdateSiteSiteGroups',
      data: { siteIds: [siteId] , siteGroupId: null },
    };
    await sendRequest(chainId, queryObject, this.accessToken);
  }

  fetchFreeSitesInChain = async (chainId) => {
    const queryObject = {
      procName: 'GetSitesInChain',
      data: { chainId },
    };
    const result = await sendRequest(chainId, queryObject, this.accessToken);
    return result.filter(site => !site.siteGroup);
  }

  updateSelectedSites = async (chainId, siteGroupId, siteIds) => {
    const queryObject = {
      procName: 'UpdateSiteSiteGroups',
      data: { siteIds, siteGroupId },
    };
    return sendRequest(chainId, queryObject, this.accessToken);
  }

  deleteSiteGroup = async (chainId, siteGroupId) => {
    const queryObject = {
      procName: 'DeleteSiteGroup',
      data: { siteGroupId },
    };
    await sendRequest(chainId, queryObject, this.accessToken);
  }
}