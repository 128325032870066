import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  errorMessage: {
    padding: '13px 12px',
    backgroundColor: 'rgba(197,0,0,0.1)',
    border: '1px solid #C50000',
    borderRadius: 4,
    color: '#C50000',
  }
}));

function ErrorMessage({message}) {
  const classes = useStyle();
  return (
    <div className={classes.errorMessage}>
      {message}
    </div>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorMessage;
