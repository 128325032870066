import { createCompany, updateCompany, deleteCompany } from './companyFormStore.js';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';
import css from './company-form-dialog-css';

// Form field IDs.
const NAME = 'name';
const BUSINESS_IDENTIFIER = 'businessIdentifier';
const SOKU_IDENTIFIER = 'sokuIdentifier';
const FORM_FIELD_IDS = [
  NAME,
  BUSINESS_IDENTIFIER,
  SOKU_IDENTIFIER,
];
const EMPTY_FORM_DATA = {
  [NAME]: '',
  [BUSINESS_IDENTIFIER]: '',
  [SOKU_IDENTIFIER]: '',
};

window.customElements.define('company-form-dialog', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    this._state = {
      originalData: Object.assign({}, EMPTY_FORM_DATA),
      formData: Object.assign({}, EMPTY_FORM_DATA),
      sendingData: false,
      onClose: () => {},
    }
    this.build();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  addModules() {
    this.confirmationDialog = document.createElement('confirmation-dialog');
  }

  disconnectedCallback() {
    this._state.originalData = Object.assign({}, EMPTY_FORM_DATA);
    this._state.formData = Object.assign({}, EMPTY_FORM_DATA);
    this.refreshData();
  }

  set onClose(value) {
    this._state.onClose = value;
  }

  set data(value) {
    this._state.formData = Object.assign({}, value);
    this._state.originalData = Object.assign({}, value);
    this.refreshData();
  }

  set onDelete(value) {
    this._state.onDelete = value;
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
  }

  build = async () => {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addModules();
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'company-form-dialog');
    this.shadowRoot.appendChild(this.root);

    const backdrop = document.createElement('div');
    backdrop.setAttribute('class', 'dialog-backdrop');
    this.root.appendChild(backdrop);

    const dialogContainer = document.createElement('div');
    dialogContainer.setAttribute('class', 'dialog-container');
    backdrop.appendChild(dialogContainer);

    const titleContainer = document.createElement('div');
    titleContainer.setAttribute('class', 'title');
    titleContainer.textContent = 'Uusi yritys';
    dialogContainer.appendChild(titleContainer);

    this.formContainer = document.createElement('div');
    this.formContainer.setAttribute('class', 'form-container');
    dialogContainer.appendChild(this.formContainer);

    this.buttonsContainer = document.createElement('div');
    this.buttonsContainer.setAttribute('class', 'buttons-container');
    dialogContainer.appendChild(this.buttonsContainer);

    this.renderForm();
  }

  createCompany = async () => {
    this._state.sendingData = true;
    this.checkFormButtonStatus();

    try {
      await createCompany({
        ...this._state.formData,
        chainId: this.controlState.chainId
      }, this.controlState.accessToken);
      sendNotificationEvent(this.translationService.translate('siteView.newCompanyCreated'));
      this._state.onClose();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('siteView.companyCreatingFailed'), 'error');
    }
    this._state.sendingData = false;
    this.checkFormButtonStatus();
  }

  updateCompany = async () => {
    this._state.sendingData = true;
    this.checkFormButtonStatus();

    try {
      await updateCompany({
        chainId: this.controlState.chainId,
        companyId: this._state.formData.id,
        [NAME]: this._state.formData[NAME],
        [BUSINESS_IDENTIFIER]: this._state.formData[BUSINESS_IDENTIFIER],
        [SOKU_IDENTIFIER]: this._state.formData[SOKU_IDENTIFIER],
      }, this.controlState.accessToken);
      sendNotificationEvent(this.translationService.translate('siteView.companyUpdated'));
      this._state.onClose();
    } catch (error) {
      sendNotificationEvent(this.translationService.translate('siteView.companyUpdateFailed'), 'error');
    }
    this._state.sendingData = false;
    this.checkFormButtonStatus();
  }

  /**
   * Checks if form is modified or contains any errors, and sets button statuses accordingly.
   */
  checkFormButtonStatus = () => {
    if (this._state.sendingData) {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.setAttribute('disabled', '');
    }

    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    if (errorInputs.length > 0) {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.removeAttribute('disabled');
      return;
    }

    if (this.getChangedFields().length === 0) {
      this.saveButton.setAttribute('disabled', '');
    } else {
      this.saveButton.removeAttribute('disabled');
    }
    this.cancelButton.removeAttribute('disabled');
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
    const input = this.formContainer.querySelector(`input#${property}`);

    if (!value) {
      input.setAttribute('error', '');
    } else if (value) {
      input.removeAttribute('error');
    }

    this.checkFormButtonStatus();
  }

  getChangedFields = () => {
    const changedFormFieldIds = [];

    FORM_FIELD_IDS.forEach((fieldId) => {
      if (this._state.originalData[fieldId] !== this._state.formData[fieldId]) {
        changedFormFieldIds.push(fieldId);
      }
    });
    return changedFormFieldIds;
  }

  getInputFieldDefinitions = () => [
    {
      id: NAME,
      label: this.translationService.translate('siteView.name'),
      maxLength: 200,
    },
    {
      id: BUSINESS_IDENTIFIER,
      label: this.translationService.translate('siteView.businessIdentifier'),
      maxLength: 200,
    },
    {
      id: SOKU_IDENTIFIER,
      label: this.translationService.translate('siteView.sokuIdentifier'),
      maxLength: 200,
    },
  ];

  handleSaveClick = () => {
    if (this._state.formData.id) {
      this.updateCompany();
    } else {
      this.createCompany();
    }
  }

  handleAcceptDeletion = async () => {
    this.removeConfirmationDialog();
    try {
      await deleteCompany({
        chainId: this.controlState.chainId,
        companyId: this._state.formData.id,
      }, this.controlState.accessToken);
      sendNotificationEvent(this.translationService.translate('siteView.companyDeleted'));
      this._state.onDelete(this._state.formData.id);
      this._state.onClose();
    } catch (error) {
      console.log('Failed to delete company', error);
      sendNotificationEvent(this.translationService.translate('siteView.companyDeletionFailed'), 'error');
    }
  }

  addConfirmationDialog = () => {
    window.requestAnimationFrame(() => {
      this.confirmationDialog.values = {
        content: `${this.translationService.translate('siteView.deleteCompanyConfirmation')} '${this._state.originalData.name}'?`,
        onAccept: this.handleAcceptDeletion,
        acceptButtonText: this.translationService.translate('siteView.deleteCompany'),
        onCancel: this.removeConfirmationDialog,
      };
      this.root.appendChild(this.confirmationDialog);
    });
  }

  removeConfirmationDialog = () => {
    window.requestAnimationFrame(() => this.root.removeChild(this.confirmationDialog));
  }

  addFields = () => {
    this.getInputFieldDefinitions().map((inputDef) => {
      const container = document.createElement('div');
      this.formContainer.appendChild(container);

      const label = document.createElement('div');
      label.setAttribute('class', 'input-label');
      label.textContent = inputDef.label;
      container.appendChild(label);

      const input = document.createElement('input');
      container.appendChild(input);
      input.setAttribute('id', inputDef.id);
      if (this._state.formData[inputDef.id]) {
        input.setAttribute('value', this._state.formData[inputDef.id]);
      }
      input.setAttribute('maxlength', inputDef.maxLength);
      if (this._sendingData) {
        input.setAttribute('disabled', '');
      }
      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
    });
  }

  addButtons = () => {
    if (this._state.formData.id) {
      this.deleteButton = document.createElement('button');
      this.deleteButton.textContent = this.translationService.translate('siteView.deleteCompany');
      this.deleteButton.onclick = this.addConfirmationDialog;
      this.buttonsContainer.appendChild(this.deleteButton);
    }
    const rightButtonContainer = document.createElement('div');
    this.buttonsContainer.appendChild(rightButtonContainer);

    this.cancelButton = document.createElement('button');
    this.cancelButton.textContent = this.translationService.translate('cancel');
    this.cancelButton.onclick = () => this._state.onClose();
    rightButtonContainer.appendChild(this.cancelButton);

    this.saveButton = document.createElement('button');
    this.saveButton.setAttribute('class', 'primary');
    this.saveButton.setAttribute('disabled', '');
    this.saveButton.textContent = this.translationService.translate('save');
    this.saveButton.onclick = this.handleSaveClick;
    rightButtonContainer.appendChild(this.saveButton);
  }

  renderForm = () => {
    this.addFields();
    this.addButtons();
  }

  refreshData = () => {
    if (!this.formContainer) {
      return;
    }
    while (this.formContainer.childNodes.length > 0) {
      this.formContainer.removeChild(this.formContainer.firstChild);
    }
    while (this.buttonsContainer.childNodes.length > 0) {
      this.buttonsContainer.removeChild(this.buttonsContainer.firstChild);
    }
    this.addFields();
    this.addButtons();
  }
});
