import { createSlice } from '@reduxjs/toolkit';
import { sendRequest } from '../api/api';

const vehicleGroupSlice = createSlice({
  name: 'vehicleGroup',
  initialState: {
    vehicleGroups: [],
    isFetching: false,
    fetchError: null,
  },
  reducers: {
    fetchVehicleGroupsStart(state) {
      state.isFetching = true;
    },
    fetchVehicleGroupsSuccess(state, { payload }) {
      state.vehicleGroups = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    fetchVehicleGroupsFailure(state, { payload }) {
      state.isFetching = false;
      state.fetchError = payload;
    },
  },
});

export const {
  fetchVehicleGroupsStart,
  fetchVehicleGroupsSuccess,
  fetchVehicleGroupsFailure,
} = vehicleGroupSlice.actions;

export default vehicleGroupSlice.reducer;

export const fetchVehicleGroups = (chainId) => async (dispatch, getState) => {
  try {
    dispatch(fetchVehicleGroupsStart());
    const queryData = {
      procName: 'GetVehicleGroupsInChain',
      data: { chainId },
    };
    const { auth } = getState();
    const vehicleGroups = await sendRequest(chainId, queryData, 'POST', auth.token);
    dispatch(fetchVehicleGroupsSuccess(vehicleGroups));
  } catch (err) {
    dispatch(fetchVehicleGroupsFailure(err));
  }
}
