import { stableSort } from '../../assets/lib/commonFunctions'

function sendRequest(chainId, data = {}, accessToken) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + accessToken
    },
    body: JSON.stringify(data),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

export class SiteGroupStore {
  constructor(translationService) {
    this.data = null;
    this.chainId = null;
    this.translate = translationService.translate;
  }

  setTranslationService(service) {
    this.translate = service.translate;
  }

  clearData() {
    this.data = null;
    this.chainId = null;
  }

  get dataLength() {
    return this.data ? this.data.length : 0;
  }

  filterData = (columnIds, filterValue) => {
    return this.data.filter((value) => columnIds.some(columnId => value[columnId] && value[columnId].includes(filterValue)));
  }

  getSiteGroupData = async (chainId, sortInfo, filterValue, columnIds, paginationInfo) => {
    // Event to Redux.
    const reduxEvent = new CustomEvent('reduxEvent', {
      bubbles: true,
      composed: true,
      detail: {
        action: 'fetchSiteGroups',
        data: { chainId },
      },
    });
    window.dispatchEvent(reduxEvent);

    const queryObject = {
      procName: 'GetSiteGroupsInChain',
      data: { chainId },
    };

    if (this.chainId !== chainId) {
      this.data = await sendRequest(chainId, queryObject, this.accessToken);
      this.chainId = chainId;
    }

    if (sortInfo) {
      const { sortBy, sortDirection, secondarySortBy } = sortInfo;
      this.data = stableSort(this.data, sortBy, sortDirection, secondarySortBy);
    }

    let result = this.data;
    if (filterValue) {
      result = this.filterData(columnIds, filterValue);
    }
    if (paginationInfo) {
      const { index, itemsPerPage } = paginationInfo;
      result = result.slice(index * itemsPerPage, (index + 1) * itemsPerPage);
    }
    return result;
  }
}