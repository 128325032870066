import { ChainUserStore } from '../chainUserStore.js';
import { sendNotificationEvent } from '../../../appShell/notificationHelpers';
import css from './info-section-css';

// Form field IDs.
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const EMAIL = 'email';
const PHONE_NUMBER = 'phoneNumber';
const USERNAME = 'username';
const USER_NUMBER = 'userNumber';
const STATE = 'isActive';
const FORM_FIELD_IDS = [
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  USERNAME,
  USER_NUMBER,
  STATE
];
const MANDATORY_FORM_FIELD_IDS = [FIRST_NAME, LAST_NAME, EMAIL, USERNAME];

const PASSWORD = 'password';
const PASSWORD_REPEAT = 'passwordRepeat';
const PASSWORD_MIN_LENGTH = 8;

class UserInfoSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this.store = new ChainUserStore(this.translationService);
    const userInitial = {
      [FIRST_NAME]: '',
      [LAST_NAME]: '',
      [EMAIL]: '',
      [PHONE_NUMBER]: '',
      [USERNAME]: '',
      [USER_NUMBER]: '',
      [STATE]: true,
    };
    this._state = {
      isFetching: false,
      user: userInitial,
      userOrig: Object.assign({}, userInitial),
    }

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.build();
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance
    this.store.setTranslationService(this.translationService);
  }

  init(store, user, sites, siteGroups, deleteManager, addDiscardConfirmationDialog, removeConfirmationDialog) {
    this._state = {
      ...this._state,
      user: {...this._state.user, ...user},
      userOrig: {...this._state.user, ...user},
    };
    this.deleteManager = deleteManager;
    this.addDiscardConfirmationDialog = addDiscardConfirmationDialog;
    this.removeConfirmationDialog = removeConfirmationDialog;
    this.refreshData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  async build() {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'chain-info-section');
    this.shadowRoot.appendChild(this.root);

    const formContainer = document.createElement('div');
    formContainer.setAttribute('class', 'form-container');
    this.formContainer = formContainer;
    this.root.appendChild(formContainer);

    const buttonsContainer = document.createElement('div');
    buttonsContainer.setAttribute('class', 'buttons-container');
    this.buttonsContainer = buttonsContainer;
    this.root.appendChild(buttonsContainer);

    this.renderForm();
  }

  renderForm = () => {
    this.addBasicInfoFields();
    this.addLoginFields();
    this.addButtons();
  }

  isUserDataValid = () => !MANDATORY_FORM_FIELD_IDS.some(f => !(this._state.user[f]));

  onDeleteUser = () => this.deleteManager.addConfirmationDialog(this.shadowRoot);

  /**
   * Checks if form is modified or contains any errors, and sets button statuses accordingly.
   */
  checkFormButtonStatus = () => {
    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    const isValid = this.isUserDataValid();
    if (errorInputs.length > 0 || !isValid) {
      this.saveButton.setAttribute('disabled', '');
      return;
    }

    (this.isDirty() && isValid) ? this.saveButton.removeAttribute('disabled') : this.saveButton.setAttribute('disabled', '');
  }

  checkPasswordButtonStatus = () => {
    const password = this._state.user[PASSWORD] || '';
    const passwordRepeat = this._state.user[PASSWORD_REPEAT] || '';

    // If either password field is empty, disable button.
    if (password.length === 0 || passwordRepeat.length === 0) {
      this.passwordInfoText.textContent = ''
      this.passwordSaveButton.setAttribute('disabled', '');
      return;
    }

    // Check minimum length.
    if (password.length < PASSWORD_MIN_LENGTH && passwordRepeat.length < PASSWORD_MIN_LENGTH) {
      this.passwordInfoText.textContent = `${this.translationService.translate('usersView.passwordMinimumLength')}: ${PASSWORD_MIN_LENGTH}`;
      this.passwordSaveButton.setAttribute('disabled', '');
      return;
    }

    // Check that repeat password is same as password.
    if (password === passwordRepeat) {
      this.passwordInfoText.textContent = ''
      this.passwordSaveButton.removeAttribute('disabled');
      return;
    }

    // Password mismatch.
    this.passwordInfoText.textContent = this.translationService.translate('usersView.passwordsDoNotMatch');
    this.passwordSaveButton.setAttribute('disabled', '');
  }

  setFormValue = (property, value) => {
    this._state.user[property] = value;

    if (property === PASSWORD || property === PASSWORD_REPEAT) {
      this.checkPasswordButtonStatus();
    } else {
      this.checkFormButtonStatus();
    }
  }

  // Helpers.
  isDirty = () => FORM_FIELD_IDS.map(fid => this._state.userOrig[fid] !== this._state.user[fid]).some(v => Boolean(v));
  isNew = () => !this._state.user.id;

  saveUser = async () => {
    if (this._state.isFetching) {
      return;
    }

    // Prevent sending multiple requests.
    this.saveButton.setAttribute('disabled', '');
    this._state.isFetching = true;

    // Create new.
    if(this.isNew()) {
      try {
        // Send data.
        let data = {...this._state.user, chainId: this.controlState.chainId, userType: 'user'};
        const response = await this.store.addUser(this.controlState.chainId, data);
        data = {...data, id: response.userId};

        // Update state and reset form.
        this._state = {
          ...this._state,
          user: Object.assign({}, data),
          userOrig: Object.assign({}, data)
        };

        // Tell parent about the new user.
        const navEvent = new CustomEvent('usersNavEvent', {
          bubbles: true,
          composed: true,
          detail: {
            route: '/users-detail',
            data: this._state.user
          },
        });
        this.shadowRoot.dispatchEvent(navEvent);

        // Done.
        sendNotificationEvent(this.translationService.translate('chainView.updateSuccessful'));
      } catch (error) {
        if (error.message.includes('usernameDuplicate')) {
          sendNotificationEvent(this.translationService.translate('usersView.usernameDuplicate'), 'error');
        } else {
          sendNotificationEvent(this.translationService.translate('usersView.saveFailed'), 'error');
        }
        this.saveButton.removeAttribute('disabled');
      }
      this._state.isFetching = false;
      return;
    }

    try {
      // Send data.
      const data = {...this._state.user, chainId: this.controlState.chainId, userType: 'user'};
      await this.store.updateUser(this.controlState.chainId, this._state.user.id, data);

      // Reset form state.
      this._state.userOrig = Object.assign({}, data);
      sendNotificationEvent(this.translationService.translate('chainView.updateSuccessful'));
    } catch (error) {
      if (error.message.includes('usernameDuplicate')) {
        sendNotificationEvent(this.translationService.translate('usersView.usernameDuplicate'), 'error');
      } else {
        sendNotificationEvent(this.translationService.translate('usersView.saveFailed'), 'error');
      }
      this.saveButton.removeAttribute('disabled');
    }

    this._state.isFetching = false;
  }

  goToListView = () => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-list',
        data: {}
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  handleDiscardChanges = () => {
    this.removeConfirmationDialog();
    this.goToListView();
  }

  onResetPassword = async () => {
    try {
      const data = {
        userId: this._state.user.id,
        [PASSWORD]: this._state.user[PASSWORD],
        [PASSWORD_REPEAT]: this._state.user[PASSWORD_REPEAT],
      };
      await this.store.resetPassword(this.controlState.chainId, data);
      sendNotificationEvent(this.translationService.translate('dataSaved'));

      // After updating password reset content.
      [PASSWORD, PASSWORD_REPEAT].forEach((inputId) => {
        const input = this.shadowRoot.getElementById(inputId);
        input.value = '';
        this.setFormValue(inputId, '');
      });
    } catch (error) {
      console.error('Failed to update password:', error);
      sendNotificationEvent(this.translationService.translate('saveFailed'), 'error');
    }
  }

  getBasicInfoInputDefinitions = () => [
    {
      id: FIRST_NAME,
      label: this.translationService.translate('usersView.firstName'),
      maxLength: 200,
      required: true,
    },
    {
      id: LAST_NAME,
      label: this.translationService.translate('usersView.lastName'),
      maxLength: 200,
      required: true,
    },
    {
      id: EMAIL,
      label: this.translationService.translate('usersView.email'),
      maxLength: 200,
      required: true,
    },
    {
      id: PHONE_NUMBER,
      label: this.translationService.translate('usersView.phoneNumber'),
      maxLength: 200,
    },
  ];

  addBasicInfoFields = () => {
    const basicInfoContainer = document.createElement('div');
    basicInfoContainer.setAttribute('class', 'form-column');
    this.formContainer.appendChild(basicInfoContainer);

    this.getBasicInfoInputDefinitions().forEach(inputDef => {
      const inputContainer = document.createElement('div');
      basicInfoContainer.appendChild(inputContainer);

      const inputLabel = document.createElement('div');
      inputLabel.setAttribute('class', 'input-label');
      inputLabel.textContent = `${inputDef.label}${inputDef.required ? ' *' : ''}`;
      inputContainer.appendChild(inputLabel);

      const input = document.createElement('input');
      input.setAttribute('id', inputDef.id);
      input.setAttribute('value', this._state.user[inputDef.id] || '');
      input.setAttribute('maxlength', inputDef.maxLength);
      if (this._state.isFetching) {
        input.setAttribute('disabled', '');
      }
      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
      inputContainer.appendChild(input);
    });
  }

  getLoginInputDefinitions = () => [
    {
      id: USERNAME,
      label: this.translationService.translate('usersView.username'),
      maxLength: 200,
      required: true,
    },
    {
      id: USER_NUMBER,
      label: this.translationService.translate('usersView.userNumber'),
      maxLength: 50,
      required: false,
    },
    {
      id: STATE,
      label: this.translationService.translate('usersView.state'),
      maxLength: 200,
    },
    {
      id: PASSWORD,
      label: this.translationService.translate('myInfoView.enterNewPassword'),
      maxLength: 50,
    },
    {
      id: PASSWORD_REPEAT,
      label: this.translationService.translate('myInfoView.repeatNewPassword'),
      maxLength: 50,
    },
  ];

  addLoginFields = () => {
    const loginContainer = document.createElement('div');
    loginContainer.setAttribute('class', 'form-column');
    this.formContainer.appendChild(loginContainer);

    const userHasWritePermission = this.controlState.user?.permissions && this.controlState.user?.permissions.find(permission => permission.scope === 'allowPwdChange' && permission.canWrite);
    const userIsAdmin = ['superuser', 'admin'].includes(this.controlState.user?.userType?.toLowerCase());
    const editingExistingUser = Boolean(this._state.user?.id);

    this.getLoginInputDefinitions().forEach(inputDef => {
      const inputContainer = document.createElement('div');
      loginContainer.appendChild(inputContainer);

      const inputLabel = document.createElement('div');
      inputLabel.setAttribute('class', 'input-label');
      inputLabel.textContent = `${inputDef.label}${inputDef.required ? ' *' : ''}`;
      inputContainer.appendChild(inputLabel);

      if (inputDef.id === STATE) {
        const input = document.createElement('select');
        input.setAttribute('id', inputDef.id);
        input[0] = new Option(this.translationService.translate('active'), 1, false, this._state.user[inputDef.id] === true);
        input[1] = new Option(this.translationService.translate('inActive'), 0, false, this._state.user[inputDef.id] === false);
        input.setAttribute('maxlength', inputDef.maxLength);
        if (this._state.isFetching) {
          input.setAttribute('disabled', '');
        }
        input.addEventListener('change', () => this.setFormValue(STATE, input.value === '1'));
        inputContainer.appendChild(input);
        return;
      }

      if (inputDef.id === PASSWORD || inputDef.id === PASSWORD_REPEAT) {
        // Show password fields if user is an admin and is editing an existing user.
        if ((userIsAdmin || userHasWritePermission) && editingExistingUser) {
          const passwordInput = document.createElement('input');
          passwordInput.setAttribute('id', inputDef.id);
          passwordInput.setAttribute('type', 'password');
          passwordInput.setAttribute('maxlength', inputDef.maxLength);
          passwordInput.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
          inputContainer.appendChild(passwordInput);
        } else {
          inputLabel.setAttribute('hidden', '');
        }
        return;
      }

      const input = document.createElement('input');
      input.setAttribute('id', inputDef.id);
      input.setAttribute('value', this._state.user[inputDef.id] || '');
      input.setAttribute('maxlength', inputDef.maxLength);
      if (this._state.isFetching) {
        input.setAttribute('disabled', '');
      }
      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
      inputContainer.appendChild(input);
    });

    // Show password save button if user is an admin and is editing an existing user.
    if ((userIsAdmin || userHasWritePermission) && editingExistingUser) {
      const passwordButtonDiv = document.createElement('div');
      passwordButtonDiv.setAttribute('class', 'password-button-div');
      loginContainer.appendChild(passwordButtonDiv);

      this.passwordSaveButton = document.createElement('button');
      this.passwordSaveButton.textContent = this.translationService.translate('usersView.changePassword');
      this.passwordSaveButton.setAttribute('disabled', '');
      this.passwordSaveButton.onclick = this.onResetPassword;
      passwordButtonDiv.appendChild(this.passwordSaveButton);

      this.passwordInfoText = document.createElement('div');
      passwordButtonDiv.appendChild(this.passwordInfoText);
    }
  }

  addButtons = () => {
    const elem = document.createElement('div');

    this.saveButton = document.createElement('button');
    this.saveButton.setAttribute('class', 'primary');
    this.saveButton.setAttribute('disabled', '');
    this.saveButton.textContent = this.translationService.translate('save');
    this.saveButton.onclick = this.saveUser;
    elem.appendChild(this.saveButton);

    this.cancelButton = document.createElement('button');
    this.cancelButton.textContent = this.translationService.translate('cancel');
    this.cancelButton.onclick = () => {
      if (this.isDirty()) {
        this.addDiscardConfirmationDialog(() => this.goToListView());
      } else {
        this.goToListView();
      }
    }
    elem.appendChild(this.cancelButton);

    this.buttonsContainer.appendChild(elem);

    this.deleteButton = document.createElement('button');
    this.deleteButton.textContent = this.translationService.translate('usersView.delete');
    this.deleteButton.onclick = this.onDeleteUser;
    this.buttonsContainer.appendChild(this.deleteButton);
  }

  refreshForm = () => {
    this.addBasicInfoFields();
    this.addLoginFields();
  }

  /**
   * Refresh view with updated data.
   */
  refreshData = () => {
    while (this.formContainer.childNodes.length > 0) {
      this.formContainer.removeChild(this.formContainer.firstChild);
    }

    this.refreshForm();
  }
}

window.customElements.define('user-info-section', UserInfoSection);