import css from './job-types-css';
import { JobTypeStore } from './jobTypeStore.js';
import addWhiteImage from '../../assets/icons/add_white.svg';
import searchImage from '../../assets/icons/search.svg';

const NAME = 'name';
const CATEGORY = 'category'
const COLUMN_IDS = [CATEGORY, NAME];

window.customElements.define('job-types-view', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };
    this.store = new JobTypeStore();
    this._state = {
      data: [],
      sortBy: NAME,
      sortDirection: 'asc',
      isBuilt: false,
      categoryValue: null,
      filterValue: null
    };

    this.requestServices(
      [
        ['translationService', (instance) => {this.translationService = instance; }],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
    this.addEventListener('dataTableRowClick', this.handleDataTableRowClick);
  }

  connectedCallback() {
    if(!this._state.isBuilt) {
      this.addRoot().then( () =>
        window.requestAnimationFrame(() => { this.render() }));
      this._state.isBuilt = true;
    } else {
      window.requestAnimationFrame(() => { this.refreshData() });
    }
  }

  disconnectedCallback() {
    this.store.clearData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged' || controlState.lastAction === 'chainIdChanged') {
      this.refreshData();
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
    this.refreshData();
  }

  handleDataTableRowClick = (event) => {
    if(!event.detail.data) {
      return;
    }

    const jobTypeNavEvent = new CustomEvent( 'jobTypeNavigationEvent', {
      bubbles: true,
      composed: true,
      detail: { route: 'jobTypeDetail' , jobTypeId: event.detail.data.id}
    });
    this.shadowRoot.dispatchEvent(jobTypeNavEvent);
  }

  addRoot = async () => {
    const root = document.createElement('div');
    root.setAttribute('id', 'job-types-view');
    root.setAttribute('class', 'main-container');
    this.root = root;
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.shadowRoot.appendChild(root);
  }

  addTitle = () => {
    const titleContainer = document.createElement('div');
    titleContainer.setAttribute('class', 'title-container');
    //Title text
    const title = document.createElement('div');
    title.setAttribute('class', 'title');
    title.textContent = this.translationService.translate('jobTypes');
    titleContainer.appendChild(title);
    //Search
    this.addSearchInput(titleContainer);
    //Category selecter
    const categoryContainer = document.createElement('div')
    categoryContainer.setAttribute('class','category-container');
    this.categorySelect = document.createElement('select');
    this.categorySelect.setAttribute('class', 'dropdown');
    this.categorySelect.onchange = this.handleCategoryChange;
    categoryContainer.appendChild(this.categorySelect);
    titleContainer.appendChild(categoryContainer);
    //Add button
    this.buttonContainer = document.createElement('div');
    this.buttonContainer.setAttribute('class', 'buttons-container');
    const addButton = document.createElement('button');
    addButton.setAttribute('id', 'add-button');
    addButton.setAttribute('class', 'primary');
    addButton.onclick = this.addJobType;
    const addIcon = document.createElement('img');
    addIcon.setAttribute('src', addWhiteImage);
    addButton.appendChild(addIcon);
    const buttonText = document.createElement('div');
    buttonText.textContent = this.translationService.translate('jobTypesView.addJobType');
    addButton.appendChild(buttonText);
    this.buttonContainer.appendChild(addButton);
    titleContainer.appendChild(this.buttonContainer);
    this.root.appendChild(titleContainer);
  }

  addSearchInput = (parent) => {
    const filterContainer = document.createElement('label');
    filterContainer.setAttribute('class', 'filter-container');
    parent.appendChild(filterContainer);
    const filterIcon = document.createElement('img');
    filterIcon.setAttribute('src', searchImage);
    filterContainer.appendChild(filterIcon);
    const filterInput = document.createElement('input');
    filterInput.setAttribute('placeholder', this.translationService.translate('jobTypesView.searchJobType'));
    filterInput.oninput = this.handleSearch;
    filterContainer.appendChild(filterInput);
  }

    // Search jobtype, with the given text.
    handleSearch = (event) => {
      const filterValue = event.target.value;
      if (this._state.filterTimeout) {
        clearTimeout(this._state.filterTimeout);
      }
      this._state.filterTimeout = setTimeout(() => {
        window.requestAnimationFrame(() => {
          this._state.filterValue = filterValue;
          this.dataTable.index = 0;
          this.refreshJobTypes();
        });
      }, 250);
    }

  handleCategoryChange = (event) => {
    //Filter data table  per category in event.target.value
    var category = event.target.value;
    this._state.categoryValue = category;
    this.refreshJobTypes();
  }

  addDataTable = () => {
    this.dataTable = document.createElement('data-table');
    this.root.appendChild(this.dataTable);
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`jobTypesView.${columnId}`),
        sortable: true,
        primary: columnId === NAME,
      };
    });
  }

  render = async () => {
    this.addTitle();
    this.addDataTable();
    this.refreshCategories();
    this.refreshData();
  }

  refreshData = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    this.refreshJobTypes();
    this.refreshCategories();
  }

  addJobType = async (event) => {
    const jobTypeNavEvent = new CustomEvent( 'jobTypeNavigationEvent', {
      bubbles: true,
      composed: true,
      detail: { route: 'newJobType' }
    });
    this.shadowRoot.dispatchEvent(jobTypeNavEvent);
  }

  refreshJobTypes = async () => {
    const { sortBy, sortDirection } = this._state;
    let data = await this.store.getJobTypes(this.translationService, this.controlState.chainId, sortBy, sortDirection);

    // Exclude condition inspection job types, those with baseJobType[0].id === 8.
    data = data.filter( (d) => d.baseJobType[0].id !== 8);

    // Filter per category and filterValue.
    const categoryId = (!this._state.categoryValue || this._state.categoryValue === 'ALL') ? undefined : this._state.categoryValue;
    const filter = (!this._state.filterValue || this._state.filterValue === '' ) ? undefined : this._state.filterValue.toLowerCase();
    this.dataTable.dataAndLayout = {
      data: data.filter( (d) => categoryId ? d.baseJobType[0].id === Number(categoryId) : 1)
                .filter( (d) => filter ? d.name.toLowerCase().includes(filter) : 1),
      columns: this.getTableColumnDefinitions(),
      sortBy:sortBy,
      sortDirection: sortDirection,
    };
  }

  refreshCategories = async () => {
    if (!this.controlState.chainId) {
      return;
    }

    const data = await this.store.getCategories(this.controlState.chainId, this.translationService);
    //Remove existing children
    while (this.categorySelect.firstChild) {
      this.categorySelect.removeChild(this.categorySelect.firstChild);
    }
    //Add selection for All first
    const allCategory = document.createElement('option');
    allCategory.textContent = this.translationService.translate('jobTypesView.allCategories');
    allCategory.setAttribute( 'value', 'ALL' );
    this.categorySelect.appendChild(allCategory);
    //Then rest from the backend
    data.forEach( (d) => {
      const category = document.createElement('option');
      category.textContent = d.name;
      category.setAttribute( 'value', d.id );
      this.categorySelect.appendChild(category);
    });
  }
});