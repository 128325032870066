import { getChain, updateChain } from './chainStore.js';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';
import css from './chain-info-section-css';
import { resizeImageAsBase64 } from '../../assets/lib/commonFunctions';

// Form field IDs.
const NAME = 'name';
const ADDITIONAL_INFORMATION = 'additionalInformation';
const LOGO_BASE_64 = 'logoBase64';
const PROVISION_REDUCTION = 'provisionReduction';
const WEEKDAY_PROVISION = 'weekdayProvision';
const SATURDAY_PROVISION = 'saturdayProvision';
const SUNDAY_PROVISION = 'sundayProvision';
const ENABLE_SITEPRINT = 'enableSitePrint';
const USE_DORIS_SITEPRINT = 'useSitePrint';
const FORM_FIELD_IDS = [
  NAME,
  ADDITIONAL_INFORMATION,
  LOGO_BASE_64,
  PROVISION_REDUCTION,
  WEEKDAY_PROVISION,
  SATURDAY_PROVISION,
  SUNDAY_PROVISION,
  ENABLE_SITEPRINT,
  USE_DORIS_SITEPRINT,
];

window.customElements.define('chain-info-section', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: word => word };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );

    this._fetchingData = true;
    this._originalData = {
      [NAME]: '',
      [ADDITIONAL_INFORMATION]: '',
      [LOGO_BASE_64]: '',
      [PROVISION_REDUCTION]: '',
      [WEEKDAY_PROVISION]: '',
      [SATURDAY_PROVISION]: '',
      [SUNDAY_PROVISION]: '',
      [ENABLE_SITEPRINT]: false,
      [USE_DORIS_SITEPRINT]: false,
    };
    this._formData = {
      [NAME]: '',
      [ADDITIONAL_INFORMATION]: '',
      [LOGO_BASE_64]: '',
      [PROVISION_REDUCTION]: '',
      [WEEKDAY_PROVISION]: '',
      [SATURDAY_PROVISION]: '',
      [SUNDAY_PROVISION]: '',
      [ENABLE_SITEPRINT]: false,
      [USE_DORIS_SITEPRINT]: false,
    };
    this.build();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    if (!this.controlState.chainId && controlState.chainId) {
      this.fetchChainData();
    }
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
    if (controlState.lastAction === 'chainIdChanged') {
      this.fetchChainData();
    }
  }

  build = async () => {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addRoot();
  }

  addRoot = () => {
    const root = document.createElement('div');
    root.setAttribute('id', 'chain-info-section');
    this.root = root;
    this.shadowRoot.appendChild(root);

    const formContainer = document.createElement('div');
    formContainer.setAttribute('class', 'form-container');
    this.formContainer = formContainer;
    this.root.appendChild(formContainer);

    const buttonsContainer = document.createElement('div');
    buttonsContainer.setAttribute('class', 'buttons-container');
    this.buttonsContainer = buttonsContainer;
    this.root.appendChild(buttonsContainer);

    this.renderForm();
  }

  connectedCallback() {
    this.fetchChainData();
  }

  fetchChainData = () => {
    if (!this.controlState.chainId) {
      return;
    }
    getChain(this.controlState.chainId, this.controlState.accessToken)
      .then((result) => {
        const chainData = result[0];
        // Convert numbers to strings.
        const numberFieldIds = [WEEKDAY_PROVISION, SATURDAY_PROVISION, SUNDAY_PROVISION, PROVISION_REDUCTION];
        numberFieldIds.forEach(id => {
          if (chainData[id]) {
            chainData[id] = chainData[id].toFixed(2).toString().replace('.', ',');
          }
        });
        this._originalData = Object.assign({}, chainData);
        this._formData = Object.assign({}, chainData);
        this._fetchingData = false;
        this.refreshData();
      })
      .catch((error) => {
        console.error('Failed to fetch chain data', error);
        this._fetchingData = false;
        sendNotificationEvent(this.translationService.translate('chainView.fetchFailed'), 'error');
      });
  }

  uploadFile = () => {
    const file = this.fileInput.files[0];
    if (!file) {
      return;
    }
    resizeImageAsBase64(file, (dataUrl) => {
      this._formData.logoBase64 = dataUrl;
      this.refreshData();
      this.checkFormButtonStatus();
    });
  }

  openFileInput = () => {
    this.fileInput.click();
  }

  /**
   * Checks if form is modified or contains any errors, and sets button statuses accordingly.
   */
  checkFormButtonStatus = () => {
    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    if (errorInputs.length > 0) {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.removeAttribute('disabled');
      return;
    }

    if (this.getChangedFields().length === 0) {
      this.saveButton.setAttribute('disabled', '');
      this.cancelButton.setAttribute('disabled', '');
    } else {
      this.saveButton.removeAttribute('disabled');
      this.cancelButton.removeAttribute('disabled');
    }
  }

  setFormValue = (property, value) => {
    this._formData[property] = value;

    if (property !== ENABLE_SITEPRINT && property !== USE_DORIS_SITEPRINT) {
      const input = this.formContainer.querySelector(`input#${property}`);
      const valueIsFloat = /^[0-9]{1,8}([.,][0-9]{1,2})?$/.test(value);

      if (!value) {
        input.setAttribute('error', '');
      } else if (property === WEEKDAY_PROVISION || property === SATURDAY_PROVISION
        || property === SUNDAY_PROVISION) {
        if (!valueIsFloat) {
          input.setAttribute('error', '');
        } else {
          input.removeAttribute('error');
        }
      } else if (value) {
        input.removeAttribute('error');
      }
    }

    this.checkFormButtonStatus();
  }

  getChangedFields = () => {
    const changedFormFieldIds = [];

    FORM_FIELD_IDS.forEach(fieldId => {
      if (this._originalData[fieldId] !== this._formData[fieldId]) {
        changedFormFieldIds.push(fieldId);
      }
    });
    return changedFormFieldIds;
  }

  sendUpdateChainRequest = () => {
    if (this._fetchingData) {
      return;
    }
    // Prevent sending multiple requests.
    this.saveButton.setAttribute('disabled', '');
    this._fetchingData = true;

    const {
      id,
      name,
      additionalInformation,
      logoBase64,
      weekdayProvision,
      saturdayProvision,
      sundayProvision,
      provisionReduction,
      enableSitePrint,
      useSitePrint,
    } = this._formData;

    const data = {
      chainId: id,
      name,
      additionalInformation,
      logoBase64: logoBase64 || '',
      weekdayProvision: parseFloat(weekdayProvision.replace(',', '.')),
      saturdayProvision: parseFloat(saturdayProvision.replace(',', '.')),
      sundayProvision: parseFloat(sundayProvision.replace(',', '.')),
      provisionReduction: parseFloat(provisionReduction.replace(',', '.')),
      enableSitePrint: !!enableSitePrint,
      useSitePrint: !!useSitePrint,
    };
    updateChain(data, this.controlState.accessToken)
    .then(() => {
      // Reset form state.
      this._originalData = Object.assign({}, this._formData);
      this.cancelButton.setAttribute('disabled', '');
      this._fetchingData = false;
      sendNotificationEvent(this.translationService.translate('chainView.updateSuccessful'));
    })
    .catch((error) => {
      console.log('Error while saving chain information', error);
      sendNotificationEvent(this.translationService.translate('chainView.updateFailed'), 'error');
      this.saveButton.removeAttribute('disabled');
      this._fetchingData = false;
    });
  }

  resetForm = () => {
    this._formData = Object.assign({}, this._originalData);
    this.saveButton.setAttribute('disabled', '');
    this.cancelButton.setAttribute('disabled', '');
    window.requestAnimationFrame(() => this.refreshData());
  }

  getBasicInfoInputDefinitions = () => [
    {
      id: NAME,
      label: this.translationService.translate('chainView.name'),
      maxLength: 200,
    },
    {
      id: ADDITIONAL_INFORMATION,
      label: this.translationService.translate('chainView.additionalInfo'),
      maxLength: 2000,
    },
    {
      id: LOGO_BASE_64,
      label: this.translationService.translate('chainView.logo'),
      maxLength: 200,
    },
  ];

  addBasicChainInfoFields = () => {
    const basicInfoContainer = document.createElement('div');
    basicInfoContainer.setAttribute('class', 'form-column');
    this.formContainer.appendChild(basicInfoContainer);

    const basicInfoTitle = document.createElement('div');
    basicInfoTitle.setAttribute('class', 'secondary-title');
    basicInfoTitle.textContent = this.translationService.translate('basicInfo');
    basicInfoContainer.appendChild(basicInfoTitle);

    this.getBasicInfoInputDefinitions().forEach(inputDef => {
      const inputContainer = document.createElement('div');
      basicInfoContainer.appendChild(inputContainer);

      const inputLabel = document.createElement('div');
      inputLabel.setAttribute('class', 'input-label');
      inputLabel.textContent = inputDef.label;
      inputContainer.appendChild(inputLabel);

      if (inputDef.id === LOGO_BASE_64) {
        const logoContainer = document.createElement('div');
        logoContainer.setAttribute('id', inputDef.id);
        logoContainer.setAttribute('class', 'logo-input');
        inputContainer.appendChild(logoContainer);

        const logo = document.createElement('img');
        logo.setAttribute('src', this._formData.logoBase64);
        if (!this._formData.logoBase64 || this._formData.logoBase64.length === 0) {
          logo.setAttribute('src', '//:0'); // Prevent displaying image with invalid src.
          logo.setAttribute('width', 0);
          logo.setAttribute('height', 0);
        }
        logoContainer.appendChild(logo);

        const fileInput = document.createElement('input');
        this.fileInput = fileInput;
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('class', 'file-input');
        fileInput.setAttribute('accept', 'image/*');
        fileInput.addEventListener('change', this.uploadFile);
        inputContainer.appendChild(fileInput);

        const uploadButton = document.createElement('button');
        uploadButton.textContent = this.translationService.translate('chainView.changeLogo');
        uploadButton.onclick = this.openFileInput;
        if (this._fetchingData) {
          uploadButton.setAttribute('disabled', '');
        }
        inputContainer.appendChild(uploadButton);
        return;
      }

      const input = document.createElement('input');
      input.setAttribute('id', inputDef.id);
      if (this._formData[inputDef.id]) {
        input.setAttribute('value', this._formData[inputDef.id]);
      }
      input.setAttribute('maxlength', inputDef.maxLength);
      if (this._fetchingData) {
        input.setAttribute('disabled', '');
      }
      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
      inputContainer.appendChild(input);
    });
  }

  getCommissionInputDefinitions = () => [
    {
      id: WEEKDAY_PROVISION,
      label: this.translationService.translate('chainView.monFriCoefficient'),
    },
    {
      id: SATURDAY_PROVISION,
      label: this.translationService.translate('chainView.satCoefficient'),
    },
    {
      id: SUNDAY_PROVISION,
      label: this.translationService.translate('chainView.sunCoefficient'),
    },
  ];

  addCommissionFields = () => {
    const commissionsContainer = document.createElement('div');
    commissionsContainer.setAttribute('class', 'form-column');
    this.formContainer.appendChild(commissionsContainer);

    const commissionsTitle = document.createElement('div');
    commissionsTitle.setAttribute('class', 'secondary-title');
    commissionsTitle.textContent = this.translationService.translate('chainView.commissions');
    commissionsContainer.appendChild(commissionsTitle);

    const commissionsInputContainer = document.createElement('div');
    commissionsInputContainer.setAttribute('class', 'commissions-container');
    commissionsContainer.appendChild(commissionsInputContainer);

    this.getCommissionInputDefinitions().forEach(inputDef => {
      const inputContainer = document.createElement('div');
      commissionsInputContainer.appendChild(inputContainer);

      const inputLabel = document.createElement('div');
      inputLabel.setAttribute('class', 'input-label');
      inputLabel.textContent = inputDef.label;
      inputContainer.appendChild(inputLabel);

      const input = document.createElement('input');
      input.setAttribute('id', inputDef.id);
      input.setAttribute('class', 'small');
      if (this._formData[inputDef.id]) {
        input.setAttribute('value', this._formData[inputDef.id].toString().replace('.', ','));
      }
      input.setAttribute('maxlength', '11');
      if (this._fetchingData) {
        input.setAttribute('disabled', '');
      }
      input.oninput = (event) => this.setFormValue(inputDef.id, event.target.value);
      inputContainer.appendChild(input);
    });

    const reductionInputContainer = document.createElement('div');
    commissionsContainer.appendChild(reductionInputContainer);

    const reductionInputLabel = document.createElement('div');
    reductionInputLabel.setAttribute('class', 'input-label');
    reductionInputLabel.textContent = this.translationService.translate('chainView.commissionReduction');
    reductionInputContainer.appendChild(reductionInputLabel);

    const reductionInput = document.createElement('input');
    reductionInput.setAttribute('id', PROVISION_REDUCTION);
    reductionInput.setAttribute('class', 'small');
    if (this._formData.provisionReduction) {
      reductionInput.setAttribute('value', this._formData.provisionReduction);
    }
    reductionInput.setAttribute('maxlength', '11');
    reductionInput.oninput = (event) => this.setFormValue(PROVISION_REDUCTION, event.target.value);
    if (this._fetchingData) {
      reductionInput.setAttribute('disabled', '');
    }
    reductionInputContainer.appendChild(reductionInput);

    this.addSitePrintFields(commissionsContainer);
  }

  addSitePrintFields = (commissionsContainer) => {
    const sitePrintContainer = document.createElement('div');
    commissionsContainer.appendChild(sitePrintContainer);

    const enableSitePrintLabel = document.createElement('div');
    enableSitePrintLabel.setAttribute('class', 'input-label');
    enableSitePrintLabel.textContent = this.translationService.translate('chainView.enableSitePrint');
    sitePrintContainer.appendChild(enableSitePrintLabel);

    const enableSitePrintInput = document.createElement('input');
    enableSitePrintInput.setAttribute('id', ENABLE_SITEPRINT);
    enableSitePrintInput.setAttribute('type', 'checkbox');
    enableSitePrintInput.setAttribute('class', 'small');
    if (this._formData.enableSitePrint) {
      enableSitePrintInput.setAttribute('checked', this._formData.enableSitePrint);
    }
    enableSitePrintInput.oninput = (event) => this.setFormValue(ENABLE_SITEPRINT, event.target.checked);
    if (this._fetchingData) {
      enableSitePrintInput.setAttribute('disabled', '');
    }
    sitePrintContainer.appendChild(enableSitePrintInput);


    const useSitePrintLabel = document.createElement('div');
    useSitePrintLabel.setAttribute('class', 'input-label');
    useSitePrintLabel.textContent = this.translationService.translate('chainView.useSitePrint');
    sitePrintContainer.appendChild(useSitePrintLabel);

    const useSitePrintInput = document.createElement('input');
    useSitePrintInput.setAttribute('id', USE_DORIS_SITEPRINT);
    useSitePrintInput.setAttribute('type', 'checkbox');
    useSitePrintInput.setAttribute('class', 'small');
    if (this._formData.useSitePrint) {
      useSitePrintInput.setAttribute('checked', this._formData.useSitePrint);
    }
    useSitePrintInput.oninput = (event) => this.setFormValue(USE_DORIS_SITEPRINT, event.target.checked);
    if (this._fetchingData) {
      useSitePrintInput.setAttribute('disabled', '');
    }
    sitePrintContainer.appendChild(useSitePrintInput);
  }

  addButtons = () => {
    const saveButton = this.saveButton = document.createElement('button');
    saveButton.setAttribute('class', 'primary');
    saveButton.setAttribute('disabled', '');
    saveButton.textContent = this.translationService.translate('save');
    saveButton.onclick = this.sendUpdateChainRequest;
    this.buttonsContainer.appendChild(saveButton);

    const cancelButton = this.cancelButton = document.createElement('button');
    cancelButton.textContent = this.translationService.translate('cancel');
    cancelButton.setAttribute('disabled', '');
    cancelButton.onclick = this.resetForm;
    this.buttonsContainer.appendChild(cancelButton);
  }

  renderForm = () => {
    this.addBasicChainInfoFields();
    this.addCommissionFields();
    // this.addSitePrintFields();
    this.addButtons();
  }

  refreshForm = () => {
    this.addBasicChainInfoFields();
    this.addCommissionFields();
    // this.addSitePrintFields();
  }

  /**
   * Refresh view with updated data.
   */
  refreshData = () => {
    if (!this.formContainer) {
      return;
    }

    while (this.formContainer.childNodes.length > 0) {
      this.formContainer.removeChild(this.formContainer.firstChild);
    }

    this.refreshForm();
  }
});
