import { refreshInputField } from '../siteGroupHelpers.js';
import html from './info-section-html';
import css from './info-section-css';

// Form field IDs.
const NAME = 'name';
const DESCRIPTION = 'description';
const EMPTY_FORM_DATA = {
  [NAME]: '',
  [DESCRIPTION]: '',
};

class SiteGroupInfoSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };

    this._state = {
      onChange: null,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };

    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ],
    );

    this.build();
  }

  disconnectedCallback() {
    this._state = {
      ...this._state,
      formData: Object.assign({}, EMPTY_FORM_DATA),
    };
  }

  set onChange(val) {
    this._state.onChange = val;
  }

  get isValid() {
    const errorInputs = this.formContainer.querySelectorAll('input[error]');
    const requiredFieldsEmpty = this.getInputDefinitions()
      .some(def => def.required && !this._state.formData[def.id]);
    return errorInputs.length === 0 && !requiredFieldsEmpty;
  }

  set siteGroup(val) {
    this._state.formData = Object.assign({}, val);
    this.refreshData();
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance;
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    if (controlState.lastAction === 'languageChanged') {
      this.refreshData();
    }
  }

  async build() {
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style>' + html;
    this.addRoot();
  }

  addRoot = () => {
    this.root = this.shadowRoot.getElementById('site-group-info-section');
    this.formContainer = this.shadowRoot.getElementById('form-container');
    this._state.isBuilt = true;
    this.renderForm();
  }

  setFormValue = (property, value) => {
    this._state.formData[property] = value;
    // Set error attribute if value is invalid.
    const input = this.formContainer.querySelector(`input#${property}`);
    const inputDef = this.getInputDefinitions().find(def => def.id === property);
    if (inputDef.required && !value) {
      input.setAttribute('error', '');
    } else if (value) {
      input.removeAttribute('error');
    }
    this._state.onChange(property, value);
  }

  getInputDefinitions = () => [
    {
      id: NAME,
      label: this.translationService.translate('siteGroupView.name'),
      maxLength: 200,
      required: true,
    },
    {
      id: DESCRIPTION,
      label: this.translationService.translate('siteGroupView.description'),
      maxLength: 400,
    },
  ];

  refreshData = () => {
    if (!this.root) return;
    this.getInputDefinitions().forEach((inputDef) => {
      const label = this.shadowRoot.getElementById(`${inputDef.id}-label`);
      label.textContent = inputDef.required ? `${inputDef.label} *` : inputDef.label;
      const value = this._state.formData[inputDef.id];
      refreshInputField(this.shadowRoot, inputDef, value, (event) => this.setFormValue(inputDef.id, event.target.value), this._fetchingData);
    });
  }

  renderForm = () => {
    this.refreshData();
  }
}

window.customElements.define('site-group-info-section', SiteGroupInfoSection);
