export default `
<style type="text/css">
.table-container {
  width: 100%;
}

table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
}

tbody > tr:nth-child(even) {
  background-color: #FBFBFB;
}

tbody > tr:not(.disabled) {
  cursor: pointer;
}

tbody > tr:not(.disabled):hover {
  background-color: rgba(137,62,255,0.1);
}

tr {
  height: 43px;
}

tr.selected > td {
  background-color: rgba(137,62,255,0.1);
}

tr.disabled > td {
  color: rgba(0,0,0,0.4);
}

th {
  border-bottom: 1px solid rgba(209,209,209,0.25);
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.6);
  padding: 0px 20px;
  text-align: left;
}

th > div {
  display: flex;
  align-items: center;
}

th > div.align-right {
  justify-content: flex-end;
}

.sortable {
  cursor: pointer;
}

.sortable:hover {
  color: var(--primaryColor);
}

td {
  padding: 0px 20px;
  border-bottom: 1px solid rgba(209,209,209,0.25);
}

td.align-right {
  text-align: right;
}

td[primary-column] {
  color: var(--primaryColor);
}

.remove-column {
  min-width: 65px;
}

.remove-cell {
  display: none;
  justify-content: flex-end;
  align-items: flex-end;
}

.remove-cell > div {
  background-image: url('./assets/icons/close.svg');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.remove-cell > div:hover {
  background-image: url('./assets/icons/close_error.svg');
}

tr:hover > td > .remove-cell {
  display: flex;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  height: 63px;
}

.pagination-container[hidden] {
  display: none;
}

.pagination-container > div {
  display: flex;
  align-items: center;
}

.pagination-container > div:not(:last-child) {
  margin-right: 30px;
}

.select {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  -webkit-tap-highlight-color: transparent;
}

.select:hover > div:first-child {
  color: var(--primaryColor);
}

.dropdown {
  position: absolute;
  max-height: 500px;
  transform: max-height 0.3s;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  top: calc(100% + 5px);
  font-size: 16px;
  line-height: 23px;
  border-radius: 4px;
  background-color: #FFFFFF;
  cursor: pointer;
  min-width: 100px;
  transition: max-height 0.3s;
  transition: box-shadow 0.1s;
  overflow: hidden;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 0;
}

.dropdown.collapsed {
  max-height: 0px;
  box-shadow: none;
}

.dropdown > div {
  padding: 16px;
}

.dropdown > div:hover {
  color: var(--primaryColor);
}

.dropdown > div:not(:last-child) {
  padding-bottom: 8px;
}

.dropdown > div:not(:first-child) {
  padding-top: 8px;
}

.dropdown-backdrop {
  z-index: 100;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  cursor: default;
}

.dropdown-backdrop.collapsed {
  display: none;
}

button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: 5px;
  cursor: pointer;
}

button:hover {
  background-color: var(--buttonBackgroundHover);
}

button[disabled]:hover {
  background-color: transparent;
  cursor: default;
}

button:focus {
  outline: none;
}

button > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  box-sizing: border-box;
  border: 1px solid var(--inputBorderColor);
  border-radius: 4px;
  height: 38px;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  padding: 0px 12px;
  width: calc(100% - 24px);
}

input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
}

</style>
<div id="table-container">
  <table>
    <thead>
      <tr id="table-head-row"></tr>
    </thead>
    <tbody id="table-body"></tbody>
  </table>
  <div id="pagination-container" class="pagination-container">
    <div id="items-per-page-container">
      <div id="items-per-page-text"></div>
      <div id="items-per-page-dropdown-backdrop" class="dropdown-backdrop collapsed"></div>
      <div id="items-per-page-select" class="select">
        <div id="items-per-page-select-text"></div>
        <img id="items-per-page-select-image" />
        <div id="items-per-page-dropdown" class="dropdown collapsed"></div>
      </div>
    </div>
    <div id="page-container"></div>
    <div id="button-container">
      <button id="prev-button">
        <div id="prev-image-container">
          <img id="prev-image" />
        </div>
      </button>
      <button id="next-button">
        <div id="next-image-container">
          <img id="next-image" />
        </div>
      </button>
    </div>
  </div>
</div>
`;