import dayjs from 'dayjs';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest, sendSiteRequest } from '../api/api';

export const updateMeetingMinutes = createAsyncThunk(
  'meetingMinutes/updateMinutes',
  async ({ chainId, payload }, API) => {
    const procName = payload.id ? 'UpdateMeetingMinutes' : 'CreateMeetingMinutes';

    const transformedPayload = {
      ...payload,
      chainId: parseInt(chainId),
      siteId: parseInt(payload.siteId),
      createdBy: payload.createdBy,
      lastModifiedBy: payload.lastModifiedBy,
      content: payload.content,
    };

    if (procName === 'CreateMeetingMinutes') {
      delete transformedPayload.id;
      delete transformedPayload.lastModifiedBy;
    }

    if (procName === 'UpdateMeetingMinutes') {
      delete transformedPayload.createdBy;
    }

    const query = {
      procName,
      data: transformedPayload,
    };

    const state = API.getState();
    const response = await sendRequest(chainId, query, 'POST', state.auth.token);
    return { ...payload, id: response.id };
  }
);

export const fetchMeetingMinutes = createAsyncThunk(
  'meetingMinutes/fetchMinute',
  async ({ chainId, siteId, minuteId }, API) => {
    const query = {
      procName: 'GetMeetingMinutes',
      data: { chainId, siteId, id: minuteId },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const listMeetingMinutes = createAsyncThunk(
  'meetingMinutes/listMinutes',
  async ({ chainId, siteId }, API) => {
    const query = {
      procName: 'ListMeetingMinutes',
      data: { chainId, siteId },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const deleteMeetingMinutes = createAsyncThunk(
  'meetingMinutes/deleteMinute',
  async ({ chainId, siteId, minuteId, deletedBy }, API) => {
    const query = {
      procName: 'DeleteMeetingMinutes',
      data: {
        id: minuteId,
        chainId,
        siteId,
        deletedBy,
      },
    };
    const state = API.getState();
    await sendRequest(chainId, query, 'POST', state.auth.token);
    return minuteId;
  }
);

export const NEW_MEETING_MINUTES_INITIAL_STATE = {
  name: '',
  meetingDate: dayjs().format(),
  state: 'draft',
  content: '',
  templateId: '',
  description: '',
};

export const fetchMeetingMinutesTemplates = createAsyncThunk(
  'meetingMinutes/fetchTemplates',
  async ({ chainId, siteId }, API) => {
    const query = {
      procName: 'ListMeetingMinutesTemplates',
      data: { chainId, siteId },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const fetchMeetingMinutesTemplate = createAsyncThunk(
  'meetingMinutes/fetchTemplate',
  async ({ chainId, siteId, templateId }, API) => {
    const query = {
      procName: 'GetMeetingMinutesTemplate',
      data: { chainId, siteId, id: templateId },
    };
    const state = API.getState();
    return await sendRequest(chainId, query, 'POST', state.auth.token);
  }
);

export const deleteMeetingMinutesTemplate = createAsyncThunk(
  'meetingMinutes/deleteTemplate',
  async ({ chainId, siteId, templateId, deletedBy }, API) => {
    const query = {
      procName: 'DeleteMeetingMinutesTemplate',
      data: {
        id: templateId,
        chainId,
        siteId,
        // deletedBy,
      },
    };
    const state = API.getState();
    await sendRequest(chainId, query, 'POST', state.auth.token);
    return templateId;
  }
);

export const publishUnpublishMeetingMinutes = createAsyncThunk(
  'meetingMinutes/publishUnpublish',
  async ({ chainId, siteId, minuteId, lastModifiedBy, publish = true }, API) => {
    const query = {
      procName: 'PublishUnpublishMeetingMinutes',
      data: {
        id: minuteId,
        chainId,
        siteId,
        publishedAt: publish ? new Date().toISOString() : null,
        lastModifiedBy,
      },
    };
    const state = API.getState();
    await sendRequest(chainId, query, 'POST', state.auth.token);
    return { minuteId, publish };
  }
);

export const updateMeetingMinutesTemplate = createAsyncThunk(
  'meetingMinutes/updateTemplate',
  async ({ chainId, payload }, API) => {
    const procName = payload.id ? 'UpdateMeetingMinutesTemplate' : 'CreateMeetingMinutesTemplate';

    const transformedPayload = {
      ...payload,
      chainId: parseInt(chainId),
      siteId: parseInt(payload.siteId),
      createdBy: payload.createdBy,
      lastModifiedBy: payload.lastModifiedBy,
      name: payload.name,
      description: payload.description || null,
      content: payload.content,
      isActive: payload.isActive ?? true
    };

    delete transformedPayload.meetingDate;

    if (procName === 'CreateMeetingMinutesTemplate') {
      delete transformedPayload.id;
      delete transformedPayload.lastModifiedBy;
    }

    if (procName === 'UpdateMeetingMinutesTemplate') {
      delete transformedPayload.createdBy;
    }

    const query = {
      procName,
      data: transformedPayload,
    };

    const state = API.getState();
    const response = await sendRequest(chainId, query, 'POST', state.auth.token);
    return { ...payload, id: response.id };
  }
);

export const duplicateMeetingMinutesTemplate = createAsyncThunk(
  'meetingMinutes/duplicateTemplate',
  async ({ chainId, siteId, templateId, language, userId }, API) => {

    const getQuery = {
      procName: 'GetMeetingMinutesTemplate',
      data: { chainId, siteId, id: templateId },
    };
    const state = API.getState();
    const template = await sendRequest(chainId, getQuery, 'POST', state.auth.token);

    const newTemplate = {
      chainId: parseInt(chainId),
      siteId: parseInt(siteId),
      name: `${template.name} (${language === 'fi-FI' ? 'kopio' : 'kopia'})`,
      createdBy: userId,
      description: template.description,
      content: template.content,
    };

    const createQuery = {
      procName: 'CreateMeetingMinutesTemplate',
      data: newTemplate,
    };

    const response = await sendRequest(chainId, createQuery, 'POST', state.auth.token);
    return { ...newTemplate, id: response.id };
  }
);

export const generateMeetingMinutes = createAsyncThunk(
  'meetingMinutes/generateMinutes',
  async (query, API) => {
    const state = API.getState();
    const { chainId, siteId } = query;
    return await sendSiteRequest(chainId, siteId, 'POST', '/quality-control/meeting-minutes', '', query, state.auth.token);
  }
);

const meetingMinutesSlice = createSlice({
  name: 'meetingMinutes',
  initialState: {
    minutes: [],
    currentMinute: null,
    isRequesting: false,
    isFetchingMinute: false,
    newMinute: NEW_MEETING_MINUTES_INITIAL_STATE,
    templates: [],
    currentTemplate: null,
    isLoadingTemplates: false,
    isLoadingTemplate: false,
    selectedListType: 'minutes',
  },
  reducers: {
    setNewMinute: (state, action) => {
      state.newMinute = { ...NEW_MEETING_MINUTES_INITIAL_STATE, ...action.payload };
    },
    clearNewMinute: (state) => {
      state.newMinute = NEW_MEETING_MINUTES_INITIAL_STATE;
    },
    updateNewMinute: (state, action) => {
      state.newMinute = { ...state.newMinute, ...action.payload };
    },
    setSelectedListType: (state, action) => {
      state.selectedListType = action.payload;
    },
  },
  extraReducers: {
    [updateMeetingMinutes.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateMeetingMinutes.fulfilled]: (state, { payload }) => {
      state.currentMinute = payload;
      state.isRequesting = false;
    },
    [updateMeetingMinutes.rejected]: (state) => {
      state.isRequesting = false;
    },

    [fetchMeetingMinutes.pending]: (state) => {
      state.isFetchingMinute = true;
    },
    [fetchMeetingMinutes.fulfilled]: (state, { payload }) => {
      state.currentMinute = payload;
      state.isFetchingMinute = false;
    },
    [fetchMeetingMinutes.rejected]: (state) => {
      state.isFetchingMinute = false;
    },

    [listMeetingMinutes.pending]: (state) => {
      state.isRequesting = true;
    },
    [listMeetingMinutes.fulfilled]: (state, { payload }) => {
      state.minutes = payload;
      state.isRequesting = false;
    },
    [listMeetingMinutes.rejected]: (state) => {
      state.isRequesting = false;
    },

    [deleteMeetingMinutes.pending]: (state) => {
      state.isRequesting = true;
    },
    [deleteMeetingMinutes.fulfilled]: (state, { payload }) => {
      state.minutes = state.minutes.filter(minute => minute.id !== payload);
      state.isRequesting = false;
    },
    [deleteMeetingMinutes.rejected]: (state) => {
      state.isRequesting = false;
    },

    [fetchMeetingMinutesTemplates.pending]: (state) => {
      state.isLoadingTemplates = true;
    },
    [fetchMeetingMinutesTemplates.fulfilled]: (state, { payload }) => {
      state.templates = payload;
      state.isLoadingTemplates = false;
    },
    [fetchMeetingMinutesTemplates.rejected]: (state) => {
      state.isLoadingTemplates = false;
    },

    [fetchMeetingMinutesTemplate.pending]: (state) => {
      state.isLoadingTemplate = true;
    },
    [fetchMeetingMinutesTemplate.fulfilled]: (state, { payload }) => {
      state.currentTemplate = payload;
      state.isLoadingTemplate = false;
    },
    [fetchMeetingMinutesTemplate.rejected]: (state) => {
      state.isLoadingTemplate = false;
    },

    [publishUnpublishMeetingMinutes.pending]: (state) => {
      state.isRequesting = true;
    },
    [publishUnpublishMeetingMinutes.fulfilled]: (state, { payload }) => {
      state.isRequesting = false;
      if (state.currentMinute?.id === payload.minuteId) {
        state.currentMinute.publishedAt = payload.publish ? new Date().toISOString() : null;
      }
    },
    [publishUnpublishMeetingMinutes.rejected]: (state) => {
      state.isRequesting = false;
    },

    [updateMeetingMinutesTemplate.pending]: (state) => {
      state.isRequesting = true;
    },
    [updateMeetingMinutesTemplate.fulfilled]: (state, { payload }) => {
      state.currentTemplate = payload;
      const templateIndex = state.templates.findIndex(template => template.id === payload.id);
      if (templateIndex >= 0) {
        state.templates[templateIndex] = payload;
      } else {
        state.templates.push(payload);
      }
      state.isRequesting = false;
    },
    [updateMeetingMinutesTemplate.rejected]: (state) => {
      state.isRequesting = false;
    },

    [deleteMeetingMinutesTemplate.pending]: (state) => {
      state.isRequesting = true;
    },
    [deleteMeetingMinutesTemplate.fulfilled]: (state, { payload }) => {
      state.templates = state.templates.filter(template => template.id !== payload);
      state.isRequesting = false;
    },
    [deleteMeetingMinutesTemplate.rejected]: (state) => {
      state.isRequesting = false;
    },

    [duplicateMeetingMinutesTemplate.pending]: (state) => {
      state.isRequesting = true;
    },
    [duplicateMeetingMinutesTemplate.fulfilled]: (state, { payload }) => {
      state.templates = [...state.templates, payload];
      state.isRequesting = false;
    },
    [duplicateMeetingMinutesTemplate.rejected]: (state) => {
      state.isRequesting = false;
    },
  },
});

export const { setNewMinute, clearNewMinute, updateNewMinute, setSelectedListType } = meetingMinutesSlice.actions;
export default meetingMinutesSlice.reducer;
