import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import HaynesInfo from './haynesInfo';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Jost, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 9,
    paddingRight: 53,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.black.main,
  },
  summary: {
    backgroundColor: 'rgba(191,191,191,0.2)',
    paddingLeft: 16,
    paddingRight: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.2,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  noAtjInfo: {
    color: '#000000',
    opacity: 0.6,
    padding: 16,
  },
  valueRoot: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  valueLabel: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    fontWeight: 600,
  },
  valueText: {
    opacity: 0.6,
    color: '#000000',
    fontSize: '16px',
  },
  group: {
    paddingTop: 16,
  },
  subGroupHeader: {
    opacity: 0.6,
    color: '#000000',
    fontSize: 16,
    marginBottom: 15,
  },
  tireGroupHeader: {
    opacity: 0.6,
    color: '#000000',
    fontSize: 16,
    marginBottom: 2,
    marginTop: 16,
  },
  root: {
    width: '100%',
  },
  heading: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  divider: {
    height: 1,
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
}));

const REMOVED_FIELDS = [
  'mkajanalkupvm',
  'mkajanloppupvm'
];

const DATE_FIELDS = [
  'ensirekisterointipaiva',
  'kayttoonottopaiva',
  'edellinenmakajankohta',
  'viimhyvaksyttymakpvm',
  'seuraavamkajanalkupvm',
  'mkajanloppupvm',
  'seuraavamkajanloppupvm',
];

const TIRE_TYPE_TYPE_APPROVED = '1';
const TIRE_TYPE_MARKED_IN_INSPECTION = '2';

/**
 * Represents a single name-value pair.
 */
function NamedValue({ title, value, translate }) {
  const classes = useStyles();

  // Handle booleans.
  let valueText = value;
  const caseInsensitiveValue = (value ?? '').toLowerCase();
  if (['true', 'false'].includes(caseInsensitiveValue)) {
    valueText = caseInsensitiveValue === 'true' ? translate('yes') : translate('no');
  }

  return (
    <Grid item xs={6} className={classes.valueRoot}>
      <div>
        <Typography className={classes.valueLabel}>
          {title}
        </Typography>
      </div>
      <div>
        <Typography className={classes.valueText}>
          {valueText}
        </Typography>
      </div>
    </Grid>
  )
}

// Tire fields.
const TIRE_FIELDS = [
  'kuormitusjanopeusluokka',
  'rengaskoko',
  'vannekokosisaltaenoffset',
  'rengaslaji',
];

/**
 * Returns true if the given field name belongs to tires.
 * @param {string} name
 * @returns
 */
function isTireField(name) {
  const parts = name.split('_');
  return TIRE_FIELDS.includes(parts.length > 1 ? parts[1] : parts[0]);
}

/**
 * Groups tire values together.
 * @param {Array} tireValues
 * @returns
 */
function groupAxleTiresByType(tireValues) {

  // No values.
  const result = new Map();
  result.set(TIRE_TYPE_TYPE_APPROVED, []);
  result.set(TIRE_TYPE_MARKED_IN_INSPECTION, []);
  if (!tireValues) {
    return result;
  }

  var tempValues = new Map();

  // Group values
  for (const item of tireValues) {
    const parts = item.name.split('_');
    const counter = Number(parts[0]);
    if (!tempValues.has(counter)) {
      tempValues.set(counter, {});
    }
    const name = parts[1];
    tempValues.get(counter)[name] = item.value;

    // Get the origin tire type.
    if (name === 'rengaslaji') {
      tempValues.get(counter)[name] = item.identifier;
    }
  }
  const data = [...tempValues.values()];
  result.set(TIRE_TYPE_TYPE_APPROVED, data.filter(item => item['rengaslaji'] === TIRE_TYPE_TYPE_APPROVED));
  result.set(TIRE_TYPE_MARKED_IN_INSPECTION, data.filter(item => item['rengaslaji'] === TIRE_TYPE_MARKED_IN_INSPECTION));
  return result;
}

/**
 * A sub group of name-value pairs.
 */
function AtjInfoSubGroup({ groupId, title, value, children, translate }) {
  const classes = useStyles();

  // Handle tires separately.
  let values = children;
  let typeApprovedTires = [];
  let markedInInspectionTires = [];
  if (groupId === 'akselit') {
    values = children.filter((item) => !isTireField(item.name));
    const tireValues = children.filter((item) => isTireField(item.name));
    const tires = groupAxleTiresByType(tireValues);
    typeApprovedTires = tires.get(TIRE_TYPE_TYPE_APPROVED);
    markedInInspectionTires = tires.get(TIRE_TYPE_MARKED_IN_INSPECTION);
  }

  return (
    <Grid item xs={12} className={classes.valueRoot}>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <div className={classes.subGroupHeader}>{`${title} ${value}`}</div>
      <Grid container spacing={1}>
        {values.map(v => {
          return (
            <NamedValue
              key={v.id}
              children={v.children}
              title={translate(`atjInformation.${v.name}`)}
              value={v.description ?? v.value}
              translate={translate}
            />
          )
        })}
        {(typeApprovedTires.length > 0 || markedInInspectionTires.length > 0) && (
          <div>
            <div className={classes.tireGroupHeader}>{translate('atjInformation.typeApprovedTires')}:</div>
            <table border='0' cellPadding={1}>
              <thead>
                <tr>
                  <th>{translate('atjInformation.rengaskoko')}</th>
                  <th>{translate('atjInformation.liJanopeusluokka')}</th>
                  <th>{translate('atjInformation.vannekokosisaltaenoffset')}</th>
                </tr>
              </thead>
              <tbody>
                {typeApprovedTires.map((tire, index) => {
                  return (
                    <tr key={index + '-' + tire.id}>
                      <td>{tire['rengaskoko']}</td>
                      <td>{tire['kuormitusjanopeusluokka']}</td>
                      <td>{tire['vannekokosisaltaenoffset']}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className={classes.tireGroupHeader}>{translate('atjInformation.markedInInspectionTires')}:</div>
            <table border='0'>
              <thead>
                <tr>
                  <th>{translate('atjInformation.rengaskoko')}</th>
                  <th>{translate('atjInformation.liJanopeusluokka')}</th>
                  <th>{translate('atjInformation.vannekokosisaltaenoffset')}</th>
                </tr>
              </thead>
              <tbody>
                {markedInInspectionTires.map(tire => {
                  return (
                    <tr key={tire.id}>
                      <td>{tire['rengaskoko']}</td>
                      <td>{tire['kuormitusjanopeusluokka']}</td>
                      <td>{tire['vannekokosisaltaenoffset']}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

/**
 * Group of name-value pairs.
 */
function AtjInfoGroup({ expanded, handleChange, translate, groupId, title, values }) {
  const classes = useStyles();

  return (
    <Accordion expanded={expanded} onChange={handleChange(groupId)} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`{groupId}-content`}
        id={`{groupId}-header`}
        className={classes.summary}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {values.map(v => {
            if (v.children && v.children.length > 1 /* groups with one children are treated as NamedValue */) {
              return (
                <AtjInfoSubGroup
                  key={v.id}
                  children={v.children}
                  groupId={groupId}
                  title={translate(`atjInformation.${v.name}`)}
                  value={v.description ?? v.value}
                  translate={translate}
                />
              );
            }

            let displayedValue = v.description ?? v.value;
            // Parse dates.
            if (DATE_FIELDS.includes(v.name) && v.value) {
              displayedValue = dayjs(v.value).format('DD.MM.YYYY');
            }
            return (
              <NamedValue
                key={v.id}
                children={v.children}
                title={translate(`atjInformation.${v.name}`)}
                value={displayedValue}
                translate={translate}
              />
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

AtjInfoGroup.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  })).isRequired
}

/**
 * Groups ATJ values in to groups with a key.
 * @param {Array} atjInfo
 * @returns
 */
function groupByGroupId(atjInfo) {
  const result = new Map();
  if (!atjInfo) {
    return;
  }
  for (const item of atjInfo) {
    const { groupId } = item;
    if (REMOVED_FIELDS.includes(item.name)) {
      continue; // Skip removed items.
    }
    if (!result.has(groupId)) {
      result.set(groupId, []);
    }
    result.get(groupId).push(item);
  }
  return result;
}

function InspectionAtjInfo({ open, onClose }) {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const job = useSelector(state => state.jobs.job);
  const selectedSite = useSelector(state => state.sites?.sites?.find(s => s.id === state.sites?.selectedSiteId) ?? null);

  const [expanded, setExpanded] = React.useState('tunnus');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // No job.
  if (!job) {
    return null;
  }

  const groupedInfo = job.groupedAtjInformation;
  const hasAtjInformation = groupedInfo && groupedInfo.length > 0;
  const infoByGroupId = hasAtjInformation ? groupByGroupId(groupedInfo) : [];

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.drawer}
        role='presentation'
        onKeyDown={onClose}
      >
        <div className={classes.header}>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
          <div className={classes.title}>{selectedSite?.useHaynesData ? translate('inspectionView.atjAndHaynesInfo') : translate('inspectionView.atjInfo')}</div>
        </div>

        <div className={classes.list}>
          <HaynesInfo
              expanded={expanded === 'haynes'}
              handleChange={handleChange('haynes')}
              translate={translate}
              showSearchButton={true}
              title={translate('haynesInformation.title')}
              values={job.haynesInformation}
          />
          {hasAtjInformation ? [...infoByGroupId.entries()].filter(([key]) => key !== null).map(([key, values]) =>
            <AtjInfoGroup
              key={key}
              expanded={expanded === key}
              values={values}
              handleChange={handleChange}
              groupId={key}
              translate={translate}
              title={translate(`atjInformation.${key}`)}
            />)
            :
            <div className={classes.noAtjInfo}>{translate('atjInformation.noAtjInformation')}</div>
          }
        </div>

      </div>
    </Drawer>
  );
}

InspectionAtjInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default InspectionAtjInfo;
