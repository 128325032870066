export function refreshInputField(shadowRoot, inputDef, value, onChange, disabled) {
  const input = shadowRoot.getElementById(inputDef.id);
  input.value = value || '';
  if (inputDef.maxLength) {
    input.setAttribute('maxlength', inputDef.maxLength);
  }
  if (disabled) {
    input.setAttribute('disabled', '');
  } else {
    input.removeAttribute('disabled');
  }
  input.oninput = onChange;
}

export function refreshCheckboxField(shadowRoot, inputDef, value, onChange, disabled) {
  const input = shadowRoot.getElementById(inputDef.id);
  input.checked = !!value;
  if (disabled) {
    input.setAttribute('disabled', '');
  } else {
    input.removeAttribute('disabled');
  }
  input.oninput = onChange;
}

export function refreshSelectField(shadowRoot, translationService, inputDef, value, onChange, onCompanyClick) {
  const selectText = shadowRoot.getElementById(`${inputDef.id}-text`);
  const selectedValue = inputDef.options.find(option => option.id === value);
  selectText.textContent = (selectedValue && selectedValue.name) || '';

  const backdrop = shadowRoot.getElementById(`${inputDef.id}-backdrop`);
  const dropdown = shadowRoot.getElementById(`${inputDef.id}-dropdown`);
  const toggleDropdown = () => {
    dropdown.classList.toggle('collapsed');
    backdrop.classList.toggle('collapsed');
  }

  backdrop.onclick = (event) => {
    if (event.target === event.currentTarget) {
      toggleDropdown();
    }
  }

  while (dropdown.hasChildNodes()) {
    dropdown.removeChild(dropdown.firstChild);
  }

  if (!inputDef.options || inputDef.options.length === 0) {
    const button = document.createElement('div');
    button.textContent = translationService.translate('siteView.noOptions');
    button.onclick = (event) => {
      event.stopPropagation();
      toggleDropdown();
    }
    dropdown.appendChild(button);
  }
  inputDef.options.forEach((option) => {
    const button = document.createElement('div');
    button.textContent = option.name;
    button.onclick = (event) => {
      event.stopPropagation();
      toggleDropdown();
      onChange(inputDef.id, option.id);
    }
    dropdown.appendChild(button);
  });
  const select = shadowRoot.getElementById(inputDef.id);
  select.onclick = toggleDropdown;

  if (inputDef.id === 'companyId') {
    const addCompanyButton = shadowRoot.getElementById('add-company-button');
    addCompanyButton.onclick = () => onCompanyClick();
    const editCompanyButton = shadowRoot.getElementById('edit-company-button');
    editCompanyButton.onclick = () => onCompanyClick(value);
    if (value) {
      editCompanyButton.removeAttribute('disabled');
    } else {
      editCompanyButton.setAttribute('disabled', '');
    }
  }
}

export function refreshImageField(shadowRoot, translationService, inputDef, value, onClick, disabled) {
  const image = shadowRoot.getElementById(`${inputDef.id}-img`);
  if (value && value.length > 0) {
    image.setAttribute('src', value);
    image.removeAttribute('width');
    image.removeAttribute('height');
  } else {
    image.setAttribute('src', '//:0'); // Prevent displaying image with invalid src.
    image.setAttribute('width', 0);
    image.setAttribute('height', 0);
  }

  const uploadButton = shadowRoot.getElementById(`${inputDef.id}-button`);
  uploadButton.textContent = translationService.translate('siteView.changeLogo');
  uploadButton.onclick = onClick;
  if (disabled) {
    uploadButton.setAttribute('disabled', '');
  } else {
    uploadButton.removeAttribute('disabled');
  }
}
