export class ServiceBroker {
  constructor(controlStateManager) {
    window.addEventListener('registerService', this.RegisterService);
    window.addEventListener('requestService', this.ProvideService);
    this.services = {};
    this.csm = controlStateManager;
  }

  RegisterService = (event) => {
    if (!event.detail.name || !event.detail.instance) {
      console.error('Invalid event details when trying to register service!');
      return;
    }
    this.services[event.detail.name] = event.detail.instance;
  }

  RegisterServiceDirectly = (name, instance) => {
    this.services[name] = instance;
  }

  ProvideService = (event) => {
    if (event.detail.name === 'controlState') {
      this.csm.addControlStateClient(event.detail.callback);
      return;
    }
    event.detail.callback(this.services[event.detail.name]);
  }
}
