import AppShell from '../../appShell/appShell';

import '../../theme/theme.css';

import '../confirmation-dialog/confirmation-dialog';
import '../data-select-dialog/data-select-dialog';
import '../collapsible-section/collapsible-section';

import '../chain-view/chain-view';
import '../vehicle-groups-view/vehicle-group-root-view';
import '../users-app/users-app';
import '../sites-app/sites-app';
import '../site-groups-app/site-groups-app';
import '../job-types-app/job-types-app';

const ROUTE_VIEWS = {
  '/vehicleGroups': 'vehicle-group-root-view',
  '/jobTypes': 'job-types-app',
  '/chain': 'chain-view',
  '/sites': 'sites-app',
  '/siteGroups': 'site-groups-app',
  '/users': 'users-app',
};

window.customElements.define('root-app', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.currentRoute = '/reception';
    this._chainId = '';
    this._siteId = '';
    this._sites = [];

    this.appShell = new AppShell(() => {
      this.requestServices(
        [
          ['controlState', this.onControlStateChange],
        ],
      );
      this.createRoot();
      this.addEventListeners();
    });
  }

  set chains(value) {
    this._chains = value;
    this.controlState.proposeChange('chainsChanged', value);
  }

  set sites(value) {
    this._sites = value;
    this.controlState.proposeChange('sitesChanged', value);
  }

  set accessToken(token) {
    this.controlState.proposeChange('accessTokenChanged', token);
  }

  static get observedAttributes() {
    return ['current-route', 'item-id', 'chain-id', 'site-id'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'current-route' && newValue) {
      this.currentRoute = newValue;
      if (this.mainContent) {
        this.changeMainContent(newValue);
      }
      return;
    }
    if (name === 'item-id' && oldValue && !newValue) {
      // Refresh content if returning to list view from detail view (clicking link in TopBar).
      if (this.mainContent) {
        this.changeMainContent(this.currentRoute);
      }
    }
    if (name === 'chain-id' && newValue) {
      this._chainId = newValue;
      if (this.controlState) {
        this.controlState.proposeChange('chainIdChanged', newValue);
      }
    }
    if (name === 'site-id' && newValue) {
      this._siteId = newValue;
      if (this.controlState) {
        this.controlState.proposeChange('siteIdChanged', newValue);
      }
    }
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    this.controlState = controlState;
  }

  createRoot = async () => {
    const appRoot = document.createElement('div');
    appRoot.setAttribute('id', 'root-app');
    appRoot.setAttribute('class', 'app');
    this.root = appRoot;
    this.shadowRoot.appendChild(this.root);
    window.requestAnimationFrame(() => this.initialRender());
  }

  pushRoute = () => {
    const routeName = this.navBarEvent.detail.route;
    this.currentRoute = routeName;
    this.changeMainContent(routeName);
  }

  changeMainContent = (routeName) => {
    this.mainContent.removeChild(this.mainContent.childNodes[0]);
    const newContent = document.createElement(ROUTE_VIEWS[routeName]);
    this.mainContent.appendChild(newContent);
  }

  navBarEventListener = (event) => {
    this.navBarEvent = event;
    window.requestAnimationFrame(() => this.pushRoute());
  }

  //Add event listeners for events dispatched by modules 
  addEventListeners = () => {
    this.addEventListener('navBarEvent', this.navBarEventListener);
  }

  addMainLayout = () => {
    const mainContent = this.mainContent = document.createElement('div');
    this.root.appendChild(mainContent);
  }

  initialRender = () => {
    this.addMainLayout();
    const initialContent = document.createElement(ROUTE_VIEWS[this.currentRoute]);
    this.mainContent.appendChild(initialContent);
  }
});
