import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessage from '../common/ErrorMessage';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function LoginFailedView() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <ErrorMessage message={t('loginFailed')} />
    </div>
  )
}

export default LoginFailedView;