import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest, sendSiteRequest, uploadBlobToAzure } from '../api/api';

export const fetchAttachments = createAsyncThunk('attachments/fetchAttachments', async ({ chainId, siteId, entityType, entityId }, API) => {
  const state = API.getState();
  const query = {
    procName: 'ListDocuments',
    data: {
      chainId,
      siteId,
      entityType,
      entityId,
    },
  };
  return sendRequest(chainId, query, 'POST', state.auth.token);
});

export const uploadAttachment = createAsyncThunk(
  'attachments/uploadAttachment',
  async ({ chainId, siteId, entityId, userId, file, description, entityType }, API) => {
    const state = API.getState();
    const fileNameParts = file.name.split('.');
    const extension = '.' + fileNameParts[fileNameParts.length - 1].toLowerCase();
    const tokenData = await sendSiteRequest(chainId, siteId, 'GET', '/blobDocumentToken', `&extension=${extension}&jobId=${entityId}`, null, state.auth.token);

    await uploadBlobToAzure(tokenData.account, tokenData.container, tokenData.sas, tokenData.blobName, file);

    const queryData = {
      procName: 'InsertDocument',
      data: {
        chainId,
        siteId,
        entityType,
        entityId,
        addedBy: userId,
        guid: tokenData.resourceId,
        name: file.name,
        extension: tokenData.extension,
        description,
        contentLocation: tokenData.resourceUri,
      },
    };
    return sendRequest(chainId, queryData, 'POST', state.auth.token);
  });

export const deleteAttachment = createAsyncThunk('attachments/deleteAttachment', 
  async ({ chainId, siteId, jobId, documentId }, API) => {
    const state = API.getState();
    await sendSiteRequest(chainId, siteId, 'DELETE', `/job/${jobId}/document/${documentId}`, '', null, state.auth.token);
    return documentId;
});

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: {
    attachments: [],
    isFetching: false,
  },
  extraReducers: {
    [fetchAttachments.fulfilled]: (state, { payload }) => {
      state.attachments = payload;
      state.isFetching = false;
    },
    [fetchAttachments.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchAttachments.rejected]: (state) => {
      state.isFetching = false;
    },
    [uploadAttachment.fulfilled]: (state, { payload }) => {
      state.attachments.push(payload);
    },
    [deleteAttachment.fulfilled]: (state, { payload: attachmentId }) => {
      state.attachments = state.attachments.filter(att => att.id !== attachmentId);
    },
  },
});

export default attachmentsSlice.reducer;
