import css from './job-types-css';
import './job-types-view';
import './job-type-detail-view';

window.customElements.define('job-types-app', class extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({ mode: 'open' });
      this._state = {};
      this._state.views = {};
      this.addEventListener('jobTypeNavigationEvent', this.onJobTypeNavigationEvent);
      this.build();
    }

  build = async () => {
    this._state.views.job_types_view = document.createElement('job-types-view');
    this._state.views.job_type_detail_view = document.createElement('job-type-detail-view');
    this.shadowRoot.innerHTML = '<style type="text/css" media="screen">' + css + '</style><div id="job-type-root-view" class="root-container"/>';
    this.root = this.shadowRoot.getElementById('job-type-root-view');
    this.root.appendChild(this._state.views['job_types_view'])
  }

  switchView = (view) => {
    this.root.removeChild(this.root.children[0]);
    this.root.appendChild(this._state.views[view]);
  }

  onJobTypeNavigationEvent = (event) => {
    let route = '/jobTypes';
    if (event.detail.route === 'newJobType') {
      route = '/jobTypes/new';
    } else if (event.detail.route === 'jobTypeDetail') {
      route = `/jobTypes/${event.detail.jobTypeId}`;
    }
    //Navigation event to React root.
    const navigationEvent = new CustomEvent('navigationEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route,
      },
    });
    window.dispatchEvent(navigationEvent);
    
    if(event.detail.route === 'newJobType') {
      this._state.views['job_type_detail_view'].isNew = true;
      this.switchView('job_type_detail_view');
    } else if(event.detail.route === 'jobTypeDetail') {
      this._state.views['job_type_detail_view'].jobTypeId = event.detail.jobTypeId;
      this.switchView('job_type_detail_view');
    } else if(event.detail.route === 'jobTypes') {
      this.switchView('job_types_view');
    }
  }
});