import { ChainUserStore } from './chainUserStore.js';
import { sendNotificationEvent } from '../../appShell/notificationHelpers';
import css from './users-list-view-css';
import searchImage from '../../assets/icons/search.svg';
import addImage from '../../assets/icons/add_white.svg';

const NAME_COLUMN = 'name';
const STATE_COLUMN = 'state';
const COLUMN_IDS = [NAME_COLUMN, 'phoneNumber', 'email', STATE_COLUMN];
const ORDER_BY = NAME_COLUMN;
const ORDER_DIRECTION = 'asc';

class UsersListView extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.translationService = { translate: (word) => { return word; } };
    this.controlState = {};

    // Element store & state.
    this.store = new ChainUserStore(this.translationService);
    this._state = {
      data: [],
      sortBy: ORDER_BY,
      sortDirection: ORDER_DIRECTION,
      searchText: ''
    };

    // Request services.
    this.requestServices(
      [
        ['translationService', this.setTranslationService],
        ['controlState', this.onControlStateChange],
      ]
    )

    // Build custom element.
    this.build();
  }

  // Requests needed services.
  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      // Using window.dispatchEvent, because this element is not added to the DOM still.
      window.dispatchEvent(requestServiceEvent);
    });
  }

  // Sets translation service.
  setTranslationService = (instance) => {
    this.translationService = instance
    this.store.setTranslationService(this.translationService);
  }

  // Listeners for control state changes.
  onControlStateChange = (controlState) => {
    this.controlState = controlState;
    const {lastAction, chainId, user} = controlState;
    if(['chainIdChanged', 'userChanged', 'languageChanged'].includes(lastAction)) {
      if(chainId && user) {
        this.refreshData();
      }
    }
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
  }

  // Life cycle callback, invoked when the custom element is first connected to the document's DOM.
  connectedCallback() {
    // Refresh.
    this.refreshData();

    // Listen for table events.
    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
    this.addEventListener('dataTableRowClick', this.goToUserDetails);
  }

  // Free store.
  disconnectedCallback() {
    this.store.clearData();
  }

  // Row clicks.
  dataTableRowClick = (event) => this.goToUserDetails(event.detail.data);

  // Handles data table events.
  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
    this.refreshData();
  }

  // new user.
  newUser = () => this.goToUserDetails({});

  // Go to details / new page.
  goToUserDetails = (event) => {
    const navEvent = new CustomEvent('usersNavEvent', {
      bubbles: true,
      composed: true,
      detail: {
        route: '/users-detail',
        data: event.detail ? event.detail.data : {}
      },
    });
    this.root.dispatchEvent(navEvent);
  }

  build = async () => {
    this.shadow.innerHTML = '<style type="text/css" media="screen">' + css + '</style>';
    this.addRoot();
  }

  addRoot = () => {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'users-view');
    this.root.setAttribute('class', 'main-container');
    window.requestAnimationFrame(() => this.initialRender());
  }

  initialRender = () => {
    this.toolbar = this.createToolbar();
    this.root.appendChild(this.toolbar);
    this.dataTable = document.createElement('data-table');
    this.root.appendChild(this.dataTable);
    this.shadow.appendChild(this.root);
    this.refreshData();
  }

  createToolbar = () => {
    const toolbar = document.createElement('div');
    toolbar.setAttribute('class', 'toolbar');

    // Title.
    const title = document.createElement('div')
    title.setAttribute('class', 'title');
    title.appendChild(document.createTextNode(this.translationService.translate('usersView.title')));
    toolbar.appendChild(title);

    // Actions.
    const actions = document.createElement('div');
    actions.setAttribute('class', 'actions');
    toolbar.appendChild(actions);

    // Add actions.
    this.addSearchInput(actions);
    this.addNewUserButton(actions);

    // Toolbar element.
    return toolbar;
  }

  addSearchInput = (parent) => {
    const filterContainer = document.createElement('div');
    filterContainer.setAttribute('class', 'filter-container');
    parent.appendChild(filterContainer);
    const filterIcon = document.createElement('img');
    filterIcon.setAttribute('src', searchImage);
    filterContainer.appendChild(filterIcon);
    const filterInput = document.createElement('input');
    filterInput.setAttribute('placeholder', this.translationService.translate('usersView.search'));
    filterInput.oninput=(e) => this.doSearch(e, filterInput);
    filterContainer.appendChild(filterInput);
    parent.appendChild(filterContainer);
  }

  addNewUserButton = (parent) => {
    const addButton = document.createElement('button');
    addButton.setAttribute('class', 'button');
    addButton.onclick = () => this.newUser();
    const addIcon = document.createElement('img');
    addIcon.setAttribute('src', addImage);
    addButton.appendChild(addIcon);
    const buttonText = document.createElement('div');
    buttonText.textContent = this.translationService.translate('usersView.new');
    addButton.appendChild(buttonText);
    parent.appendChild(addButton);
  }

  // Search user, with the given text.
  doSearch = (e, elem) => {
    // Search only when there are three or more characters.
    const searchText = elem.value || '';
    this._state.searchText = searchText.toLowerCase();
    this.refreshData();
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      const order = this._orderBy === columnId ? this._order : '';
      return {
        id: columnId,
        text: this.translationService.translate(`usersView.${columnId}`),
        sortable: columnId === NAME_COLUMN || columnId === STATE_COLUMN,
        sortDirection: order,
        primary: columnId === NAME_COLUMN,
      };
    });
  }

  refreshData = async () => {
    const { chainId, user } = this.controlState;
    if (!chainId || !user) {
      console.warn(`UsersListView.refreshData: chainId: ${chainId}, user: `, user);
      return;
    }
    const { sortBy, sortDirection, searchText } = this._state;
    try {
      const data = await this.store.getChainUsers(chainId, sortBy, sortDirection, searchText);
      this._state.data = data;
      this.dataTable.dataAndLayout = {
        data: this._state.data,
        columns: this.getTableColumnDefinitions(),
        sortBy: sortBy,
        sortDirection: sortDirection,
      };
    } catch(error) {
      sendNotificationEvent(this.translationService.translate('usersView.fetchFailed'), 'error');
    }
  }
}
window.customElements.define('users-list-view', UsersListView);