import layout from './layout-html';

class DataSelectDialog extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({mode: 'open'});
    this._state = {
      isBuilt: false
    };
  }

  //#region callbacks
  static get observedAttributes() {
    return ['title', 'cancel', 'ok', 'active'];
  }

  connectedCallback() {
    if(!this._state.isBuilt) {
      this.build();
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this._state[name] = newValue;
    this.refresh();
  }
  //#endregion callbacks

  triggerEvent = (flag) => {
      // Emit custom event.
      const event = new CustomEvent('dDataSelect', {
        bubbles: true,
        composed: true,
        detail: {flag},
      });
      this.shadowRoot.dispatchEvent(event);
  }

  build = async () => {
    this.shadowRoot.innerHTML = layout;
    this.titleElem = this.shadowRoot.querySelector('.title');
    this.contentElem = this.shadowRoot.querySelector('.form-container');
    this.cancel = this.shadowRoot.getElementById('cancel');
    this.ok = this.shadowRoot.getElementById('ok');
    this.ok.addEventListener('click', () => this.triggerEvent(true));
    this.cancel.addEventListener('click', () => this.triggerEvent(false));
    this.refresh();
  }

  refresh = () => {
    if(!this.titleElem) {
      return;
    }
    this.titleElem.textContent = this._state.title;
    this.cancel.textContent = this._state.cancel;
    this.ok.textContent = this._state.ok;
    if(this._state.active === 'true') {
      this.ok.removeAttribute('disabled');
    } else {
      this.ok.setAttribute('disabled', '');
    }
  }
}

window.customElements.define('data-select-dialog', DataSelectDialog);
