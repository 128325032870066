import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, IconButton, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import { 
  updateQualityControlNotice, 
  deleteQualityControlNotice, 
} from '../../../reducers/qualityControlNoticesReducer';
import ConfirmationDialog from '../../common/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  noticesList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  noticeItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  noticeContent: {
    width: '100%',
  },
  date: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  description: {
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  editForm: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  editButtons: {
    marginTop: theme.spacing(1),
  },
}));

function InspectionNoticesList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [editingNotice, setEditingNotice] = useState(null);
  const [editedDescription, setEditedDescription] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [noticeToDelete, setNoticeToDelete] = useState(null);
  
  const notices = useSelector(state => state.qualityControlNotices.notices);
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);

  const sortedNotices = [...notices].sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));

  const handleEditClick = (notice) => {
    setEditingNotice(notice);
    setEditedDescription(notice.description);
  };

  const handleCancelEdit = () => {
    setEditingNotice(null);
    setEditedDescription('');
  };

  const handleSaveEdit = () => {
    if (editingNotice) {
      const payload = {
        id: editingNotice.id,
        chainId: selectedChainId,
        siteId: selectedSiteId,
        description: editedDescription,
      };
      dispatch(updateQualityControlNotice({ 
        chainId: selectedChainId, 
        siteId: selectedSiteId, 
        payload
      }));
      setEditingNotice(null);
      setEditedDescription('');
    }
  };

  const handleDeleteClick = (notice) => {
    setNoticeToDelete(notice);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    if (noticeToDelete) {
      dispatch(deleteQualityControlNotice({ 
        chainId: selectedChainId, 
        siteId: selectedSiteId, 
        noticeId: noticeToDelete.id,
        jobId: noticeToDelete.jobId,
      }));
      setDeleteConfirmOpen(false);
      setNoticeToDelete(null);
    }
  };

  return (
    <>
      <div className={classes.noticesList}>
        {sortedNotices.map((notice) => (
          <div key={notice.id} className={classes.noticeItem}>
            <div className={classes.noticeContent}>
              <Typography variant="subtitle2" className={classes.date}>
                {dayjs(notice.createdAt).format('DD.MM.YYYY')} {notice.createdByName}
              </Typography>
              {editingNotice?.id === notice.id ? (
                <form className={classes.editForm}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    value={editedDescription}
                    onChange={(e) => setEditedDescription(e.target.value)}
                  />
                  <div className={classes.editButtons}>
                    <Button onClick={handleSaveEdit} color="primary">
                      Save
                    </Button>
                    <Button onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </div>
                </form>
              ) : (
                <Typography variant="body1" className={classes.description}>
                  {notice.description}
                </Typography>
              )}
            </div>
            {editingNotice?.id !== notice.id && (
              <div className={classes.actionButtons}>
                <IconButton color="primary" size="small" onClick={() => handleEditClick(notice)}>
                  <EditIcon />
                </IconButton>
                <IconButton color="primary" size="small" onClick={() => handleDeleteClick(notice)} className={classes.actionButton}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      </div>
      <ConfirmationDialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onAccept={handleConfirmDelete}
        acceptText={t('delete')}
        text={t('qualityControlView.deleteNoticeConfirmation')}
      />
    </>
  );
}

export default InspectionNoticesList;
