import { createTheme, alpha } from '@material-ui/core/styles';

const PRIMARY_COLOR = '#6200EE';
const SECONDARY_COLOR = '#757575';
const SELECTED_OPACITY = 0.08;

const theme = createTheme({
  typography: {
    fontFamily: [
      'Jost',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: {
      main: '#B71634',
    },
    primaryLight: {
      main: 'rgba(137,62,255,0.1)',
    },
    green: {
      main: '#5EBC00',
    },
    orange: {
      main: '#FF7000',
    },
    borderGrey: {
      main: 'rgba(0,0,0,0.2)',
    },
    textDarkGrey: {
      main: 'rgba(0,0,0,0.8)',
    },
    backgroundWhite: {
      main: '#FAFAFA',
    },
    white: {
      main: '#FFFFFF',
    },
    black: {
      main: '#000000',
    },
    fault: {
      recommendation: '#A6A6A6',
      rejected: '#FFAF80',
      immobilized: '#FF8580',
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        color: 'rgba(0,0,0,0.87)',
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: SECONDARY_COLOR,
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Jost, sans-serif',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Jost, sans-serif',
        letterSpacing: 0.3,
      },
      containedPrimary: {
        '&$disabled': {
          backgroundColor: PRIMARY_COLOR,
          color: 'white',
          opacity: 0.5,
        },
        '&:hover': {
          '&$disabled': {
            backgroundColor: PRIMARY_COLOR,
            color: 'white',
            opacity: 0.5,
          },
        },
      },
      outlinedPrimary: {
        '&$disabled': {
          borderColor: PRIMARY_COLOR,
          color: PRIMARY_COLOR,
          opacity: 0.5,
        },
      },
    },
    MuiDialog: {
      root: {
        fontFamily: 'Jost, sans-serif',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: alpha(PRIMARY_COLOR, SELECTED_OPACITY),
        },
      },
    },
  },
});

export default theme;