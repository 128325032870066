const sendRequest = (chainId, procName, data, accessToken) => {
  const body = {
    procName,
    data,
  };
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/chain/${chainId}/DorisCRUD?code=${process.env.REACT_APP_API_CODE}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [process.env.REACT_APP_AUTH_HEADER_NAME]: 'Bearer ' + accessToken
    },
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Request failed:', response.statusText);
    }
    return response.json();
  });
}

/**
 * Fetch chain information from API.
 * @param {number} chainId - ID of the chain.
 */
export const getChain = (chainId, accessToken) => {
  const reduxEvent = new CustomEvent('reduxEvent', {
    bubbles: true,
    composed: true,
    detail: {
      action: 'fetchChains',
    },
  });
  window.dispatchEvent(reduxEvent);

  return sendRequest(chainId, 'GetChain', { chainId }, accessToken);
}

/**
 * Update chain with given data in API.
 * @param {number} chainId
 * @param {object} data
 */
export const updateChain = async (data, accessToken) => {
  const result = await sendRequest(data.chainId, 'UpdateChain', data, accessToken);

  const reduxEvent = new CustomEvent('reduxEvent', {
    bubbles: true,
    composed: true,
    detail: {
      action: 'fetchChains',
    },
  });
  window.dispatchEvent(reduxEvent);

  return result;
}
