import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  select: {
    height: 28,
    fontSize: 14,
    lineHeight: '20px',
    backgroundColor: '#FFFFFF',
  },
  selectRoot: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

function SmallSelectField({ value, onChange, options }) {
  const classes = useStyles();
  return (
    <Select
      value={value}
      onChange={onChange}
      className={classes.select}
      classes={{ root: classes.selectRoot }}
      variant='outlined'
      fullWidth
    >
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
}

SmallSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
  })),
}

export default SmallSelectField;