import layout from './settings-data-table-layout-html';
import arrowDownImage from '../../../assets/icons/arrow_down.svg';
import arrowLeftImage from '../../../assets/icons/arrow_left.svg';
import arrowRightImage from '../../../assets/icons/arrow_right.svg'

const ALL = 'all';
const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 50, ALL];
const ASC = 'asc';
const DESC = 'desc';

class SettingsDataTable extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({mode: 'open'});

    this._state = {
      columns: [],
      data: [],
      originalSortBy: '',
      sortBy: '',
      originalSortDirection: ASC,
      sortDirection: ASC,
      selectedIds: [],
      removable: false,
      paginated: false,
      selectable: false,
      totalItemCount: 0,
      pagination: {
        index: 0,
        itemsPerPage: ITEMS_PER_PAGE_OPTIONS[0],
      },
      isBuilt: false,
    };
  }

  //#region callbacks
  static get observedAttributes() {
    return ['title', 'cancel', 'ok', 'active'];
  }

  connectedCallback() {
    if(!this._state.isBuilt) {
      this.build();
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this._state[name] = newValue;
    this.refresh();
  }
  //#endregion callbacks

  triggerEvent = (flag) => {
      // Emit custom event.
      const event = new CustomEvent('dDataSelect', {
        bubbles: true,
        composed: true,
        detail: {flag},
      });
      this.shadowRoot.dispatchEvent(event);
  }

  build = async () => {
    this.shadowRoot.innerHTML = layout;
    this.root = this.shadowRoot.getElementById('table-container');
    this.tableHeadRow = this.shadowRoot.getElementById('table-head-row');
    this.tableBody = this.shadowRoot.getElementById('table-body');
    this.paginationContainer = this.shadowRoot.getElementById('pagination-container');
    const itemsPerPageImage = this.shadowRoot.getElementById('items-per-page-select-image');
    itemsPerPageImage.src = arrowDownImage;
    const prevImage = this.shadowRoot.getElementById('prev-image');
    prevImage.src = arrowLeftImage;
    const nextImage = this.shadowRoot.getElementById('next-image');
    nextImage.src = arrowRightImage;
    window.requestAnimationFrame(() => this.render());
  }

  set dataAndLayout(val) {
    this._state = {
      ...this._state,
      data: val.data,
      paginated: Boolean(val.paginated),
      columns: val.columns,
      removable: Boolean(val.removable),
      originalSortBy: val.sortBy || this._state.sortBy,
      sortBy: val.sortBy || this._state.sortBy,
      originalSortDirection: val.sortDirection || this._state.sortDirection,
      sortDirection: val.sortDirection || this._state.sortDirection,
      selectedIds: val.selectedIds || [],
      selectable: val.selectable || false,
    };
    window.requestAnimationFrame(() => this.render());
  }

  render() {
    if (!this.root) {
      return;
    }

    const data = this._state.data;
    if (!Array.isArray(data)) {
      return;
    }

    this.tableHeadRow.querySelectorAll('*').forEach(n => n.remove());
    this.tableBody.querySelectorAll('*').forEach(n => n.remove());

    this.renderHeader();
    this.renderBody();
    this.renderPagination();
  }

  renderHeader = () => {
    // List given columns.
    this._state.columns.forEach((columnDef) => {
      if (columnDef.invisible) {
        return;
      }
      const headCell = document.createElement('th');
      headCell.setAttribute('scope', 'col');
      this.tableHeadRow.appendChild(headCell);

      const headCellContent = document.createElement('div');
      headCell.appendChild(headCellContent);

      const text = document.createElement('div');
      text.textContent = columnDef.text;
      headCellContent.appendChild(text);

      if (columnDef.align === 'right') {
        headCellContent.setAttribute('class', 'align-right');
      }
      // Add sorting icon.
      if (columnDef.sortable) {
        const icon = document.createElement('img');
        headCellContent.appendChild(icon);
        headCellContent.setAttribute('class', 'sortable');
        headCellContent.onclick = () => this.updateSorting(columnDef.id);
        if (this._state.sortBy === columnDef.id) {
          if (this._state.sortDirection === ASC) {
            icon.setAttribute('src', './assets/icons/arrow_up.svg');
          } else {
            icon.setAttribute('src', './assets/icons/arrow_down.svg');
          }
        } else {
          icon.setAttribute('src', './assets/icons/sort.svg');
        }
      }
    });
  }

  dispatchTableEvent = (event) => {
    const tableEvent = new CustomEvent('dDataTableInputEvent', {
      bubbles: true,
      composed: true,
      detail: {
        siteId: Number(event.target.id),
        field: event.target.name,
        value: event.target.value
      },
    });
    this.shadowRoot.dispatchEvent(tableEvent);
  }

  addCell = (cell, columnDef, rowData) => {
    if(columnDef.id === 'atjUsername') {
      const input = document.createElement('input');
      input.setAttribute('id', rowData.id);
      input.setAttribute('name', 'atjUsername');
      input.setAttribute('maxlength', '50');
      input.setAttribute('value', rowData.atjUsername || '');
      input.addEventListener('input', (event) => this.dispatchTableEvent(event));
      cell.appendChild(input);
      return;
    }
    if(columnDef.id === 'commissionCoefficient') {
      const input = document.createElement('input');
      input.setAttribute('id', rowData.id);
      input.setAttribute('type', 'number');
      input.setAttribute('name', 'commissionCoefficient');
      input.setAttribute('maxlength', '50');
      input.setAttribute('value', rowData.commissionCoefficient || '');
      input.addEventListener('input', (event) => this.dispatchTableEvent(event));
      cell.appendChild(input);
      return;
    }
    cell.textContent = rowData[columnDef.id];
  }

  renderBody = () => {
    this._state.data.forEach((rowData) => {
      const tableRow = document.createElement('tr');
      this.tableBody.appendChild(tableRow);
      const rowSelected = this._state.selectedIds.includes(rowData.id);
      if (!rowData.disabled && rowSelected) {
        tableRow.setAttribute('class', 'selected');
      }

      this._state.columns.forEach(columnDef => {
        if (columnDef.invisible) {
          return;
        }
        const cell = document.createElement('td');
        this.addCell(cell, columnDef, rowData);
        tableRow.appendChild(cell);
        if (columnDef.align === 'right') {
          cell.setAttribute('class', 'align-right');
        }
        if (columnDef.primary) {
          cell.setAttribute('primary-column', '');
        }
      });
    });
  }

  renderPagination = () => {
    if (!this._state.paginated) {
      this.paginationContainer.setAttribute('hidden', '');
      return;
    }
    this.paginationContainer.removeAttribute('hidden');

    const { index, itemsPerPage } = this._state.pagination;
    const { totalItemCount, data } = this._state;

    const itemsPerPageText = this.shadowRoot.getElementById('items-per-page-text');
    itemsPerPageText.textContent = 'Rivejä sivulla:';

    const itemsPerPageDropdownBackdrop = this.shadowRoot.getElementById('items-per-page-dropdown-backdrop');
    itemsPerPageDropdownBackdrop.onclick = (event) => this.handleBackdropClick(event, () => this.toggleDropdown(itemsPerPageSelectDropdown, itemsPerPageDropdownBackdrop));

    const itemsPerPageSelectText = this.shadowRoot.getElementById('items-per-page-select-text');
    itemsPerPageSelectText.textContent = itemsPerPage === ALL ? 'Kaikki' : itemsPerPage;

    const itemsPerPageSelect = this.shadowRoot.getElementById('items-per-page-select');
    itemsPerPageSelect.onclick = () => this.toggleDropdown(itemsPerPageSelectDropdown, itemsPerPageDropdownBackdrop);

    const itemsPerPageSelectDropdown = this.shadowRoot.getElementById('items-per-page-dropdown');
    if (itemsPerPageSelectDropdown.childNodes.length === 0) {
      ITEMS_PER_PAGE_OPTIONS.forEach((choiceDef) => {
        const choice = document.createElement('div');
        choice.textContent = choiceDef === ALL ? 'Kaikki' : choiceDef;
        choice.onclick = () => this.setPaginationValue('itemsPerPage', choiceDef);
        itemsPerPageSelectDropdown.appendChild(choice);
      });
    }

    const pageContainer = this.shadowRoot.getElementById('page-container');
    const displayedItemsStart = itemsPerPage === ALL
      ? 1
      : index * itemsPerPage + 1;
    const displayedItemsEnd = itemsPerPage === ALL
      ? data.length
      : index * itemsPerPage + data.length;
    pageContainer.textContent = `${displayedItemsStart}-${displayedItemsEnd} / ${totalItemCount}`;

    const prevButton = this.shadowRoot.getElementById('prev-button');
    prevButton.onclick = () => this.setPaginationValue('index', index - 1);
    if (itemsPerPage === ALL || index < 1) {
      prevButton.setAttribute('disabled', '');
    } else {
      prevButton.removeAttribute('disabled');
    }
    const nextButton = this.shadowRoot.getElementById('next-button');
    nextButton.onclick = () => this.setPaginationValue('index', index + 1);
    if (itemsPerPage === ALL || totalItemCount <= index * itemsPerPage + data.length) {
      nextButton.setAttribute('disabled', '');
    } else {
      nextButton.removeAttribute('disabled');
    }
  }

  updateSorting = (columnId) => {
    if (this._state.sortBy === columnId) {
      this._state.sortDirection = this._state.sortDirection === DESC ? ASC : DESC;
    } else {
      this._state.sortBy = columnId;
      this._state.sortDirection = ASC;
    }
    this.dispatchTableEvent();
  }
}

window.customElements.define('settings-data-table', SettingsDataTable);
