export function sendNotificationEvent(message, type = '') {
  const notificationEvent = new CustomEvent('reduxEvent', {
    bubbles: true,
    composed: true,
    detail: {
      action: 'setNotification',
      data: { message, type },
    },
  });
  window.dispatchEvent(notificationEvent);
}
