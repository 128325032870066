export default `
<div id="settings-section">
  <div id="form-container" class="form-container">
    <div class="form-column">
      <div id="commissions-title" class="secondary-title"></div>

      <div class="commissions-container">
        <div>
          <div id="weekdayProvision-label" class="input-label"></div>
          <input id="weekdayProvision" class="small" />
        </div>
        <div>
          <div id="saturdayProvision-label" class="input-label"></div>
          <input id="saturdayProvision" class="small" />
        </div>
        <div>
          <div id="sundayProvision-label" class="input-label"></div>
          <input id="sundayProvision" class="small" />
        </div>
      </div>

      <div class="reductions-container">
        <div>
          <div id="provisionReduction-label" class="input-label"></div>
          <input id="provisionReduction" class="small" />
        </div>
        <div>
          <div id="provisionReductionSaturday-label" class="input-label"></div>
          <input id="provisionReductionSaturday" class="small" />
        </div>
      </div>

    </div>

    <div class="form-column">
      <div id="other-settings-title" class="secondary-title"></div>

      <div>
        <div id="afterInspectionPrice-label" class="input-label"></div>
        <input id="afterInspectionPrice" class="small" />
      </div>

      <div>
        <div id="additionalInformation-label" class="input-label"></div>
        <input id="additionalInformation" />
      </div>

      <div>
        <input type="checkbox" id="enableSitePrint" class="small" />
        <label for="enableSitePrint" id="enableSitePrint-label" class="input-label"></label>
        <label hidden id="sitePrintEnabledOnChain">enabled</label>
      </div>

      <div>
        <input type="checkbox" id="useSitePrint" class="small" />
        <label for="useSitePrint" id="useSitePrint-label" class="input-label"></label>
        <label hidden id="sitePrintInUseOnChain">in use</label>
      </div>

      <div>
        <input type="checkbox" id="useHaynesData" class="small" />
        <label for="useHaynesData" id="useHaynesData-label" class="input-label"></label>
      </div>

      <div>
        <input type="checkbox" id="enableConditionInspection" class="small" />
        <label for="enableConditionInspection" id="enableConditionInspection-label" class="input-label"></label>
      </div>
      </div>
  </div>
</div>
`;
