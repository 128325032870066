import { ChainCompanyStore } from './chainCompanyStore.js';
import '../data-table/data-table';

const NAME = 'name';
const BUSINESS_IDENTIFIER = 'businessIdentifier';
const SOKU_IDENTIFIER = 'sokuIdentifier';
const SITE_COUNT = 'siteCount';
const COLUMN_IDS = [NAME, BUSINESS_IDENTIFIER, SOKU_IDENTIFIER, SITE_COUNT];

window.customElements.define('chain-companies-section', class extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.translationService = { translate: word => word };
    this.controlState = {
      language: window.preferredLanguage || 'fi-FI',
      chainId: null,
    };
    this.store = new ChainCompanyStore();
    this._state = {
      data: [],
      sortBy: NAME,
      sortDirection: 'asc',
    };

    this.requestServices(
      [
        ['translationService', (instance) => this.translationService = instance],
        ['controlState', this.onControlStateChange],
      ],
    );
    this.build();

    this.addEventListener('dataTableEvent', this.handleDataTableEvent);
  }

  disconnectedCallback() {
    this.store.clearData();
  }

  requestServices = (services) => {
    services.forEach(serviceDef => {
      const requestServiceEvent = new CustomEvent('requestService', {
        bubbles: true,
        composed: true,
        detail: {
          name: serviceDef[0],
          callback: serviceDef[1],
        },
      });
      window.dispatchEvent(requestServiceEvent);
    });
  }

  onControlStateChange = (controlState) => {
    const oldChainId = this.controlState.chainId;
    this.controlState = controlState;
    if (controlState.accessToken) {
      this.store.accessToken = controlState.accessToken;
    }
    if (!oldChainId && controlState.chainId) {
      this.refreshData();
    }
    if (controlState.lastAction === 'languageChanged' || controlState.lastAction === 'chainIdChanged') {
      this.refreshData();
    }
  }

  handleDataTableEvent = (event) => {
    this._state.sortDirection = event.detail.sortDirection;
    this._state.sortBy = event.detail.sortBy;
    this.refreshData();
  }

  build() {
    this.addRoot();
    this.dataTable = document.createElement('data-table');
    window.requestAnimationFrame(() => { this.render() });
  }

  addRoot = () => {
    const root = document.createElement('div');
    root.setAttribute('id', 'chain-companies-section');
    this.root = root;
    this.shadowRoot.appendChild(root);
  }

  getTableColumnDefinitions = () => {
    return COLUMN_IDS.map((columnId) => {
      return {
        id: columnId,
        text: this.translationService.translate(`chainView.${columnId}`),
        sortable: true,
      };
    });
  }

  render = async () => {
    this.root.appendChild(this.dataTable);
    this.refreshData();
  }

  refreshData = async () => {
    if (!this.controlState.chainId) {
      return;
    }
    const { sortBy, sortDirection } = this._state;
    const data = await this.store.getCompanyData(this.translationService, this.controlState.chainId, sortBy, sortDirection);
    this.dataTable.dataAndLayout = {
      data,
      columns: this.getTableColumnDefinitions(),
      sortBy:sortBy,
      sortDirection: sortDirection,
    };
  }
});
